import {
  createStore,
  applyMiddleware,
  combineReducers,
  Reducer,
  Middleware
} from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

import { authReducer, IAuthState } from './modules/auth/reducers/auth.reducer';
import { commonReducer, ICommonState } from './common/reducers';
import { registerReducer, IRegisterState } from './modules/register/reducers';
import { aboutUsReducer, IAboutUsState } from './modules/about_us/reducers';
import {
  manifestHistoryReducer,
  IManifestHistoryState
} from './modules/manifest_history/reducers';
import {
  termsAndConditionsReducer,
  ITermsAndConditionsState
} from './modules/tramsAndConditions/reducers';
import { legalsReducer, ILegalsState } from './modules/legal/reducers';
import {
  supportAndFeedbackReducer,
  ISupportAndFeedbackState
} from './modules/supportsAndFeedback/reducers';
import {
  IForgotPasswordState,
  forgotPasswordReducer
} from './modules/forgotPassword/reducers';
import {
  IDashboardState,
  dashboardReducer
} from './modules/dashboard/reducers';
import { ILicensesState, licensesReducer } from './modules/license/reducers';
import { IReportsState, reportsReducer } from './modules/reports/reducers';
import {
  IWarehouseTruckState,
  warehouseTruckReducer
} from './modules/warehouseTruckRegistration/reducers';
import {
  ICurrentManifestState,
  currentManifestReducer
} from './modules/currentManifest/reducers';

export interface IAppState {
  auth: IAuthState;
  register: IRegisterState;
  aboutUs: IAboutUsState;
  common: ICommonState;
  termsAndConditions: ITermsAndConditionsState;
  legals: ILegalsState;
  supportAndFeedback: ISupportAndFeedbackState;
  forgotPassword: IForgotPasswordState;
  dashboard: IDashboardState;
  license: ILicensesState;
  reports: IReportsState;
  warehouseTruck: IWarehouseTruckState;
  manifestHistory: IManifestHistoryState;
  currentManifest: ICurrentManifestState;
}

const reducer: Reducer<IAppState> = combineReducers({
  auth: authReducer,
  register: registerReducer,
  aboutUs: aboutUsReducer,
  common: commonReducer,
  termsAndConditions: termsAndConditionsReducer,
  legals: legalsReducer,
  supportAndFeedback: supportAndFeedbackReducer,
  forgotPassword: forgotPasswordReducer,
  dashboard: dashboardReducer,
  license: licensesReducer,
  reports: reportsReducer,
  warehouseTruck: warehouseTruckReducer,
  manifestHistory: manifestHistoryReducer,
  currentManifest: currentManifestReducer
});

const middlewares: Middleware<unknown>[] = [];
middlewares.push(thunk);
if (process.env.NODE_ENV !== 'production') {
  middlewares.push(logger);
}

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);
export { store };
