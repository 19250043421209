/* istanbul ignore file */
import React, { FunctionComponent, useState } from 'react';
import { Box, Text } from '@chakra-ui/core';

import TruckIcon from '../../../assets/images/truck.png';
import { User, USER_ROLE } from '../../../common/models/user';
import {
  APPROVE_STATUS_TYPE,
  CATEGORY_BELONG_TYPE,
  ICurrentManifest,
  IProduct
} from '../models/currentManifest';
import { ConfirmAlert } from '../../../common/components';
import {
  dateFormatForForm,
  getMessage,
  isAdmin,
  signageDisplayClassDivision,
  timeFormat,
  upperCaseFirstLetter
} from '../../../helper';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../../constant';
import { unloadProduct, unloadAllProducts } from '../actions';
import { ClassDivisionImage } from '../../../common/components/helpers/class_division_image.component';
import Stop from '../../../assets/images/stop.png';
import DangerousGood from '../../../assets/images/dangerous_goods.png';
import EmergencyIndicator from './indicator.component';

export interface IProps {
  toastManager: any;
  t: any;
  user: User;
  operator_id?: string;
  currentManifest: ICurrentManifest;
  unloadProduct: typeof unloadProduct;
  unloadAllProducts: typeof unloadAllProducts;
}

export const CurrentManifest: FunctionComponent<IProps> = (props: IProps) => {
  const {
    currentManifest,
    toastManager,
    user,
    operator_id,
    unloadProduct,
    unloadAllProducts
  } = props;
  const [productForUnload, setProductForUnload] = useState<IProduct | null>(
    null
  );
  const [isUnloadAll, setIsUnloadAll] = useState(false);
  const _unloadProduct = async (product: IProduct) => {
    try {
      await unloadProduct(Number(currentManifest.report_id), product.id);
      toastManager.add(getMessage(SUCCESS_MESSAGE.UNLOAD_PRODUCT), {
        appearance: 'success',
        autoDismiss: true
      });
    } catch (err) {
      console.log(':: _unloadProduct err ', err);
      toastManager.add(getMessage(ERROR_MESSAGE.UNLOAD_PRODUCT), {
        appearance: 'error',
        autoDismiss: true
      });
    } finally {
      setProductForUnload(null);
    }
  };

  const _unloadAllProducts = async () => {
    try {
      await unloadAllProducts(Number(currentManifest.report_id));
      toastManager.add(getMessage(SUCCESS_MESSAGE.UNLOAD_PRODUCT), {
        appearance: 'success',
        autoDismiss: true
      });
    } catch (err) {
      console.log(':: _unloadAllProducts err ', err);
      toastManager.add(getMessage(ERROR_MESSAGE.UNLOAD_PRODUCT), {
        appearance: 'error',
        autoDismiss: true
      });
    } finally {
      setIsUnloadAll(false);
    }
  };
  const getTotalQuantity = () => {
    let quantity = 0;
    currentManifest.product.forEach((datum) => {
      if (datum.quantity) {
        quantity = quantity + Number(datum.quantity);
      }
    });
    return quantity;
  };
  const getMeasurement = () => {
    const measurement: string[] = [];
    currentManifest.product.forEach((datum) => {
      if (
        datum.quantity &&
        datum.mesurement &&
        !measurement.includes(datum.mesurement)
      ) {
        measurement.push(datum.mesurement);
      }
    });
    return measurement.join(' / ');
  };
  return (
    <Box>
      {currentManifest.packagingData && currentManifest.product.length ? (
        <>
          <Box className="mt-5 ab-bx-3 p-3" style={{ marginBottom: '0px' }}>
            <Box>
              <Text textAlign="center" fontWeight="bold">
                PLACARDS TO DISPLAY (ALL 4 SIDES OF VEHICLE)
              </Text>
            </Box>
          </Box>
          <Box>
            <Box>
              {currentManifest.packagingData.category_belong ===
              CATEGORY_BELONG_TYPE.LIMITED ? (
                <Text textAlign="center" fontWeight="bold">
                  NO PLACARDS REQUIRED
                </Text>
              ) : (
                <Box className="row justify-content-center">
                  <Box className="col-auto">
                    {signageDisplayClassDivision(currentManifest.product, false)
                      .length === 1 ? (
                      <ClassDivisionImage
                        className="signage-display"
                        class_division={
                          signageDisplayClassDivision(
                            currentManifest.product,
                            false
                          ).classDivision
                        }
                        isSignage={true}
                      />
                    ) : currentManifest.is_safe ||
                      currentManifest.approvestatus ===
                        APPROVE_STATUS_TYPE.APPROVED ? (
                      <Box className="class-division-image">
                        <img
                          src={DangerousGood}
                          alt="DangerousGood"
                          className="signage-display"
                        />
                      </Box>
                    ) : (
                      <Box className="class-division-image">
                        <img
                          src={Stop}
                          alt="stop"
                          className="signage-display"
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          {currentManifest.packagingData.category_belong ===
          CATEGORY_BELONG_TYPE.MANIFEST ? (
            <>
              <Box className="mt-3 ab-bx-3 p-3 ">
                <Box>
                  <Text textAlign="center" fontWeight="bold">
                    {`EMERGENCY INDICATOR PANELS TO DISPLAY (BOTH SIDES AND REAR OF VEHICLE)`}
                  </Text>
                </Box>
              </Box>
              <Box mb="20px">
                <Box className="row justify-content-center">
                  {currentManifest.product.map((datum) => (
                    <Box className="col-auto" key={datum.id} p="md">
                      <EmergencyIndicator product={datum} />
                    </Box>
                  ))}
                </Box>
              </Box>
            </>
          ) : null}
          <Box className="mt-5 ab-bx-3 p-3 ">
            <Box>
              <Text textAlign="center" fontWeight="bold">
                {`Dangerous Goods Drivers Licence ${
                  currentManifest.packagingData.category_belong !==
                  CATEGORY_BELONG_TYPE.MANIFEST
                    ? 'Not'
                    : ''
                } Required`}
              </Text>
              <Text textAlign="center" fontWeight="bold">
                Manifest Type:
                {currentManifest.is_safe
                  ? ' Safe'
                  : operator_id &&
                    user.user_id.toString() === operator_id &&
                    isAdmin(user.role)
                  ? ' Unsafe'
                  : ` Unsafe (${
                      currentManifest.approvestatus ===
                      APPROVE_STATUS_TYPE.WAITING
                        ? 'Waiting For Approval'
                        : upperCaseFirstLetter(currentManifest.approvestatus)
                    })`}
              </Text>
              <Text textAlign="center" fontWeight="bold">
                Quantity Type:
                {` ${upperCaseFirstLetter(
                  currentManifest.packagingData.category_belong
                )}`}
              </Text>
            </Box>
          </Box>
        </>
      ) : null}
      <Box className="table-responsive">
        <table className="dbTable" width="100%" style={{ padding: '10px' }}>
          <thead>
            <tr>
              <th>Date Loaded</th>
              <th>Time Loaded</th>
              <th>Class Division</th>
              <th>Class Icon</th>
              <th>Chemical Name</th>
              <th>UN Number</th>
              <th>Packing Group</th>
              <th>Quantity</th>
              <th>Measurement</th>
              {user.role !== USER_ROLE.ADMIN ? (
                <th style={{ width: '100px' }}>Unload</th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {currentManifest.product.map((datum) => (
              <tr key={datum.id}>
                <td className="current-manifest-table">
                  {dateFormatForForm(currentManifest.created_at.toString())}
                </td>
                <td className="current-manifest-table">
                  {timeFormat(currentManifest.created_at)}
                </td>
                <td className="current-manifest-table">{datum.class}</td>
                <td className="current-manifest-table">
                  <ClassDivisionImage
                    className="w-100"
                    class_division={datum.class}
                    isCurrentManifest={true}
                  />
                </td>
                <td className="current-manifest-table">
                  {datum.name_description}
                </td>
                <td className="current-manifest-table">{datum.un_no}</td>
                <td className="current-manifest-table">
                  {datum.packagingType}
                </td>
                <td className="current-manifest-table">{datum.quantity}</td>
                <td className="current-manifest-table">{datum.mesurement}</td>
                {user.role !== USER_ROLE.ADMIN ? (
                  <td
                    className="current-manifest-table"
                    style={{ width: '100px' }}
                  >
                    <img
                      src={TruckIcon}
                      alt="unload good"
                      title="Unload Good"
                      style={{ cursor: 'pointer', marginLeft: '25px' }}
                      onClick={() => {
                        setProductForUnload(datum);
                      }}
                    />
                  </td>
                ) : null}
              </tr>
            ))}
            {currentManifest.product.length ? (
              <tr
                style={{
                  fontWeight: 'bold'
                }}
              >
                <td colSpan={6}></td>
                <td>Total</td>
                <td>{getTotalQuantity()}</td>
                <td>{getMeasurement()}</td>
                {user.role !== USER_ROLE.ADMIN ? (
                  <td style={{ width: '100px', paddingRight: '15px' }}>
                    <Box
                      onClick={() => {
                        setIsUnloadAll(true);
                      }}
                      style={{
                        background: '#DC143C',
                        color: '#FFF',
                        borderRadius: '30px',
                        padding: '5px 3px  5px 3px',
                        cursor: 'pointer'
                      }}
                    >
                      Unload All
                    </Box>
                  </td>
                ) : null}
              </tr>
            ) : (
              <tr
                style={{
                  fontWeight: 'bold'
                }}
              >
                <td colSpan={user.role !== USER_ROLE.ADMIN ? 10 : 9}>
                  No Result Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Box>
      {productForUnload ? (
        <ConfirmAlert
          title={'Unload Good Confirmation'}
          description={'Do you really want to unload these goods?'}
          onCancel={() => setProductForUnload(null)}
          onConfirm={() => {
            _unloadProduct(productForUnload);
          }}
          type={'danger'}
          cancelText="No"
          confirmText="Yes"
        />
      ) : null}

      {isUnloadAll ? (
        <ConfirmAlert
          title={'Unload Good Confirmation'}
          description={'Do you really want to unload all goods?'}
          onCancel={() => setIsUnloadAll(false)}
          onConfirm={() => {
            _unloadAllProducts();
          }}
          type={'danger'}
          cancelText="No"
          confirmText="Yes"
        />
      ) : null}
    </Box>
  );
};

export default CurrentManifest;
