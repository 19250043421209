/* istanbul ignore file */
import React, { FunctionComponent, useState, useEffect } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { RouteComponentProps } from 'react-router-dom';
import { Box } from '@chakra-ui/core';
import { STATUS, ERROR_MESSAGE } from '../../../constant';
import { ErrorAlert } from '../../../common/components/ErrorAlert.component';
import { getLocalstorage, getMessage } from '../../../helper';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  t: any;
  resendVerificationCodeAPIStatus: STATUS;
  verifyOTPAPIStatus: STATUS;
  generateOTP: () => void;
  verifyOTP: (code: string) => void;
}

export const VerificationComponent: FunctionComponent<IProps> = (
  props: IProps
) => {
  const {
    className,
    t,
    resendVerificationCodeAPIStatus,
    verifyOTPAPIStatus,
    generateOTP,
    verifyOTP
  } = props;
  const email = getLocalstorage('email');
  const [digitOne, setDigitOne] = useState('');
  const [digitTwo, setDigitTwo] = useState('');
  const [digitThree, setDigitThree] = useState('');
  const [digitFour, setDigitFour] = useState('');
  const [digitFive, setDigitFive] = useState('');
  const [digitSix, setDigitSix] = useState('');

  const sendCode = async () => {
    if (
      digitOne &&
      digitTwo &&
      digitThree &&
      digitFour &&
      digitFive &&
      digitSix
    ) {
      const code = `${digitOne}${digitTwo}${digitThree}${digitFour}${digitFive}${digitSix}`;
      await verifyOTP(code);
    }
  };
  useEffect(() => {
    if (verifyOTPAPIStatus === STATUS.FAILURE) {
      setDigitOne('');
      setDigitTwo('');
      setDigitThree('');
      setDigitFour('');
      setDigitFive('');
      setDigitSix('');
    }
  }, [verifyOTPAPIStatus]);
  useEffect(() => {
    sendCode();
  }, [digitOne, digitTwo, digitThree, digitFour, digitFive, digitSix]);
  const changeFocus = (event: any, number?: string) => {
    const form = event.target.form;
    const index = Array.prototype.indexOf.call(form, event.target);
    if (number) {
      if (index === 5) {
        form.elements[0].focus();
      } else {
        form.elements[index + 1].focus();
      }
    }
  };
  const handleKey = (event: any) => {
    const form = event.target.form;
    const index = Array.prototype.indexOf.call(form, event.target);
    if (event.key === 'Backspace') {
      if (index === 0) {
        form.elements[0].focus();
      } else {
        form.elements[index - 1].focus();
      }
    }
  };
  return (
    <form className={className}>
      <h3>Verify Logout</h3>

      <p>
        A verification code has been sent to your registered email address{' '}
        {email}
      </p>
      <p>
        Please enter the code to logout from existing device and login here.
      </p>
      <ul className="otpBox">
        <li>
          <input
            type="text"
            value={digitOne}
            onKeyDown={(event) => {
              handleKey(event);
            }}
            onChange={(event) => {
              const number = event.target.value.match(/\d/g)?.join('');
              const digit = number && number.length > 1 ? digitOne : number;
              setDigitOne(digit ?? '');
              changeFocus(event, number);
            }}
          />
        </li>
        <li>
          <input
            type="text"
            value={digitTwo}
            onKeyDown={(event) => {
              handleKey(event);
            }}
            onChange={(event) => {
              const number = event.target.value.match(/\d/g)?.join('');
              const digit = number && number.length > 1 ? digitTwo : number;
              setDigitTwo(digit ?? '');
              changeFocus(event, number);
            }}
          />
        </li>
        <li>
          <input
            type="text"
            value={digitThree}
            onKeyDown={(event) => {
              handleKey(event);
            }}
            onChange={(event) => {
              const number = event.target.value.match(/\d/g)?.join('');
              const digit = number && number.length > 1 ? digitThree : number;
              setDigitThree(digit ?? '');
              changeFocus(event, number);
            }}
          />
        </li>
        <li>
          <input
            type="text"
            value={digitFour}
            onKeyDown={(event) => {
              handleKey(event);
            }}
            onChange={(event) => {
              const number = event.target.value.match(/\d/g)?.join('');
              const digit = number && number.length > 1 ? digitFour : number;
              setDigitFour(digit ?? '');
              changeFocus(event, number);
            }}
          />
        </li>
        <li>
          <input
            type="text"
            value={digitFive}
            onKeyDown={(event) => {
              handleKey(event);
            }}
            onChange={(event) => {
              const number = event.target.value.match(/\d/g)?.join('');
              const digit = number && number.length > 1 ? digitFive : number;
              setDigitFive(digit ?? '');
              changeFocus(event, number);
            }}
          />
        </li>
        <li>
          <input
            type="text"
            value={digitSix}
            onKeyDown={(event) => {
              handleKey(event);
            }}
            onChange={(event) => {
              const number = event.target.value.match(/\d/g)?.join('');
              const digit = number && number.length > 1 ? digitSix : number;
              setDigitSix(digit ?? '');
              changeFocus(event, number);
            }}
          />
        </li>
      </ul>
      <Box className="mt-3">
        <p>
          {t('REGISTER_MAKE_SURE_TO_KEEP_THIS_WINDOW_OPEN')}{' '}
          <a href="#" onClick={generateOTP}>
            {t('REGISTER_RESEND_CODE')}
          </a>
        </p>
      </Box>

      {resendVerificationCodeAPIStatus === STATUS.FAILURE ? (
        <Box>
          <ErrorAlert
            errorMessage={getMessage(
              ERROR_MESSAGE.RESEND_VERIFICATION_CODE_FAILED
            )}
            retry={generateOTP}
          />
        </Box>
      ) : null}
    </form>
  );
};

export default withToastManager(VerificationComponent);
