import { AnyAction, Reducer } from 'redux';
import { IAppState } from '../../../../store';
import { STATUS } from '../../../../constant';
import { ITermsAndConditions } from '../../models/termsAndConditions.model';
import {
  RETRIEVE_ABOUT_US_CONTENT__START,
  RETRIEVE_ABOUT_US_CONTENT__FAILURE,
  RETRIEVE_ABOUT_US_CONTENT__SUCCESS
} from '../../actions';

export interface ITermsAndConditionsPageState {
  termsAndConditions: ITermsAndConditions | null;
  retrieveTermsAndConditionAPIStatus: STATUS;
}

export const defaultState: ITermsAndConditionsPageState = {
  termsAndConditions: null,
  retrieveTermsAndConditionAPIStatus: STATUS.NOT_STARTED
};

export const termsAndConditionsPageReducer: Reducer<ITermsAndConditionsPageState> = (
  state: ITermsAndConditionsPageState = defaultState,
  action: AnyAction
): ITermsAndConditionsPageState => {
  switch (action.type) {
    case RETRIEVE_ABOUT_US_CONTENT__START: {
      return {
        ...state,
        termsAndConditions: defaultState.termsAndConditions,
        retrieveTermsAndConditionAPIStatus: STATUS.LOADING
      };
    }
    case RETRIEVE_ABOUT_US_CONTENT__FAILURE: {
      return {
        ...state,
        termsAndConditions: defaultState.termsAndConditions,
        retrieveTermsAndConditionAPIStatus: STATUS.FAILURE
      };
    }
    case RETRIEVE_ABOUT_US_CONTENT__SUCCESS: {
      const { termsAndConditions } = action.payload;
      return {
        ...state,
        termsAndConditions,
        retrieveTermsAndConditionAPIStatus: STATUS.SUCCESS
      };
    }
    default: {
      return state;
    }
  }
};

export const getTermsAndCondition = (state: IAppState) => {
  return state.termsAndConditions.ui.termsAndConditionsPage.termsAndConditions;
};

export const getTermsAndConditionAPIStatus = (state: IAppState) => {
  return state.termsAndConditions.ui.termsAndConditionsPage
    .retrieveTermsAndConditionAPIStatus;
};
