/* istanbul ignore file */

import { Method } from 'axios';
import request from '../../../services/request.service';
import GlobalConfig from '../../../globalConfig';
import { IProduct, ICurrentManifest } from '../models/currentManifest';

export interface IResponse {
  status: number;
  message: string;
}

interface IServiceProps {
  method: Method;
  headers: Record<string, any>;
  url: string;
  data?: Record<string, any>;
  responseType?: any;
}
export interface ICurrentManifestResponse extends IResponse {
  data: ICurrentManifest | null;
}

export const retrieveCurrentManifestParams = (user_id: string): IServiceProps => ({
  method: 'GET',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(`mobile/getMenifestDetail/${user_id}`)
});

export class CurrentManifestServices {
  static async retrieveCurrentManifest(
    operatorId: string
  ): Promise<ICurrentManifestResponse> {
    const res = await request(retrieveCurrentManifestParams(operatorId));
    const product: IProduct[] = [];
    let data: ICurrentManifest | null = null;
    if (res?.data?.data?.length) {
      Object.keys(res?.data?.data[0].product).forEach((datumProduct: any) => {
        if (
          !product.find(
            (datumProductFind) =>
              datumProductFind.un_no ===
                res?.data?.data[0].product[datumProduct].un_no &&
              datumProductFind.class ===
                res?.data?.data[0].product[datumProduct].class
          )
        ) {
          product.push({
            ...res?.data?.data[0].product[datumProduct],
            packagingType:
              res?.data?.data[0].product[datumProduct].packagingtype
          });
        }
      });
      data = {
        ...res?.data?.data[0],
        product,
        packagingData: res?.data?.packaging
      };
    }
    return {
      status: res?.data?.status,
      message: res?.data?.message,
      data
    };
  }
}
