/* istanbul ignore file */
import React, { FunctionComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Box, Alert } from '@chakra-ui/core';

import { STATUS, ERROR_MESSAGE } from '../../constant';
import { getMessage } from '../../helper';
import { ErrorAlert } from './ErrorAlert.component';

export interface IProps extends RouteComponentProps {
  content: string;
  title: string;
  retrieveContentAPIStatus: STATUS;
  retrieveContentData: () => void;
}

export const CMSComponent: FunctionComponent<IProps> = (props: IProps) => {
  const {
    title,
    content,
    retrieveContentAPIStatus,
    retrieveContentData
  } = props;

  return (
    <Box>
      {retrieveContentAPIStatus === STATUS.FAILURE ? (
        <Box>
          <ErrorAlert
            errorMessage={getMessage(ERROR_MESSAGE.DATA_FAILURE)}
            retry={retrieveContentData}
          />
        </Box>
      ) : null}
      {retrieveContentAPIStatus === STATUS.SUCCESS &&
      (!content || content === '') ? (
        <Box mt="md">
          <Alert mb="xs" status="warning">
            {getMessage(ERROR_MESSAGE.NO_MATCHING_RESULT)}
          </Alert>
        </Box>
      ) : null}
      {retrieveContentAPIStatus === STATUS.SUCCESS &&
      content &&
      content !== '' ? (
        <>
          <h3>{title}</h3>
          <Box dangerouslySetInnerHTML={{ __html: content }} />
        </>
      ) : null}
    </Box>
  );
};

export default withRouter(CMSComponent);
