import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Formik, FormikHelpers as FormikActions, FormikProps } from 'formik';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { emailValidator, getMessage } from '../../../helper';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../../constant';
import {
  ForgotPasswordForm,
  IFormValues
} from '../components/ForgotPassword.component';
import Logo3 from '../../../assets/images/logo3.png';
import { forgotPassword } from '../actions';

export interface IProps extends RouteComponentProps {
  className?: string;
  toggleView: (modalNum: number | null) => void;
  t: any;
  forgotPassword: typeof forgotPassword;
  toastManager: any;
}

export class ForgotPassword extends PureComponent<IProps> {
  static defaultProps: Partial<IProps> = {
    className: ''
  };

  async onFormSubmit(values: IFormValues, actions: FormikActions<IFormValues>) {
    const { email, licenseKey } = values;
    const { forgotPassword, toggleView, toastManager } = this.props;
    actions.setSubmitting(true);
    try {
      await forgotPassword(email, licenseKey);
      toastManager.add(getMessage(SUCCESS_MESSAGE.FORGOT_PASSWORD_OTP), {
        appearance: 'success',
        autoDismiss: true
      });
      toggleView(3);
      actions.setSubmitting(false);
    } catch (err) {
      const message =
        err?.errorObj?.message ??
        err?.message ??
        getMessage(ERROR_MESSAGE.FORGOT_PASSWORD_OTP);
      toastManager.add(message, { appearance: 'error', autoDismiss: true });
      console.log(':: forgot password err ', err);
      actions.setSubmitting(false);
    }
  }

  validateForm(values: IFormValues) {
    const errors: any = {};
    if (!values.email) {
      errors.email = getMessage(ERROR_MESSAGE.required);
    } else if (!emailValidator(values.email)) {
      errors.email = getMessage(ERROR_MESSAGE.email);
    }

    if (!values.licenseKey) {
      errors.licenseKey = getMessage(ERROR_MESSAGE.required);
    }

    return errors;
  }

  render() {
    const { className, toggleView } = this.props;

    return (
      <div className={className}>
        <div className="_cstModal">
          <div className="_mdBody">
            <div className="text-center">
              <img src={Logo3} alt="logo" />
            </div>
            <div className="mt-5">
              <Formik
                validate={this.validateForm.bind(this)}
                initialValues={{ email: '', licenseKey: '' }}
                validateOnMount
                onSubmit={(
                  values: IFormValues,
                  actions: FormikActions<IFormValues>
                ) => {
                  this.onFormSubmit(values, actions);
                }}
                component={(formikProps: FormikProps<IFormValues>) => {
                  return (
                    <ForgotPasswordForm {...formikProps} {...this.props} />
                  );
                }}
              />
              <div
                className="_close"
                onClick={() => {
                  toggleView(null);
                }}
              >
                x Close
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ forgotPassword }, dispatch);
}

export default connect(null, mapDispatchToProps)(withRouter(ForgotPassword));
