export interface ICoupon {
  id: number;
  value: number;
  type: string;
  coupon: string;
  start_date: string;
  end_date: string;
  is_deleted: 0;
  deleted_at: string;
  status: 1;
  created_at: string;
  updated_at: string;
}

export enum ICouponType {
  PERCENT = 'percent',
  AMOUNT = 'amount'
}
