/* istanbul ignore file */
import React, { FunctionComponent } from 'react';
import { Box } from '@chakra-ui/core';
import { withToastManager } from 'react-toast-notifications';
import { RouteComponentProps } from 'react-router-dom';
import ReactPlayer from 'react-player';

import Image1 from '../../../assets/images/image-1.png';
import AppImage1 from '../../../assets/images/app-image-1.png';
import AppImage2 from '../../../assets/images/app-image-2.png';
import AppStore from '../../../assets/images/app-store.png';
import PlayStore from '../../../assets/images/play-store.png';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  t: any;
}

export const HomePageFeature: FunctionComponent<IProps> = (props: IProps) => {
  const { className, t } = props;
  return (
    <Box className={className}>
      <section className="_fs">
        <Box className="container">
          <h3>{t('HOME_PAGE_FEATURES')}</h3>
          <span className="subTxt">
            {t(
              'HOME_PAGE_HAVE_YOU_EVER_BEEN_STUCK_IN_THE_MOMENT_TRYING_TO_FIGURE_OUT_IF_YOU_CANT_HANDLE_TRANSPORT_OR_STORE_PACKAGED_DANGEROUS_GOODS_SAFELY'
            )}
          </span>
          <Box className="fInfo">
            <ul>
              <li className="transition">
                <Box className="d-flex justify-content-start align-items-start">
                  <Box className="pr-3">
                    <Box className="_fsAfter">
                      <Box className="_sn transition transition">
                        <span>1</span>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <span>
                      {t(
                        'HOME_PAGE_TAKE_THE_GUESSWORK_OUT_OF_DANGEROUS_GOODS_COMPLIANCE'
                      )}
                    </span>
                  </Box>
                </Box>
              </li>
              <li className="transition">
                <Box className="d-flex justify-content-start align-items-start">
                  <Box className="pr-3">
                    <Box className="_fsAfter">
                      <Box className="_sn transition">
                        <span>2</span>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <span>
                      {t(
                        'HOME_PAGE_COMPARE_MULTIPLE_DANGEROUS_GOOD_CLASS_COMBINATIONS'
                      )}
                    </span>
                  </Box>
                </Box>
              </li>
              <li className="transition">
                <Box className="d-flex justify-content-start align-items-start">
                  <Box className="pr-3">
                    <Box className="_fsAfter">
                      <Box className="_sn transition">
                        <span>3</span>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <span>
                      {t(
                        'HOME_PAGE_TAKE_A_PHOTO_AND_RECORD_ALL_HISTORY_OF_YOUR_LOADS_FOR_COMPLIANCE'
                      )}
                    </span>
                  </Box>
                </Box>
              </li>
              <li className="transition">
                <Box className="d-flex justify-content-start align-items-start">
                  <Box className="pr-3">
                    <Box className="_fsAfter">
                      <Box className="_sn transition">
                        <span>4</span>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <span>
                      {t(
                        'HOME_PAGE_REPORT_VIA_EMAIL_YOUR_LOADS_COMPLIANCE_OR_NON_COMPLIANCE'
                      )}
                    </span>
                  </Box>
                </Box>
              </li>
              <li className="transition">
                <Box className="d-flex justify-content-start align-items-start">
                  <Box className="pr-3">
                    <Box className="_fsAfter">
                      <Box className="_sn transition">
                        <span>5</span>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <span>
                      {t(
                        'HOME_PAGE_EMERGENCY_CONTACT_INFORMATION_PROCEDURES_GUIDE'
                      )}
                    </span>
                  </Box>
                </Box>
              </li>
              <li className="transition">
                <Box className="d-flex justify-content-start align-items-start">
                  <Box className="pr-3">
                    <Box className="_fsAfter">
                      <Box className="_sn transition">
                        <span>6</span>
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <span>
                      {t(
                        'HOME_PAGE_REFERENCE_TRAINING_INFORMATION_ABOUT_TYPES_OF_LOADS'
                      )}
                    </span>
                  </Box>
                </Box>
              </li>
            </ul>
            <Box className="_hndImage">
              <img src={Image1} alt="pdg" />
            </Box>
          </Box>
        </Box>
      </section>

      <section className="hiw">
        <Box className="container">
          <a href="#">
            <ReactPlayer
              className="w-100"
              url="files/how-it-works.mp4"
              controls
              playing
              loop
            />
          </a>
        </Box>
      </section>

      <section className="appSection d-flex align-items-center">
        <Box className="container">
          <Box className="_appInfo">
            <h3>
              &#34;{t('HOME_PAGE_WINNER_OF_BEST_PRACTICE')} <br />
              {t('HOME_PAGE_SAFETY_AWARD')}&#34;
            </h3>
            <span className="_sb1">
              {t('HOME_PAGE_2019_AUSTRALIAN_FREIGHT_INDUSTRY_AWARDS')}
            </span>
            <Box className="mt-5 mbQRCode">
              <Box className="mt-3 row">
                <a
                  className="col-6"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://apps.apple.com/in/app/pdg/id1563547906"
                >
                  <img src={AppImage1} alt="app store" />
                  <img
                    src={AppStore}
                    alt="app store"
                    style={{ marginTop: '20px', maxWidth: '78%' }}
                  />
                </a>
                <a
                  className="col-6"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.pdg"
                >
                  <img src={AppImage2} alt="play store" />
                  <img
                    src={PlayStore}
                    alt="play store"
                    style={{ marginTop: '20px', maxWidth: '78%' }}
                  />
                </a>
              </Box>
            </Box>
          </Box>
        </Box>
      </section>
    </Box>
  );
};

export default withToastManager(HomePageFeature);
