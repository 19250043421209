import React, { FunctionComponent } from 'react';
import { FormikProps } from 'formik';
import { Box } from '@chakra-ui/core';

import { IProps } from '../pages/UpdatePassword.page';
import { OTP_LENGTH } from '../../../constant';

export interface IFormValues {
  code: string;
  password: string;
  cpassword: string;
  email: string;
  license_key: string;
}

export const UpdatePasswordForm: FunctionComponent<
  FormikProps<IFormValues> & Partial<IProps>
> = ({
  values,
  handleSubmit,
  errors,
  touched,
  setFieldValue,
  t,
  toggleView
}) => {
  return (
    <>
      <div className="login-container">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder={t('OTP')}
              name="code"
              value={values.code}
              onChange={(event) => {
                const number = event.target.value.match(/\d/g)?.join('');
                const code =
                  number && number.length > OTP_LENGTH ? values.code : number;
                setFieldValue('code', code ?? '');
              }}
            />
            {errors.code && touched.code ? (
              <Box className="invalid-feedback">{errors.code}</Box>
            ) : null}
          </div>
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              placeholder={t('NEW_PASSWORD')}
              name="password"
              value={values.password}
              onChange={(event) => {
                setFieldValue('password', event.target.value);
              }}
            />
            {errors.password && touched.password ? (
              <Box className="invalid-feedback">{errors.password}</Box>
            ) : null}
          </div>
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              placeholder={t('CONFIRM_PASSWORD')}
              name="cpassword"
              value={values.cpassword}
              onChange={(event) => {
                setFieldValue('cpassword', event.target.value);
              }}
            />
            {errors.cpassword && touched.cpassword ? (
              <Box className="invalid-feedback">{errors.cpassword}</Box>
            ) : null}
          </div>
          <div className="form-group">
            <button className="btn btn-primary w-100" type="submit">
              {t('SUBMIT')}
            </button>
          </div>
          <div className="mt-3 text-center">
            <span>
              {t('ALREADY_HAVE_AN_ACCOUNT')}
              <a
                className="linkTxt"
                href="#"
                onClick={() => {
                  toggleView && toggleView(1);
                }}
              >
                {t('LOGIN')}
              </a>
            </span>
          </div>
        </form>
      </div>
    </>
  );
};
