/* istanbul ignore file */

import { Method } from 'axios';
import request from '../../../services/request.service';
import GlobalConfig from '../../../globalConfig';
import { AuthRepository } from '../../auth/repositories/auth.repository';
import { IWarehouseTruck } from '../models/warehouseTruckRegistration.model';

export interface IResponse {
  status: number;
  message: string;
}

interface IServiceProps {
  method: Method;
  headers: Record<string, any>;
  url: string;
  data?: Record<string, any>;
  responseType?: any;
}

export interface IWarehouseTruckResponse extends IResponse {
  warehouseTruck: IWarehouseTruck[];
}

export interface IAddWareHouseTruckParams {
  name: string;
  type: number;
  truckRegistrationImage?: string;
  id?: number;
}

export const retrieveWarehouseTruckParams = (
  userId: string
): IServiceProps => ({
  method: 'GET',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(`mobile/getWarehouseTruck/${userId}`)
});

export const addWarehouseTruckParams = (
  warehouseTruckData: IAddWareHouseTruckParams,
  userId: string
): IServiceProps => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(`mobile/saveWarehouseTruck`),
  data: {
    ...warehouseTruckData,
    user_id: userId
  }
});

export class WarehouseTruckServices {
  static async retrieveWarehouseTruck(
    userId: string
  ): Promise<IWarehouseTruckResponse> {
    const res = await request(retrieveWarehouseTruckParams(userId));
    return {
      status: res.data.status,
      message: res.data.message,
      warehouseTruck: res.data?.warehouse.map((datum: any) => ({
        ...datum,
        truckRegistrationImage: datum.file
      }))
    };
  }
  static async addWarehouseTruck(
    warehouseTruckData: IAddWareHouseTruckParams
  ): Promise<IResponse> {
    const userId = AuthRepository.retrieveUserId() as string;
    const res = await request(
      addWarehouseTruckParams(warehouseTruckData, userId)
    );
    return {
      status: res.data.status,
      message: res.data.message
    };
  }
}
