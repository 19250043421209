import React, { FunctionComponent, useState } from 'react';
import { FormikProps } from 'formik';
import { Box } from '@chakra-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import { ROUTES } from '../../../constant';
import { IProps } from '../pages/Login.page';
import { FullPageLoader } from '../../../common/components/Loader.component';

export interface IFormValues {
  email: string;
  password: string;
  license: string;
}

export const LoginForm: FunctionComponent<
  FormikProps<IFormValues> &
    Partial<IProps> & {
      onEmailChange: () => void;
    }
> = ({
  values,
  handleSubmit,
  errors,
  toggleView,
  touched,
  setFieldValue,
  isSubmitting,
  t
}) => {
  const [isShowPass, setIsShowPass] = useState(false);
  return (
    <>
      <div className="login-container">
        {isSubmitting ? (
          <Box>
            <FullPageLoader />
          </Box>
        ) : null}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              placeholder={t('LOGIN_EMAIL')}
              name="email"
              value={values.email}
              onChange={(event) => {
                setFieldValue('email', event.target.value);
              }}
            />
            {errors.email && touched.email ? (
              <Box className="invalid-feedback">{errors.email}</Box>
            ) : null}
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder={t('LOGIN_LICENSE')}
              name="license"
              value={values.license}
              onChange={(event) => {
                setFieldValue('license', event.target.value);
              }}
            />
            {errors.license && touched.license ? (
              <Box className="invalid-feedback">{errors.license}</Box>
            ) : null}
          </div>
          <div className="form-group">
            <input
              type={isShowPass ? 'true' : 'password'}
              className="form-control"
              placeholder={t('LOGIN_PASSWORD')}
              name="password"
              value={values.password}
              onChange={(event) => {
                setFieldValue('password', event.target.value);
              }}
            />
            <span
              className="EyePstn"
              onClick={() => {
                setIsShowPass(!isShowPass);
              }}
            >
              {isShowPass ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </span>
            {errors.password && touched.password ? (
              <Box className="invalid-feedback">{errors.password}</Box>
            ) : null}
          </div>
          <div className="d-flex flex-row-reverse form-group">
            <a
              href="#"
              className="linkTxt"
              onClick={() => {
                toggleView && toggleView(2);
              }}
            >
              {t('LOGIN_FORGOT_PASSWORD')}
            </a>
          </div>
          <div className="form-group">
            <button className="btn btn-primary w-100" type="submit">
              {t('LOGIN')}
            </button>
          </div>
          <div className="mt-3 text-center">
            <span>
              {t('LOGIN_DONT_HAVE_ACCOUNT')}
              <a className="linkTxt" href={ROUTES.REGISTER}>
                {t('LOGIN_REGISTER')}
              </a>
            </span>
          </div>
        </form>
      </div>
    </>
  );
};
