import { AnyAction, Reducer } from 'redux';
import { IAppState } from '../../store';
import { STATUS } from '../../constant';
import {
  CHANGE_PASSWORD__START,
  CHANGE_PASSWORD__FAILURE,
  CHANGE_PASSWORD__SUCCESS
} from '../actions';

export interface IChangePasswordPageState {
  changePasswordAPIStatus: STATUS;
}

export const defaultState: IChangePasswordPageState = {
  changePasswordAPIStatus: STATUS.NOT_STARTED
};

export const changePasswordPageReducer: Reducer<IChangePasswordPageState> = (
  state: IChangePasswordPageState = defaultState,
  action: AnyAction
): IChangePasswordPageState => {
  switch (action.type) {
    case CHANGE_PASSWORD__START: {
      return {
        ...state,
        changePasswordAPIStatus: STATUS.LOADING
      };
    }
    case CHANGE_PASSWORD__FAILURE: {
      return {
        ...state,
        changePasswordAPIStatus: STATUS.FAILURE
      };
    }
    case CHANGE_PASSWORD__SUCCESS: {
      return {
        ...state,
        changePasswordAPIStatus: STATUS.SUCCESS
      };
    }
    default: {
      return state;
    }
  }
};

export const getChangePasswordAPIStatus = (state: IAppState) => {
  return state.common.changePassword.changePasswordAPIStatus;
};
