/* istanbul ignore file */

import React, { FunctionComponent, HTMLProps } from 'react';
import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';

import { Label } from '../components/forms';

interface IProps {
  className?: string;
  errorMessage?: string;
  error?: boolean;
  success?: boolean;
  label?: string;
}

const TextAreaComponent: FunctionComponent<
  IProps & Partial<HTMLProps<HTMLTextAreaElement>>
> = ({ className, errorMessage, error, label, ...rest }: IProps) => {
  return (
    <div className={className}>
      {label ? <Label>{label}</Label> : null}
      <textarea rows={3} {...rest} />
      {error ? (
        <div className="error invalid-feedback">
          {error ? errorMessage : ''}
        </div>
      ) : null}
    </div>
  );
};

TextAreaComponent.defaultProps = {
  className: '',
  errorMessage: '',
  error: false,
  success: false,
  label: ''
};

/* istanbul ignore next */
export const TextArea = styled(TextAreaComponent)`
  textarea {
    width: 100%;
    resize: none;
    border: 1px solid
      ${({ success, error, theme }) =>
        (success && (theme as any).colors.successFormInputBorder) ||
        (error && (theme as any).colors.errorFormInputBorder) ||
        (theme as any).colors.defaultFormInputBorder};
    background-color: ${themeGet('colors.formInputBg')};
    padding: 10px;
    font-size: ${themeGet('fontSizes.regular')};
    color: ${themeGet('colors.grey9')};
    border-radius: 3px;
    &:focus {
      background-color: ${themeGet('colors.focusedFormInputBg')};
      border-color: ${({ success, error, theme }) =>
        (success && (theme as any).colors.successFormInputBorder) ||
        (error && (theme as any).colors.errorFormInputBorder) ||
        (theme as any).colors.focusedFormInputBorder};
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    }
    &:valid {
      background-color: ${themeGet('colors.focusedFormInputBg')};
      border-color: ${({ success, error, theme }) =>
        (success && (theme as any).colors.successFormInputBorder) ||
        (error && (theme as any).colors.errorFormInputBorder) ||
        (theme as any).colors.focusedFormInputBorder};
    }
    &:disabled {
      background-color: ${themeGet('colors.disabledFormInputBg')};
    }
  }
  .error {
    color: ${themeGet('colors.danger.default')};
    min-height: 22px;
    font-size: ${themeGet('fontSizes.sm')};
    padding-top: 4px;
  }
`;

TextArea.displayName = 'TextArea';
