import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Formik, FormikHelpers as FormikActions, FormikProps } from 'formik';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { getMessage, passwordValidator } from '../../../helper';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../../constant';
import {
  UpdatePasswordForm,
  IFormValues
} from '../components/UpdatePassword.component';
import Logo3 from '../../../assets/images/logo3.png';
import { updatePassword } from '../actions';
import { IAppState } from '../../../store';
import {
  getForgotPasswordEmail,
  getForgotPasswordLicenseKey
} from '../../forgotPassword/reducers/ui';

export interface IProps extends RouteComponentProps {
  className?: string;
  toggleView: (modalNum: number | null) => void;
  t: any;
  updatePassword: typeof updatePassword;
  toastManager: any;
  email: string;
  licenseKey: string;
}

export class UpdatePassword extends PureComponent<IProps> {
  static defaultProps: Partial<IProps> = {
    className: ''
  };

  async onFormSubmit(values: IFormValues, actions: FormikActions<IFormValues>) {
    const { updatePassword, toggleView, toastManager } = this.props;
    actions.setSubmitting(true);
    try {
      await updatePassword(values);
      toastManager.add(getMessage(SUCCESS_MESSAGE.UPDATE_PASSWORD), {
        appearance: 'success',
        autoDismiss: true
      });
      toggleView(1);
      actions.setSubmitting(false);
    } catch (err) {
      const message =
        err?.errorObj?.message ??
        err?.message ??
        getMessage(ERROR_MESSAGE.UPDATE_PASSWORD);
      toastManager.add(message, { appearance: 'error', autoDismiss: true });
      console.log(':: update password err ', err);
      actions.setSubmitting(false);
    }
  }

  validateForm(values: IFormValues) {
    const errors: any = {};
    if (!values.password) {
      errors.password = getMessage(ERROR_MESSAGE.required);
    } else if (!passwordValidator(values.password)) {
      errors.password = getMessage(ERROR_MESSAGE.password);
    }

    if (!values.cpassword) {
      errors.cpassword = getMessage(ERROR_MESSAGE.required);
    }

    if (!values.code) {
      errors.code = getMessage(ERROR_MESSAGE.required);
    }

    if (values.password !== values.cpassword) {
      errors.cpassword = 'Password and Confirm Password should be same';
    }

    return errors;
  }

  render() {
    const { className, toggleView, email, licenseKey } = this.props;

    return (
      <div className={className}>
        <div className="_cstModal">
          <div className="_mdBody">
            <div className="text-center">
              <img src={Logo3} alt="pdg" />
            </div>
            <div className="mt-5">
              <Formik
                validate={this.validateForm.bind(this)}
                initialValues={{
                  code: '',
                  password: '',
                  cpassword: '',
                  email,
                  license_key: licenseKey
                }}
                validateOnMount
                onSubmit={(
                  values: IFormValues,
                  actions: FormikActions<IFormValues>
                ) => {
                  this.onFormSubmit(values, actions);
                }}
                component={(formikProps: FormikProps<IFormValues>) => {
                  return (
                    <UpdatePasswordForm {...formikProps} {...this.props} />
                  );
                }}
              />
              <div
                className="_close"
                onClick={() => {
                  toggleView(null);
                }}
              >
                x Close
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IAppState) => {
  const email = getForgotPasswordEmail(state);
  const licenseKey = getForgotPasswordLicenseKey(state);
  return {
    email,
    licenseKey
  };
};

/* istanbul ignore next */
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ updatePassword }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UpdatePassword));
