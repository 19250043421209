import { AnyAction, Reducer } from 'redux';
import { IAppState } from '../../../../store';
import { STATUS } from '../../../../constant';
import { IReport } from '../../models/reports-list.model';
import {
  RETRIEVE_REPORT_LIST__START,
  RETRIEVE_REPORT_LIST__FAILURE,
  RETRIEVE_REPORT_LIST__SUCCESS,
  RETRIEVE_REPORT_DETAIL__START,
  RETRIEVE_REPORT_DETAIL__FAILURE,
  RETRIEVE_REPORT_DETAIL__SUCCESS,
  RETRIEVE_UN_NUMBER_LIST__START,
  RETRIEVE_UN_NUMBER_LIST__FAILURE,
  RETRIEVE_UN_NUMBER_LIST__SUCCESS,
  RETRIEVE_COMBINATION_STATUS__START,
  RETRIEVE_COMBINATION_STATUS__FAILURE,
  RETRIEVE_COMBINATION_STATUS__SUCCESS,
  DELETE_REPORT__START,
  DELETE_REPORT__FAILURE,
  DELETE_REPORT__SUCCESS,
  CREATE_REPORT__START,
  CREATE_REPORT__FAILURE,
  CREATE_REPORT__SUCCESS,
  ADD_IMAGE_IN_UN_NUMBER_INTO_CART,
  REMOVE_IMAGE_IN_UN_NUMBER_INTO_CART,
  ADD_PRODUCT_VALUE_IN_UN_NUMBER_INTO_CART,
  CREATE_REPORT_BY_CLASS_NAME__START,
  CREATE_REPORT_BY_CLASS_NAME__FAILURE,
  CREATE_REPORT_BY_CLASS_NAME__SUCCESS,
  UPDATE_GOODS_IN_THE_CART,
  UPDATE_GOODS_IN_THE_CART_BY_CLASS_NAME,
  RETRIEVE_CLASS_DIVISION_LIST__START,
  RETRIEVE_CLASS_DIVISION_LIST__FAILURE,
  RETRIEVE_CLASS_DIVISION_LIST__SUCCESS,
  UNLOAD_PRODUCT__START,
  UNLOAD_PRODUCT__FAILURE,
  UNLOAD_PRODUCT__SUCCESS,
  RESET_REPORT_STATE
} from '../../actions';
import { IProductInCart } from '../../models/un-number.model';
import {
  IClassDivision,
  IClassDivisionCart
} from '../../models/classDivision.model';
import classDivisionObj from '../../../../common/components/utils/class_division.json';

export interface IAddImage {
  id: string;
  image: string;
}

export interface IProductValue {
  packagingType?: string;
  mesurement?: string;
  quantity?: number;
}

export interface IProductValueWithId {
  id: string;
  productValue: IProductValue;
}

export interface ICombinationData {
  message: string[];
  safe_status: boolean;
  tunnel_value?: string;
}

export interface IReportsPageState {
  currentReportIds: string[] | null;
  selectedReportId: string | null;
  unNumbersInCart: IProductInCart[];
  classDivisions: IClassDivision[];
  classDivisionsInCart: IClassDivisionCart[];
  combinationData: ICombinationData | null;
  retrieveReportsAPIStatus: STATUS;
  retrieveReportDetailAPIStatus: STATUS;
  retrieveUnNumbersAPIStatus: STATUS;
  retrieveCombinationAPIStatus: STATUS;
  retrieveCombinationByClassNameAPIStatus: STATUS;
  createReportAPIStatus: STATUS;
  createReportByClassNameAPIStatus: STATUS;
  deleteReportAPIStatus: STATUS;
  retrieveClassDivisionAPIStatus: STATUS;
  unloadProductAPIStatus: STATUS;
}

export const defaultState: IReportsPageState = {
  currentReportIds: null,
  classDivisions: classDivisionObj,
  unNumbersInCart: [],
  combinationData: null,
  selectedReportId: null,
  classDivisionsInCart: [],
  retrieveCombinationByClassNameAPIStatus: STATUS.NOT_STARTED,
  retrieveReportDetailAPIStatus: STATUS.NOT_STARTED,
  retrieveCombinationAPIStatus: STATUS.NOT_STARTED,
  retrieveUnNumbersAPIStatus: STATUS.NOT_STARTED,
  retrieveReportsAPIStatus: STATUS.NOT_STARTED,
  createReportAPIStatus: STATUS.NOT_STARTED,
  createReportByClassNameAPIStatus: STATUS.NOT_STARTED,
  deleteReportAPIStatus: STATUS.NOT_STARTED,
  retrieveClassDivisionAPIStatus: STATUS.NOT_STARTED,
  unloadProductAPIStatus: STATUS.NOT_STARTED
};

export const reportsPageReducer: Reducer<IReportsPageState> = (
  state: IReportsPageState = defaultState,
  action: AnyAction
): IReportsPageState => {
  switch (action.type) {
    case RETRIEVE_REPORT_LIST__START: {
      return {
        ...state,
        currentReportIds: defaultState.currentReportIds,
        retrieveReportsAPIStatus: STATUS.LOADING
      };
    }
    case RETRIEVE_REPORT_LIST__FAILURE: {
      return {
        ...state,
        currentReportIds: defaultState.currentReportIds,
        retrieveReportsAPIStatus: STATUS.FAILURE
      };
    }
    case RETRIEVE_REPORT_LIST__SUCCESS: {
      const { reports } = action.payload;
      const currentReportIds = reports.map((datum: IReport) => datum.report_id);
      return {
        ...state,
        currentReportIds,
        retrieveReportsAPIStatus: STATUS.SUCCESS
      };
    }
    case RETRIEVE_REPORT_DETAIL__START: {
      return {
        ...state,
        retrieveReportDetailAPIStatus: STATUS.LOADING
      };
    }
    case RETRIEVE_REPORT_DETAIL__FAILURE: {
      return {
        ...state,
        retrieveReportDetailAPIStatus: STATUS.FAILURE
      };
    }
    case RETRIEVE_REPORT_DETAIL__SUCCESS: {
      const { report } = action.payload;
      return {
        ...state,
        selectedReportId: report?.report_id ?? null,
        retrieveReportDetailAPIStatus: STATUS.SUCCESS
      };
    }
    case RETRIEVE_UN_NUMBER_LIST__START: {
      return {
        ...state,
        retrieveUnNumbersAPIStatus: STATUS.LOADING
      };
    }
    case RETRIEVE_UN_NUMBER_LIST__FAILURE: {
      return {
        ...state,
        retrieveUnNumbersAPIStatus: STATUS.FAILURE
      };
    }
    case RETRIEVE_UN_NUMBER_LIST__SUCCESS: {
      return {
        ...state,
        retrieveUnNumbersAPIStatus: STATUS.SUCCESS
      };
    }
    case UPDATE_GOODS_IN_THE_CART: {
      const { unNumbersInCart } = action.payload;
      return {
        ...state,
        unNumbersInCart
      };
    }
    case RETRIEVE_COMBINATION_STATUS__START: {
      return {
        ...state,
        combinationData: defaultState.combinationData,
        retrieveCombinationAPIStatus: STATUS.LOADING
      };
    }
    case RETRIEVE_COMBINATION_STATUS__FAILURE: {
      return {
        ...state,
        combinationData: defaultState.combinationData,
        retrieveCombinationAPIStatus: STATUS.FAILURE
      };
    }
    case RETRIEVE_COMBINATION_STATUS__SUCCESS: {
      const { data, safe_status, tunnel_value } = action.payload;
      const combinationData: ICombinationData = {
        message: data,
        safe_status,
        tunnel_value
      };
      return {
        ...state,
        combinationData,
        retrieveCombinationAPIStatus: STATUS.SUCCESS
      };
    }
    case ADD_IMAGE_IN_UN_NUMBER_INTO_CART: {
      const { id, image } = action.payload;
      const { unNumbersInCart } = state;
      const index = unNumbersInCart.findIndex(
        (item: IProductInCart) =>
          item.un_no === id ||
          (item.class_division === id && (!item.un_no || item.un_no === ''))
      );
      if (index !== -1) {
        unNumbersInCart[index].images.push(image);
        return {
          ...state,
          unNumbersInCart: [...unNumbersInCart]
        };
      }
      return state;
    }
    case REMOVE_IMAGE_IN_UN_NUMBER_INTO_CART: {
      const { id, image } = action.payload;
      const { unNumbersInCart } = state;
      const index = unNumbersInCart.findIndex(
        (item: IProductInCart) =>
          item.un_no === id ||
          (item.class_division === id && (!item.un_no || item.un_no === ''))
      );
      if (index !== -1) {
        const images = unNumbersInCart[index].images.filter(
          (datum) => datum !== image
        );
        unNumbersInCart[index].images = [...images];
        return {
          ...state,
          unNumbersInCart: [...unNumbersInCart]
        };
      }
      return state;
    }
    case ADD_PRODUCT_VALUE_IN_UN_NUMBER_INTO_CART: {
      const { id, productValue } = action.payload;
      const { unNumbersInCart } = state;
      const index = unNumbersInCart.findIndex(
        (item: IProductInCart) =>
          item.un_no === id ||
          (item.class_division === id && (!item.un_no || item.un_no === ''))
      );
      if (index !== -1) {
        unNumbersInCart[index] = {
          ...unNumbersInCart[index],
          mesurement: productValue.mesurement,
          packagingType: productValue.packagingType,
          quantity: productValue.quantity
        };
        return {
          ...state,
          unNumbersInCart: [...unNumbersInCart]
        };
      }
      return state;
    }
    case CREATE_REPORT__START: {
      return {
        ...state,
        createReportAPIStatus: STATUS.LOADING
      };
    }
    case CREATE_REPORT__FAILURE: {
      return {
        ...state,
        createReportAPIStatus: STATUS.FAILURE
      };
    }
    case CREATE_REPORT__SUCCESS: {
      return {
        ...state,
        createReportAPIStatus: STATUS.SUCCESS,
        unNumbersInCart: []
      };
    }
    case DELETE_REPORT__START: {
      return {
        ...state,
        deleteReportAPIStatus: STATUS.LOADING
      };
    }
    case DELETE_REPORT__FAILURE: {
      return {
        ...state,
        deleteReportAPIStatus: STATUS.FAILURE
      };
    }
    case DELETE_REPORT__SUCCESS: {
      return {
        ...state,
        deleteReportAPIStatus: STATUS.SUCCESS,
        unNumbersInCart: [],
        classDivisionsInCart: []
      };
    }
    case UPDATE_GOODS_IN_THE_CART_BY_CLASS_NAME: {
      const { classDivisionsInCart } = action.payload;
      return {
        ...state,
        classDivisionsInCart
      };
    }
    case CREATE_REPORT_BY_CLASS_NAME__START: {
      return {
        ...state,
        createReportByClassNameAPIStatus: STATUS.LOADING
      };
    }
    case CREATE_REPORT_BY_CLASS_NAME__FAILURE: {
      return {
        ...state,
        createReportByClassNameAPIStatus: STATUS.FAILURE
      };
    }
    case CREATE_REPORT_BY_CLASS_NAME__SUCCESS: {
      return {
        ...state,
        createReportByClassNameAPIStatus: STATUS.SUCCESS,
        classDivisionsInCart: []
      };
    }
    case RETRIEVE_CLASS_DIVISION_LIST__START: {
      return {
        ...state,
        classDivisions: defaultState.classDivisions,
        retrieveClassDivisionAPIStatus: STATUS.LOADING
      };
    }
    case RETRIEVE_CLASS_DIVISION_LIST__FAILURE: {
      return {
        ...state,
        classDivisions: defaultState.classDivisions,
        retrieveClassDivisionAPIStatus: STATUS.FAILURE
      };
    }
    case RETRIEVE_CLASS_DIVISION_LIST__SUCCESS: {
      const { classDivisions } = action.payload;
      return {
        ...state,
        classDivisions,
        retrieveClassDivisionAPIStatus: STATUS.SUCCESS
      };
    }
    case UNLOAD_PRODUCT__START: {
      return {
        ...state,
        unloadProductAPIStatus: STATUS.LOADING
      };
    }
    case UNLOAD_PRODUCT__FAILURE: {
      return {
        ...state,
        unloadProductAPIStatus: STATUS.FAILURE
      };
    }
    case UNLOAD_PRODUCT__SUCCESS: {
      return {
        ...state,
        unloadProductAPIStatus: STATUS.SUCCESS
      };
    }
    case RESET_REPORT_STATE: {
      return {
        ...defaultState,
        unNumbersInCart: [],
        classDivisionsInCart: []
      };
    }
    default: {
      return state;
    }
  }
};

export const getCurrentReportIds = (state: IAppState) => {
  return state.reports.ui.reportsPage.currentReportIds;
};
export const getReportsAPIStatus = (state: IAppState) => {
  return state.reports.ui.reportsPage.retrieveReportsAPIStatus;
};

export const getSelectedReportId = (state: IAppState) => {
  return state.reports.ui.reportsPage.selectedReportId;
};
export const getReportDetailAPIStatus = (state: IAppState) => {
  return state.reports.ui.reportsPage.retrieveReportDetailAPIStatus;
};

export const getUnNumbersAPIStatus = (state: IAppState) => {
  return state.reports.ui.reportsPage.retrieveUnNumbersAPIStatus;
};

export const getUnNumbersFromCart = (state: IAppState) => {
  return state.reports.ui.reportsPage.unNumbersInCart;
};

export const getCombinationData = (state: IAppState) => {
  return state.reports.ui.reportsPage.combinationData;
};
export const getCombinationAPIStatus = (state: IAppState) => {
  return state.reports.ui.reportsPage.retrieveCombinationAPIStatus;
};

export const getCreateReportAPIStatus = (state: IAppState) => {
  return state.reports.ui.reportsPage.createReportAPIStatus;
};

export const getDeleteReportAPIStatus = (state: IAppState) => {
  return state.reports.ui.reportsPage.deleteReportAPIStatus;
};

export const getCombinationByClassNameAPIStatus = (state: IAppState) => {
  return state.reports.ui.reportsPage.retrieveCombinationByClassNameAPIStatus;
};

export const getClassDivisionFromCart = (state: IAppState) => {
  return state.reports.ui.reportsPage.classDivisionsInCart;
};

export const getCreateReportByClassNameAPIStatus = (state: IAppState) => {
  return state.reports.ui.reportsPage.createReportByClassNameAPIStatus;
};

export const getClassDivisions = (state: IAppState) => {
  return state.reports.ui.reportsPage.classDivisions;
};

export const getClassDivisionsAPIStatus = (state: IAppState) => {
  return state.reports.ui.reportsPage.retrieveClassDivisionAPIStatus;
};

export const getUnloadProductAPIStatus = (state: IAppState) => {
  return state.reports.ui.reportsPage.unloadProductAPIStatus;
};
