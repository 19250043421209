/* istanbul ignore file */
import React, {
  FunctionComponent,
  RefObject,
  createRef,
  useState
} from 'react';
import { withToastManager } from 'react-toast-notifications';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Box } from '@chakra-ui/core';
import { FormikProps, FormikHelpers as FormikActions, Formik } from 'formik';
import Camera from '../../../assets/images/camera.png';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import {
  ERROR_MESSAGE,
  STATUS,
  ISD_CODE,
  PHONE_NUMBER_LENGTH,
  ALLOW_PROFILE_PIC_EXTENSION,
  ROUTES
} from '../../../constant';
import {
  emailValidator,
  passwordValidator,
  phoneNumberValidator,
  getMessage
} from '../../../helper';
import ProfileIcon from '../../../assets/images/profile.png';
import { FullPageLoader } from '../../../common/components/Loader.component';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  createUserAPIStatus: STATUS;
  nextStep: () => void;
  submitRegistration: (values: IRegisterFormValues) => void;
  t: any;
}

export interface IRegisterFormValues {
  name?: string | null;
  email?: string | null;
  password?: string | null;
  cPassword?: string | null;
  termsAndConditions: boolean;
  phone?: string | null;
  code?: string | null;
  image?: string | null;
}

export const RegisterForm: FunctionComponent<
  FormikProps<IRegisterFormValues> & Partial<IProps>
> = ({
  handleSubmit,
  touched,
  errors,
  values,
  setFieldValue,
  t,
  toastManager
}) => {
  const [isShowPass, setIsShowPass] = useState(false);
  const [isShowCPass, setIsShowCPass] = useState(false);
  const _downloadLinkRef: RefObject<HTMLInputElement> = createRef();
  const uploadImage = (file: any) => {
    const rex = /(?:\.([^.]+))?$/;
    const extension = rex.exec(file.name);
    if (
      extension &&
      extension.length &&
      ALLOW_PROFILE_PIC_EXTENSION.includes(extension[1])
    ) {
      const reader = new FileReader();
      reader.onload = (evt) => {
        setFieldValue('image', evt.target?.result);
      };
      reader.readAsDataURL(file);
    } else {
      toastManager.add(getMessage(ERROR_MESSAGE.PROFILE_PIC_INVALID), {
        appearance: 'error',
        autoDismiss: true
      });
    }
  };

  return (
    <Box className="mt-3 row _stp2" onSubmit={handleSubmit}>
      <Box className="_prImage text-center">
        <i
          className="_cmImg"
          onClick={() => {
            _downloadLinkRef.current?.click();
          }}
        >
          <img src={Camera} alt="blank pic" />
        </i>
        <img
          className=""
          src={values.image && values.image !== '' ? values.image : ProfileIcon}
          alt="user logo"
          onClick={() => {
            _downloadLinkRef.current?.click();
          }}
        />
        <input
          type="file"
          ref={_downloadLinkRef}
          onChange={(event) => {
            if (event.target.files?.length) {
              uploadImage(event.target.files[0]);
            }
          }}
          style={{ display: 'none' }}
        />
        {errors.image && touched.image ? (
          <Box className="invalid-feedback">{errors.image}</Box>
        ) : null}
      </Box>
      <Box className="col-12 form-group">
        <label>{t('REGISTER_NAME')}</label>
        <input
          type="text"
          className="form-control"
          name="name"
          value={values.name ?? ''}
          onChange={(event) => {
            setFieldValue('name', event.target.value);
          }}
        />
        {errors.name && touched.name ? (
          <Box className="invalid-feedback">{errors.name}</Box>
        ) : null}
      </Box>
      <Box className="col-12 form-group">
        <label>{t('REGISTER_EMAIL')}</label>
        <input
          type="email"
          className="form-control"
          name="email"
          value={values.email ?? ''}
          onChange={(event) => {
            setFieldValue('email', event.target.value);
          }}
        />
        {errors.email && touched.email ? (
          <Box className="invalid-feedback">{errors.email}</Box>
        ) : null}
      </Box>
      <Box className="col-3 form-group">
        <label>{t('REGISTER_CODE')}</label>
        <select
          className="form-control"
          name="code"
          onChange={(event) => {
            setFieldValue('code', event.target.value);
          }}
        >
          {ISD_CODE.map((datum) => (
            <option value={datum} key={datum}>
              {datum}
            </option>
          ))}
        </select>
        {errors.code && touched.code ? (
          <Box className="invalid-feedback">{errors.code}</Box>
        ) : null}
      </Box>
      <Box className="col-9 form-group">
        <label>{t('REGISTER_PHONE')}</label>
        <input
          type="text"
          className="form-control"
          name="phone"
          value={values.phone ?? ''}
          onChange={(event) => {
            const number = event.target.value.match(/\d/g)?.join('');
            const phone =
              number && number.length > PHONE_NUMBER_LENGTH
                ? values.phone
                : number;
            setFieldValue('phone', phone);
          }}
        />
        {errors.phone && touched.phone ? (
          <Box className="invalid-feedback">{errors.phone}</Box>
        ) : null}
      </Box>

      <Box className="col-12 col-lg-6 form-group">
        <label>{t('REGISTER_PASSWORD')}</label>
        <input
          type={isShowPass ? 'true' : 'password'}
          className="form-control"
          name="password"
          value={values.password ?? ''}
          onChange={(event) => {
            setFieldValue('password', event.target.value);
          }}
        />
        <span
          className="EyePstn2"
          onClick={() => {
            setIsShowPass(!isShowPass);
          }}
        >
          {isShowPass ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </span>
        {errors.password && touched.password ? (
          <Box className="invalid-feedback">{errors.password}</Box>
        ) : null}
      </Box>
      <Box className="col-12 col-lg-6 form-group">
        <label>{t('REGISTER_CONFIRM_PASSWORD')}</label>
        <input
          type={isShowCPass ? 'true' : 'password'}
          className="form-control"
          name="cPassword"
          value={values.cPassword ?? ''}
          onChange={(event) => {
            setFieldValue('cPassword', event.target.value);
          }}
        />
        <span
          className="EyePstn2"
          onClick={() => {
            setIsShowCPass(!isShowCPass);
          }}
        >
          {isShowCPass ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </span>
        {errors.cPassword && touched.cPassword ? (
          <Box className="invalid-feedback">{errors.cPassword}</Box>
        ) : null}
      </Box>
      <Box className="col-12 form-group">
        <Box className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id="termsAndConditions"
            name="termsAndConditions"
            checked={!!values.termsAndConditions}
            onChange={(event) => {
              setFieldValue('termsAndConditions', event.target.checked);
            }}
          />
          <label className="custom-control-label" htmlFor="termsAndConditions">
            {t('REGISTER_AGREE_TO')}{' '}
            <a
              href={ROUTES.TERMS_AND_CONDITIONS_WITHOUT_LOGIN}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('REGISTER_TERMS_AND_CONDITIONS')}
            </a>
          </label>
          {errors.termsAndConditions && touched.termsAndConditions ? (
            <Box className="invalid-feedback">{errors.termsAndConditions}</Box>
          ) : null}
        </Box>
      </Box>
      <Box className="col-12">
        <button
          className="btn btn-primary w-100 transition"
          disabled={!values.termsAndConditions}
          onClick={() => {
            handleSubmit();
          }}
        >
          {t('REGISTER_SUBMIT')}
        </button>
      </Box>
    </Box>
  );
};

export const RegisterComponent: FunctionComponent<IProps> = (props: IProps) => {
  const { className, t, submitRegistration, createUserAPIStatus } = props;
  const _validateFilterForm = (values: IRegisterFormValues) => {
    const errors: any = {};
    if (!values.name) {
      errors.name = getMessage(ERROR_MESSAGE.required);
    }

    if (!values.email) {
      errors.email = getMessage(ERROR_MESSAGE.required);
    } else if (!emailValidator(values.email)) {
      errors.email = getMessage(ERROR_MESSAGE.email);
    }

    if (!values.password) {
      errors.password = getMessage(ERROR_MESSAGE.required);
    } else if (!passwordValidator(values.password)) {
      errors.password = getMessage(ERROR_MESSAGE.password);
    }

    if (!values.code) {
      errors.code = getMessage(ERROR_MESSAGE.required);
    }

    if (!values.phone) {
      errors.phone = getMessage(ERROR_MESSAGE.required);
    } else if (!phoneNumberValidator(values.phone)) {
      errors.phone = getMessage(ERROR_MESSAGE.phone);
    }

    if (!values.cPassword) {
      errors.cPassword = getMessage(ERROR_MESSAGE.required);
    }

    if (values.password !== values.cPassword) {
      errors.cPassword = 'Password and Confirm Password should be same';
    }

    if (!values.termsAndConditions) {
      errors.termsAndConditions = 'Please checked the terms and conditions';
    }

    return errors;
  };

  const _onFilterFormSubmit = async (
    values: IRegisterFormValues,
    actions: FormikActions<IRegisterFormValues>
  ) => {
    actions.setSubmitting(true);
    try {
      await submitRegistration(values);
      actions.setSubmitting(false);
    } catch (err) {
      actions.setSubmitting(false);
    }
  };
  return (
    <Box className={className}>
      {createUserAPIStatus === STATUS.LOADING ? (
        <Box p={'lg'}>
          <FullPageLoader />
        </Box>
      ) : null}
      <h3>{t('REGISTER_SETUP_YOUR_PROFILE')}</h3>
      <Formik
        validate={(values: IRegisterFormValues) => _validateFilterForm(values)}
        enableReinitialize
        initialValues={{
          name: '',
          email: '',
          password: '',
          cPassword: '',
          termsAndConditions: false,
          phone: '',
          code: ISD_CODE[0],
          image: ''
        }}
        onSubmit={(
          values: IRegisterFormValues,
          actions: FormikActions<IRegisterFormValues>
        ) => {
          _onFilterFormSubmit(values, actions);
        }}
        component={(formikProps: FormikProps<IRegisterFormValues>) => {
          return <RegisterForm {...formikProps} {...props} />;
        }}
      />
    </Box>
  );
};

export default withToastManager(withRouter(RegisterComponent));
