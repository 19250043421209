/* istanbul ignore file */
import React, { FunctionComponent, useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Box } from '@chakra-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

import {
  createUrl,
  getLocalstorage,
  changeLanguage,
  getMessage
} from '../../../helper';
import SearchComponent from '../../../common/components/Search.component';
import { ERROR_MESSAGE, ROUTES, STATUS } from '../../../constant';
import Logo from '../../../assets/images/logo.png';
import Logo2 from '../../../assets/images/logo-2.png';
import Login from '../../../modules/auth/pages/Login.page';
import ForgotPassword from '../../../modules/forgotPassword/pages/ForgotPassword.page';
import UpdatePassword from '../../../modules/updatePassword/pages/UpdatePassword.page';
import { FullPageLoader } from '../../../common/components/Loader.component';
import {
  SelectLanguage,
  SelectLanguageMobile
} from '../../../common/components/helpers/showSelectedLanguage.component';
import { retrieveUnNumbers } from '../../../modules/reports/actions';
import { IUnNumbersParams } from '../../../modules/reports/services/api.service';
import { getUnNumbersAPIStatus } from '../../../modules/reports/reducers/ui';

interface IOwnProps extends RouteComponentProps {
  className: string;
  isShowSecondLogo: boolean;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  t: any;
}
interface ISearchFormValues {
  searchTerm: string;
}

export const Header: FunctionComponent<IProps> = (props: IProps) => {
  const {
    t,
    history,
    isShowSecondLogo,
    toastManager,
    location: { pathname }
  } = props;
  const dispatch = useDispatch();
  const retrieveUnNumbersAPIStatus = useSelector(getUnNumbersAPIStatus);
  const [isShowMenu, setIsShowMenu] = useState(false);
  const [isShowSearchUn, setIsShowSearchUn] = useState(false);
  const lang = getLocalstorage('lang');
  const [searchTerm, setSearchTerm] = useState('');
  const [language, setLanguage] = useState(lang);
  const [isGuideOpen, setIsGuideOpen] = useState(false);
  const [isOpenLogin, setIsOpenLogin] = useState<number | null>(null);

  const _retrieveUnNumbers = async () => {
    try {
      const params: IUnNumbersParams = {
        unNumber: searchTerm
      };
      const res: any = await dispatch(retrieveUnNumbers(params));
      if (res?.data?.length) {
        const unURL = createUrl(ROUTES.UN_NUMBER_DETAIL, {
          id: res.data[0].un_no
        });
        history.push(unURL);
      } else {
        toastManager.add(getMessage(ERROR_MESSAGE.UN_NUMBER_SEARCH_INVALID), {
          appearance: 'error',
          autoDismiss: true
        });
      }
    } catch (err) {
      console.log(':: _retrieveUnNumbers err ', err);
      toastManager.add(getMessage(ERROR_MESSAGE.UN_NUMBER_SEARCH_FAILED), {
        appearance: 'error',
        autoDismiss: true
      });
    }
  };
  useEffect(() => {
    if (searchTerm) {
      _retrieveUnNumbers();
    }
  }, [searchTerm]);

  const setUserLang = (newLang: string) => {
    setLanguage(newLang);
    changeLanguage(newLang);
  };
  return (
    <div>
      {retrieveUnNumbersAPIStatus === STATUS.LOADING &&
      props.match.path !== ROUTES.UN_NUMBER_DETAIL ? (
        <Box>
          <FullPageLoader />
        </Box>
      ) : null}
      <Box className={`mbMenu ${isShowMenu ? 'show' : ''}`}>
        <span
          className="cross"
          onClick={() => {
            setIsShowMenu(false);
          }}
        >
          <CloseIcon />
        </span>
        <ul>
          <li>
            <a
              href="#"
              className="transition"
              onClick={() => {
                setIsOpenLogin(1);
                setIsShowMenu(false);
              }}
            >
              {t('HOME_PAGE_LOGIN')}
            </a>
          </li>
          <li
            onClick={() => {
              setIsShowMenu(false);
            }}
          >
            <a href={ROUTES.REGISTER}>{t('HOME_PAGE_REGISTER')}</a>
          </li>
          <li
            onClick={() => {
              setIsShowMenu(false);
            }}
          >
            <a href={ROUTES.CLASSES}>{t('CLASSES')}</a>
          </li>
          <li
            onClick={() => {
              setIsShowMenu(false);
            }}
          >
            <a href={ROUTES.GHS}>{t('GHS')}</a>
          </li>
          <li
            onClick={() => {
              setIsShowMenu(false);
            }}
          >
            <a
              href="https://www.ntc.gov.au/sites/default/files/assets/files/ADG%20Code%207.7_0.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('AUSTRALIAN_DANGEROUS_CODE')}
            </a>
          </li>
          <li
            onClick={() => {
              setIsShowMenu(false);
            }}
          >
            <a
              href="https://www.ntc.gov.au/sites/default/files/assets/files/Australian-Emergency-Response-Guide-Book-2018%28errata%201%29_0.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('AUSTRALIAN_EMERGENCY_RESPONSE_GUIDE')}
            </a>
          </li>
        </ul>
      </Box>
      <Box className={`mbMenu ${isShowSearchUn ? 'show' : ''}`}>
        <span
          className="cross"
          onClick={() => {
            setIsShowSearchUn(false);
          }}
        >
          <CloseIcon />
        </span>
        <Box>
          <SearchComponent
            placeholder={t('SEARCH_UN_NO')}
            maxLength={8}
            searchTerm={searchTerm}
            onSearchFormSubmit={(values: ISearchFormValues) => {
              setSearchTerm(values.searchTerm);
            }}
            onSearchFormReset={() => {
              setSearchTerm('');
            }}
          />
        </Box>
      </Box>
      <Box className="d-flex justify-content-between align-items-start">
        <Box className="h_right">
          <ul>
            <li className="mbtggle" style={{ paddingRight: '30px' }}>
              <a
                href="#"
                className="transition"
                onClick={() => {
                  setIsShowSearchUn(true);
                }}
              >
                UN No.
              </a>
            </li>
            <li className="mb-hidden">
              <SearchComponent
                placeholder={t('SEARCH_UN_NO')}
                maxLength={8}
                searchTerm={searchTerm}
                onSearchFormSubmit={(values: ISearchFormValues) => {
                  setSearchTerm(values.searchTerm);
                }}
                onSearchFormReset={() => {
                  setSearchTerm('');
                }}
              />
            </li>
            <li className="mb-hidden">
              <a
                href={ROUTES.CLASSES}
                className={`transition ${
                  pathname.includes(ROUTES.CLASSES) ? 'active' : ''
                }`}
                style={{
                  border: 'none'
                }}
              >
                {t('CLASSES')}
              </a>
            </li>
            <li className="mb-hidden">
              <a
                href={ROUTES.GHS}
                className={`transition ${
                  pathname.includes(ROUTES.GHS) ? 'active' : ''
                }`}
                style={{
                  border: 'none'
                }}
              >
                {t('GHS')}
              </a>
            </li>
            <li
              className="mb-hidden LangDrop"
              style={{
                color: '#fff',
                fontSize: '15px'
              }}
              onClick={() => {
                setIsGuideOpen(!isGuideOpen);
              }}
            >
              {t('GUIDE')}
              {isGuideOpen ? (
                <ul className="guide_dropdown">
                  <li
                    onClick={() => {
                      setIsGuideOpen(false);
                    }}
                  >
                    <a
                      href="https://www.ntc.gov.au/sites/default/files/assets/files/ADG%20Code%207.7_0.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('AUSTRALIAN_DANGEROUS_CODE')}
                    </a>
                  </li>
                  <li
                    onClick={() => {
                      setIsGuideOpen(false);
                    }}
                  >
                    <a
                      href="https://www.ntc.gov.au/sites/default/files/assets/files/Australian-Emergency-Response-Guide-Book-2018%28errata%201%29_0.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('AUSTRALIAN_EMERGENCY_RESPONSE_GUIDE')}
                    </a>
                  </li>
                </ul>
              ) : null}
            </li>
          </ul>
        </Box>
        <Box className="_l">
          <a href={ROUTES.HOME}>
            <img src={Logo} alt="logo" />
            {isShowSecondLogo ? <img src={Logo2} alt="logo2" /> : null}
          </a>
        </Box>
        <Box className="h_right">
          <ul>
            <li className="LangDrop mb-hidden">
              <SelectLanguage
                selectedLanguage={language ?? lang}
                setLanguage={(lang: string) => {
                  setUserLang(lang);
                }}
              />
            </li>
            <li className="LangDrop mbtggle">
              <SelectLanguageMobile
                selectedLanguage={language ?? lang}
                setLanguage={(lang: string) => {
                  setUserLang(lang);
                }}
              />
            </li>
            <li className="mb-hidden">
              <a href={ROUTES.REGISTER} className="transition">
                {t('HOME_PAGE_REGISTER')}
              </a>
            </li>
            <li className="mb-hidden">
              <a
                href="#"
                className="transition"
                onClick={() => {
                  setIsOpenLogin(1);
                }}
              >
                {t('HOME_PAGE_LOGIN')}
              </a>
            </li>
            <li className="mbtggle">
              <a
                href="#"
                onClick={() => {
                  setIsShowMenu(true);
                }}
              >
                <MenuIcon />
              </a>
            </li>
          </ul>
        </Box>
      </Box>

      {isOpenLogin === 1 ? (
        <Login
          {...props}
          toggleView={(modalNum: number | null) => {
            setIsOpenLogin(modalNum);
          }}
        />
      ) : null}
      {isOpenLogin === 2 ? (
        <ForgotPassword
          {...props}
          toggleView={(modalNum: number | null) => {
            setIsOpenLogin(modalNum);
          }}
        />
      ) : null}
      {isOpenLogin === 3 ? (
        <UpdatePassword
          {...props}
          toggleView={(modalNum: number | null) => {
            setIsOpenLogin(modalNum);
          }}
        />
      ) : null}
    </div>
  );
};
