import { createAction } from 'redux-actions';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  RegisterServices,
  ICouponResponse,
  ILicenseDataResponse,
  IServiceResponse
} from '../services/regiseter.service';
import { ISubscription, ILicenseData } from '../models/subscription.model';
import { ICoupon } from '../models/coupon.model';
import { IRegisterFormValues } from '../components/register.component';
import { IPaymentFormValues } from '../components/payment.component';
import { IResponse } from '../../reports/services/api.service';

export const RETRIEVE_SUBSCRIPTION_LIST__START =
  'pdg.subscription.list.ui.start';
export const retrieveSubscriptionStart = createAction(
  RETRIEVE_SUBSCRIPTION_LIST__START
);

export const RETRIEVE_SUBSCRIPTION_LIST__FAILURE =
  'pdg.subscription.list.ui.failure';
export const retrieveSubscriptionFailure = createAction(
  RETRIEVE_SUBSCRIPTION_LIST__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const RETRIEVE_SUBSCRIPTION_LIST__SUCCESS =
  'pdg.subscription.list.ui.success';
export const retrieveSubscriptionSuccess = createAction(
  RETRIEVE_SUBSCRIPTION_LIST__SUCCESS,
  (subscriptionList: ISubscription[]) => {
    return { subscriptionList };
  }
);

export const retrieveSubscription = (): ThunkAction<
  Promise<ISubscription[]>,
  {},
  {},
  AnyAction
> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(retrieveSubscriptionStart());
      const subscriptionList = await RegisterServices.retrieveSubscription();
      dispatch(retrieveSubscriptionSuccess(subscriptionList));
      return subscriptionList;
    } catch (err) {
      dispatch(retrieveSubscriptionFailure(err));
      throw err;
    }
  };
};

export const retrieveSubscriptionWithUserId = (
  userId: string
): ThunkAction<Promise<ISubscription[]>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(retrieveSubscriptionStart());
      const subscriptionList = await RegisterServices.retrieveSubscriptionListWithUserId(userId);
      dispatch(retrieveSubscriptionSuccess(subscriptionList));
      return subscriptionList;
    } catch (err) {
      dispatch(retrieveSubscriptionFailure(err));
      throw err;
    }
  };
};

export const SUBMIT_COUPON_LIST__START = 'pdg.submit.coupon.code.ui.start';
export const submitCouponCodeStart = createAction(SUBMIT_COUPON_LIST__START);

export const SUBMIT_COUPON_LIST__FAILURE = 'pdg.submit.coupon.code.ui.failure';
export const submitCouponCodeFailure = createAction(
  SUBMIT_COUPON_LIST__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const SUBMIT_COUPON_LIST__SUCCESS = 'pdg.submit.coupon.code.ui.success';
export const submitCouponCodeSuccess = createAction(
  SUBMIT_COUPON_LIST__SUCCESS,
  (coupon: ICoupon) => {
    return { coupon };
  }
);

export const submitCouponCode = (
  coupon_code: string
): ThunkAction<Promise<ICouponResponse>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(submitCouponCodeStart());
      const res = await RegisterServices.submitCouponCode(coupon_code);
      if (res.status) {
        dispatch(submitCouponCodeSuccess(res.coupon));
      } else throw res.message;
      return res;
    } catch (err) {
      dispatch(submitCouponCodeFailure(err));
      throw err;
    }
  };
};

export const SELECT_SUBSCRIPTION = 'pdg.subscription.ui.select';
export const selectSubscription = createAction(
  SELECT_SUBSCRIPTION,
  (subscriptionId: number) => {
    return { subscriptionId };
  }
);

export const SUBMIT_REGISTRATION__START = 'pdg.submit.registration.ui.start';
export const submitRegistrationStart = createAction(SUBMIT_REGISTRATION__START);

export const SUBMIT_REGISTRATION__FAILURE =
  'pdg.submit.registration.ui.failure';
export const submitRegistrationFailure = createAction(
  SUBMIT_REGISTRATION__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const SUBMIT_REGISTRATION__SUCCESS =
  'pdg.submit.registration.ui.success';
export const submitRegistrationSuccess = createAction(
  SUBMIT_REGISTRATION__SUCCESS
);

export const submitRegistration = (
  values: IRegisterFormValues
): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(submitRegistrationStart());
      const res = await RegisterServices.submitRegistration(values);
      if (res && res.status !== 0) {
        dispatch(submitRegistrationSuccess());
        return res;
      }
      throw res;
    } catch (err) {
      dispatch(submitRegistrationFailure(err));
      throw err;
    }
  };
};

export const RESEND_VERIFICATION_CODE__START =
  'pdg.resend.verification.code.ui.start';
export const resendVerificationCodeStart = createAction(
  RESEND_VERIFICATION_CODE__START
);

export const RESEND_VERIFICATION_CODE__FAILURE =
  'pdg.resend.verification.code.ui.failure';
export const resendVerificationCodeFailure = createAction(
  RESEND_VERIFICATION_CODE__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const RESEND_VERIFICATION_CODE__SUCCESS =
  'pdg.resend.verification.code.ui.success';
export const resendVerificationCodeSuccess = createAction(
  RESEND_VERIFICATION_CODE__SUCCESS
);

export const resendVerificationCode = (): ThunkAction<
  Promise<any>,
  {},
  {},
  AnyAction
> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(resendVerificationCodeStart());
      const res = await RegisterServices.resendVerificationCode();
      dispatch(resendVerificationCodeSuccess());
      return res;
    } catch (err) {
      dispatch(resendVerificationCodeFailure(err));
      throw err;
    }
  };
};

export const SUBMIT_PAYMENT__START = 'pdg.submit.payment.ui.start';
export const submitPaymentStart = createAction(SUBMIT_PAYMENT__START);

export const SUBMIT_PAYMENT__FAILURE = 'pdg.submit.payment.ui.failure';
export const submitPaymentFailure = createAction(
  SUBMIT_PAYMENT__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const SUBMIT_PAYMENT__SUCCESS = 'pdg.submit.payment.ui.success';
export const submitPaymentSuccess = createAction(SUBMIT_PAYMENT__SUCCESS);

export const submitPayment = (
  values: IPaymentFormValues,
  isValidCoupon: boolean | null
): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(submitPaymentStart());
      const res = await RegisterServices.submitPayment(values, isValidCoupon);
      if (res && res.status !== 0) {
        dispatch(submitPaymentSuccess());
        return res;
      }
      throw res;
    } catch (err) {
      dispatch(submitPaymentFailure(err));
      throw err;
    }
  };
};

export const SEND_VERIFICATION_CODE__START =
  'pdg.send.verification.code.ui.start';
export const sendVerificationCodeStart = createAction(
  SEND_VERIFICATION_CODE__START
);

export const SEND_VERIFICATION_CODE__FAILURE =
  'pdg.send.verification.code.ui.failure';
export const sendVerificationCodeFailure = createAction(
  SEND_VERIFICATION_CODE__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const SEND_VERIFICATION_CODE__SUCCESS =
  'pdg.send.verification.code.ui.success';
export const sendVerificationCodeSuccess = createAction(
  SEND_VERIFICATION_CODE__SUCCESS
);

export const sendVerificationCode = (
  code: string
): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(sendVerificationCodeStart());
      const res = await RegisterServices.sendVerificationCode(code);
      if (res && res.status !== 0) {
        dispatch(sendVerificationCodeSuccess());
        return res;
      }
      throw res;
    } catch (err) {
      dispatch(sendVerificationCodeFailure(err));
      throw err;
    }
  };
};

export const UPDATE_SUBSCRIPTION__START = 'pdg.update.subscription.ui.start';
export const updatesubscriptionStart = createAction(UPDATE_SUBSCRIPTION__START);

export const UPDATE_SUBSCRIPTION__FAILURE =
  'pdg.update.subscription.ui.failure';
export const updatesubscriptionFailure = createAction(
  UPDATE_SUBSCRIPTION__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const UPDATE_SUBSCRIPTION__SUCCESS =
  'pdg.update.subscription.ui.success';
export const updatesubscriptionSuccess = createAction(
  UPDATE_SUBSCRIPTION__SUCCESS
);

export const updatesubscription = (
  subscription_id: number,
  userId: string
): ThunkAction<Promise<IResponse>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(updatesubscriptionStart());
      const res = await RegisterServices.updatesubscription(
        subscription_id,
        userId
      );
      if (res && res.status !== 0) {
        dispatch(updatesubscriptionSuccess());
        return res;
      }
      throw res;
    } catch (err) {
      dispatch(updatesubscriptionFailure(err));
      throw err;
    }
  };
};

export const RETRIEVE_LICENSE_DATA__START = 'pdg.license.data.ui.start';
export const retrieveLicenseDataStart = createAction(
  RETRIEVE_LICENSE_DATA__START
);

export const RETRIEVE_LICENSE_DATA__FAILURE = 'pdg.license.data.ui.failure';
export const retrieveLicenseDataFailure = createAction(
  RETRIEVE_LICENSE_DATA__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const RETRIEVE_LICENSE_DATA__SUCCESS = 'pdg.license.data.ui.success';
export const retrieveLicenseDataSuccess = createAction(
  RETRIEVE_LICENSE_DATA__SUCCESS,
  (licenseData: ILicenseData | null) => {
    return { licenseData };
  }
);

export const retrieveLicenseData = (
  userId: string
): ThunkAction<Promise<ILicenseDataResponse>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(retrieveLicenseDataStart());
      const res = await RegisterServices.retrieveLicenseData(userId);
      if (res.status === 1) {
        dispatch(retrieveLicenseDataSuccess(res.licenseData));
      } else {
        throw res;
      }
      return res;
    } catch (err) {
      dispatch(retrieveLicenseDataFailure(err));
      throw err;
    }
  };
};

export const CANCEL_SUBSCRIPTION__START = 'pdg.cancel.subscription.ui.start';
export const cancelSubscriptionStart = createAction(CANCEL_SUBSCRIPTION__START);

export const CANCEL_SUBSCRIPTION__FAILURE =
  'pdg.cancel.subscription.ui.failure';
export const cancelSubscriptionFailure = createAction(
  CANCEL_SUBSCRIPTION__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const CANCEL_SUBSCRIPTION__SUCCESS =
  'pdg.cancel.subscription.ui.success';
export const cancelSubscriptionSuccess = createAction(
  CANCEL_SUBSCRIPTION__SUCCESS
);

export const cancelSubscription = (
  userId: string
): ThunkAction<Promise<IServiceResponse>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(cancelSubscriptionStart());
      const res = await RegisterServices.cancelSubscription(userId);
      if (res.status === 1) {
        dispatch(cancelSubscriptionSuccess());
      } else {
        throw res;
      }
      return res;
    } catch (err) {
      dispatch(cancelSubscriptionFailure(err));
      throw err;
    }
  };
};
