import React, { FunctionComponent } from 'react';
import { Box, Text, Link } from '@chakra-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import DangerousGood from '../../assets/images/dangerous_goods.png';
export interface IProps {
  t: any;
  onClose: () => void;
}
export const MixedGoodsInfo: FunctionComponent<IProps> = ({ onClose, t }) => {
  return (
    <div className="_cstModal">
      <div className="_mdBody">
        <Box className="close-icon">
          <span
            onClick={() => {
              onClose();
            }}
          >
            <CloseIcon />
          </span>
        </Box>
        <Box>
          <Text fontWeight="bold" textAlign="center" fontSize="3xl">
            {t('CLASS')}: {t('MIXED')}
          </Text>
          <Box>
            <img
              src={DangerousGood}
              alt="dangerous good"
              className="mixed-goods signage-display"
            />
          </Box>
          <Text fontWeight="500" fontSize="lg">
            {t('LOAD_CONSISTS_OF_VARIOUS_LOAD_TYPES')}
          </Text>
          <Text fontWeight="500" fontSize="md">
            {t('THE_MIXED_CLASS_LABEL')}
          </Text>
          <Box background="#d3d3d3" p="sm">
            <Text color="#959595" fontSize="md">
              {t('PLEASE_REFER_TO_THE_DANGEROUS_GOODS_CODE')}
            </Text>
            <Link
              color="#959595"
              href="https://www.ntc.gov.au/heavy-vehicles/safety/australian-dangerous-goods-code"
              target="_blank"
            >
              https://www.ntc.gov.au/heavy-vehicles/safety/australian-dangerous-goods-code
            </Link>
          </Box>
        </Box>
      </div>
    </div>
  );
};
