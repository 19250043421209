/* istanbul ignore file */
import React, { FunctionComponent } from 'react';
import { Box, Text } from '@chakra-ui/core';

import { IProduct } from '../models/currentManifest';
import { ClassDivisionImage } from '../../../common/components/helpers/class_division_image.component';
import { transformName } from '../../../helper';

export interface IProps {
  product: IProduct;
}

export const EmergencyIndicator: FunctionComponent<IProps> = (
  props: IProps
) => {
  const { product } = props;
  return (
    <Box className="_pBox">
      <Box height="40px">
        <Box className="p-1">
          <h5 className="mb-0">
            {transformName(50, product.name_description)}
          </h5>
        </Box>
      </Box>
      <Box className="d-flex">
        <Box className="w-50">
          <ul className="list-group list-group-indicater">
            <li className="list-group-item">
              <Text width="fit-content" fontSize="10px">
                UN No.
              </Text>
              <Text width="fit-content" fontSize="30px" fontWeight="bold">
                {product.un_no}
              </Text>
            </li>
            <li className="list-group-item">
              <Text width="fit-content" fontSize="10px">
                HAZCHEM
              </Text>
              <Text width="fit-content" fontSize="30px" fontWeight="bold">
                {product.HAZCHEM}
              </Text>
            </li>
          </ul>
        </Box>
        <Box className="w-50">
          <Box className="d-flex align-items-center justify-content-center h-100 w-100">
            <ClassDivisionImage
              className="w-100"
              class_division={product.class}
              isCurrentManifest={true}
            />
          </Box>
        </Box>
      </Box>
      <Box className="d-flex" borderTop="2px solid #000">
        <Box className="w-50">
          <Box className="_pBox_l p-1">
            <h6 className="mb-0">IN EMERGENCY CALL</h6>
            <h6 className="mb-0">000 POLICE OR FIRE BRIGADE</h6>
          </Box>
        </Box>
        <Box className="w-50">
          <Box className="_pBox_r p-1">
            <span>SPECIALIST ADVICE</span>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default EmergencyIndicator;
