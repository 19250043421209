/* istanbul ignore file */
import React, { FunctionComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Box } from '@chakra-ui/core';

import { IUnNumber } from '../models/un-number.model';
import { ClassDivisionImage } from '../../../common/components/helpers/class_division_image.component';
// import { EPGLinks } from '../../../common/components/helpers/epg_link.component';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  unNumberDetail: IUnNumber;
  t: any;
}

export const UnNumberDetail: FunctionComponent<IProps> = (props: IProps) => {
  const { className, unNumberDetail, t } = props;

  return (
    <Box className={`${className}`}>
      <Box className="row dgd-bx mt-3">
        <Box className="col-12 col-lg-3">
          <span>{t('CLASS_DIVISION')}</span>
          <p>{unNumberDetail.class_division_value ?? 'N/A'}</p>
        </Box>
        <Box className="col-12 col-lg-3">
          <span>Classification</span>
          <p>{unNumberDetail.class_division ?? 'N/A'}</p>
        </Box>
        <Box className="col-12 col-lg-4">
          <span>{t('EXCEPTED_QUANTITIES')}</span>
          <p>{unNumberDetail.excepted_quantities ?? 'N/A'}</p>
        </Box>
        <Box className="col-12 col-lg-5">
          <span>{t('LIMITED_QUANTITIES')}</span>
          <p>{unNumberDetail.limited_quantities ?? 'N/A'}</p>
        </Box>
      </Box>
      <Box className="row dgd-bx mt-3">
        <Box className="col-12 col-lg-3">
          <span>{t('INSTRUCTIONS')}</span>
          <p>{unNumberDetail.instructions ?? 'N/A'}</p>
        </Box>
        <Box className="col-12 col-lg-4">
          <span>{t('PACKING_INSTRUCTION')}</span>
          <p>{unNumberDetail.packing_Instruction ?? 'N/A'}</p>
        </Box>
        <Box className="col-12 col-lg-5">
          <span>{t('PACKING_GROUP')}</span>
          <p>{unNumberDetail.packing_group ?? 'N/A'}</p>
        </Box>
      </Box>
      <Box className="row align-items-center mt-3">
        <Box className="col-12 col-lg-6">
          <Box className="row align-items-center mt-3">
            <Box className="col-12 col-lg-6">
              <span>{t('SPECIAL_PROVISIONS')}</span>
              <p>
                {unNumberDetail.special_provisions?.replace(/,/g, ', ') ??
                  'N/A'}
              </p>
            </Box>
            <Box className="col-12 col-lg-6">
              <span>{t('SPECIAL_PROVISIONS_DATA')}</span>
              <p>
                {unNumberDetail.special_provisions_data?.replace(/,/g, ', ') ??
                  'N/A'}
              </p>
            </Box>
            <Box className="col-12 col-lg-6">
              <span>{t('SPECIAL_PACKING_PROVISIONS')}</span>
              <p>
                {unNumberDetail.special_packing_provisions?.replace(
                  /,/g,
                  ', '
                ) ?? 'N/A'}
              </p>
            </Box>
            <Box className="col-12 col-lg-6">
              <span>{t('SPECIAL_PROVISION_LOADING')}</span>
              <p>{unNumberDetail.special_provisions_loading ?? 'N/A'}</p>
            </Box>
            <Box className="col-12 col-lg-6">
              <span>{t('SPECIAL_PROVISION_OPERATION')}</span>
              <p>{unNumberDetail.special_provisions_operation ?? 'N/A'}</p>
            </Box>
            <Box className="col-12 col-lg-6">
              <span>{t('SPECIAL_PROVISION_PACKAGES')}</span>
              <p>{unNumberDetail.special_provisions_packages ?? 'N/A'}</p>
            </Box>
            <Box className="col-12 col-lg-6">
              <span>{t('SUBSIDIARY_HAZARD')}</span>
              <p>{unNumberDetail.subsidiary_hazard ?? 'N/A'}</p>
            </Box>
            <Box className="col-12 col-lg-6"></Box>
            <Box className="col-12 col-lg-6">
              <span>{t('TRANSPORT_CATEGORY')}</span>
              <p>{unNumberDetail.transport_category ?? 'N/A'}</p>
            </Box>
            <Box className="col-12 col-lg-6">
              <span>{t('TUNNEL_CODE')}</span>
              <p>{unNumberDetail.tunnel_code ?? 'N/A'}</p>
            </Box>
          </Box>
        </Box>
        <Box className="col-12 col-lg-6">
          <ClassDivisionImage
            class_division={unNumberDetail.class_division_value}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default UnNumberDetail;
