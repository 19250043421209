/* istanbul ignore file */
import React, { FunctionComponent, useEffect } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { Box, Alert } from '@chakra-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import { getUnNumbersAPIStatus } from '../../reports/reducers/ui';
import { getUnsSelector } from '../../reports/selectors/index.page.selector';
import { ERROR_MESSAGE, STATUS } from '../../../constant';
import { FullPageLoader } from '../../../common/components/Loader.component';
import { retrieveUnNumbers } from '../../reports/actions';
import { IUnNumbersParams } from '../../reports/services/api.service';
import { getMessage } from '../../../helper';
import { ClassDivisionImage } from '../../../common/components/helpers/class_division_image.component';
import { Header } from '../../../common/components/helpers/header.component';
import { IUnNumber } from '../../reports/models/un-number.model';
// import { EPGLinks } from '../../../common/components/helpers/epg_link.component';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  t: any;
}

export const UnNumberDetail: FunctionComponent<IProps> = (props: IProps) => {
  const dispatch = useDispatch();
  const { className, match, t } = props;
  const { params } = match;
  const unNumber = (params as any).id;
  const retrieveUnNumbersAPIStatus = useSelector(getUnNumbersAPIStatus);
  const unNumbers: IUnNumber[] | null = useSelector(getUnsSelector);

  const _retrieveUnNumbers = async () => {
    try {
      const params: IUnNumbersParams = {
        unNumber
      };
      await dispatch(retrieveUnNumbers(params));
    } catch (err) {
      console.log(':: _retrieveUnNumbers err ', err);
    }
  };
  useEffect(() => {
    if (unNumber) {
      _retrieveUnNumbers();
    }
  }, [unNumber]);
  return (
    <div className={className}>
      {retrieveUnNumbersAPIStatus === STATUS.LOADING ? (
        <Box>
          <FullPageLoader />
        </Box>
      ) : null}
      <section className="inn-bnr container-fluid header-with-login">
        <Header isShowSecondLogo={false} {...props} />
      </section>
      {unNumbers &&
      unNumbers.length &&
      retrieveUnNumbersAPIStatus === STATUS.SUCCESS
        ? unNumbers.map((datum: IUnNumber) => (
            <section className="inSectn un-number-detail" key={datum.un_no}>
              <div className="inn-container">
                <div className="mt-104">
                  <div className="unBox p-4">
                    <h5>
                      <strong>Dangerous Goods</strong>
                    </h5>
                    <Box className="d-flex align-items-center justify-content-start">
                      <Box className="ab-txt">
                        <span>
                          {datum.un_no}, {datum.class_division_value}
                        </span>
                      </Box>
                    </Box>
                    <Box className="ab-bottom">
                      <Box className="show-more row justify-content-between">
                        <span className="col-11">{datum.name_description}</span>
                      </Box>
                    </Box>
                    <Box>
                      <Box className="row dgd-bx mt-3">
                        <Box className="col-12 col-lg-3">
                          <span>{t('CLASS_DIVISION')}</span>
                          <p>{datum?.class_division_value ?? 'N/A'}</p>
                        </Box>
                        <Box className="col-12 col-lg-3">
                          <span>Classification</span>
                          <p>{datum.class_division ?? 'N/A'}</p>
                        </Box>
                        <Box className="col-12 col-lg-4">
                          <span>{t('EXCEPTED_QUANTITIES')}</span>
                          <p>{datum?.excepted_quantities ?? 'N/A'}</p>
                        </Box>
                        <Box className="col-12 col-lg-5">
                          <span>{t('LIMITED_QUANTITIES')}</span>
                          <p>{datum?.limited_quantities ?? 'N/A'}</p>
                        </Box>
                      </Box>
                      <Box className="row dgd-bx mt-3">
                        <Box className="col-12 col-lg-3">
                          <span>{t('INSTRUCTIONS')}</span>
                          <p>{datum?.instructions ?? 'N/A'}</p>
                        </Box>
                        <Box className="col-12 col-lg-4">
                          <span>{t('PACKING_INSTRUCTION')}</span>
                          <p>{datum?.packing_Instruction ?? 'N/A'}</p>
                        </Box>
                        <Box className="col-12 col-lg-5">
                          <span>{t('PACKING_GROUP')}</span>
                          <p>{datum?.packing_group ?? 'N/A'}</p>
                        </Box>
                      </Box>
                      <Box className="row align-items-center mt-3">
                        <Box className="col-12 col-lg-6">
                          <Box className="row align-items-center mt-3">
                            <Box className="col-12 col-lg-6">
                              <span>{t('SPECIAL_PROVISIONS')}</span>
                              <p>
                                {datum?.special_provisions?.replace(
                                  /,/g,
                                  ', '
                                ) ?? 'N/A'}
                              </p>
                            </Box>
                            <Box className="col-12 col-lg-6">
                              <span>{t('SPECIAL_PROVISIONS_DATA')}</span>
                              <p>
                                {datum?.special_provisions_data?.replace(
                                  /,/g,
                                  ', '
                                ) ?? 'N/A'}
                              </p>
                            </Box>
                            <Box className="col-12 col-lg-6">
                              <span>{t('SPECIAL_PACKING_PROVISIONS')}</span>
                              <p>
                                {datum?.special_packing_provisions?.replace(
                                  /,/g,
                                  ', '
                                ) ?? 'N/A'}
                              </p>
                            </Box>
                            <Box className="col-12 col-lg-6">
                              <span>{t('SPECIAL_PROVISION_LOADING')}</span>
                              <p>
                                {datum?.special_provisions_loading ?? 'N/A'}
                              </p>
                            </Box>
                            <Box className="col-12 col-lg-6">
                              <span>{t('SPECIAL_PROVISION_OPERATION')}</span>
                              <p>
                                {datum?.special_provisions_operation ?? 'N/A'}
                              </p>
                            </Box>
                            <Box className="col-12 col-lg-6">
                              <span>{t('SPECIAL_PROVISION_PACKAGES')}</span>
                              <p>
                                {datum?.special_provisions_packages ?? 'N/A'}
                              </p>
                            </Box>
                            <Box className="col-12 col-lg-6">
                              <span>{t('SUBSIDIARY_HAZARD')}</span>
                              <p>{datum?.subsidiary_hazard ?? 'N/A'}</p>
                            </Box>
                            <Box className="col-12 col-lg-6"></Box>
                            <Box className="col-12 col-lg-6">
                              <span>{t('TRANSPORT_CATEGORY')}</span>
                              <p>{datum?.transport_category ?? 'N/A'}</p>
                            </Box>
                            <Box className="col-12 col-lg-6">
                              <span>{t('TUNNEL_CODE')}</span>
                              <p>{datum?.tunnel_code ?? 'N/A'}</p>
                            </Box>
                          </Box>
                        </Box>
                        <Box className="col-12 col-lg-6">
                          <ClassDivisionImage
                            class_division={datum?.class_division_value}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </div>
                </div>
              </div>
            </section>
          ))
        : null}
      {retrieveUnNumbersAPIStatus === STATUS.SUCCESS &&
      (!unNumbers || !unNumbers.length) ? (
        <Box mt="md">
          <Alert mb="xs" status="warning">
            {getMessage(ERROR_MESSAGE.NO_MATCHING_RESULT)}
          </Alert>
        </Box>
      ) : null}
    </div>
  );
};

export default withNamespaces()(withToastManager(withRouter(UnNumberDetail)));
