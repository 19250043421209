import { Reducer, combineReducers } from 'redux';
import {
  ISupportAndFeedbackPageState,
  supportAndFeedbackPageReducer
} from './ui';

export interface ISupportAndFeedbackPageUI {
  supportAndFeedbackPage: ISupportAndFeedbackPageState;
}

export interface ISupportAndFeedbackState {
  ui: ISupportAndFeedbackPageUI;
}

const ui = combineReducers({
  supportAndFeedbackPage: supportAndFeedbackPageReducer
});

export const supportAndFeedbackReducer: Reducer<ISupportAndFeedbackState> = combineReducers(
  { ui }
);
