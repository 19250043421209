/* istanbul ignore file */
import { Box, Divider } from '@chakra-ui/core';
import React, { FunctionComponent } from 'react';
import Pagination from 'react-responsive-pagination';
import { manifestHistoryDateFormat } from '../../../helper';

import { ILog } from '../models/manifest_log';

export interface IProps {
  logsList: ILog[];
  totalCount: number;
  currentPage: number;
  handlePageChange: (page: number) => void;
}

export const LogsListItem: FunctionComponent<IProps> = (props: IProps) => {
  const { logsList, currentPage, totalCount, handlePageChange } = props;
  const pageCount = Math.ceil(totalCount / 10);
  return (
    <>
      <Box mb="md">
        {logsList.map((datum, index) => (
          <Box
            key={index}
            p="md"
            mb="2xl"
            style={{
              background: '#fff',
              borderRadius: '10px',
              width: '850px',
              marginLeft: '15%'
            }}
          >
            <Box className="row justify-content-between">
              <Box className="col-9">
                <p>
                  <strong>{datum.message}</strong>
                </p>
              </Box>
              <Box className="col-3">
                <span
                  style={{
                    fontSize: 'small',
                    color: '#b5b5b5'
                  }}
                >
                  {manifestHistoryDateFormat(datum.created_at)}
                </span>
              </Box>
            </Box>
            {datum.logdetail.map((datumDetail, index) => (
              <Box key={index}>
                <Box
                  key={datumDetail.id}
                  p="md"
                  style={{
                    color: '#646464',
                    fontSize: 'small'
                  }}
                >
                  <Box className="row justify-content-start">
                    <Box className="col-12">
                      <strong>TYPE: </strong>
                      {datumDetail.type}
                    </Box>
                    <Box className="col-12">
                      <strong>{datumDetail.type.toUpperCase()}: </strong>
                      {datumDetail.value}
                    </Box>
                  </Box>
                  <Box className="row justify-content-start">
                    <Box className="col-auto" mr="md">
                      <strong>PACKAGING: </strong>
                      {datumDetail.packing}
                    </Box>
                    <Box className="col-auto" mr="md">
                      <strong>MEASUREMENT: </strong>
                      {datumDetail.measurement}
                    </Box>
                    <Box className="col-auto">
                      <strong>OUANTITY: </strong>
                      {datumDetail.quantity}
                    </Box>
                  </Box>
                </Box>

                <Box mt="md" />
                {datum.logdetail.length - 1 > index ? (
                  <Divider color="#646464" />
                ) : null}
              </Box>
            ))}
          </Box>
        ))}
      </Box>
      <Pagination
        current={currentPage}
        onPageChange={(page: number) => {
          handlePageChange(page);
        }}
        total={pageCount}
      />
    </>
  );
};

export default LogsListItem;
