import isArray from 'lodash/isArray';
import get from 'lodash/get';
import isString from 'lodash/isString';
import isNil from 'lodash/isNil';
import { History } from 'history';
import { generatePath } from 'react-router';
import queryString, { stringify, ParsedQuery } from 'query-string';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { NOT_ALLOW_EMAIL_DOMAIN, PHONE_NUMBER_LENGTH } from './constant';
import find from 'lodash/find';
import i18n from './i18n';
import { IProductInCart } from './modules/reports/models/un-number.model';
import { IProduct } from './modules/reports/models/reports-list.model';
import { USER_ROLE } from './common/models/user';

dayjs.extend(utc);

export const dateFormatForListing = (
  date: number | string | Date,
  format: string | null = null
): string =>
  date
    ? dayjs(new Date(date))
        .local()
        .format(format ?? 'DD MMM YYYY')
    : '';

export const boolToString = (value: boolean | unknown): string => {
  if (!isNil(value)) {
    return value ? 'Yes' : 'No';
  } else {
    return '-';
  }
};

export const replaceNullWithString = (
  val: string | null | undefined
): string => {
  return val === null || val === undefined ? '-' : val;
};

export const serialize = (
  obj: { [key: string]: string | number | boolean } = {}
): string => {
  const str: string[] = [];
  const keys = Object.keys(obj);
  keys.forEach((key) => {
    str.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
  });
  return str.join('&');
};

export const removeEmptyArray = (data: any): {} => {
  const original = { ...data };
  const keys = Object.keys(original);
  keys.forEach((key) => {
    if (isArray(original[key]) && !original[key].length) {
      delete original[key];
    }
  });
  return original;
};

/* istanbul ignore next */
export const setLocalstorage = (key: string, value: any): void => {
  localStorage.setItem(key, JSON.stringify(value));
};

/* istanbul ignore next */
export const getLocalstorage = (key: string): any | null => {
  const value = localStorage.getItem(`${key}`);
  if (value) {
    return JSON.parse(value);
  } else {
    return null;
  }
};

/* istanbul ignore next */
export const removeItemFromLocalStorage = (key: string): void => {
  localStorage.removeItem(key);
};

export const emailValidator = (email: string): boolean => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
  return re.test(email) && !NOT_ALLOW_EMAIL_DOMAIN.filter((datum) => email.endsWith(datum)).length;
};

export const passwordValidator = (password: string): boolean => {
  const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return typeof password === 'string' && re.test(password);
};

export const phoneNumberValidator = (phone: string): boolean => {
  return typeof phone === 'string' && phone.length === PHONE_NUMBER_LENGTH;
};

export const checkErrorType = (
  response: any,
  status: number | string
): boolean => get(response, 'errorCode') === status;

export const isObject = (value: any): boolean => {
  return value && typeof value === 'object' && value.constructor === Object;
};

/* istanbul ignore next */

export const createUrl = (
  pattern: string,
  params?: { [paramName: string]: string | number | boolean | undefined },
  query?: { [paramName: string]: string | number | boolean | undefined }
): string => {
  let path = generatePath(pattern, params);

  if (query) {
    const queryString = stringify(query);
    path = `${path}?${queryString}`;
  }

  return path;
};

export const findDocumentType = (
  documentArray: any,
  countryCode: string,
  type: string
): string => {
  return documentArray.find((document: any) => {
    return document.type === `${countryCode}_${type}`;
  });
};

export const hasFormError = (
  formKeys: { [key: string]: string | number | boolean },
  errorKeys: { [key: string]: string | number | boolean }
): boolean => {
  const matchingKeyWithError = Object.keys(formKeys).find((key) => {
    return Boolean(errorKeys[key]);
  });
  return Boolean(matchingKeyWithError);
};

export const dateFormatForApi = (date: string): number | null => {
  if (!date) {
    return null;
  }

  const initial = date.split(/\//);
  return new Date([initial[1], initial[0], initial[2]].join('/')).getTime();
};

export const dateFormatForCollectionApi = (date?: Date): string => {
  if (!date) {
    return '';
  }
  return dayjs(date).format('YYYY-MM-DD');
};

export const dateFormatForSettlementApi = (
  date: Date | string | null
): string => {
  if (!date) {
    return '';
  }
  return dayjs(new Date(date)).format('DD-MM-YYYY');
};

export const dateFormatForForm = (date: number | string): string => {
  if (!date) {
    return '';
  }
  return dayjs(new Date(date)).format('DD/MM/YYYY');
};

export const dateAndTimeFormat = (date: Date): string => {
  if (!date) {
    return date;
  }
  return dayjs(new Date(date)).format('MMM DD | hh:mm A');
};

export const timeFormat = (date: Date): string => {
  if (!date) {
    return date;
  }
  return dayjs(new Date(date).toLocaleString("en-US", {timeZone: "Australia/Sydney"})).format('hh:mm A');
};

export const notificationFormat = (date: Date): string => {
  if (!date) {
    return date;
  }
  return dayjs(new Date(date)).format('MMM DD YYYY');
};

export const dateFormat = (date: string): string => {
  if (!date) {
    return date;
  }
  return dayjs(new Date(date)).format('MMM DD, YYYY');
};

export const createObjectId = () => {
  const timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
  const suffix = 'xxxxxxxxxxxxxxxx'
    .replace(/[x]/g, () => ((Math.random() * 16) | 0).toString(16))
    .toLowerCase();
  return `${timestamp}${suffix}`;
};

export const dateToISOString = (date: Date | null) => {
  if (!date) {
    return null;
  }
  return dayjs(date).toISOString();
};

export const isNumeric = (number: string) => {
  return /^\d*$/.test(number);
};

export const setStartDate = (startDate: Date) => {
  if (!startDate) {
    return null;
  }
  return dayjs(startDate).local().startOf('day').format('YYYY-MM-DDTHH:mm');
};

export const setEndDate = (endDate: Date) => {
  if (!endDate) {
    return null;
  }
  return dayjs(endDate).local().endOf('day').format('YYYY-MM-DDTHH:mm');
};

export const manifestHistoryDateFormat = (date: string) => {
  if (!date) {
    return null;
  }
  return dayjs(date).format('DD/MM/YYYY | hh:mm A');
};

export const getQueryParamValue = (
  queryParams: ParsedQuery,
  param: string
): string | undefined => {
  const paramValue = queryParams[param];

  if (!paramValue) {
    return;
  }

  if (isArray(paramValue)) {
    return paramValue[0];
  } else if (isString(paramValue)) {
    return paramValue;
  } else {
    return paramValue + '';
  }
};

export const getMultiValueQueryParamValue = (
  queryParams: ParsedQuery,
  param: string
): string[] | undefined => {
  const paramValue = queryParams[param];

  if (!paramValue) {
    return;
  }

  if (isArray(paramValue)) {
    return paramValue;
  } else if (isString(paramValue)) {
    return [paramValue];
  } else {
    return [paramValue + ''];
  }
};

export const updateQueryString = (history: History, queryParams: any) => {
  const {
    replace,
    location: { pathname }
  } = history;

  replace({
    pathname,
    search: queryString.stringify({
      ...queryParams
    })
  });
};

export const currencyCodeByCountry = (countryCode: string) => {
  const currencyCodes = [
    {
      currnecyCode: 'AED',
      countryCode: 'UAE'
    },
    {
      currnecyCode: 'SAR',
      countryCode: 'KSA'
    }
  ];
  return find(currencyCodes, (datum) => datum.countryCode === countryCode);
};

const ordinal_suffix_of = (i: number) => {
  const j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + 'st';
  }
  if (j === 2 && k !== 12) {
    return i + 'nd';
  }
  if (j === 3 && k !== 13) {
    return i + 'rd';
  }
  return i + 'th';
};

export const addSerialNumber = (data: object[]) => {
  return data.map((datum, index) => ({
    ...datum,
    serialNumber: ordinal_suffix_of(index + 1)
  }));
};

export const changeLanguage = (lang: string) => {
  i18n.changeLanguage(lang);
  setLocalstorage('lang', lang);
};

export const createReportName = () => {
  return dayjs().format('YYYY-MM-DD | hh:mm:ss A');
};

export type MESSAGE = { [key: string]: string };

export const getMessage = (message: MESSAGE): string => {
  const lang = getLocalstorage('lang');
  if (lang === 'en') return message['en'];
  if (lang === 'in') return message['in'];
  if (lang === 'uk') return message['uk'];
  if (lang === 'au') return message['au'];
  if (lang === 'zh') return message['zh'];
  else return message['en'];
};

export const setNotificationCount = (count: number) => {
  setLocalstorage('notificationCount', count);
};

export const newNotificationCount = (count: number) => {
  const notificationCount = getLocalstorage('notificationCount');
  return count - notificationCount > 0 ? Number(notificationCount) : count;
};

export const upperCaseFirstLetter = (str: string): string => {
  return `${str[0].toUpperCase()}${str.slice(1)}`;
};

export const transformName = (length: number, name?: string) => {
  let str = name?.substring(0, length);
  if (name && name?.length > length && str) {
    str += '...';
  }
  return str ?? '';
};

export const signageDisplayClassDivision = (
  products: IProduct[] | IProductInCart[],
  isCart: boolean
): { length: number; classDivision: string } => {
  if (isCart) {
    const filterLength =
      (products as IProduct[]).filter(
        (datum: IProduct) => datum.class === (products as IProduct[])[0].class
      ).length === products.length
        ? 1
        : products.length;
    return {
      length: filterLength,
      classDivision:
        filterLength === 1
          ? (products as IProductInCart[])[0].class_division_value
          : ''
    };
  }
  const filterLength =
    (products as IProduct[]).filter(
      (datum: IProduct) => datum.class === (products as IProduct[])[0].class
    ).length === products.length
      ? 1
      : products.length;
  return {
    length: filterLength,
    classDivision: filterLength === 1 ? (products as IProduct[])[0].class : ''
  };
};

export const isAdmin = (role?: USER_ROLE) => {
  return  !!(role && (role === USER_ROLE.COMPANY_ADMIN || role === USER_ROLE.MAIN))
}