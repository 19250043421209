import { createSelector } from 'reselect';
import map from 'lodash/map';
import { getReportById, ReportById } from '../reducers/entity';
import { getCurrentReportIds, getSelectedReportId } from '../reducers/ui';
import { getUnByNo, getUnAllNos, UnById } from '../reducers/entity/unNumber';
import { IUnNumber } from '../models/un-number.model';

export const getReportsSelector = createSelector(
  getCurrentReportIds,
  getReportById,
  (reportAllIds: string[] | null, reportById: ReportById) => {
    if (!reportAllIds) {
      return null;
    }

    return map(reportAllIds, (datum) => {
      return reportById[datum];
    });
  }
);

export const getReportDetailSelector = createSelector(
  getSelectedReportId,
  getReportById,
  (reportId: string | null, reportById: ReportById) => {
    if (!reportId) {
      return null;
    }

    return reportById[reportId];
  }
);

export const getUnsSelector = createSelector(
  getUnAllNos,
  getUnByNo,
  (unNos: string[] | null, unByNos: UnById): IUnNumber[] | null => {
    if (!unNos) {
      return null;
    }

    return map(unNos, (datum) => {
      return unByNos[datum];
    });
  }
);
