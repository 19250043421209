/* istanbul ignore file */
import React, { FunctionComponent, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Box, Text } from '@chakra-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import { generateOTP } from '../actions/auth.action';
import {
  ROUTES,
  STATUS,
  ERROR_MESSAGE,
  SUCCESS_MESSAGE
} from '../../../constant';
import { getLocalstorage, getMessage } from '../../../helper';
import { ErrorAlert } from '../../../common/components/ErrorAlert.component';
import { FullPageLoader } from '../../../common/components/Loader.component';
import { getGenerateOTPAPIStatus } from '../reducers/auth.reducer';

interface IOwnProps extends RouteComponentProps {
  className?: string;
  backToLogin: () => void;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  t: any;
}

export const GenerateOTP: FunctionComponent<IProps> = (props: IProps) => {
  const { className, history, toastManager, backToLogin } = props;
  const dispatch = useDispatch();
  const generateOTPAPIStatus = useSelector(getGenerateOTPAPIStatus);
  const userId = useSelector(() => getLocalstorage('user_id'));
  useEffect(() => {
    if (!userId) {
      history.push(ROUTES.HOME);
    }
  }, [userId]);
  const _generateOTP = async () => {
    try {
      await dispatch(generateOTP());
      history.push(ROUTES.VERIFY_OTP);
      toastManager.add(getMessage(SUCCESS_MESSAGE.RESEND_VERIFICATION_CODE), {
        appearance: 'success',
        autoDismiss: true
      });
    } catch (err) {
      let message = getMessage(ERROR_MESSAGE.RESEND_VERIFICATION_CODE_FAILED);
      if (err?.errorObj?.message) {
        message = err?.errorObj?.message;
      } else if (
        err?.message &&
        typeof err?.message === 'string' &&
        err?.message
      ) {
        message = err?.message;
      } else if (
        err?.message &&
        typeof err?.message === 'object' &&
        err?.message.length
      ) {
        message = err?.message[0];
      }
      toastManager.add(message, { appearance: 'error', autoDismiss: true });
      console.log(':: _generateOTP err ', err);
    }
  };
  return (
    <div className={className}>
      {generateOTPAPIStatus === STATUS.LOADING ? (
        <Box p={'lg'}>
          <FullPageLoader />
        </Box>
      ) : null}
      <Box className="_snInfo">
        <Text fontSize="md" fontWeight="bold">
          This account is already logged into another device or browser. Do you{' '}
          want to login here?
        </Text>
        <Box className="row justify-content-between" mt="50px">
          <Box className="col-6">
            <button
              className="btn btn-primary w-100 transition"
              onClick={() => {
                _generateOTP();
              }}
            >
              Proceed
            </button>
          </Box>
          <Box className="col-6">
            <button
              className="btn w-100 transition"
              onClick={() => {
                backToLogin();
              }}
            >
              Cancel
            </button>
          </Box>
        </Box>
      </Box>
      {generateOTPAPIStatus === STATUS.FAILURE ? (
        <Box>
          <ErrorAlert
            errorMessage={getMessage(ERROR_MESSAGE.GENERATE_OTP)}
            retry={_generateOTP}
          />
        </Box>
      ) : null}
    </div>
  );
};
