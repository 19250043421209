import { AnyAction, Reducer } from 'redux';
import { IAppState } from '../../../../store';
import { STATUS } from '../../../../constant';
import {
  RETRIEVE_CURRENT_MANIFEST_DETAIL__START,
  RETRIEVE_CURRENT_MANIFEST__FAILURE,
  RETRIEVE_CURRENT_MANIFEST__SUCCESS,
  RESET_DATA
} from '../../actions';

export interface ICurrentManifestPageState {
  retrieveCurrentManifestAPIStatus: STATUS;
}

export const defaultState: ICurrentManifestPageState = {
  retrieveCurrentManifestAPIStatus: STATUS.NOT_STARTED
};

export const currentManifestPageReducer: Reducer<ICurrentManifestPageState> = (
  state: ICurrentManifestPageState = defaultState,
  action: AnyAction
): ICurrentManifestPageState => {
  switch (action.type) {
    case RETRIEVE_CURRENT_MANIFEST_DETAIL__START: {
      return {
        ...state,
        retrieveCurrentManifestAPIStatus: STATUS.LOADING
      };
    }
    case RETRIEVE_CURRENT_MANIFEST__FAILURE: {
      return {
        ...state,
        retrieveCurrentManifestAPIStatus: STATUS.FAILURE
      };
    }
    case RETRIEVE_CURRENT_MANIFEST__SUCCESS: {
      return {
        ...state,
        retrieveCurrentManifestAPIStatus: STATUS.SUCCESS
      };
    }
    case RESET_DATA: {
      return {
        ...state,
        retrieveCurrentManifestAPIStatus:
          defaultState.retrieveCurrentManifestAPIStatus
      };
    }
    default:
      return state;
  }
};

export const getCurrentManifestAPIStatus = (state: IAppState) => {
  return state.currentManifest.ui.currentManifestPage
    .retrieveCurrentManifestAPIStatus;
};
