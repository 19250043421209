/* istanbul ignore file */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { RouteComponentProps } from 'react-router-dom';
import { Box, Alert } from '@chakra-ui/core';
import { withNamespaces } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { STATUS, ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../../constant';
import { ConfirmAlert } from '../../../common/components';
import { dateFormat, getMessage } from '../../../helper';
import { FullPageLoader } from '../../../common/components/Loader.component';
import SubscriptionComponent from '../components/subscription.component';
import { getSubscriptionSelector } from '../selectors/index-page.selector';
import {
  getSubscriptionsAPIStatus,
  getLicenseDataAPIStatus,
  getLicenseData,
  getCancelSubscriptionAPIStatus
} from '../reducers/ui';
import {
  retrieveSubscriptionWithUserId,
  updatesubscription,
  retrieveLicenseData,
  cancelSubscription
} from '../actions';
import { ErrorAlert } from '../../../common/components/ErrorAlert.component';
import { getLoggedInUserDetails } from '../../auth/selectors/auth.selectors';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  t: any;
}

export const SubscriptionWithUserId: FunctionComponent<IProps> = (
  props: IProps
) => {
  const dispatch = useDispatch();
  const subscriptionList = useSelector(getSubscriptionSelector);
  const loggedInUserDetails = useSelector(getLoggedInUserDetails);
  const retrieveLicenseDataAPIStatus = useSelector(getLicenseDataAPIStatus);
  const licenseData = useSelector(getLicenseData);
  const cancelSubscriptionAPIStatus = useSelector(
    getCancelSubscriptionAPIStatus
  );
  const retrieveSubscriptionsAPIStatus = useSelector(getSubscriptionsAPIStatus);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<
    number | null
  >(null);
  const { className, t, toastManager } = props;
  const userId: string =
    loggedInUserDetails?.user?.parent_id &&
    loggedInUserDetails?.user?.parent_id !== 0
      ? loggedInUserDetails?.user?.parent_id?.toString()
      : loggedInUserDetails?.user?.user_id
      ? loggedInUserDetails?.user.user_id
      : '';

  const _retrieveSubscriptionWithUserId = async () => {
    try {
      await dispatch(retrieveSubscriptionWithUserId(userId));
    } catch (err) {
      console.log(':: _retrieveSubscriptionWithUserId err ', err);
    }
  };

  useEffect(() => {
    _retrieveSubscriptionWithUserId();
  }, []);

  const _updatesubscription = async () => {
    try {
      if (selectedSubscriptionId) {
        await dispatch(updatesubscription(selectedSubscriptionId, userId));
        toastManager.add(
          getMessage(SUCCESS_MESSAGE.UPDATE_SUBSCRIPTION),
          {
            appearance: 'success',
            autoDismiss: true
          },
          () => {
            _retrieveSubscriptionWithUserId();
            setSelectedSubscriptionId(null);
          }
        );
      }
    } catch (err) {
      console.log(':: _updatesubscription err ', err);
      let message = getMessage(ERROR_MESSAGE.UPDATE_SUBSCRIPTION);
      if (err?.errorObj?.message) {
        message = err?.errorObj?.message;
      } else if (
        err?.message &&
        typeof err?.message === 'string' &&
        err?.message
      ) {
        message = err?.message;
      } else if (
        err?.message &&
        typeof err?.message === 'object' &&
        err?.message.length
      ) {
        message = err?.message[0];
      }
      toastManager.add(message, { appearance: 'error', autoDismiss: true });
    }
  };

  const _retrieveLicenseData = async () => {
    try {
      await dispatch(retrieveLicenseData(userId));
    } catch (err) {
      console.log(':: _retrieveLicenseData err ', err);
    }
  };

  useEffect(() => {
    if (retrieveLicenseDataAPIStatus === STATUS.NOT_STARTED) {
      _retrieveLicenseData();
    }
  }, [loggedInUserDetails.user, retrieveLicenseDataAPIStatus]);

  const _cancelSubscription = async () => {
    try {
      await dispatch(cancelSubscription(userId));
      _retrieveLicenseData();
      toastManager.add(getMessage(SUCCESS_MESSAGE.CANCEL_SUBSCRIPTION), {
        appearance: 'success',
        autoDismiss: true
      });
    } catch (err) {
      console.log(':: _cancelSubscription err ', err);
      const message =
        err?.errorObj?.message ??
        err?.message ??
        getMessage(ERROR_MESSAGE.CANCEL_SUBSCRIPTION);
      toastManager.add(message, { appearance: 'error', autoDismiss: true });
    }
  };

  return (
    <Box className={className}>
      {retrieveSubscriptionsAPIStatus === STATUS.LOADING ||
      retrieveLicenseDataAPIStatus === STATUS.LOADING ||
      cancelSubscriptionAPIStatus === STATUS.LOADING ? (
        <Box p={'lg'}>
          <FullPageLoader />
        </Box>
      ) : null}
      {retrieveLicenseDataAPIStatus !== STATUS.LOADING &&
      retrieveLicenseDataAPIStatus !== STATUS.NOT_STARTED ? (
        <Box mt="2xl">
          <Box className="container">
            <Box className="_abBox">
              {retrieveLicenseDataAPIStatus === STATUS.FAILURE ? (
                <Box>
                  <ErrorAlert
                    errorMessage={getMessage(ERROR_MESSAGE.DATA_FAILURE)}
                    retry={_retrieveLicenseData}
                  />
                </Box>
              ) : null}
              {retrieveLicenseDataAPIStatus === STATUS.SUCCESS &&
              !licenseData ? (
                <Box mt="md">
                  <Alert mb="xs" status="warning">
                    {getMessage(ERROR_MESSAGE.NO_SUBSCRIPTION_DATA)}
                  </Alert>
                </Box>
              ) : null}
              {retrieveLicenseDataAPIStatus === STATUS.SUCCESS &&
              licenseData ? (
                <>
                  {licenseData?.subscription_status ? (
                    <>
                      <h3>
                        {licenseData?.totalLicense} {t('LICENSE')}
                      </h3>
                      <Box className="d-flex align-items-center justify-content-between mt-3">
                        <Box>
                          <span>
                            {t('CURRENT_LICENSE_EXPIRED_ON')}{' '}
                            {dateFormat(licenseData?.expiryDate.toString())}
                          </span>
                          <span className="mt-2">
                            {t('NEW_SUBSCRIPTION_STARTS_ON')}{' '}
                            {dateFormat(
                              licenseData?.nextBillingDate.toString()
                            )}
                          </span>
                        </Box>
                        <Box>
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              _cancelSubscription();
                            }}
                          >
                            {t('CANCEL_SUBSCRIPTION')}
                          </button>
                        </Box>
                      </Box>
                    </>
                  ) : (
                    <>
                      <h3>
                        {licenseData.totalLicense} {t('LICENSE')}
                      </h3>
                      <Box className="d-flex align-items-center justify-content-between mt-3">
                        <Box>
                          <span>{t('YOU_HAVE_CANCELLED')}</span>
                          <span className="mt-2">
                            {t('YOUR_CURRENT_SUBSCRIPTION')}{' '}
                            {dateFormat(licenseData.expiryDate.toString())}
                          </span>
                        </Box>
                        <Box>
                          <Box className="cancelled-text">{t('CANCELLED')}</Box>
                        </Box>
                      </Box>
                    </>
                  )}
                </>
              ) : null}
            </Box>
          </Box>
        </Box>
      ) : null}
      <section className="upgrade_subscription">
        <Box className="container">
          <Box className="_abBox">
            <h3 style={{ textAlign: 'center' }}>{t('UPGRADE_SUBSCRIPTION')}</h3>
            <SubscriptionComponent
              {...props}
              subscriptionList={subscriptionList}
              isUpgradeSubscription={true}
              retrieveSubscriptionsAPIStatus={retrieveSubscriptionsAPIStatus}
              selectSubscription={(selectedSubscription: number) => {
                setSelectedSubscriptionId(selectedSubscription);
              }}
              retry={() => {
                _retrieveSubscriptionWithUserId();
              }}
            />
          </Box>
        </Box>
      </section>
      {selectedSubscriptionId ? (
        <ConfirmAlert
          title={t('UPGRADE_SUBSCRIPTION')}
          description={t('UPGRADE_SUBSCRIPTION_OPTION')}
          onCancel={() => setSelectedSubscriptionId(null)}
          onConfirm={() => {
            _updatesubscription();
          }}
          type={'danger'}
          cancelText="Cancel"
          confirmText="Confirm"
        />
      ) : null}
    </Box>
  );
};

export default withNamespaces()(withToastManager(SubscriptionWithUserId));
