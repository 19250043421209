import { createSelector } from 'reselect';
import map from 'lodash/map';
import { getLogsById, getLogsAllIds, LogById } from '../reducers/entity';

export const getLogsListSelector = createSelector(
  getLogsAllIds,
  getLogsById,
  (logsAllIds: number[], logsById: LogById) => {
    if (!logsAllIds) {
      return null;
    }

    return map(logsAllIds, (datum) => {
      return logsById[datum];
    });
  }
);
