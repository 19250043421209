/* istanbul ignore file */

import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';

interface IProps {
  className?: string;
}

const Permission: FunctionComponent<IProps> = ({ className }) => {
  return (
    <div className={className}>
      <div className="content-box">
        <h3>Un Oh!</h3>
        <p>You shouldn&apos;t be landing here!</p>
      </div>
    </div>
  );
};

Permission.defaultProps = {
  className: ''
};

export const PermissionDenied = styled(Permission)`
  height: 100%;
  .container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    h3 {
      font-size: ${themeGet('fontSizes.lg')};
      margin: ${themeGet('space.md')} 0;
    }
    p {
      font-size: ${themeGet('fontSizes.sm')};
    }
  }
`;
