/* istanbul ignore file */
import React, { FunctionComponent, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Box, Alert } from '@chakra-ui/core';

import UnsafeIcon from '../../../assets/images/shield-unsafe.png';
import SafeIcon from '../../../assets/images/shield-safe.png';
import ProductList from '../components/ProductList.component';
import { IProduct, IReport } from '../models/reports-list.model';
import { ConfirmAlert } from '../../../common/components';
import {
  dateAndTimeFormat,
  getMessage,
  isAdmin,
  signageDisplayClassDivision
} from '../../../helper';
import { SpillInfo } from '../../../common/components/SpillInfo.component';
import { SignageDisplay } from '../../../common/components/SignageDisplay.component';
import { getHtmlForEmail } from '../../../common/components/utils/getHtmlForEmail';
import { MANIFEST_APPROVE_STATUS } from '../services/api.service';
import { ApproveAndRejectPopup } from './ApprovedAndRejectPopup.component';
import { User, USER_ROLE } from '../../../common/models/user';
import { unloadProduct } from '../actions';
import { ERROR_MESSAGE } from '../../../constant';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  reportData: IReport;
  t: any;
  user: User;
  selectedOperatorId: string;
  deleteReportData: () => void;
  unloadProduct: typeof unloadProduct;
  handleUpdateManifestApproveStatus: (
    status: MANIFEST_APPROVE_STATUS,
    reason: string | null
  ) => void;
}

export const ReportDetailComponent: FunctionComponent<IProps> = (
  props: IProps
) => {
  const {
    t,
    toastManager,
    reportData,
    user,
    selectedOperatorId,
    deleteReportData,
    handleUpdateManifestApproveStatus
  } = props;
  const [isDeleteReportAlertOpen, setIsDeleteReportAlertOpen] = useState(false);
  const [isSpillInfoOpen, setIsSpillInfoOpen] = useState(false);
  const [status, setStatus] = useState<MANIFEST_APPROVE_STATUS | null>(null);

  const _sendEmail = () => {
    const url = `mailto:?subject=Packaged Dangerous Goods Guide - Dangerous Goods Report ${
      reportData && reportData.report_name
    } &body=${getHtmlForEmail(reportData)}`;
    return url;
  };
  return (
    <>
      {reportData.product.length ? (
        <Box className="mt-5 ab-bx-3 p-3 ">
          <Box
            className="sc-1"
            backgroundColor={reportData.is_safe ? '#4cb034' : '#ed4f1c'}
          >
            <span>
              {reportData.is_safe ? (
                <img src={SafeIcon} alt="safe" />
              ) : (
                <img src={UnsafeIcon} alt="unsafe" />
              )}
            </span>{' '}
            <p>
              {reportData.is_safe ? (
                <span>{t('SAFE_COMBINATION')}</span>
              ) : (
                <span>{t('UNSAFE_COMBINATION')}</span>
              )}
            </p>
          </Box>
          <p className="text-center d-block mt-3 mb-0">
            {reportData.is_safe
              ? t('COMBINATION_SAFE')
              : t('COMBINATION_UNSAFE')}{' '}
          </p>
          {reportData.is_safe &&
          reportData?.tunnel_value &&
          (user.user_id.toString() !== selectedOperatorId ||
            !isAdmin(user.role)) ? (
            <p className="text-center d-block mt-3 mb-0">
              Tunnel Info: {reportData?.tunnel_value ?? ''}
            </p>
          ) : null}
          <SignageDisplay
            t={t}
            length={
              signageDisplayClassDivision(
                reportData?.product.length ? reportData?.product : [],
                false
              ).length
            }
            isSafe={!!reportData.is_safe}
            class_division={
              signageDisplayClassDivision(
                reportData?.product.length ? reportData?.product : [],
                false
              ).classDivision
            }
          />
          <div className="d-block mt-3 mb-0">
            <Box className="row justify-content-between">
              <Box className="col-2"></Box>
              <Box className="col-8" textAlign="center">
                {!reportData.is_safe &&
                (user.user_id.toString() !== selectedOperatorId ||
                  !isAdmin(user.role)) ? (
                  reportData.approvestatus &&
                  reportData.approvestatus !==
                    MANIFEST_APPROVE_STATUS.WAITING ? (
                    <Box>
                      <span
                        style={{
                          padding: '10px',
                          color:
                            reportData.approvestatus ===
                            MANIFEST_APPROVE_STATUS.APPROVED
                              ? 'green'
                              : '#dc143c'
                        }}
                      >
                        {`${reportData.approvestatus.toUpperCase()} `}
                        by <strong>{reportData.approvedby}</strong>
                      </span>
                      {reportData.reason ? (
                        <Box fontSize="small" mt="sm">
                          <span
                            style={{
                              color:
                                reportData.approvestatus ===
                                MANIFEST_APPROVE_STATUS.APPROVED
                                  ? 'green'
                                  : '#dc143c'
                            }}
                          >
                            Reason: <strong>{reportData.reason}</strong>
                          </span>
                        </Box>
                      ) : null}
                    </Box>
                  ) : (
                    <Box fontSize="small" mt="sm" color="#ff9017">
                      <span>
                        Manifest is not approved yet.
                        {user.role !== USER_ROLE.MANAGER &&
                        user.role !== USER_ROLE.SUPERVISOR
                          ? ` Please contact Manager or Supervisor.`
                          : ` Please either approve or reject this manifest.`}
                      </span>
                    </Box>
                  )
                ) : null}
              </Box>
              <Box
                className="col-2"
                top={
                  !reportData.is_safe && !isAdmin(user.role)
                    ? '20px'
                    : '0px'
                }
              >
                {dateAndTimeFormat(reportData.created_at)}
              </Box>
            </Box>
          </div>
        </Box>
      ) : null}
      {!reportData.product.length ? (
        <Box mt="md">
          <Alert mb="xs" status="warning">
            {getMessage(ERROR_MESSAGE.NO_PRODUCT_DETAIL_SCREEN)}
          </Alert>
        </Box>
      ) : null}
      <Box className="mt-4 row">
        {reportData.product.map((datum: IProduct, index: number) => (
          <ProductList key={datum.un_no ?? index} product={datum} {...props} />
        ))}
      </Box>
      {!reportData.approvedby &&
      !reportData.is_safe &&
      reportData.product.length &&
      (user.role === USER_ROLE.MANAGER ||
        user.role === USER_ROLE.SUPERVISOR) ? (
        <Box className="mt-4 row justify-content-center">
          <Box className="col-auto">
            <button
              className="btn-primary transition"
              onClick={() => {
                setStatus(MANIFEST_APPROVE_STATUS.APPROVED);
              }}
              style={{ background: 'green' }}
            >
              Approve
            </button>
          </Box>
          <Box className="col-auto">
            <button
              className="btn-primary transition"
              onClick={() => {
                setStatus(MANIFEST_APPROVE_STATUS.REJECTED);
              }}
            >
              Reject
            </button>
          </Box>
        </Box>
      ) : null}
      <Box className="mt-4 row justify-content-center">
        {reportData.product.length ? (
          <Box className="col-auto">
            <button
              className="btn-primary transition"
              onClick={() => {
                setIsSpillInfoOpen(true);
              }}
            >
              {t('EMERGENCY_PROCEDURES')}
            </button>
          </Box>
        ) : null}
        {/* <Box className="col-auto">
          <button
            className="btn-primary transition"
            onClick={() => {
              setIsDeleteReportAlertOpen(true);
            }}
          >
            {t('DELETE')}
          </button>
        </Box>
        <Box className="col-auto">
          <button
            className="btn-primary transition"
            onClick={() => {
              history.push(editUrl);
            }}
          >
            {t('EDIT')}
          </button>
        </Box> */}
      </Box>
      {reportData.product.length ? (
        <Box className="mt-4 row justify-content-center">
          <Box className="col-auto">
            <a
              className="btn-primary transition"
              href={_sendEmail()}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('EMAIL')}
            </a>
          </Box>
          {reportData.pdf ? (
            <Box className="col-auto">
              <a
                className="btn-primary transition"
                href={reportData.pdf}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('DOWNLOAD_REPORT')}
              </a>
            </Box>
          ) : null}
        </Box>
      ) : null}
      {isDeleteReportAlertOpen ? (
        <ConfirmAlert
          title="Delete Report"
          description={`${t('DELETE_REPORT_CONFIRMATION')} "${
            reportData.report_name
          }"`}
          onCancel={() => setIsDeleteReportAlertOpen(false)}
          onConfirm={() => {
            deleteReportData();
          }}
          type={'danger'}
          cancelText="Cancel"
          confirmText="Confirm"
        />
      ) : null}
      {isSpillInfoOpen ? (
        <SpillInfo
          t={t}
          onClose={() => {
            setIsSpillInfoOpen(false);
          }}
        />
      ) : null}
      {status ? (
        <ApproveAndRejectPopup
          status={status}
          t={t}
          toastManager={toastManager}
          onClose={() => {
            setStatus(null);
          }}
          handleSubmit={(reason: string | null) => {
            handleUpdateManifestApproveStatus(status, reason);
            setStatus(null);
          }}
        />
      ) : null}
    </>
  );
};

export default withRouter(ReportDetailComponent);
