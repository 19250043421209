import { IWarehouseTruck } from '../../models/warehouseTruckRegistration.model';
import { AnyAction, Reducer } from 'redux';
import {
  RETRIEVE_WAREHOUSE_TRUCK__START,
  RETRIEVE_WAREHOUSE_TRUCK__SUCCESS
} from '../../actions';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import { IAppState } from '../../../../store';

export type WarehouseTruckById = { [key: string]: IWarehouseTruck };

export interface IWarehouseTruckInfoState {
  warehouseTruckById: WarehouseTruckById;
  warehouseTruckAllIds: string[];
}

export const defaultState: IWarehouseTruckInfoState = {
  warehouseTruckById: {},
  warehouseTruckAllIds: []
};

export const warehouseTruckInfoReducer: Reducer<IWarehouseTruckInfoState> = (
  state: IWarehouseTruckInfoState = defaultState,
  action: AnyAction
): IWarehouseTruckInfoState => {
  switch (action.type) {
    case RETRIEVE_WAREHOUSE_TRUCK__START: {
      return {
        ...state,
        warehouseTruckAllIds: defaultState.warehouseTruckAllIds,
        warehouseTruckById: defaultState.warehouseTruckById
      };
    }
    case RETRIEVE_WAREHOUSE_TRUCK__SUCCESS: {
      const { warehouseTruck } = action.payload;
      const byId = keyBy(warehouseTruck, (datum) => {
        return datum.id;
      });
      const ids = map(warehouseTruck, (datum) => {
        return datum.id;
      });
      return {
        ...state,
        warehouseTruckById: { ...state.warehouseTruckById, ...byId },
        warehouseTruckAllIds: uniq([...state.warehouseTruckAllIds, ...ids])
      };
    }
    default:
      return state;
  }
};

export const getWarehouseTruckById = (state: IAppState) => {
  return state.warehouseTruck.entities.warehouseTruckInfo.warehouseTruckById;
};

export const getWarehouseTruckAllIds = (state: IAppState) => {
  return state.warehouseTruck.entities.warehouseTruckInfo.warehouseTruckAllIds;
};
