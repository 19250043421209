/* istanbul ignore file */
import React, { FunctionComponent, useEffect } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Box } from '@chakra-ui/core';
import { FormikProps, FormikHelpers as FormikActions, Formik } from 'formik';
import {
  ERROR_MESSAGE,
  CARD_NUMBER_LENGTH,
  CVV_NUMBER_LENGTH,
  MONTHS
} from '../../../constant';
import { getMessage } from '../../../helper';
import { ICoupon } from '../models/coupon.model';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  amount: number;
  isApplied: boolean | null;
  nextStep: () => void;
  coupon: ICoupon | null;
  t: any;
  submitPayment: (values: IPaymentFormValues) => void;
  submitCouponCode: (coupon_code: string) => void;
}

export interface IPaymentFormValues {
  card?: string | null;
  month?: string | null;
  year?: string | null;
  cvv?: string | null;
  isCode: boolean;
  code?: string | null;
}

export const FilterForm: FunctionComponent<
  FormikProps<IPaymentFormValues> & Partial<IProps>
> = ({
  handleSubmit,
  touched,
  errors,
  values,
  setFieldValue,
  submitCouponCode,
  coupon,
  t,
  isApplied,
  amount
}) => {
  useEffect(() => {
    if (!isApplied && typeof isApplied === 'boolean') setFieldValue('code', '');
  }, [isApplied]);
  const YEARS = [];
  for (
    let i = new Date().getFullYear() + 20;
    i > new Date().getFullYear() - 1;
    i--
  ) {
    YEARS.push(i);
  }
  return (
    <Box className="mt-3 row _stp2" onSubmit={handleSubmit}>
      {amount ? (
        <>
          <Box className="col-12 form-group">
            <label>{t('REGISTER_CARD_NUMBER')}</label>
            <input
              type="text"
              className="form-control"
              name="card"
              value={values.card ?? ''}
              onChange={(event) => {
                const number = event.target.value.match(/\d/g)?.join('');
                const card =
                  number && number.length > CARD_NUMBER_LENGTH.max
                    ? values.card
                    : number;
                setFieldValue('card', card);
              }}
            />
            {errors.card && touched.card ? (
              <Box className="invalid-feedback">{errors.card}</Box>
            ) : null}
          </Box>
          <Box className="col-12 col-lg-6 form-group">
            <label>{t('REGISTER_MONTH')}</label>
            <select
              className="form-control"
              name="month"
              onChange={(event) => {
                setFieldValue('month', event.target.value);
              }}
            >
              <option>{t('REGISTER_SELECT')}</option>
              {MONTHS.map((datum) => (
                <option value={datum.digit} key={datum.digit}>
                  {datum.digit}
                </option>
              ))}
            </select>
            {errors.month && touched.month ? (
              <Box className="invalid-feedback">{errors.month}</Box>
            ) : null}
          </Box>
          <Box className="col-12 col-lg-6 form-group">
            <label>{t('REGISTER_YEAR')}</label>
            <select
              className="form-control"
              name="year"
              onChange={(event) => {
                setFieldValue('year', event.target.value);
              }}
            >
              <option>{t('REGISTER_SELECT')}</option>
              {YEARS.map((datum) => (
                <option value={datum} key={datum}>
                  {datum}
                </option>
              ))}
            </select>
            {errors.year && touched.year ? (
              <Box className="invalid-feedback">{errors.year}</Box>
            ) : null}
          </Box>
          <Box className="col-12 col-lg-6 form-group">
            <label>{t('REGISTER_CVV')}</label>
            <input
              type="password"
              className="form-control"
              name="cvv"
              value={values.cvv ?? ''}
              onChange={(event) => {
                const number = event.target.value.match(/\d/g)?.join('');
                const cvv =
                  number && number?.length > CVV_NUMBER_LENGTH.max
                    ? values.cvv
                    : number;
                setFieldValue('cvv', cvv);
              }}
            />
            {errors.cvv && touched.cvv ? (
              <Box className="invalid-feedback">{errors.cvv}</Box>
            ) : null}
          </Box>
          <Box className="col-12 col-lg-6 form-group">
            <span className="cvv">{t('REGISTER_DIGITS_USUALLY_FOUND')}</span>
          </Box>
        </>
      ) : null}
      <Box className="col-12 form-group">
        <span
          className="_stp2txt"
          onClick={() => {
            setFieldValue('isCode', !values.isCode);
          }}
        >
          <span>{t('REGISTER_HAVE_A_COUPON')}</span>{' '}
          {t('REGISTER_CLICK_HERE_TO_ENTER_YOUR_CODE')}
        </span>
      </Box>
      {values.isCode ? (
        <>
          <Box className="col-12 col-lg-8 form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Enter Code"
              name="code"
              value={coupon?.coupon ?? values.code ?? ''}
              onChange={(event) => {
                setFieldValue('code', event.target.value);
              }}
            />
          </Box>
          <Box className="col-12 col-lg-4 form-group">
            <button
              className="appCp"
              disabled={!values.code}
              onClick={() => {
                if (values.code) {
                  submitCouponCode && submitCouponCode(values.code);
                }
              }}
            >
              {t('REGISTER_APPLY_COUPON')}
            </button>
          </Box>
        </>
      ) : null}
      <Box className="col-12">
        <button
          className="btn btn-primary w-100 transition"
          type="submit"
          onClick={() => {
            handleSubmit();
          }}
        >
          {t('REGISTER_PROCEED')}
        </button>
      </Box>
    </Box>
  );
};

export const PaymentComponent: FunctionComponent<IProps> = (props: IProps) => {
  const { className, t, submitPayment, amount } = props;
  const _validateFilterForm = (values: IPaymentFormValues) => {
    const errors: any = {};
    if (amount && !values.card) {
      errors.card = getMessage(ERROR_MESSAGE.required);
    } else if (
      amount &&
      values.card &&
      (values.card.length > CARD_NUMBER_LENGTH.max ||
        values.card.length < CARD_NUMBER_LENGTH.min)
    ) {
      errors.card = getMessage(ERROR_MESSAGE.CARD_NUMBER_INVALID);
    }

    if (amount && !values.month) {
      errors.month = getMessage(ERROR_MESSAGE.required);
    }

    if (amount && !values.year) {
      errors.year = getMessage(ERROR_MESSAGE.required);
    }

    if (
      amount &&
      values.month &&
      values.year &&
      values.year === new Date().getFullYear().toString() &&
      Number(values.month) < new Date().getMonth()
    ) {
      errors.month = getMessage(ERROR_MESSAGE.INVALID_EXPIRY_DATE);
    }

    if (amount && !values.cvv) {
      errors.cvv = getMessage(ERROR_MESSAGE.required);
    } else if (
      amount &&
      values.cvv &&
      (values.cvv.length > CVV_NUMBER_LENGTH.max ||
        values.cvv.length < CVV_NUMBER_LENGTH.min)
    ) {
      errors.cvv = getMessage(ERROR_MESSAGE.CVV_INVALID);
    }

    return errors;
  };

  const _onFilterFormSubmit = async (
    values: IPaymentFormValues,
    actions: FormikActions<IPaymentFormValues>
  ) => {
    actions.setSubmitting(true);
    try {
      await submitPayment(values);
      actions.setSubmitting(false);
    } catch (e) {
      console.log(':: payment err ', e);
      actions.setSubmitting(false);
    }
  };
  return (
    <Box className={className}>
      <h3>{t('REGISTER_MAKE_PAYMENT')}</h3>
      <Formik
        validate={(values: IPaymentFormValues) => _validateFilterForm(values)}
        enableReinitialize
        initialValues={{
          card: '',
          month: '',
          year: '',
          cvv: '',
          isCode: false,
          code: ''
        }}
        onSubmit={(
          values: IPaymentFormValues,
          actions: FormikActions<IPaymentFormValues>
        ) => {
          _onFilterFormSubmit(values, actions);
        }}
        component={(formikProps: FormikProps<IPaymentFormValues>) => {
          return <FilterForm {...formikProps} {...props} />;
        }}
      />
    </Box>
  );
};

export default withToastManager(withRouter(PaymentComponent));
