import React, { FunctionComponent, useContext } from 'react';
import {
  Route,
  Switch,
  Redirect,
  withRouter,
  RouteComponentProps
} from 'react-router-dom';

import { AuthContext } from '../authorization/auth.context';
import { AuthStatus } from '../modules/auth/models/auth-status';
import { ROUTES } from '../constant';
import HomePage from '../modules/homepage/pages/hompage.page';
import Register from '../modules/register/pages/register.page';
import VerifyOTP from '../modules/auth/pages/verification.page';
import Subscription from '../modules/register/pages/subscription.page';
import Verification from '../modules/register/pages/verification.page';
import Payment from '../modules/register/pages/payment.page';
import ProtectedRoutes from './ProctedRoutes';
import AuthContainer from '../authorization/AuthContainer';
import TermsAndConditionsWithoutLogin from '../modules/tramsAndConditions/pages/termsAndConditionsWithoutLogin.page';
import AboutUsWithoutLogin from '../modules/about_us/pages/about_us_withoutLogin.page';
import LegalsWithoutLogin from '../modules/legal/pages/legals_without_login.page';
import SupportAndFeedbackWithoutLogin from '../modules/supportsAndFeedback/pages/supportAndFeedbackWithoutLogin.page';
import UnNumberDetail from '../modules/homepage/pages/UnNumber.page';
import Classes from '../modules/homepage/pages/Classes.page';
import GHS from '../modules/homepage/pages/Ghs.page';
import GHSDetail from '../modules/homepage/pages/GhsDetail.page';
// import ComingSoon from '../modules/comingSoon/pages/comingsoon.page';

const AppRoutes: FunctionComponent<RouteComponentProps> = () => {
  const authContext = useContext(AuthContext);

  return (
    <>
      <Switch>
        <Route path={ROUTES.HOME} exact>
          <HomePage />
        </Route>
        <Route path={ROUTES.PDG_HOME} exact>
          <HomePage />
        </Route>
        <Route path={ROUTES.UN_NUMBER_DETAIL} exact>
          <UnNumberDetail />
        </Route>
        <Route path={ROUTES.CLASSES} exact>
          <Classes />
        </Route>
        <Route path={ROUTES.GHS} exact>
          <GHS />
        </Route>
        <Route path={ROUTES.GHS_DETAIL} exact>
          <GHSDetail />
        </Route>
        <Route path={ROUTES.SUBSCRIPTION} exact>
          <Subscription />
        </Route>
        <Route path={ROUTES.REGISTER} exact>
          <Register />
        </Route>
        <Route path={ROUTES.VERIFY_OTP} exact>
          <VerifyOTP />
        </Route>
        <Route path={ROUTES.VERIFICATION} exact>
          <Verification />
        </Route>
        <Route path={ROUTES.PAYMENT} exact>
          <Payment />
        </Route>
        <Route path={ROUTES.TERMS_AND_CONDITIONS_WITHOUT_LOGIN} exact>
          <TermsAndConditionsWithoutLogin />
        </Route>
        <Route path={ROUTES.ABOUT_US_WITHOUT_LOGIN} exact>
          <AboutUsWithoutLogin />
        </Route>
        <Route path={ROUTES.LEGALS_WITHOUT_LOGIN} exact>
          <LegalsWithoutLogin />
        </Route>
        <Route path={ROUTES.SUPPORT_FEEDBACK_WITHOUT_LOGIN} exact>
          <SupportAndFeedbackWithoutLogin />
        </Route>
        <AuthContainer>
          <Route
            path="/"
            render={(innerProps) => {
              if (authContext.status === AuthStatus.AUTHENTICATED) {
                return <ProtectedRoutes {...innerProps} />;
              } else {
                return (
                  <Redirect
                    to={{
                      pathname: ROUTES.HOME,
                      state: { from: innerProps.location }
                    }}
                  />
                );
              }
            }}
          />
        </AuthContainer>
      </Switch>
    </>
  );
};

export default withRouter(AppRoutes);
