import React, { FunctionComponent } from 'react';
import { Box } from '@chakra-ui/core';

export interface IProps {
  t: any;
  isSafe: boolean;
  onClose: () => void;
  onYes: () => void;
}
export const SaveReportConfirmation: FunctionComponent<IProps> = ({
  isSafe,
  onClose,
  onYes
}) => {
  return (
    <div className="_cstModal">
      <div className="_mdBody">
        <p className="save-popup-header">Alert</p>
        <p>Does this vehicle have:</p>
        <div className="rule-list">
          <p>- Correctly fitted gates</p>
          <p>- Dangerous Goods Signage</p>
          <p>- EPG in Vehicle Door Compartment</p>
        </div>
        <p className="text-2">
          If NO, you can not legally carry or dispatch dangerous goods
        </p>
        <p className="text-3">
          *You are responsible for ensuring that the weights and total weight of{' '}
          your load complies with regulations
        </p>
        {!isSafe ? (
          <p className="text-3">
            <strong>
              Your Manifest is unsafe. Do you want to submit for Approval?
            </strong>
          </p>
        ) : null}
        <Box className="row justify-content-end bottom-btn">
          <Box
            className="col-auto bottom-btn-text"
            onClick={() => {
              onClose();
            }}
          >
            No
          </Box>
          <Box
            className="col-auto bottom-btn-text"
            onClick={() => {
              onYes();
            }}
          >
            Yes
          </Box>
        </Box>
      </div>
    </div>
  );
};
