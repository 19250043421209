import React, { FunctionComponent } from 'react';
import { FormikProps } from 'formik';
import { Box } from '@chakra-ui/core';

import { IProps } from './AddSubUser.component';
import { ISD_CODE, PHONE_NUMBER_LENGTH } from '../../../constant';
import MessageIcon from '../../../assets/images/message-icon.png';
import PhoneIcon from '../../../assets/images/phone-icon.png';
import UserIcon from '../../../assets/images/user-icon.png';
import { USER_ROLE } from '../../../common/models/user';

export interface IAddSubUserFormValues {
  name: string;
  email: string;
  code: string;
  phone: string;
  role?: string;
  truck?: number;
}

export const AddSubUserForm: FunctionComponent<
  FormikProps<IAddSubUserFormValues> & Partial<IProps>
> = ({
  values,
  handleSubmit,
  errors,
  touched,
  setFieldValue,
  license,
  t,
  warehouseTrucks,
  toggleView
}) => {
  return (
    <Box onSubmit={handleSubmit}>
      <Box className="mt-4 update_form">
        <form>
          <Box className="form-group">
            <input
              type="text"
              className="form-control"
              name="name"
              placeholder="Name"
              value={values.name}
              onChange={(event) => {
                setFieldValue('name', event.target.value);
              }}
            />
            <i className="_Ficon">
              <img src={UserIcon} alt="icon" />
            </i>
            {errors.name && touched.name ? (
              <Box className="invalid-feedback">{errors.name}</Box>
            ) : null}
          </Box>
          <Box className="form-group">
            <input
              type="text"
              className="form-control"
              name="email"
              placeholder="Email"
              disabled={!!license?.user?.email}
              value={values.email}
              onChange={(event) => {
                setFieldValue('email', event.target.value);
              }}
            />
            <i className="_Ficon">
              <img src={MessageIcon} alt="icon" />
            </i>
            {errors.email && touched.email ? (
              <Box className="invalid-feedback">{errors.email}</Box>
            ) : null}
          </Box>
          <Box className="row _mdChange">
            <Box className="col-12 col-lg-3 form-group">
              <select
                className="form-control"
                name="code"
                value={values.code}
                onChange={(event) => {
                  setFieldValue('code', event.target.value);
                }}
              >
                {ISD_CODE.map((datum) => (
                  <option value={datum} key={datum}>
                    {datum}
                  </option>
                ))}
              </select>
              {errors.code && touched.code ? (
                <Box className="invalid-feedback">{errors.code}</Box>
              ) : null}
            </Box>
            <Box className="col-12 col-lg-9 form-group">
              <input
                type="number"
                className="form-control"
                name="phone"
                placeholder="Phone Number"
                value={values.phone}
                onChange={(event) => {
                  const number = event.target.value
                    ? event.target.value.match(/\d/g)?.join('')
                    : null;
                  const phone =
                    number && number.length > PHONE_NUMBER_LENGTH
                      ? values.phone
                      : number;
                  setFieldValue('phone', phone);
                }}
              />
              <i className="_Ficon">
                <img src={PhoneIcon} alt="icon" />
              </i>
              {errors.phone && touched.phone ? (
                <Box className="invalid-feedback">{errors.phone}</Box>
              ) : null}
            </Box>
          </Box>
          <Box className="form-group">
            <select
              className="form-control"
              name="role"
              value={values.role}
              onChange={(event) => {
                setFieldValue('role', event.target.value);
                if (event.target.value !== 'operator') {
                  setFieldValue('truck', null);
                }
              }}
            >
              <option value="">Select Role</option>
              <option value={USER_ROLE.MANAGER}>Manager</option>
              <option value={USER_ROLE.SUPERVISOR}>Supervisor</option>
              <option value={USER_ROLE.OPERATOR}>Operator</option>
            </select>
            {errors.role && touched.role ? (
              <Box className="invalid-feedback">{errors.role}</Box>
            ) : null}
          </Box>

          {values.role === USER_ROLE.OPERATOR ? (
            <Box className="form-group">
              <select
                className="form-control"
                name="truck"
                value={values.truck}
                onChange={(event) => {
                  setFieldValue('truck', event.target.value);
                }}
              >
                <option value="">Select Warehouse or truck</option>
                {warehouseTrucks?.map((datum) => (
                  <option value={datum.id} key={datum.id}>{`${
                    datum.type === 1 ? 'Truck' : 'Warehouse'
                  } - ${datum.name}`}</option>
                ))}
              </select>
              {errors.truck && touched.truck ? (
                <Box className="invalid-feedback">{errors.truck}</Box>
              ) : null}
            </Box>
          ) : null}
          <Box className="form-group mt-4">
            <button className="btn btn-primary w-100" type="submit">
              {license?.user?.email ? t('UPDATE') : t('SUBMIT')}
            </button>
          </Box>
        </form>
        <Box
          className="_close"
          onClick={() => {
            toggleView && toggleView();
          }}
        >
          x {t('CLOSE')}
        </Box>
      </Box>
    </Box>
  );
};
