import React, { FunctionComponent } from 'react';
import { Box } from '@chakra-ui/core';

import ClassDivision_1 from '../../../assets/images/icons/1.png';
import ClassDivision_1_4 from '../../../assets/images/icons/1_4.png';
import ClassDivision_1_5 from '../../../assets/images/icons/1_5.png';
import ClassDivision_1_6 from '../../../assets/images/icons/1_6.png';
import ClassDivision_2_1_A from '../../../assets/images/icons/2_1_A.png';
import ClassDivision_2_1_B from '../../../assets/images/icons/2_1_B.png';
import ClassDivision_2_2_A from '../../../assets/images/icons/2_2_A.png';
import ClassDivision_2_2_B from '../../../assets/images/icons/2_2_B.png';
import ClassDivision_2_3 from '../../../assets/images/icons/2_3.jpg';
import ClassDivision_3_A from '../../../assets/images/icons/3_A.png';
import ClassDivision_3_B from '../../../assets/images/icons/3_B.png';
import ClassDivision_4_1 from '../../../assets/images/icons/4_1.png';
import ClassDivision_4_2 from '../../../assets/images/icons/4_2.png';
import ClassDivision_4_3_A from '../../../assets/images/icons/4_3_A.png';
import ClassDivision_4_3_B from '../../../assets/images/icons/4_3_B.png';
import ClassDivision_5_1 from '../../../assets/images/icons/5_1.png';
import ClassDivision_5_2_A from '../../../assets/images/icons/5_2_A.png';
import ClassDivision_5_2_B from '../../../assets/images/icons/5_2_B.png';
import ClassDivision_6_1 from '../../../assets/images/icons/6_1.jpg';
import ClassDivision_6_2 from '../../../assets/images/icons/6_2.jpg';
import ClassDivision_7_A from '../../../assets/images/icons/7_A.png';
import ClassDivision_7_B from '../../../assets/images/icons/7_B.png';
import ClassDivision_7_C from '../../../assets/images/icons/7_C.png';
import ClassDivision_7_E from '../../../assets/images/icons/7_E.png';
import ClassDivision_8 from '../../../assets/images/icons/8.png';
import ClassDivision_9 from '../../../assets/images/icons/9.png';
import ClassDivision_9_A from '../../../assets/images/icons/9_A.png';

export interface IProps {
  class_division: string;
  className?: string;
}

export const ClassDivisionImage: FunctionComponent<IProps> = ({
  class_division,
  className
}) => {
  const getClassDivisionImage = () => {
    switch (class_division) {
      case '1_1.5': {
        return (
          <Box className="row justify-content-end">
            <img src={ClassDivision_1} alt="class 1" className={className} />
            <img
              src={ClassDivision_1_5}
              alt="class 1.5"
              className={className}
            />
          </Box>
        );
      }
      case '1.4_1.6': {
        return (
          <Box className="row justify-content-end">
            <img
              src={ClassDivision_1_4}
              alt="class 1.4"
              className={className}
            />
            <img
              src={ClassDivision_1_6}
              alt="class 1.6"
              className={className}
            />
          </Box>
        );
      }
      case '1.4': {
        return (
          <Box className="row justify-content-end">
            <img
              src={ClassDivision_1_4}
              alt="class 1.4"
              className={className}
            />
          </Box>
        );
      }
      case '1.6': {
        return (
          <Box className="row justify-content-end">
            <img
              src={ClassDivision_1_6}
              alt="class 1.6"
              className={className}
            />
          </Box>
        );
      }
      case '2.1': {
        return (
          <Box className="row justify-content-end">
            <img
              src={ClassDivision_2_1_A}
              alt="class 2.1 A"
              className={className}
            />
            <img
              src={ClassDivision_2_1_B}
              alt="class 2.2 B"
              className={className}
            />
          </Box>
        );
      }
      case '2.2': {
        return (
          <Box className="row justify-content-end">
            <img
              src={ClassDivision_2_2_A}
              alt="class 2.2 A"
              className={className}
            />
            <img
              src={ClassDivision_2_2_B}
              alt="class 2.2 B"
              className={className}
            />
          </Box>
        );
      }
      case '2.3': {
        return (
          <Box className="row justify-content-end">
            <img
              src={ClassDivision_2_3}
              alt="class 2.3"
              className={className}
            />
          </Box>
        );
      }
      case '3': {
        return (
          <Box className="row justify-content-end">
            <img
              src={ClassDivision_3_A}
              alt="class 3 A"
              className={className}
            />
            <img
              src={ClassDivision_3_B}
              alt="class 3 B"
              className={className}
            />
          </Box>
        );
      }
      case '4.1': {
        return (
          <Box className="row justify-content-end">
            <img
              src={ClassDivision_4_1}
              alt="class 4.1"
              className={className}
            />
          </Box>
        );
      }
      case '4.2': {
        return (
          <Box className="row justify-content-end">
            <img
              src={ClassDivision_4_2}
              alt="class 4.2"
              className={className}
            />
          </Box>
        );
      }
      case '4.3': {
        return (
          <Box className="row justify-content-end">
            <img
              src={ClassDivision_4_3_A}
              alt="class 4.3 A"
              className={className}
            />
            <img
              src={ClassDivision_4_3_B}
              alt="class 4.3 B"
              className={className}
            />
          </Box>
        );
      }
      case '5.1': {
        return (
          <Box className="row justify-content-end">
            <img
              src={ClassDivision_5_1}
              alt="class 5.1"
              className={className}
            />
          </Box>
        );
      }
      case '5.2': {
        return (
          <Box className="row justify-content-end">
            <img
              src={ClassDivision_5_2_A}
              alt="class 5.2 A"
              className={className}
            />
            <img
              src={ClassDivision_5_2_B}
              alt="class 5.2 B"
              className={className}
            />
          </Box>
        );
      }
      case '6.1': {
        return (
          <Box className="row justify-content-end">
            <img
              src={ClassDivision_6_1}
              alt="class 6.1"
              className={className}
            />
          </Box>
        );
      }
      case '6.2': {
        return (
          <Box className="row justify-content-end">
            <img
              src={ClassDivision_6_2}
              alt="class 6.2"
              className={className}
            />
          </Box>
        );
      }
      case '7': {
        return (
          <Box className="row justify-content-end">
            <img
              src={ClassDivision_7_A}
              alt="class 7 A"
              className={className}
            />
            <img
              src={ClassDivision_7_B}
              alt="class 7 B"
              className={className}
            />
            <img
              src={ClassDivision_7_C}
              alt="class 7 C"
              className={className}
            />
            <img
              src={ClassDivision_7_E}
              alt="class 7 E"
              className={className}
            />
          </Box>
        );
      }
      case '8': {
        return (
          <Box className="row justify-content-end">
            <img src={ClassDivision_8} alt="class 8" className={className} />
          </Box>
        );
      }
      case '9': {
        return (
          <Box className="row justify-content-end">
            <img src={ClassDivision_9} alt="class 9" className={className} />
            <img
              src={ClassDivision_9_A}
              alt="class 9 A"
              className={className}
            />
          </Box>
        );
      }
      default: {
        return null;
      }
    }
  };
  return <Box>{getClassDivisionImage()}</Box>;
};
