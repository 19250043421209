/* istanbul ignore file */
import React, { FunctionComponent, useEffect } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Box } from '@chakra-ui/core';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import Logo2 from '../../../assets/images/logo2.png';
import LeftBanner from '../../../assets/images/leftBanner2.png';
import VerificationComponent from '../components/verification.component';
import { FullPageLoader } from '../../../common/components/Loader.component';
import {
  ROUTES,
  STATUS,
  ERROR_MESSAGE,
  SUCCESS_MESSAGE
} from '../../../constant';
import { IAppState } from '../../../store';
import { generateOTP, verifyOTP } from '../actions/auth.action';
import { getLocalstorage, getMessage } from '../../../helper';
import {
  getGenerateOTPAPIStatus,
  getVerifyOTPAPIStatus
} from '../reducers/auth.reducer';
import { AuthRepository } from '../repositories/auth.repository';
import { USER_ROLE } from '../../../common/models/user';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  t: any;
  resendVerificationCodeAPIStatus: STATUS;
  verifyOTPAPIStatus: STATUS;
  userId: string | null;
  generateOTP: typeof generateOTP;
  verifyOTP: typeof verifyOTP;
}

export const VerifyOTP: FunctionComponent<IProps> = (props: IProps) => {
  const {
    className,
    generateOTP,
    verifyOTP,
    toastManager,
    history,
    verifyOTPAPIStatus,
    userId
  } = props;
  useEffect(() => {
    if (!userId) {
      history.push(ROUTES.HOME);
    }
  }, [userId]);
  const _generateOTP = async () => {
    try {
      await generateOTP();
      toastManager.add(getMessage(SUCCESS_MESSAGE.RESEND_VERIFICATION_CODE), {
        appearance: 'success',
        autoDismiss: true
      });
    } catch (err) {
      const message =
        err?.errorObj?.message ??
        err?.message ??
        getMessage(ERROR_MESSAGE.RESEND_VERIFICATION_CODE_FAILED);
      toastManager.add(message, { appearance: 'error' });
      console.log(':: _generateOTP err ', err);
    }
  };

  const _verifyOTP = async (code: string) => {
    try {
      const fcmToken = Math.random().toString();
      AuthRepository.storeDeviceToken(fcmToken);
      const params = {
        user_id: userId,
        code,
        device_token: fcmToken,
        device_type: 'web'
      };
      const user: any = await verifyOTP(params);
      if (user.role && user.role !== USER_ROLE.ADMIN) {
        history.push(ROUTES.REPORTS);
      } else {
        history.push(ROUTES.MANIFEST);
      }
    } catch (err) {
      const message =
        err?.errorObj?.message ??
        err?.message ??
        getMessage(ERROR_MESSAGE.VERIFICATION_CODE_INVALID);
      toastManager.add(message, { appearance: 'error', autoDismiss: true });
      console.log(':: _verifyOTP err ', err);
    }
  };
  return (
    <div className={className}>
      {verifyOTPAPIStatus === STATUS.LOADING ? (
        <Box p={'lg'}>
          <FullPageLoader />
        </Box>
      ) : null}
      <Box className="signupAr">
        <Box className="lftImage">
          <a href={ROUTES.HOME}>
            <img src={Logo2} className="innLogo" alt="pdg" />
          </a>
          <img src={LeftBanner} alt="pdg" />
        </Box>
        <Box className="_snInfo">
          <VerificationComponent
            {...props}
            generateOTP={_generateOTP}
            verifyOTP={(code: string) => {
              _verifyOTP(code);
            }}
          />
        </Box>
      </Box>
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: IAppState) => {
  const resendVerificationCodeAPIStatus = getGenerateOTPAPIStatus(state);
  const verifyOTPAPIStatus = getVerifyOTPAPIStatus(state);
  const userId = getLocalstorage('user_id');
  return {
    resendVerificationCodeAPIStatus,
    verifyOTPAPIStatus,
    userId
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  return bindActionCreators(
    {
      generateOTP,
      verifyOTP
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(withToastManager(withRouter(VerifyOTP))));
