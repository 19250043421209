import React, { FunctionComponent } from 'react';
import { Text, BoxProps as TextProps } from '@chakra-ui/core';
import { FormattedNumber } from 'react-intl';

export const H1: FunctionComponent<TextProps> = (props: TextProps) => {
  return (
    <Text as="h1" fontFamily="heading" fontSize="3xl" margin={0} {...props} />
  );
};

export const H2: FunctionComponent<TextProps> = (props: TextProps) => {
  return (
    <Text as="h2" fontFamily="heading" fontSize="2xl" margin={0} {...props} />
  );
};

export const H3: FunctionComponent<TextProps> = (props: TextProps) => {
  return (
    <Text as="h3" fontFamily="heading" fontSize="xl" margin={0} {...props} />
  );
};

export const H4: FunctionComponent<TextProps> = (props: TextProps) => {
  return (
    <Text as="h4" fontFamily="heading" fontSize="lg" margin={0} {...props} />
  );
};

export const H5: FunctionComponent<TextProps> = (props: TextProps) => {
  return (
    <Text as="h5" fontFamily="heading" fontSize="md" margin={0} {...props} />
  );
};

export const P: FunctionComponent<TextProps> = (props: TextProps) => {
  return <Text as="p" {...props} />;
};

export const Money: FunctionComponent<{
  value: number;
  currency?: string;
}> = ({ value, currency }) => {
  if (currency) {
    return (
      <FormattedNumber
        style="currency" // eslint-disable-line react/style-prop-object
        currencyDisplay="code"
        currency={currency}
        value={value}
      />
    );
  } else {
    return (
      <FormattedNumber
        value={value}
        minimumFractionDigits={2}
        maximumFractionDigits={2}
      />
    );
  }
};
