export const baseSizes = {
  px: '1px',
  '0': '0',
  '1': '0.2857142857142857rem',
  '2': '0.5714285714285714rem',
  '3': '0.8571428571428571rem',
  '4': '1.1428571428571428rem',
  '5': '1.4285714285714286rem',
  '6': '1.7142857142857142rem',
  '8': '2.2857142857142856rem',
  '10': '2.857142857142857rem',
  '12': '3.4285714285714284rem',
  '16': '4.571428571428571rem',
  '20': '5.714285714285714rem',
  '24': '6.857142857142857rem',
  '32': '9.142857142857142rem',
  '40': '11.428571428571429rem',
  '48': '13.714285714285714rem',
  '56': '16rem',
  '64': '18.285714285714285rem'
};

const largeSizes = {
  full: '100%',
  '3xs': '14rem',
  '2xs': '16rem',
  xs: '20rem',
  sm: '24rem',
  md: '28rem',
  lg: '32rem',
  xl: '36rem',
  '2xl': '42rem',
  '3xl': '48rem',
  '4xl': '56rem',
  '5xl': '64rem',
  '6xl': '72rem'
};

const containers = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px'
};

const sizes = {
  ...baseSizes,
  ...largeSizes,
  containers
};

export default sizes;
