/* istanbul ignore file */
import React, { FunctionComponent, useState, useEffect } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { RouteComponentProps } from 'react-router-dom';
import { Box } from '@chakra-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

import Logo from '../../../assets/images/logo.png';
import Logo2 from '../../../assets/images/logo-2.png';
import DNAr from '../../../assets/images/dn-ar.png';
import { ROUTES, ERROR_MESSAGE, STATUS } from '../../../constant';
import {
  getLocalstorage,
  changeLanguage,
  createUrl,
  getMessage
} from '../../../helper';
import SearchComponent from '../../../common/components/Search.component';
import { FullPageLoader } from '../../../common/components/Loader.component';
import { retrieveUnNumbers } from '../../reports/actions';
import { IUnNumbersParams } from '../../reports/services/api.service';
import { getUnNumbersAPIStatus } from '../../reports/reducers/ui';
import {
  SelectLanguage,
  SelectLanguageMobile
} from '../../../common/components/helpers/showSelectedLanguage.component';
import AppImage1 from '../../../assets/images/app-image-1.png';
import AppImage2 from '../../../assets/images/app-image-2.png';
import AppStore from '../../../assets/images/app-store.png';
import PlayStore from '../../../assets/images/play-store.png';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  t: any;
  toggleView: (modalNum: number | null) => void;
}

interface ISearchFormValues {
  searchTerm: string;
}

export const HomePageHeader: FunctionComponent<IProps> = (props: IProps) => {
  const dispatch = useDispatch();
  const {
    className,
    history,
    t,
    toastManager,
    location: { pathname },
    toggleView
  } = props;
  const lang = getLocalstorage('lang');
  const retrieveUnNumbersAPIStatus = useSelector(getUnNumbersAPIStatus);
  const [language, setLanguage] = useState(lang);
  const [isGuideOpen, setIsGuideOpen] = useState(false);
  const [isShowSearchUn, setIsShowSearchUn] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isShowMenu, setIsShowMenu] = useState(false);

  const setUserLang = (newLang: string) => {
    setLanguage(newLang);
    changeLanguage(newLang);
  };

  const _retrieveUnNumbers = async () => {
    try {
      const params: IUnNumbersParams = {
        unNumber: searchTerm
      };
      const res: any = await dispatch(retrieveUnNumbers(params));
      if (res?.data?.length) {
        const unURL = createUrl(ROUTES.UN_NUMBER_DETAIL, {
          id: res.data[0].un_no
        });
        history.push(unURL);
      } else {
        toastManager.add(getMessage(ERROR_MESSAGE.UN_NUMBER_SEARCH_INVALID), {
          appearance: 'error',
          autoDismiss: true
        });
      }
    } catch (err) {
      console.log(':: _retrieveUnNumbers err ', err);
      toastManager.add(getMessage(ERROR_MESSAGE.UN_NUMBER_SEARCH_FAILED), {
        appearance: 'error',
        autoDismiss: true
      });
    }
  };

  useEffect(() => {
    if (searchTerm) {
      _retrieveUnNumbers();
    }
  }, [searchTerm]);

  return (
    <Box className={className}>
      {retrieveUnNumbersAPIStatus === STATUS.LOADING ? (
        <Box>
          <FullPageLoader />
        </Box>
      ) : null}
      <Box className={`mbMenu ${isShowMenu ? 'show' : ''}`}>
        <span
          className="cross"
          onClick={() => {
            setIsShowMenu(false);
          }}
        >
          <CloseIcon />
        </span>
        <ul>
          <li>
            <a
              className="transition"
              onClick={() => {
                toggleView(1);
                setIsShowMenu(false);
              }}
            >
              {t('HOME_PAGE_LOGIN')}
            </a>
          </li>
          <li
            onClick={() => {
              setIsShowMenu(false);
            }}
          >
            <a href={ROUTES.REGISTER}>{t('HOME_PAGE_REGISTER')}</a>
          </li>
          <li
            onClick={() => {
              setIsShowMenu(false);
            }}
          >
            <a href={ROUTES.CLASSES}>{t('CLASSES')}</a>
          </li>
          <li
            onClick={() => {
              setIsShowMenu(false);
            }}
          >
            <a href={ROUTES.GHS}>{t('GHS')}</a>
          </li>
          <li
            onClick={() => {
              setIsShowMenu(false);
            }}
          >
            <a
              href="https://www.ntc.gov.au/sites/default/files/assets/files/ADG%20Code%207.7_0.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('AUSTRALIAN_DANGEROUS_CODE')}
            </a>
          </li>
          <li
            onClick={() => {
              setIsShowMenu(false);
            }}
          >
            <a
              href="https://www.ntc.gov.au/sites/default/files/assets/files/Australian-Emergency-Response-Guide-Book-2018%28errata%201%29_0.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('AUSTRALIAN_EMERGENCY_RESPONSE_GUIDE')}
            </a>
          </li>
        </ul>
      </Box>
      <Box className={`mbMenu ${isShowSearchUn ? 'show' : ''}`}>
        <span
          className="cross"
          onClick={() => {
            setIsShowSearchUn(false);
          }}
        >
          <CloseIcon />
        </span>
        <Box>
          <SearchComponent
            placeholder={t('SEARCH_UN_NO')}
            maxLength={8}
            searchTerm={searchTerm}
            onSearchFormSubmit={(values: ISearchFormValues) => {
              setSearchTerm(values.searchTerm);
            }}
            onSearchFormReset={() => {
              setSearchTerm('');
            }}
          />
        </Box>
      </Box>
      <header className="container-fluid">
        <Box className="d-flex justify-content-between align-items-start">
          <Box className="h_right">
            <ul>
              <li className="mbtggle" style={{ paddingRight: '63px' }}>
                <a
                  href="#"
                  className="transition"
                  onClick={() => {
                    setIsShowSearchUn(true);
                  }}
                >
                  UN No.
                </a>
              </li>
              <li className="mb-hidden">
                <SearchComponent
                  placeholder={t('SEARCH_UN_NO')}
                  maxLength={8}
                  searchTerm={searchTerm}
                  onSearchFormSubmit={(values: ISearchFormValues) => {
                    setSearchTerm(values.searchTerm);
                  }}
                  onSearchFormReset={() => {
                    setSearchTerm('');
                  }}
                />
              </li>
              <li className="mb-hidden">
                <a
                  href={ROUTES.CLASSES}
                  className={`transition ${
                    pathname.includes(ROUTES.CLASSES) ? 'active' : ''
                  }`}
                  style={{
                    border: 'none'
                  }}
                >
                  {t('CLASSES')}
                </a>
              </li>
              <li className="mb-hidden">
                <a
                  href={ROUTES.GHS}
                  className={`transition ${
                    pathname.includes(ROUTES.GHS) ? 'active' : ''
                  }`}
                  style={{
                    border: 'none'
                  }}
                >
                  {t('GHS')}
                </a>
              </li>
              <li className="mb-hidden LangDrop">
                <a
                  href="#"
                  className="transition"
                  onClick={() => {
                    setIsGuideOpen(!isGuideOpen);
                  }}
                  style={{
                    border: 'none'
                  }}
                >
                  {t('GUIDE')}
                </a>
                {isGuideOpen ? (
                  <ul className="guide_dropdown">
                    <li
                      onClick={() => {
                        setIsGuideOpen(false);
                      }}
                    >
                      <a
                        href="https://www.ntc.gov.au/sites/default/files/assets/files/ADG%20Code%207.7_0.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('AUSTRALIAN_DANGEROUS_CODE')}
                      </a>
                    </li>
                    <li
                      onClick={() => {
                        setIsGuideOpen(false);
                      }}
                    >
                      <a
                        href="https://www.ntc.gov.au/sites/default/files/assets/files/Australian-Emergency-Response-Guide-Book-2018%28errata%201%29_0.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('AUSTRALIAN_EMERGENCY_RESPONSE_GUIDE')}
                      </a>
                    </li>
                  </ul>
                ) : null}
              </li>
            </ul>
          </Box>
          <Box className="_l">
            <a href={ROUTES.HOME}>
              <img src={Logo} alt="logo" className="rotate" />
              <img src={Logo2} alt="logo2" />
            </a>
          </Box>
          <Box className="h_right">
            <ul>
              <li className="LangDrop mb-hidden">
                <SelectLanguage
                  selectedLanguage={language ?? lang}
                  setLanguage={(lang: string) => {
                    setUserLang(lang);
                  }}
                />
              </li>
              <li className="LangDrop mbtggle">
                <SelectLanguageMobile
                  selectedLanguage={language ?? lang}
                  setLanguage={(lang: string) => {
                    setUserLang(lang);
                  }}
                />
              </li>
              <li className="mb-hidden">
                <a href={ROUTES.REGISTER} className="transition">
                  {t('HOME_PAGE_REGISTER')}
                </a>
              </li>
              <li className="mb-hidden">
                <a
                  className="transition"
                  onClick={() => {
                    toggleView(1);
                  }}
                >
                  {t('HOME_PAGE_LOGIN')}
                </a>
              </li>
              <li className="mbtggle">
                <a
                  href="#"
                  onClick={() => {
                    setIsShowMenu(true);
                  }}
                  style={{ width: '58px' }}
                >
                  <MenuIcon />
                </a>
              </li>
            </ul>
          </Box>
        </Box>
      </header>

      <section className="index-banner">
        <Box className="h_right mb-hidden app-store-qr">
          <a
            className="col-6"
            target="_blank"
            rel="noopener noreferrer"
            href="https://apps.apple.com/in/app/pdg/id1563547906"
          >
            <img src={AppImage1} alt="app store" />
            <img
              src={AppStore}
              alt="app store"
              style={{ marginTop: '20px', maxWidth: '78%', marginLeft: '9px' }}
            />
          </a>
        </Box>
        <div className="ind-sq">
          <div className="scle45">
            <h1>PACKAGED </h1>
            <h1>DANGEROUS</h1>
            <h1>GOODS </h1>
            <div className="text-center mt-4">
              <span className="d-block">Keeping You Safe,</span>
              <span className="d-block">Compliant & Efficient</span>
            </div>
            <div className="dn-ar d-flex align-items-center justify-content-center scle45">
              <img src={DNAr} className="scle45P" alt="dnar" />
            </div>
          </div>
        </div>
        <Box className="h_right mb-hidden play-store-qr">
          <a
            className="col-6"
            target="_blank"
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=com.pdg"
          >
            <img src={AppImage2} alt="play store" />
            <img
              src={PlayStore}
              alt="play store"
              style={{ marginTop: '20px', maxWidth: '78%', marginLeft: '9px' }}
            />
          </a>
        </Box>
      </section>
    </Box>
  );
};

export default withToastManager(HomePageHeader);
