import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Formik, FormikHelpers as FormikActions, FormikProps } from 'formik';
import { RouteComponentProps } from 'react-router-dom';

import {
  emailValidator,
  phoneNumberValidator,
  getMessage
} from '../../../helper';
import { ERROR_MESSAGE, SUCCESS_MESSAGE, ISD_CODE } from '../../../constant';
import {
  AddSubUserForm,
  IAddSubUserFormValues
} from './AddSubUser_editorForm.component';
import { ILicense } from '../models/license.model';
import { AuthRepository } from '../../auth/repositories/auth.repository';
import {
  IAddSubUserParams,
  IEditProfileParams
} from '../services/license.service';
import { addSubUser, editProfile } from '../actions';
import { IWarehouseTruck } from '../../warehouseTruckRegistration/models/warehouseTruckRegistration.model';
import { USER_ROLE } from '../../../common/models/user';

export interface IProps extends RouteComponentProps {
  className?: string;
  toggleView: () => void;
  retrieveLicenses: () => void;
  t: any;
  license: ILicense;
  toastManager: any;
  warehouseTrucks: IWarehouseTruck[];
  editProfile: typeof editProfile;
  addSubUser: typeof addSubUser;
}

export class AddSubUserComponent extends PureComponent<IProps> {
  static defaultProps: Partial<IProps> = {
    className: ''
  };

  async onFormSubmit(
    values: IAddSubUserFormValues,
    actions: FormikActions<IAddSubUserFormValues>
  ) {
    const {
      toastManager,
      toggleView,
      retrieveLicenses,
      editProfile,
      license,
      addSubUser
    } = this.props;
    actions.setSubmitting(true);
    try {
      if (!license || !license.user || !license.user.email) {
        const params: IAddSubUserParams = {
          licence_key: license.licence,
          name: values.name,
          phone_no: `${values.phone}`,
          country_id: values.code,
          user_id: AuthRepository.retrieveUserId() as string,
          email: values.email,
          role: values.role,
          truck: values.truck
        };
        await addSubUser(params);
        toastManager.add(getMessage(SUCCESS_MESSAGE.ADD_SUB_USER), {
          appearance: 'success',
          autoDismiss: true
        });
      } else {
        const params: IEditProfileParams = {
          name: values.name,
          phone_no: `${values.phone}`,
          country_id: values.code,
          user_id: license.user.user_id,
          role: values.role,
          truck: values.truck
        };
        await editProfile(params);
        toastManager.add(getMessage(SUCCESS_MESSAGE.EDIT_SUB_USER), {
          appearance: 'success',
          autoDismiss: true
        });
      }

      retrieveLicenses();
      toggleView();
      actions.setSubmitting(false);
    } catch (err) {
      actions.setSubmitting(false);
      const message =
        err?.errorObj?.message ??
        err?.message ??
        getMessage(ERROR_MESSAGE.ADD_SUB_USER);
      toastManager.add(message, { appearance: 'error', autoDismiss: true });
    }
  }

  validateForm(values: IAddSubUserFormValues) {
    const errors: any = {};

    if (!values.name) {
      errors.name = getMessage(ERROR_MESSAGE.required);
    }

    if (!values.email) {
      errors.email = getMessage(ERROR_MESSAGE.required);
    } else if (!emailValidator(values.email)) {
      errors.email = getMessage(ERROR_MESSAGE.email);
    }

    if (!values.code) {
      errors.code = getMessage(ERROR_MESSAGE.required);
    }

    if (!values.phone) {
      errors.phone = getMessage(ERROR_MESSAGE.required);
    } else if (!phoneNumberValidator(values.phone)) {
      errors.password = getMessage(ERROR_MESSAGE.phone);
    }

    if (!values.role) {
      errors.role = getMessage(ERROR_MESSAGE.required);
    }

    if (values.role && values.role === USER_ROLE.OPERATOR && !values.truck) {
      errors.truck = getMessage(ERROR_MESSAGE.required);
    }

    return errors;
  }

  render() {
    const { className, t, license } = this.props;
    let code = ISD_CODE[0];
    let phone = '';
    if (license && license.user && license.user.phone_no) {
      phone = license.user.phone_no;
      code = license.user.country_id;
    }
    return (
      <div className={className}>
        <div className="_cstModal">
          <div className="_mdBody">
            <h4 className="_mdheadng">
              {license?.user?.email
                ? t('UPDATE_LICENSE_USER')
                : t('ADD_LICENSE_USER')}
            </h4>
            <div className="mt-5">
              <Formik
                validate={this.validateForm.bind(this)}
                initialValues={{
                  name: license.user?.name ?? '',
                  email: license.user?.email ?? '',
                  code,
                  phone,
                  role: license.user?.role ?? '',
                  truck: license.user?.truck ?? 0
                }}
                validateOnMount
                onSubmit={(
                  values: IAddSubUserFormValues,
                  actions: FormikActions<IAddSubUserFormValues>
                ) => {
                  this.onFormSubmit(values, actions);
                }}
                component={(
                  formikProps: FormikProps<IAddSubUserFormValues>
                ) => {
                  return (
                    <AddSubUserForm
                      {...formikProps}
                      {...this.props}
                      {...this.state}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
/* istanbul ignore next */
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ addSubUser, editProfile }, dispatch);
}

export default connect(null, mapDispatchToProps)(AddSubUserComponent);
