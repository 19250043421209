import { createSelector } from 'reselect';
import map from 'lodash/map';
import {
  getLicenseAllIds,
  getLicenseById,
  LicenseById
} from '../reducers/entity';

export const getLicensesSelector = createSelector(
  getLicenseAllIds,
  getLicenseById,
  (licenseAllIds: string[], licenseById: LicenseById) => {
    if (!licenseAllIds) {
      return null;
    }
    return map(licenseAllIds, (datum) => {
      return licenseById[datum];
    });
  }
);
