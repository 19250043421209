/* istanbul ignore file */
import React, { FunctionComponent, useState } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Text, Box } from '@chakra-ui/core';
import { withNamespaces } from 'react-i18next';

import {
  classList,
  IClass,
  ISubDivision
} from '../../../common/components/utils/classes';
import { ClassDivisionImage } from '../../../common/components/helpers/class_division_withoutLogin.component';
import { Header } from '../../../common/components/helpers/header.component';
import { EPGLinks } from '../../../common/components/helpers/epg_link.component';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  t: any;
}

export const ClassesPage: FunctionComponent<IProps> = (props: IProps) => {
  const { t } = props;
  const [activeClass, setActiveClass] = useState(1);

  return (
    <div>
      <section className="inn-bnr container-fluid header-with-login">
        <Header isShowSecondLogo={false} {...props} />
      </section>

      <section className="inSectn">
        <div className="inn-container">
          <div className="mt-104">
            <div className="unBox p-4">
              <h6>
                <strong>
                  {t('CLASSES')} 1-9 {t('DANGEROUS_GOODS_EXPLAINED')}
                </strong>
              </h6>
              <div className="mt-3">
                <p className="web-txt">{t('DANGEROUS_GOODS_CLASSIFIED')}</p>
                <p className="web-txt mt-3">
                  {t('THE_CLASSES_ARE_PART_OF_THE_UNITED_NATIONS')}
                </p>
              </div>
            </div>
            <div className="pdg-tabs mt-4">
              <ul className="nav nav-tabs" role="tablist">
                {classList.map((datum: IClass) => (
                  <li className="nav-item" key={datum.class}>
                    <a
                      className={`nav-link ${
                        activeClass === datum.class ? 'active' : ''
                      }`}
                      data-toggle="tab"
                      href={`#tabs-${datum.class}`}
                      role="tab"
                      onClick={() => {
                        setActiveClass(datum.class);
                      }}
                    >
                      Class {datum.class}
                    </a>
                  </li>
                ))}
              </ul>
              <div className="tab-content p-4">
                {classList.map((datum: IClass) => (
                  <div
                    className={`tab-pane ${
                      activeClass === datum.class ? 'active' : ''
                    }`}
                    id={`tabs-${datum.class}`}
                    role="tabpanel"
                    key={datum.class}
                  >
                    <Box className="d-flex row justiy-content-between">
                      <Box className="col-auto">
                        <ClassDivisionImage
                          className="class-without-login"
                          class_division={datum.class.toString()}
                        />
                      </Box>
                      <Box className="col-7">
                        <h4 className="r-h">{datum.name}</h4>
                      </Box>
                      <Box className="col-auto">
                        <ClassDivisionImage
                          className="class-without-login"
                          class_division={`${datum.class}_others`}
                        />
                      </Box>
                    </Box>
                    <EPGLinks class_division={datum.class.toString()} />
                    <div className="mt-3">
                      {datum.description.map(
                        (datumDescription: string, index: number) => (
                          <p className="web-txt mt-2" key={index}>
                            {datumDescription}
                          </p>
                        )
                      )}
                    </div>
                    <div className="mt-4">
                      <Text fontWeight="bold" fontSize="lg">
                        Reason for Regulation
                      </Text>
                    </div>
                    <div className="mt-2">
                      <Text>{datum.reason}</Text>
                    </div>
                    <div className="mt-4">
                      <Text fontWeight="bold" fontSize="lg">
                        Sub-Divisions
                      </Text>
                    </div>
                    <div className="mt-2">
                      {datum.subDivision.map(
                        (datumSubDivision: ISubDivision, index: number) => (
                          <Box
                            className="mt-1 row justify-conent-start"
                            key={index}
                          >
                            <Text className="col-auto" fontWeight="bold">
                              {datumSubDivision.division}:
                            </Text>
                            <Text className="col-10" wordBreak="break-word">
                              {datumSubDivision.name}
                            </Text>
                          </Box>
                        )
                      )}
                    </div>
                    <div className="mt-4">
                      <Text fontWeight="bold" fontSize="lg">
                        Commonly Transported Toxic Substances; Infectious{' '}
                        Substances
                      </Text>
                    </div>
                    <div className="mt-2">
                      <Text>{datum.commonlyTransportedGas}</Text>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default withNamespaces()(withToastManager(withRouter(ClassesPage)));
