export interface IQuestionsAndPoints {
  question: string;
  points: string[];
}
export interface IGHS {
  code: string;
  name: string;
  mainDescription: string;
  mainDescription2?: string;
  description: string[];
  questionsAndPoints: IQuestionsAndPoints[];
}

export const ghsList: IGHS[] = [
  {
    code: 'GHS01',
    name: 'Exploding Bomb',
    mainDescription:
      'The exploding bomb pictogram appears on the chemical labels of substances that are:',
    description: [
      'Explosives—which is a solid or liquid chemical capable of a chemical reaction that causes damage to the surroundings,',
      'Self-Reactive—heating may cause fire or explosion without the need for air, or',
      'Organic peroxides—again, heating may cause fire or explosion.'
    ],
    questionsAndPoints: [
      {
        question: 'What does it mean?',
        points: [
          'Unstable explosive',
          'Explosive; mass explosion hazard',
          'Explosive; severe projection hazard',
          'Explosive; fire, blast or projection hazard',
          'May mass explode in fire'
        ]
      },
      {
        question: 'Examples of where we can find it',
        points: ['Fireworks, ammunition']
      },
      {
        question: 'Examples of precautionary statements',
        points: [
          'Obtain special instructions before use',
          'Do not handle until all safety precautions have been read and understood',
          'Keep away from heat/sparks/open flames/hot surfaces. – No smoking',
          'Wear protective gloves/protective clothing/eye protection/face protection',
          'Use personal protective equipment as required',
          'Explosion risk in case of fire',
          'Avoid ignition sources (sparks, flames, heat). Keep your distance!',
          'Can burn even without air, or can intensify fire in combustible materials.'
        ]
      }
    ]
  },
  {
    code: 'GHS02',
    name: 'Flame',
    mainDescription: 'It appears on chemical labels for substances that are:',
    description: [
      'Flammables–which are gases, aerosols, liquids, or solids that will burn or ignite under certain conditions,',
      'Self-Reactives–heating alone, without air, may cause fire or explosion,',
      'Pyrophorics–in small amounts, may ignite within 5 minutes after contact with air,',
      'Self-Heating–which may catch fire only in large amounts and after long periods of time when exposed to air,',
      'Emitters of flammable gas, and',
      'Organic peroxides–which, when heated, may cause fire or explosion; may be sensitive to impact or friction; and may react dangerously with other chemicals.'
    ],
    questionsAndPoints: [
      {
        question: 'What does it mean?',
        points: [
          'Extremely flammable gas',
          'Flammable gas',
          'Extremely flammable aerosol',
          'Flammable aerosol',
          'Highly flammable liquid and vapour',
          'Flammable liquid and vapour',
          'Flammable solid'
        ]
      },
      {
        question: 'Examples of where we can find it',
        points: ['Lamp oil, petrol, nail polish remover']
      },
      {
        question: 'Examples of precautionary statements',
        points: [
          'Do not spray on an open flame or other ignition source.',
          'Keep away from heat/sparks/open flames/hot surfaces – No smoking',
          'Keep container tightly closed',
          'Keep cool',
          'Protect from sunlight',
          'Flammable if exposed to ignition sources, sparks, heat.',
          'Some substances with this symbols may give off flammable gases in contact with water.',
          'Avoid ignition sources (sparks, flames, heat).',
          'Wear protective gloves/protective clothing/eye protection/face protection'
        ]
      }
    ]
  },
  {
    code: 'GHS03',
    name: 'Flame Over Circle',
    mainDescription:
      'This symbol on a chemical label means that the substance is an oxidizer. Oxidizers are chemicals that facilitate burning or make fires burn hotter and longer. Oxidizers may cause a fire by increasing the concentration of oxygen in the air.',
    description: [],
    questionsAndPoints: [
      {
        question: 'What does it mean?',
        points: [
          'May cause or intensify fire; oxidiser.',
          'May cause fire or explosion; strong oxidiser.'
        ]
      },
      {
        question: 'Examples of where we can find it',
        points: ['Bleach, oxygen for medical purposes']
      },
      {
        question: 'Examples of precautionary statements',
        points: [
          'Keep away from heat/sparks/open flames/hot surfaces. – No smoking',
          'Wear protective gloves/protective clothing/eye protection/face protection.',
          'Rinse immediately contaminated clothing and skin with plenty of water before removing clothes.',
          'Can burn even without air, or can intensify fire in combustible materials',
          'Avoid ignition sources (sparks, flames, heat).',
          'Wear protective gloves/protective clothing/eye protection/face protection'
        ]
      }
    ]
  },
  {
    code: 'GHS04',
    name: 'Gas Cylinder',
    mainDescription:
      'This pictogram on a chemical label means that the substance is a compressed, liquefied, or dissolved gas under pressure at 29 pounds per square inch or more.',
    description: [],
    questionsAndPoints: [
      {
        question: 'What does it mean?',
        points: [
          'Contains gas under pressure; may explode if heated.',
          'Contains refrigerated gas; may cause cryogenic burns or injury.'
        ]
      },
      {
        question: 'Examples of where we can find it',
        points: ['Gas containers']
      },
      {
        question: 'Examples of precautionary statements',
        points: [
          'Protect from sunlight',
          'Wear cold insulating gloves/face shield/eye protection.',
          'Get immediate medical advice/attention.',
          'Gas container may explode if heated. Do not heat containers.'
        ]
      }
    ]
  },
  {
    code: 'GHS05',
    name: 'Corrosion',
    mainDescription:
      'This pictogram on a chemical label means that the substance causes skin burns, eye damage, or destroys metals.',
    description: [],
    questionsAndPoints: [
      {
        question: 'What does it mean?',
        points: [
          'May be corrosive to metals',
          'Causes severe skin burns and eye damage'
        ]
      },
      {
        question: 'Examples of where we can find it',
        points: ['Drain cleaners, acetic acid, hydrochloric acid, ammoniac']
      },
      {
        question: 'Examples of precautionary statements',
        points: [
          'Do not breathe dust/fume/gas/mist/vapours/spray',
          'Wash…thoroughly after handling',
          'Corrosive material which may cause skin and burns and permanent eye damage.',
          'Wear protective gloves/protective clothing/eye protection/face protection',
          'Store locked up',
          'Keep only in original container'
        ]
      }
    ]
  },
  {
    code: 'GHS06',
    name: 'Skull and Crossbones',
    mainDescription:
      'Substances with a hazard of acute toxicity will have this symbol on their chemical label. Acute toxicity means that exposure to a single dose of the chemical may be toxic or fatal if inhaled or swallowed, or if it comes into contact with the skin.',
    description: [],
    questionsAndPoints: [
      {
        question: 'What does it mean?',
        points: [
          'Fatal if swallowed',
          'Fatal in contact with skin',
          'Fatal if inhaled',
          'Toxic: if swallowed',
          'Toxic in contact with skin',
          'Toxic if inhaled'
        ]
      },
      {
        question: 'Examples of where we can find it',
        points: ['Pesticide, biocide, methanol']
      },
      {
        question: 'Examples of precautionary statements',
        points: [
          'Wash... Thoroughly after handling.',
          'Do not eat, drink or smoke when using this product.',
          'If swallowed: immediately call a POISON CENTER or a doctor/physician',
          'Rinse mouth',
          'Store in a closed container',
          'Do not get in eyes, on skin, or on clothing.',
          'Wear protective gloves/protective clothing/eye protection/face protection.',
          'If on skin: gently wash with plenty of soap and water',
          'Remove/take off immediately all contaminated clothing.',
          'Wash contaminated clothing before reuse.',
          'Do not breathe dust/fume/gas/mist/vapours/spray.',
          'Use only outdoors or in a well-ventilated area',
          'Wear respiratory protection',
          'If inhaled: Remove victim to fresh air and keep at rest in a position comfortable for breathing'
        ]
      }
    ]
  },
  {
    code: 'GHS07',
    name: 'Exclamation Mark',
    mainDescription:
      'It is used on a chemical label for substances that represent the following hazards:',
    description: [
      'Irritant–irritates the skin or eyes;',
      'Skin sensitizer–which is an allergic response following skin contact;',
      'Acute toxicity–which may be fatal or cause organ damage from a single short-term exposure;',
      'Narcotic effects like drowsiness, lack of coordination, and dizziness; and',
      'Respiratory tract irritation.'
    ],
    questionsAndPoints: [
      {
        question: 'What does it mean?',
        points: [
          'May cause respiratory irritation',
          'May cause drowsiness or dizziness',
          'May cause an allergic skin reaction',
          'Causes serious eye irritation',
          'Causes skin irritation',
          'Harmful if swallowed',
          'Harmful in contact with skin',
          'Harmful if inhaled',
          'Harms public health and the environment by destroying ozone in the upper atmosphere'
        ]
      },
      {
        question: 'Examples of where we can find it',
        points: ['Washing detergents, toilet cleaner, coolant fluid']
      },
      {
        question: 'Examples of precautionary statements',
        points: [
          'Avoid breathing dust/fume/gas/mist/vapours/spray',
          'Use only outdoors or in a well-ventilated area',
          'If inhaled: remove victim to fresh air and keep at rest in a position comfortable for breathing',
          'If swallowed: call a POISON CENTER or a doctor/physician if you feel unwell',
          'Wear protective gloves/protective clothing/eye protection/face protection.',
          'If on skin: wash with plenty of soap and water',
          'If in eyes: rinse cautiously with water for several minutes. Remove contact lens, if present and easy to do. Continue rinsing.',
          'Do not eat, drink or smoke when using this product.'
        ]
      }
    ]
  },
  {
    code: 'GHS08',
    name: 'Health Hazard',
    mainDescription:
      'This pictogram is put on a chemical label when a substance presents these health hazards:',
    description: [
      'Carcinogen–may cause cancer',
      'Respiratory sensitizer–may cause respiratory irritation',
      'Reproductive toxicity–may damage fertility or the unborn child',
      'Target organ toxicity–may cause damage to bodily organs',
      'Mutagenicity–may cause genetic defects',
      'Aspiration toxicity–may be fatal if swallowed and it enters the airways'
    ],
    questionsAndPoints: [
      {
        question: 'What does it mean?',
        points: [
          'May be fatal if swallowed and enters airways',
          'Causes damage to organs',
          'May cause damage to organs',
          'May damage fertility or the unborn child',
          'Suspected of damaging fertility or the unborn child',
          'May cause cancer',
          'Suspected of causing cancer',
          'May cause genetic defects',
          'Suspected of causing genetic defects',
          'May cause allergy or asthma symptoms or breathing difficulties if inhaled'
        ]
      },
      {
        question: 'Examples of where we can find it',
        points: ['Turpentine, petrol, lamp oil']
      },
      {
        question: 'Examples of precautionary statements',
        points: [
          'If swallowed: immediately call a POISON CENTER or a doctor/physician',
          'Do NOT induce vomiting',
          'Store locked up',
          'Do not breathe dust/fume/gas/mist/vapours/spray.',
          'Wash thoroughly after handling.',
          'Do not eat, drink or smoke when using this product.',
          'Get medical advice/attention if you feel unwell',
          'If exposed: Call a POISON CENTER or doctor/physician',
          'Obtain special instructions before use',
          'Do not handle until all safety precautions have been read and understood',
          'Use personal protective equipment as required',
          'If exposed or concerned: Get medical advice/attention',
          'Avoid breathing dust/fume/gas/mist/vapours/spray',
          'In case of inadequate ventilation wear respiratory protection',
          'If inhaled: If breathing is difficult, remove victim to fresh air and keep at rest in a position comfortable for breathing'
        ]
      }
    ]
  },
  {
    code: 'GHS09',
    name: 'Environment',
    mainDescription:
      'This non-mandatory pictogram means the hazard the chemical presents is aquatic toxicity.',
    mainDescription2:
      'Toxic to aquatic organisms and may cause long lasting effects in the environment. Avoid release to the environment!',
    description: [],
    questionsAndPoints: [
      {
        question: 'What does it mean?',
        points: [
          'Very toxic to aquatic life with long lasting effects',
          'Toxic to aquatic life with long lasting effects'
        ]
      },
      {
        question: 'Examples of where we can find it',
        points: ['Pesticides, biocides, petrol, turpentine']
      },
      {
        question: 'Examples of precautionary statements',
        points: ['Avoid release to the environment', 'Collect spillage']
      }
    ]
  }
];
