import React, { Component } from 'react';

import { ErrorAlert } from '../common/components/ErrorAlert.component';

export interface IState {
  hasError: boolean;
}

export class ErrorBoundary extends Component<{}, IState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorAlert errorMessage="Unable to load requested Page. Please contact the Support Team." />
      );
    }

    return this.props.children;
  }
}
