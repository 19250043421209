import { ILicense } from '../../models/license.model';
import { AnyAction, Reducer } from 'redux';
import {
  RETRIEVE_LICENSES__SUCCESS,
  RETRIEVE_LICENSES__START,
  DELETE_USER__SUCCESS
} from '../../actions';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import { IAppState } from '../../../../store';

export type LicenseById = { [key: string]: ILicense };

export interface ILicensesInfoState {
  licenseById: LicenseById;
  licenseAllIds: string[];
}

export const defaultState: ILicensesInfoState = {
  licenseById: {},
  licenseAllIds: []
};

export const licensesInfoReducer: Reducer<ILicensesInfoState> = (
  state: ILicensesInfoState = defaultState,
  action: AnyAction
): ILicensesInfoState => {
  switch (action.type) {
    case RETRIEVE_LICENSES__START: {
      return {
        ...state,
        licenseAllIds: defaultState.licenseAllIds,
        licenseById: defaultState.licenseById
      };
    }
    case RETRIEVE_LICENSES__SUCCESS: {
      const { licenses } = action.payload;
      const byId = keyBy(licenses, (datum) => {
        return datum.licence;
      });
      const ids = map(licenses, (datum) => {
        return datum.licence;
      });
      return {
        ...state,
        licenseById: { ...state.licenseById, ...byId },
        licenseAllIds: uniq([...state.licenseAllIds, ...ids])
      };
    }
    case DELETE_USER__SUCCESS: {
      const { userId } = action.payload;
      const { licenseById, licenseAllIds } = state;
      const licenses = map(licenseAllIds, (datum) => {
        return licenseById[datum];
      });
      const filterOutLicenses = licenses.map((datum) => {
        if (datum.user?.user_id && datum.user?.user_id === userId) {
          return {
            ...datum,
            user: null
          };
        }
        return datum;
      });
      const byId = keyBy(filterOutLicenses, (datum) => {
        return datum.licence;
      });
      const ids = map(filterOutLicenses, (datum) => {
        return datum.licence;
      });
      return {
        ...state,
        licenseById: { ...state.licenseById, ...byId },
        licenseAllIds: uniq([...state.licenseAllIds, ...ids])
      };
    }
    default:
      return state;
  }
};

export const getLicenseById = (state: IAppState) => {
  return state.license.entities.licenseInfo.licenseById;
};

export const getLicenseAllIds = (state: IAppState) => {
  return state.license.entities.licenseInfo.licenseAllIds;
};
