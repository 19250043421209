export enum REPORT_TYPE {
  UN_NUMBER = 1,
  CLASS = 2,
  UN_BY_NAME = 3
}

export enum CATEGORY_BELONG_TYPE {
  LIMITED = 'limited',
  PLACARD = 'placard',
  MANIFEST = 'manifest'
}

export enum APPROVE_STATUS_TYPE {
  WAITING = 'waiting',
  REJECTED = 'rejected',
  APPROVED = 'approved'
}

export interface ICurrentManifest {
  report_id: string;
  report_name: string;
  is_safe: number;
  product: IProduct[];
  created_at: Date;
  user_name: string;
  goods_staus: string;
  report_type: REPORT_TYPE;
  tunnel_value?: string;
  pdf: string | null;
  approvedby: string | null;
  approvestatus: string;
  reason: string | null;
  packagingData: IPackaging | null;
}

export interface IProduct {
  un_no: string;
  type: string;
  class: string;
  class_display: string;
  name_description: string;
  un_no_name: string;
  tunnel_code?: string;
  id: number;
  HAZCHEM?: string;
  images: string[];
  packagingType?: string;
  mesurement?: string;
  quantity?: string;
}

export interface IPackaging {
  cat_class: number;
  category: string;
  category_belong: CATEGORY_BELONG_TYPE;
  class: string;
  id: number;
  packaging_group: number;
  quantity: string;
  report_pro_id: number;
}
