export const PASSWORD_MIN_CHAR = 8;
export const PHONE_NUMBER_LENGTH = 10;
export const EMAIL_MAX_LENGTH = 64;
export const OTP_LENGTH = 6;
export const NAME_MAX_LENGTH = 32;
export const ALLOW_PROFILE_PIC_EXTENSION = ['jpg', 'jpeg', 'png'];
export const ALLOW_TRUCK_REGISTRATION_EXTENSION = ['jpg', 'jpeg', 'png'];
export const NOT_ALLOW_EMAIL_DOMAIN = ['yomail.com'];

export enum TABLE {
  PAGE_LIMIT = 25
}

export const ISD_CODE = ['+61', '+64', '+01', '+86', '+91'];
export const CARD_NUMBER_LENGTH = {
  max: 16,
  min: 15
};
export const CVV_NUMBER_LENGTH = {
  max: 4,
  min: 3
};

export const PAGE_SIZE_OPTION = [10, 25, 50];

export enum ERROR_CODE {
  RESET_PASSWORD_TOKEN_EXPIRED = 211011,
  RESET_PASSWORD_TOKEN_ALREADY_USED = 211012,
  RESET_PASSWORD_TOKEN_INVALID = 211013,
  USER_NOT_FOUND = 211002,
  LOGIN_ACCOUNT_BLOCKED = 211003,
  USER_ALREADY_EXITS = 211015,
  NO_SETTLEMENT_DATA = 134404
}

export enum STATUS {
  NOT_STARTED = '',
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
  LOADING = 'LOADING'
}

export const ERROR_MESSAGE = {
  SERVER_ERROR: {
    en: 'Server Error',
    au: 'Server Error',
    uk: 'Server Error',
    nzl: 'Server Error',
    in: 'Server Error',
    ch: '服务器错误'
  },
  email: {
    en: 'Enter a valid email ID',
    au: 'Enter a valid email ID',
    uk: 'Enter a valid email ID',
    nzl: 'Enter a valid email ID',
    in: 'Enter a valid email ID',
    ch: '输入有效的电子邮件ID'
  },
  emailDomain: {
    en: 'Enter a valid domain email ID',
    au: 'Enter a valid domain email ID',
    uk: 'Enter a valid domain email ID',
    nzl: 'Enter a valid domain email ID',
    in: 'Enter a valid domain email ID',
    ch: '输入有效的域电子邮件ID'
  },
  emailAlreadyExist: {
    en: 'Entered email already exists',
    au: 'Entered email already exists',
    uk: 'Entered email already exists',
    nzl: 'Entered email already exists',
    in: 'Entered email already exists',
    ch: '输入的电子邮件已经存在'
  },
  password: {
    en:
      'Password must be at least 6 characters long and contains a number, an upperCase, a lowerCase and a special character.',
    au:
      'Password must be at least 6 characters long and contains a number, an upperCase, a lowerCase and a special character.',
    uk:
      'Password must be at least 6 characters long and contains a number, an upperCase, a lowerCase and a special character.',
    nzl:
      'Password must be at least 6 characters long and contains a number, an upperCase, a lowerCase and a special character.',
    in:
      'Password must be at least 6 characters long and contains a number, an upperCase, a lowerCase and a special character.',
    ch: '密码必须至少包含6个字符，并包含数字，大写字母，小写字母和特殊字符。'
  },
  phone: {
    en: 'Enter valid phone number.',
    au: 'Enter valid phone number.',
    uk: 'Enter valid phone number.',
    nzl: 'Enter valid phone number.',
    in: 'Enter valid phone number.',
    ch: '输入有效的电话号码。'
  },
  confirmPassword: {
    en: 'Both the passwords do not match',
    au: 'Both the passwords do not match',
    uk: 'Both the passwords do not match',
    nzl: 'Both the passwords do not match',
    in: 'Both the passwords do not match',
    ch: '两个密码不匹配'
  },
  required: {
    en: 'This field is required',
    au: 'This field is required',
    uk: 'This field is required',
    nzl: 'This field is required',
    in: 'This field is required',
    ch: '这是必填栏'
  },
  profilePic: {
    en: 'Profile Picture required',
    au: 'Profile Picture required',
    uk: 'Profile Picture required',
    nzl: 'Profile Picture required',
    in: 'Profile Picture required',
    ch: '需要个人资料图片'
  },
  dateFormat: {
    en: 'Entered date is invalid',
    au: 'Entered date is invalid',
    uk: 'Entered date is invalid',
    nzl: 'Entered date is invalid',
    in: 'Entered date is invalid',
    ch: '输入的日期无效'
  },
  imageUploadError: {
    en: 'Image upload failed, Please try again',
    au: 'Image upload failed, Please try again',
    uk: 'Image upload failed, Please try again',
    nzl: 'Image upload failed, Please try again',
    in: 'Image upload failed, Please try again',
    ch: '图片上传失败，请重试'
  },
  NO_MATCHING_RESULT: {
    en: 'No Matching Results found',
    au: 'No Matching Results found',
    uk: 'No Matching Results found',
    nzl: 'No Matching Results found',
    in: 'No Matching Results found',
    ch: '找不到匹配的结果'
  },
  NO_MANIFEST_FOUND: {
    en: 'No Manifest found',
    au: 'No Manifest found',
    uk: 'No Manifest found',
    nzl: 'No Manifest found',
    in: 'No Manifest found',
    ch: 'No Manifest found'
  },
  NO_SUBSCRIPTION_FOUND: {
    en: 'No Subscription to Upgrade',
    au: 'No Subscription to Upgrade',
    uk: 'No Subscription to Upgrade',
    nzl: 'No Subscription to Upgrade',
    in: 'No Subscription to Upgrade',
    ch: '无需订阅升级'
  },
  CART_EMPTY: {
    en: 'Goods is empty. Please search UN Number and add goods.',
    au: 'Goods is empty. Please search UN Number and add goods.',
    uk: 'Goods is empty. Please search UN Number and add goods.',
    nzl: 'Goods is empty. Please search UN Number and add goods.',
    in: 'Goods is empty. Please search UN Number and add goods.',
    ch: '货物是空的。请搜索联合国编号并添加商品。'
  },
  NO_LICENSE_KEY: {
    en: 'No License key found.',
    au: 'No License key found.',
    uk: 'No License key found.',
    nzl: 'No License key found.',
    in: 'No License key found.',
    ch: '找不到许可证密钥。'
  },
  DATA_FAILURE: {
    en: 'Unable to retrieve data',
    au: 'Unable to retrieve data',
    uk: 'Unable to retrieve data',
    nzl: 'Unable to retrieve data',
    in: 'Unable to retrieve data',
    ch: '无法检索订阅'
  },
  LICENSE_FAILURE: {
    en: 'Unable to retrieve licenses.',
    au: 'Unable to retrieve licenses.',
    uk: 'Unable to retrieve licenses.',
    nzl: 'Unable to retrieve licenses.',
    in: 'Unable to retrieve licenses.',
    ch: '无法检索许可证。'
  },
  RESEND_VERIFICATION_CODE_FAILED: {
    en: 'Unable to resend verification code',
    au: 'Unable to resend verification code',
    uk: 'Unable to resend verification code',
    nzl: 'Unable to resend verification code',
    in: 'Unable to resend verification code',
    ch: '无法重新发送验证码'
  },
  CRETE_USER: {
    en: 'Unable to create new user',
    au: 'Unable to create new user',
    uk: 'Unable to create new user',
    nzl: 'Unable to create new user',
    in: 'Unable to create new user',
    ch: '无法创建新用户'
  },
  CARD_NUMBER_INVALID: {
    en: 'Card number is invalid.',
    au: 'Card number is invalid.',
    uk: 'Card number is invalid.',
    nzl: 'Card number is invalid.',
    in: 'Card number is invalid.',
    ch: '卡号无效。'
  },
  INVALID_EXPIRY_DATE: {
    en: 'Card expiry date is invalid.',
    au: 'Card expiry date is invalid.',
    uk: 'Card expiry date is invalid.',
    nzl: 'Card expiry date is invalid.',
    in: 'Card expiry date is invalid.',
    ch: '卡的有效期无效。'
  },
  CVV_INVALID: {
    en: 'CVV number is invalid.',
    au: 'CVV number is invalid.',
    uk: 'CVV number is invalid.',
    nzl: 'CVV number is invalid.',
    in: 'CVV number is invalid.',
    ch: 'CVV号无效。'
  },
  COUPON_INVALID: {
    en: 'Coupon is invalid.',
    au: 'Coupon is invalid.',
    uk: 'Coupon is invalid.',
    nzl: 'Coupon is invalid.',
    in: 'Coupon is invalid.',
    ch: '优惠券无效。'
  },
  COUPON_VALUE_MORE_THAN_SUBSCRIPTION: {
    en:
      'Coupon amount is more the subscription amount. Please select another coupon.',
    au:
      'Coupon amount is more the subscription amount. Please select another coupon.',
    uk:
      'Coupon amount is more the subscription amount. Please select another coupon.',
    nzl:
      'Coupon amount is more the subscription amount. Please select another coupon.',
    in:
      'Coupon amount is more the subscription amount. Please select another coupon.',
    ch: '优惠券金额大于订阅金额。请选择另一张优惠券。'
  },
  VERIFICATION_CODE_INVALID: {
    en: 'Please check your code and try again.',
    au: 'Please check your code and try again.',
    uk: 'Please check your code and try again.',
    nzl: 'Please check your code and try again.',
    in: 'Please check your code and try again.',
    ch: '请验证代码，然后重试。'
  },
  SUBMIT_SUPPORT_FEEDBACK: {
    en: 'Submit you feedback / comments failed.',
    au: 'Submit you feedback / comments failed.',
    uk: 'Submit you feedback / comments failed.',
    nzl: 'Submit you feedback / comments failed.',
    in: 'Submit you feedback / comments failed.',
    ch: '向您提交反馈/评论失败。'
  },
  CHANGE_PASSWORD: {
    en: 'Not able to change password. Please try again after sometime.',
    au: 'Not able to change password. Please try again after sometime.',
    uk: 'Not able to change password. Please try again after sometime.',
    nzl: 'Not able to change password. Please try again after sometime.',
    in: 'Not able to change password. Please try again after sometime.',
    ch: '无法更改密码。请稍后再试。'
  },
  SIGN_IN: {
    en: 'Unable to sign-in Please try again after sometime.',
    au: 'Unable to sign-in Please try again after sometime.',
    uk: 'Unable to sign-in Please try again after sometime.',
    nzl: 'Unable to sign-in Please try again after sometime.',
    in: 'Unable to sign-in Please try again after sometime.',
    ch: '无法更改密码。请稍后再试。'
  },
  CHANGE_PASSWORD_CONFIRM_PASSWORD: {
    en: 'New Password and Confirm Password should be same.',
    au: 'New Password and Confirm Password should be same.',
    uk: 'New Password and Confirm Password should be same.',
    nzl: 'New Password and Confirm Password should be same.',
    in: 'New Password and Confirm Password should be same.',
    ch: '新密码和确认密码应该相同。'
  },
  PROFILE_PIC_INVALID: {
    en: 'Profile Pic is invalid. Please select .jpg, .jpeg or .png file',
    au: 'Profile Pic is invalid. Please select .jpg, .jpeg or .png file',
    uk: 'Profile Pic is invalid. Please select .jpg, .jpeg or .png file',
    nzl: 'Profile Pic is invalid. Please select .jpg, .jpeg or .png file',
    in: 'Profile Pic is invalid. Please select .jpg, .jpeg or .png file',
    ch: '个人资料照片无效。请选择.jpg，.jpeg或.png文件'
  },
  TRUCK_REGISTRATION_INVALID: {
    en: 'Truck Registration file is invalid. Please select .jpg, .jpeg or .png file',
    au: 'Truck Registration file is invalid. Please select .jpg, .jpeg or .png file',
    uk: 'Truck Registration file is invalid. Please select .jpg, .jpeg or .png file',
    nzl: 'Truck Registration file is invalid. Please select .jpg, .jpeg or .png file',
    in: 'Truck Registration file is invalid. Please select .jpg, .jpeg or .png file',
    ch: 'Truck Registration file is invalid. Please select .jpg, .jpeg or .png file'
  },
  EDIT_PROFILE: {
    en: 'Not able to edit your profile. Please try again after sometime.',
    au: 'Not able to edit your profile. Please try again after sometime.',
    uk: 'Not able to edit your profile. Please try again after sometime.',
    nzl: 'Not able to edit your profile. Please try again after sometime.',
    in: 'Not able to edit your profile. Please try again after sometime.',
    ch: '无法编辑您的个人资料。请稍后再试。'
  },
  ADD_SUB_USER: {
    en:
      'Not able to add Employee for this license. Please try again after sometime.',
    au:
      'Not able to add Employee for this license. Please try again after sometime.',
    uk:
      'Not able to add Employee for this license. Please try again after sometime.',
    nzl:
      'Not able to add Employee for this license. Please try again after sometime.',
    in:
      'Not able to add Employee for this license. Please try again after sometime.',
    ch: '无法为此许可添加Employee。请稍后再试。'
  },
  FORGOT_PASSWORD_OTP: {
    en:
      'Not able to send otp on your email address. Please try again after sometime.',
    au:
      'Not able to send otp on your email address. Please try again after sometime.',
    uk:
      'Not able to send otp on your email address. Please try again after sometime.',
    nzl:
      'Not able to send otp on your email address. Please try again after sometime.',
    in:
      'Not able to send otp on your email address. Please try again after sometime.',
    ch: '无法在您的电子邮件地址上发送otp。请稍后再试。'
  },
  UPDATE_PASSWORD: {
    en: 'Not able to reset your password. Please try again after sometime.',
    au: 'Not able to reset your password. Please try again after sometime.',
    uk: 'Not able to reset your password. Please try again after sometime.',
    nzl: 'Not able to reset your password. Please try again after sometime.',
    in: 'Not able to reset your password. Please try again after sometime.',
    ch: '无法重设密码。请稍后再试。'
  },
  NO_SUBSCRIPTION_DATA: {
    en: 'No Subscription found.',
    au: 'No Subscription found.',
    uk: 'No Subscription found.',
    nzl: 'No Subscription found.',
    in: 'No Subscription found.',
    ch: '找不到订阅。'
  },
  CANCEL_SUBSCRIPTION: {
    en: 'Not able to cancel you subscription. Please try again later.',
    au: 'Not able to cancel you subscription. Please try again later.',
    uk: 'Not able to cancel you subscription. Please try again later.',
    nzl: 'Not able to cancel you subscription. Please try again later.',
    in: 'Not able to cancel you subscription. Please try again later.',
    ch: '无法取消您的订阅。请稍后再试。'
  },
  CART_UPDATE: {
    en: 'Goods updation failed. Please try again later.',
    au: 'Goods updation failed. Please try again later.',
    uk: 'Goods updation failed. Please try again later.',
    nzl: 'Goods updation failed. Please try again later.',
    in: 'Goods updation failed. Please try again later.',
    ch: '商品更新失败。请稍后再试。'
  },
  CHECK_COMBINATION: {
    en: 'Combination check failed. Please try again later.',
    au: 'Combination check failed. Please try again later.',
    uk: 'Combination check failed. Please try again later.',
    nzl: 'Combination check failed. Please try again later.',
    in: 'Combination check failed. Please try again later.',
    ch: '组合检查失败。请稍后再试。'
  },
  CREATE_REPORT: {
    en: 'Manifest creation failed. Please try again later.',
    au: 'Manifest creation failed. Please try again later.',
    uk: 'Manifest creation failed. Please try again later.',
    nzl: 'Manifest creation failed. Please try again later.',
    in: 'Manifest creation failed. Please try again later.',
    ch: '报表创建失败。请稍后再试。'
  },
  REPORT_MEASUREMENT_VALIDATION: {
    en: 'Please fill Packaging Type, Measurement and Quantity.',
    au: 'Please fill Packaging Type, Measurement and Quantity.',
    uk: 'Please fill Packaging Type, Measurement and Quantity.',
    nzl: 'Please fill Packaging Type, Measurement and Quantity.',
    in: 'Please fill Packaging Type, Measurement and Quantity.',
    ch: '报表创建失败。请稍后再试。'
  },
  NO_REPORT_FOUND: {
    en: 'No Manifest found',
    au: 'No Manifest found',
    uk: 'No Manifest found',
    nzl: 'No Manifest found',
    in: 'No Manifest found',
    ch: '找不到报告'
  },
  NO_OPERATOR_FOUND_MANIFEST: {
    en: 'Select an operator to see the Manifest.',
    au: 'Select an operator to see the Manifest.',
    uk: 'Select an operator to see the Manifest.',
    nzl: 'Select an operator to see the Manifest.',
    in: 'Select an operator to see the Manifest.',
    ch: 'Select an operator to see the Manifest.'
  },
  NO_OPERATOR_FOUND_MANIFEST_HISTORY: {
    en: 'Select an operator to see the Manifest History.',
    au: 'Select an operator to see the Manifest History.',
    uk: 'Select an operator to see the Manifest History.',
    nzl: 'Select an operator to see the Manifest History.',
    in: 'Select an operator to see the Manifest History.',
    ch: 'Select an operator to see the Manifest History.'
  },
  NO_CURRENT_FOUND_CURRENT_MANIFEST: {
    en: 'Select a company to see the Manifest.',
    au: 'Select a company to see the Manifest.',
    uk: 'Select a company to see the Manifest.',
    nzl: 'Select a company to see the Manifest.',
    in: 'Select a company to see the Manifest.',
    ch: 'Select a company to see the Manifest.'
  },
  NO_COMPANY_FOUND_MANIFEST_HISTORY: {
    en: 'Select a company to see the Manifest History.',
    au: 'Select a company to see the Manifest History.',
    uk: 'Select a company to see the Manifest History.',
    nzl: 'Select a company to see the Manifest History.',
    in: 'Select a company to see the Manifest History.',
    ch: 'Select a company to see the Manifest History.'
  },
  NO_PRODUCT_FOUND: {
    en: 'No Product found',
    au: 'No Product found',
    uk: 'No Product found',
    nzl: 'No Product found',
    in: 'No Product found',
    ch: '找不到产品'
  },
  REPORT_DELETE: {
    en: 'Report deletion failed. Please try again later.',
    au: 'Report deletion failed. Please try again later.',
    uk: 'Report deletion failed. Please try again later.',
    nzl: 'Report deletion failed. Please try again later.',
    in: 'Report deletion failed. Please try again later.',
    ch: '报告删除失败。请稍后再试。'
  },
  REASON_FIELD: {
    en: 'Reason field is required to reject the manifest.',
    au: 'Reason field is required to reject the manifest.',
    uk: 'Reason field is required to reject the manifest.',
    nzl: 'Reason field is required to reject the manifest.',
    in: 'Reason field is required to reject the manifest.',
    ch: 'Reason field is required to reject the manifest.'
  },
  REPORT_UPDATE: {
    en: 'Report updation failed. Please try again later.',
    au: 'Report updation failed. Please try again later.',
    uk: 'Report updation failed. Please try again later.',
    nzl: 'Report updation failed. Please try again later.',
    in: 'Report updation failed. Please try again later.',
    ch: 'Report updation failed. Please try again later.'
  },
  BROADCAST_MESSAGE: {
    en: 'Message broadcast failed. Please try again later.',
    au: 'Message broadcast failed. Please try again later.',
    uk: 'Message broadcast failed. Please try again later.',
    nzl: 'Message broadcast failed. Please try again later.',
    in: 'Message broadcast failed. Please try again later.',
    ch: '消息广播失败。请稍后再试。'
  },
  UN_NUMBER_SEARCH_INVALID: {
    en: 'Invalid UN Number. Please check and try again.',
    au: 'Invalid UN Number. Please check and try again.',
    uk: 'Invalid UN Number. Please check and try again.',
    nzl: 'Invalid UN Number. Please check and try again.',
    in: 'Invalid UN Number. Please check and try again.',
    ch: '无效的联合国编号。请检查并重试。'
  },
  UN_NUMBER_SEARCH_FAILED: {
    en: 'Not able to search this un number. Please try again later.',
    au: 'Not able to search this un number. Please try again later.',
    uk: 'Not able to search this un number. Please try again later.',
    nzl: 'Not able to search this un number. Please try again later.',
    in: 'Not able to search this un number. Please try again later.',
    ch: '无法搜索此联合国编号。请稍后再试。'
  },
  UPDATE_SUBSCRIPTION: {
    en: 'Unable to update subscription',
    au: 'Unable to update subscription',
    uk: 'Unable to update subscription',
    nzl: 'Unable to update subscription',
    in: 'Unable to update subscription',
    ch: '无法更新订阅'
  },
  ADD_WAREHOUSE_TRUCK: {
    en: 'Not able to add Warehouse or Truck. Please try again after sometime.',
    au: 'Not able to add Warehouse or Truck. Please try again after sometime.',
    uk: 'Not able to add Warehouse or Truck. Please try again after sometime.',
    nzl: 'Not able to add Warehouse or Truck. Please try again after sometime.',
    in: 'Not able to add Warehouse or Truck. Please try again after sometime.',
    ch: '无法添加仓库或卡车。请稍后再试。'
  },
  UNLOAD_PRODUCT: {
    en: 'Good unloaded failed. Please try again later.',
    au: 'Good unloaded failed. Please try again later.',
    uk: 'Good unloaded failed. Please try again later.',
    nzl: 'Good unloaded failed. Please try again later.',
    in: 'Good unloaded failed. Please try again later.',
    ch: 'Good unloaded failed. Please try again later.'
  },
  LOGS_LIST: {
    en: 'Unable to retireve Logs list. Please try again.',
    au: 'Unable to retireve Logs list. Please try again.',
    uk: 'Unable to retireve Logs list. Please try again.',
    nzl: 'Unable to retireve Logs list. Please try again.',
    in: 'Unable to retireve Logs list. Please try again.',
    ch: 'Unable to retireve Logs list. Please try again.'
  },
  LOGS_LIST_EMPTY: {
    en: 'No Logs Found.',
    au: 'No Logs Found.',
    uk: 'No Logs Found.',
    nzl: 'No Logs Found.',
    in: 'No Logs Found.',
    ch: 'No Logs Found.'
  },
  NO_PRODUCT: {
    en: 'No loads in the list. Please add more loads by update the manifest.',
    au: 'No loads in the list. Please add more loads by update the manifest.',
    uk: 'No loads in the list. Please add more loads by update the manifest.',
    nzl: 'No loads in the list. Please add more loads by update the manifest.',
    in: 'No loads in the list. Please add more loads by update the manifest.',
    ch: 'No loads in the list. Please add more loads by update the manifest.'
  },
  NO_PRODUCT_DETAIL_SCREEN: {
    en: 'All goods for this manifest is unloaded and this manifest will be removed from list.',
    au: 'All goods for this manifest is unloaded and this manifest will be removed from list.',
    uk: 'All goods for this manifest is unloaded and this manifest will be removed from list.',
    nzl: 'All goods for this manifest is unloaded and this manifest will be removed from list.',
    in: 'All goods for this manifest is unloaded and this manifest will be removed from list.',
    ch: 'All goods for this manifest is unloaded and this manifest will be removed from list.'
  },
  CURRENT_MANIFEST_FAILURE: {
    en: 'Unable to retrieve current manifest.',
    au: 'Unable to retrieve current manifest.',
    uk: 'Unable to retrieve current manifest.',
    nzl: 'Unable to retrieve current manifest.',
    in: 'Unable to retrieve current manifest.',
    ch: 'Unable to retrieve current manifest.'
  },
  COMPANY_LIST_FAILURE: {
    en: 'Unable to retrieve companies list',
    au: 'Unable to retrieve companies list',
    uk: 'Unable to retrieve companies list',
    nzl: 'Unable to retrieve companies list',
    in: 'Unable to retrieve companies list',
    ch: 'Unable to retrieve companies list'
  },
  COMPANY_BASED_OPERATORS_LIST_FAILURE: {
    en: 'Unable to retrieve operators list',
    au: 'Unable to retrieve operators list',
    uk: 'Unable to retrieve operators list',
    nzl: 'Unable to retrieve operators list',
    in: 'Unable to retrieve operators list',
    ch: 'Unable to retrieve operators list'
  },
  DELETE_USER: {
    en: 'Unable to delete user. Please try again later.',
    au: 'Unable to delete user. Please try again later.',
    uk: 'Unable to delete user. Please try again later.',
    nzl: 'Unable to delete user. Please try again later.',
    in: 'Unable to delete user. Please try again later.',
    ch: 'Unable to delete user. Please try again later.'
  },
  GENERATE_OTP: {
    en: 'Not able generate otp. Please try again later.',
    au: 'Not able generate otp. Please try again later.',
    uk: 'Not able generate otp. Please try again later.',
    nzl: 'Not able generate otp. Please try again later.',
    in: 'Not able generate otp. Please try again later.',
    ch: 'Not able generate otp. Please try again later.'
  },
  INVALID_CARD_DETAIL: {
    en: 'Invalid Card details.',
    au: 'Invalid Card details.',
    uk: 'Invalid Card details.',
    nzl: 'Invalid Card details.',
    in: 'Invalid Card details.',
    ch: 'Invalid Card details.'
  }
};

export const SUCCESS_MESSAGE = {
  CRETE_USER: {
    en:
      'User created successfully please check your email id to verify account.',
    au:
      'User created successfully please check your email id to verify account.',
    uk:
      'User created successfully please check your email id to verify account.',
    nzl:
      'User created successfully please check your email id to verify account.',
    in:
      'User created successfully please check your email id to verify account.',
    ch: '用户创建成功，请检查您的电子邮件ID以验证帐户。'
  },
  PAYMENT_SUCCESSFUL: {
    en: 'Payment has been done. Please check your mail for login licence key.',
    au: 'Payment has been done. Please check your mail for login licence key.',
    uk: 'Payment has been done. Please check your mail for login licence key.',
    nzl: 'Payment has been done. Please check your mail for login licence key.',
    in: 'Payment has been done. Please check your mail for login licence key.',
    ch: '付款已完成。请检查您的邮件以获取登录许可证密钥。'
  },
  COUPON_APPLIED: {
    en: 'Coupon applied.',
    au: 'Coupon applied.',
    uk: 'Coupon applied.',
    nzl: 'Coupon applied.',
    in: 'Coupon applied.',
    ch: '已使用优惠券'
  },
  VERIFICATION_CODE: {
    en: 'Email id verified successfully.',
    au: 'Email id verified successfully.',
    uk: 'Email id verified successfully.',
    nzl: 'Email id verified successfully.',
    in: 'Email id verified successfully.',
    ch: '电子邮件ID已成功验证。'
  },
  RESEND_VERIFICATION_CODE: {
    en: 'Verification code sent successfully on your email address.',
    au: 'Verification code sent successfully on your email address.',
    uk: 'Verification code sent successfully on your email address.',
    nzl: 'Verification code sent successfully on your email address.',
    in: 'Verification code sent successfully on your email address.',
    ch: '验证码已成功发送到您的电子邮件地址。'
  },
  SUBMIT_SUPPORT_FEEDBACK: {
    en: 'Your feedback has been submitted.',
    au: 'Your feedback has been submitted.',
    uk: 'Your feedback has been submitted.',
    nzl: 'Your feedback has been submitted.',
    in: 'Your feedback has been submitted.',
    ch: '您的反馈已提交。'
  },
  CHANGE_PASSWORD: {
    en: 'Password changed successfully.',
    au: 'Password changed successfully.',
    uk: 'Password changed successfully.',
    nzl: 'Password changed successfully.',
    in: 'Password changed successfully.',
    ch: '密码修改成功。'
  },
  EDIT_PROFILE: {
    en: 'Profile updated successfully.',
    au: 'Profile updated successfully.',
    uk: 'Profile updated successfully.',
    nzl: 'Profile updated successfully.',
    in: 'Profile updated successfully.',
    ch: '个人资料更新成功'
  },
  ADD_SUB_USER: {
    en:
      'Employee Created Successfully. Please ask him to check his Email for next step.',
    au:
      'Employee Created Successfully. Please ask him to check his Email for next step.',
    uk:
      'Employee Created Successfully. Please ask him to check his Email for next step.',
    nzl:
      'Employee Created Successfully. Please ask him to check his Email for next step.',
    in:
      'Employee Created Successfully. Please ask him to check his Email for next step.',
    ch: '员工创建成功。请让他检查他的电子邮件以进行下一步。'
  },
  EDIT_SUB_USER: {
    en: 'Employee Updated Successfully.',
    au: 'Employee Updated Successfully.',
    uk: 'Employee Updated Successfully.',
    nzl: 'Employee Updated Successfully.',
    in: 'Employee Updated Successfully.',
    ch: 'Employee Updated Successfully.'
  },
  FORGOT_PASSWORD_OTP: {
    en:
      'OTP has been sent to your email address. Enter OTP to reset your password.',
    au:
      'OTP has been sent to your email address. Enter OTP to reset your password.',
    uk:
      'OTP has been sent to your email address. Enter OTP to reset your password.',
    nzl:
      'OTP has been sent to your email address. Enter OTP to reset your password.',
    in:
      'OTP has been sent to your email address. Enter OTP to reset your password.',
    ch:
      'OTP has been sent to your email address. Enter OTP to reset your password.'
  },
  UPDATE_PASSWORD: {
    en:
      'Password updated successfully. You can now login with your new Password.',
    au:
      'Password updated successfully. You can now login with your new Password.',
    uk:
      'Password updated successfully. You can now login with your new Password.',
    nzl:
      'Password updated successfully. You can now login with your new Password.',
    in:
      'Password updated successfully. You can now login with your new Password.',
    ch:
      'Password updated successfully. You can now login with your new Password.'
  },
  CANCEL_SUBSCRIPTION: {
    en: 'Your Subscription has been cancelled successfully.',
    au: 'Your Subscription has been cancelled successfully.',
    uk: 'Your Subscription has been cancelled successfully.',
    nzl: 'Your Subscription has been cancelled successfully.',
    in: 'Your Subscription has been cancelled successfully.',
    ch: '您的订阅已成功取消。'
  },
  CART_UPDATE: {
    en: 'Goods updated successfully.',
    au: 'Goods updated successfully.',
    uk: 'Goods updated successfully.',
    nzl: 'Goods updated successfully.',
    in: 'Goods updated successfully.',
    ch: '商品更新成功。'
  },
  CREATE_REPORT: {
    en: 'Manifest created successfully.',
    au: 'Manifest created successfully.',
    uk: 'Manifest created successfully.',
    nzl: 'Manifest created successfully.',
    in: 'Manifest created successfully.',
    ch: '报表创建成功。'
  },
  UPDATE_REPORT: {
    en: 'Manifest updated successfully.',
    au: 'Manifest updated successfully.',
    uk: 'Manifest updated successfully.',
    nzl: 'Manifest updated successfully.',
    in: 'Manifest updated successfully.',
    ch: 'Manifest updated successfully.'
  },
  DELETE_REPORT: {
    en: 'Manifest deleted successfully.',
    au: 'Manifest deleted successfully.',
    uk: 'Manifest deleted successfully.',
    nzl: 'Manifest deleted successfully.',
    in: 'Manifest deleted successfully.',
    ch: '报告已成功删除。'
  },
  BROADCAST_MESSAGE: {
    en: 'Message broadcast successfully.',
    au: 'Message broadcast successfully.',
    uk: 'Message broadcast successfully.',
    nzl: 'Message broadcast successfully.',
    in: 'Message broadcast successfully.',
    ch: '消息广播成功。'
  },
  UPDATE_SUBSCRIPTION: {
    en:
      'Your subscription has been upgraded and new Licenses are added in your account. Please check Terms & Policy for the billing cycle.',
    au:
      'Your subscription has been upgraded and new Licenses are added in your account. Please check Terms & Policy for the billing cycle.',
    uk:
      'Your subscription has been upgraded and new Licenses are added in your account. Please check Terms & Policy for the billing cycle.',
    nzl:
      'Your subscription has been upgraded and new Licenses are added in your account. Please check Terms & Policy for the billing cycle.',
    in:
      'Your subscription has been upgraded and new Licenses are added in your account. Please check Terms & Policy for the billing cycle.',
    ch:
      '您的订阅已升级，新的许可证已添加到您的帐户中。请检查计费周期的条款和政策。'
  },
  ADD_WAREHOUSE_TRUCK: {
    en: 'Warehouse or Truck added successfully.',
    au: 'Warehouse or Truck added successfully.',
    uk: 'Warehouse or Truck added successfully.',
    nzl: 'Warehouse or Truck added successfully.',
    in: 'Warehouse or Truck added successfully.',
    ch: '仓库或卡车添加成功。'
  },
  UNLOAD_PRODUCT: {
    en: 'Good unloaded successfully.',
    au: 'Good unloaded successfully.',
    uk: 'Good unloaded successfully.',
    nzl: 'Good unloaded successfully.',
    in: 'Good unloaded successfully.',
    ch: 'Good unloaded successfully.'
  },
  DELETE_USER: {
    en: 'User deleted successfully.',
    au: 'User deleted successfully.',
    uk: 'User deleted successfully.',
    nzl: 'User deleted successfully.',
    in: 'User deleted successfully.',
    ch: 'User deleted successfully.'
  },
  COUPON_ALREADY_APPLIED: {
    en: 'Coupon already applied.',
    au: 'Coupon already applied.',
    uk: 'Coupon already applied.',
    nzl: 'Coupon already applied.',
    in: 'Coupon already applied.',
    ch: 'Coupon already applied.'
  }
};

export const ROUTES = {
  HOME: '/',
  PDG_HOME: '/pdg',
  REGISTER: '/register',
  VERIFY_OTP: '/verifyOTP',
  CLASSES: '/classes',
  GHS: '/ghs',
  GHS_DETAIL: '/ghs/:id',
  SUBSCRIPTION: '/subscription',
  VERIFICATION: '/verification',
  PAYMENT: '/payment',
  SET_NEW_PASSWORD: '/set-new-password/:token',
  FORGOT_PASSWORD: '/forgot-password',
  COMING_SOON: '/coming-soon',
  UN_NUMBER_DETAIL: '/un-number-detail/:id',
  ABOUT_US: '/pdg/about_us',
  ABOUT_US_WITHOUT_LOGIN: '/about_us',
  REPORTS: '/pdg/reports',
  GUIDE: '/pdg/guide',
  REPORT_DETAIL: '/pdg/reports/:operatorId/:report_id',
  REPORT_UN_EDIT: '/pdg/reports/edit-un/:operatorId/:report_id',
  ADD_REPORT: '/pdg/reports-add/:operatorId',
  NOTIFICATION: '/pdg/notification',
  LICENSE: '/pdg/license',
  LEGALS: '/pdg/legals',
  LEGALS_WITHOUT_LOGIN: '/legals',
  TERMS_AND_CONDITIONS: '/pdg/terms-and-policy',
  TERMS_AND_CONDITIONS_WITHOUT_LOGIN: '/terms-and-policy',
  SUPPORT_FEEDBACK: '/pdg/support-feedback',
  SUPPORT_FEEDBACK_WITHOUT_LOGIN: '/support-feedback',
  UPGRADE_SUBSCRIPTION_LIST: '/upgrade-subscription',
  MANIFEST: '/pdg/manifest',
  CHECK_DANGEROUS_GOODS_SAFETY_REQUIREMENTS:
    '/pdg/dangerouse-good-safety-requirement',
  CHECK_MANIFEST_HISTORY: '/pdg/manifest-history',
  EMERGENCY_IDENTIFY_DANGERIUS_GOODS_LABELS:
    '/pdg/emergency-identify-dangerous-goods-labels',
  WAREHOUSE_AND_TRUCK_REGISTRATION: '/pdg/warehouse-and-truck-registration',
  PROFILE: '/pdg/pernissions-alert',
  ALERT: '/pdg/alert',
  PERMISSION_DENIED: '*'
};

export const APPLICATION_STATUS = [
  {
    label: 'Approve',
    value: 'APPROVED'
  },
  {
    label: 'Reject',
    value: 'REJECTED'
  }
];

export enum SupportedLanguages {
  EN = 'en',
  AR = 'ar'
}

export const LANGUAGE: Array<{ value: SupportedLanguages; label: string }> = [
  {
    value: SupportedLanguages.EN,
    label: 'English'
  },
  {
    value: SupportedLanguages.AR,
    label: 'Arabic'
  }
];

export const MONTHS = [
  {
    label: 'January',
    digit: 1
  },
  {
    label: 'February',
    digit: 2
  },
  {
    label: 'March',
    digit: 3
  },
  {
    label: 'April',
    digit: 4
  },
  {
    label: 'May',
    digit: 5
  },
  {
    label: 'June',
    digit: 6
  },
  {
    label: 'July',
    digit: 7
  },
  {
    label: 'August',
    digit: 8
  },
  {
    label: 'September',
    digit: 9
  },
  {
    label: 'October',
    digit: 10
  },
  {
    label: 'November',
    digit: 11
  },
  {
    label: 'December',
    digit: 12
  }
];
