/* istanbul ignore file */
import React, { FunctionComponent, useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Box, Alert, Skeleton } from '@chakra-ui/core';
import equal from 'fast-deep-equal';
import { FormikProps, FormikHelpers as FormikActions, Formik } from 'formik';

import {
  createUrl,
  getMessage,
  isAdmin,
  signageDisplayClassDivision
} from '../../../helper';
import UnsafeIcon from '../../../assets/images/shield-unsafe.png';
import { ErrorAlert } from '../../../common/components/ErrorAlert.component';
import SafeIcon from '../../../assets/images/shield-safe.png';
import { IProductInCart } from '../models/un-number.model';
import UnNumberCartItem from '../components/UnNumberCartItem.component';
import {
  ERROR_MESSAGE,
  ROUTES,
  STATUS,
  SUCCESS_MESSAGE
} from '../../../constant';
import {
  addImageInUnNumberIntoCart,
  removeImageInUnNumberIntoCart,
  addProductValueInUnNumberIntoCart,
  createReport,
  retrieveCombinationEvaluation
} from '../actions';
import { ICombinationData, IProductValue } from '../reducers/ui';
import {
  ICreateReportParams,
  IUnNumberClassDivisionParams
} from '../services/api.service';
import { SignageDisplay } from '../../../common/components/SignageDisplay.component';
import { SaveReportConfirmation } from '../../../common/components/SaveReportConfirmation.component';
import { IReport, REPORT_TYPE } from '../models/reports-list.model';
import { H3 } from '../../../common/components';
import { User } from '../../../common/models/user';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  unNumbersInCart: IProductInCart[];
  combinationData: ICombinationData;
  retrieveCombinationAPIStatus: STATUS;
  report: IReport | null;
  operatorId: string;
  report_type: REPORT_TYPE;
  initialProducts: string;
  user: User;
  createReport: typeof createReport;
  addImageInUnNumberIntoCart: typeof addImageInUnNumberIntoCart;
  addProductValueInUnNumberIntoCart: typeof addProductValueInUnNumberIntoCart;
  retrieveCombinationEvaluation: typeof retrieveCombinationEvaluation;
  removeImageInUnNumberIntoCart: typeof removeImageInUnNumberIntoCart;
  removeUnNumberFromCart: (unNumber: IProductInCart) => void;
  t: any;
}
interface IFormValue {
  reportName?: string;
}

export const UnNumberCartComponent: FunctionComponent<IProps> = (
  props: IProps
) => {
  const {
    t,
    user,
    toastManager,
    className,
    unNumbersInCart,
    report_type,
    report,
    operatorId,
    removeUnNumberFromCart,
    addImageInUnNumberIntoCart,
    removeImageInUnNumberIntoCart,
    addProductValueInUnNumberIntoCart,
    combinationData,
    createReport,
    retrieveCombinationAPIStatus,
    history,
    initialProducts,
    retrieveCombinationEvaluation
  } = props;
  const _retrieveCombinationEvaluation = async () => {
    try {
      const params: IUnNumberClassDivisionParams[] = unNumbersInCart.map(
        (datum: IProductInCart) => ({
          un_no: datum.un_no ? Number(datum.un_no) : 0,
          class_division_value: datum.class_division_value,
          tunnel_code: datum.tunnel_code ?? '',
          type: datum.type
        })
      );
      await retrieveCombinationEvaluation(params);
    } catch (err) {
      console.log(
        ':: _retrieveCombinationEvaluation err ',
        retrieveCombinationEvaluation
      );
      toastManager.add(getMessage(ERROR_MESSAGE.CHECK_COMBINATION), {
        appearance: 'error',
        autoDismiss: true
      });
    }
  };
  useEffect(() => {
    _retrieveCombinationEvaluation();
  }, []);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isSavePopupOpen, setIsSavePopupOpen] = useState(false);

  const validateFilterForm = (values: IFormValue) => {
    const errors: any = {};
    if (!values.reportName || values.reportName !== '') {
      errors.reportName = ERROR_MESSAGE.required;
    }
    return errors;
  };

  const _createReport = async (
    reportName: string
  ) => {
    try {
      const params: ICreateReportParams = {
        product: unNumbersInCart.map((datum: IProductInCart) => ({
          name: datum.name_description,
          class_value: datum.un_no
            ? datum.class_division_value
            : datum.class_division,
          class_division_value: datum.class_division_value,
          un_no: datum.un_no ?? '',
          tunnel_code: datum.tunnel_code ?? '',
          type: datum.type,
          image: datum.images,
          mesurement: datum.mesurement,
          packagingType: datum.packagingType,
          quantity: datum.quantity
        })),
        report_name: reportName as string,
        report_id: report?.report_id ? Number(report.report_id) : undefined,
        is_safe: combinationData?.safe_status ? 1 : 0,
        get_approval: combinationData?.safe_status ? 0 : 1,
        report_type,
        tunnel_value: combinationData?.tunnel_value ?? '',
        goods_staus:
          unNumbersInCart.length === 1
            ? 'normal'
            : combinationData?.safe_status
            ? 'safe'
            : 'dangerous'
      };
      await createReport(operatorId, params);
      setIsSavePopupOpen(false);
      toastManager.add(getMessage(SUCCESS_MESSAGE.CREATE_REPORT), {
        appearance: 'success',
        autoDismiss: true
      });
      const reportDetailUrl = createUrl(
        ROUTES.REPORTS,
        undefined,
        {
          operatorId
        }
      );
      history.push(reportDetailUrl);
    } catch (err) {
      console.log(':: _createReport err ', err);
      toastManager.add(getMessage(ERROR_MESSAGE.CREATE_REPORT), {
        appearance: 'error',
        autoDismiss: true
      });
    }
  };

  const isEqual = equal(JSON.stringify(unNumbersInCart), initialProducts);

  return (
    <Box className={`${className}`}>
      <hr />
      <H3 pt="md">Your Loads</H3>
      {unNumbersInCart && unNumbersInCart.length ? (
        <>
          {retrieveCombinationAPIStatus === STATUS.FAILURE ? (
            <Box>
              <ErrorAlert
                errorMessage={getMessage(ERROR_MESSAGE.DATA_FAILURE)}
                retry={_retrieveCombinationEvaluation}
              />
            </Box>
          ) : null}
          {retrieveCombinationAPIStatus !== STATUS.FAILURE ? (
            <Skeleton
              isLoaded={retrieveCombinationAPIStatus === STATUS.SUCCESS}
            >
              <Box className="mt-5 ab-bx-3 p-3 ">
                <Box
                  className="sc-1"
                  backgroundColor={
                    combinationData?.safe_status ? '#4cb034' : '#ed4f1c'
                  }
                >
                  <span>
                    {combinationData?.safe_status ? (
                      <img src={SafeIcon} alt="safe" />
                    ) : (
                      <img src={UnsafeIcon} alt="unsafe" />
                    )}
                  </span>{' '}
                  <p>
                    {combinationData?.safe_status ? (
                      <span>{t('SAFE_COMBINATION')}</span>
                    ) : (
                      <span>{t('UNSAFE_COMBINATION')}</span>
                    )}
                  </p>
                </Box>
                <p className="text-center d-block mt-3 mb-0">
                  {combinationData?.message?.length ? (
                    <ul>
                      {combinationData?.message.map(
                        (datum: string, index: number) => (
                          <li key={index}>{datum}</li>
                        )
                      )}
                    </ul>
                  ) : null}
                  {combinationData?.safe_status
                    ? t('COMBINATION_SAFE')
                    : t('COMBINATION_UNSAFE')}{' '}
                </p>
                {combinationData?.tunnel_value ? (
                  <p className="text-center d-block mt-3 mb-0">
                    Tunnel Info: {combinationData?.tunnel_value ?? ''}
                  </p>
                ) : null}
                <SignageDisplay
                  t={t}
                  length={
                    signageDisplayClassDivision(
                      unNumbersInCart.length ? unNumbersInCart : [],
                      true
                    ).length
                  }
                  isSafe={!!combinationData?.safe_status}
                  class_division={
                    signageDisplayClassDivision(
                      unNumbersInCart.length ? unNumbersInCart : [],
                      true
                    ).classDivision
                  }
                />
              </Box>
            </Skeleton>
          ) : null}
          <Box className="mt-4 row">
            {unNumbersInCart.map((datum: IProductInCart) => (
              <UnNumberCartItem
                key={datum.un_no}
                addImage={(image: string) => {
                  addImageInUnNumberIntoCart({
                    id:
                      datum.un_no && datum.un_no !== ''
                        ? datum.un_no
                        : datum.class_division,
                    image
                  });
                }}
                removeImage={(image: string) => {
                  removeImageInUnNumberIntoCart({
                    id:
                      datum.un_no && datum.un_no !== ''
                        ? datum.un_no
                        : datum.class_division,
                    image
                  });
                }}
                addProductValue={(productValue: IProductValue) => {
                  addProductValueInUnNumberIntoCart({
                    id:
                      datum.un_no && datum.un_no !== ''
                        ? datum.un_no
                        : datum.class_division,
                    productValue
                  });
                }}
                removeUnNumber={() => {
                  removeUnNumberFromCart(datum);
                }}
                unNumberInCart={datum}
                {...props}
              />
            ))}
          </Box>
          {!isEqual ? (
            <Box className="mt-4 text-center">
              <button
                className="btn-primary transition"
                onClick={() => {
                  const isReportValidate = unNumbersInCart.filter(
                    (datum) =>
                      !datum.packagingType ||
                      !datum.mesurement ||
                      !datum.quantity
                  ).length;
                  if (isReportValidate === 0) {
                    setIsOpenPopup(true);
                  } else {
                    toastManager.add(
                      getMessage(ERROR_MESSAGE.REPORT_MEASUREMENT_VALIDATION),
                      {
                        appearance: 'error',
                        autoDismiss: true
                      }
                    );
                  }
                }}
              >
                {t(
                  combinationData?.safe_status || isAdmin(user.role)
                    ? 'SAVE'
                    : 'GET_APPROVAL'
                )}
              </button>
            </Box>
          ) : null}
        </>
      ) : (
        <Box mt="md">
          <Alert mb="xs" status="warning">
            {getMessage(ERROR_MESSAGE.CART_EMPTY)}
          </Alert>
        </Box>
      )}
      {isOpenPopup ? (
        <SaveReportConfirmation
          t={t}
          isSafe={combinationData?.safe_status}
          onClose={() => {
            setIsOpenPopup(false);
          }}
          onYes={() => {
            setIsOpenPopup(false);
            if (!!report?.report_name && report?.report_name !== '') {
              _createReport(report?.report_name)
            } else {
              setIsSavePopupOpen(true);
            }
          }}
        />
      ) : null}

      {isSavePopupOpen ? (
        <div className="_cstModal">
          <div className="_mdBody">
            <Box className="mt-4 text-center">
              <Formik
                enableReinitialize
                validate={(values: IFormValue) => {
                  validateFilterForm(values);
                }}
                validateOnMount
                initialValues={{
                  reportName: ''
                }}
                onSubmit={(
                  values: IFormValue,
                  actions: FormikActions<IFormValue>
                ) => {
                  values.reportName && _createReport(values.reportName);
                }}
                component={(formikProps: FormikProps<IFormValue>) => {
                  return (
                    <Box
                      className="db-bx-1"
                      as="form"
                      onSubmit={formikProps.handleSubmit}
                    >
                      <Box className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="reportName"
                          placeholder="Enter Manifest Name"
                          value={formikProps.values.reportName}
                          onChange={(event) => {
                            formikProps.setFieldValue(
                              'reportName',
                              event.target.value
                            );
                          }}
                        />
                        {formikProps.errors.reportName &&
                        formikProps.touched.reportName ? (
                          <Box className="invalid-feedback">
                            {formikProps.errors.reportName}
                          </Box>
                        ) : null}
                      </Box>
                      <button
                        type="submit"
                        className="btn-primary transition"
                        style={{
                          position: 'unset'
                        }}
                        disabled={!formikProps.values.reportName}
                      >
                        {t('SAVE')}
                      </button>
                      <Box
                        className="_close"
                        onClick={() => {
                          setIsSavePopupOpen(false);
                          formikProps.setFieldValue('reportName', '')
                        }}
                        position="fixed"
                      >
                        x {t('CLOSE')}
                      </Box>
                    </Box>
                  );
                }}
              />
            </Box>
          </div>
        </div>
      ) : null}
    </Box>
  );
};

export default withRouter(UnNumberCartComponent);
