import { AnyAction, Reducer } from 'redux';
import keyBy from 'lodash/keyBy';
import { createSelector } from 'reselect';
import map from 'lodash/map';

import { IAppState } from '../../store';
import { NOTIFICATION__SUCCESS } from '../actions';
import { INotification } from '../models/notification';

export type NotificationById = { [key: string]: INotification };

export interface INotificationPageState {
  byId: NotificationById;
  allIds: string[];
}

export const defaultState: INotificationPageState = {
  byId: {},
  allIds: []
};

export const notificationPageReducer: Reducer<INotificationPageState> = (
  state: INotificationPageState = defaultState,
  action: AnyAction
): INotificationPageState => {
  switch (action.type) {
    case NOTIFICATION__SUCCESS: {
      const { notifications } = action.payload;
      const byId = keyBy(
        notifications,
        (notification: INotification) => notification.id
      );
      const allIds = notifications.map(
        (notification: INotification) => notification.id
      );
      return {
        byId,
        allIds
      };
    }
    default: {
      return state;
    }
  }
};

export const getNotificationByIds = (state: IAppState) => {
  return state.common.notifications.byId;
};

export const getNotificationAllIds = (state: IAppState) => {
  return state.common.notifications.allIds;
};

export const getNotificationListSelector = createSelector(
  getNotificationAllIds,
  getNotificationByIds,
  (allIds: string[], byId: NotificationById): INotification[] => {
    if (!allIds || !allIds.length) {
      return [];
    }
    return map(allIds, (datum) => {
      return byId[datum];
    });
  }
);
