/* istanbul ignore file */

import { Method } from 'axios';
import request from '../../../services/request.service';
import { setLocalstorage, getLocalstorage } from '../../../helper';
import GlobalConfig from '../../../globalConfig';
import { ISubscription, ILicenseData } from '../models/subscription.model';
import { ICoupon } from '../models/coupon.model';
import { IRegisterFormValues } from '../components/register.component';
import { IPaymentFormValues } from '../components/payment.component';

interface IServiceProps {
  method: Method;
  headers: Record<string, any>;
  url: string;
  data?: Record<string, any>;
  responseType?: any;
}

export interface IServiceResponse {
  status: number;
  message: string;
}

export interface ILicenseDataResponse {
  status: number;
  message: string;
  licenseData: ILicenseData | null;
}

export interface ICouponResponse {
  status: number;
  coupon: ICoupon;
  message?: string;
}

export interface IPaymentParams {
  user_id?: string;
  subscription_id?: string;
  card_no?: string;
  exp_date?: string;
  coupon_code?: string;
  cvv?: string;
}

export interface IUpdateSubscriptionParams {
  user_id: number;
  subscription_id: number;
}

export const getSubscriptionRequestParams = (data = {}): IServiceProps => ({
  method: 'GET',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot('mobile/subscription'),
  data
});

export const getSubscriptionListWithUserIdRequestParams = (
  user_id: string
): IServiceProps => ({
  method: 'GET',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(
    `mobile/getupgradesubscription/${user_id}`
  )
});

export const submitRegisterFormParams = (data = {}): IServiceProps => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot('mobile/registration'),
  data
});

export const resendVerificationCodeParams = (data = {}): IServiceProps => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot('mobile/resendcode'),
  data
});

export const sendVerificationCodeParams = (data = {}): IServiceProps => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot('mobile/verifycode'),
  data
});

export const submitCouponCodeParams = (data = {}): IServiceProps => ({
  method: 'GET',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot('mobile/coupon'),
  data
});

export const submitPaymentParams = (data: {}): IServiceProps => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot('mobile/paybypaypal'),
  data
});

export const updatesubscriptionParams = (
  data: IUpdateSubscriptionParams
): IServiceProps => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot('mobile/updatesubscription'),
  data
});

export const cancelSubscriptionParams = (userId: string): IServiceProps => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(`mobile/cancelSubscription/${userId}`)
});

export const retrieveLicenseDataParams = (userId: string): IServiceProps => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(`mobile/getpaymentdate/${userId}`)
});

export class RegisterServices {
  static async retrieveSubscription(): Promise<ISubscription[]> {
    const res = await request(getSubscriptionRequestParams());
    return res.data?.subscription;
  }

  static async submitCouponCode(coupon_code: string): Promise<ICouponResponse> {
    const res = await request(submitCouponCodeParams({ coupon_code }));
    return res.data;
  }

  static async submitRegistration(values: IRegisterFormValues) {
    const params = {
      email: values.email,
      password: values.password,
      phone_no: `${values.phone}`,
      country_id: values.code,
      name: values.name,
      image: values.image
    };
    const res = await request(submitRegisterFormParams(params));
    setLocalstorage('user_id', res.data.id);
    setLocalstorage('email', values.email);
    return res.data;
  }

  static async resendVerificationCode() {
    const params = {
      user_id: getLocalstorage('user_id')
    };
    const res = await request(resendVerificationCodeParams(params));
    return res.data;
  }

  static async sendVerificationCode(code: string) {
    const params = {
      user_id: getLocalstorage('user_id'),
      code
    };
    const res = await request(sendVerificationCodeParams(params));
    return res.data;
  }

  static async submitPayment(
    values: IPaymentFormValues,
    isValidCoupon: boolean | null
  ) {
    const params: IPaymentParams = {};
    params.card_no = values.card ?? '';
    params.exp_date =
      values.month && values.year
        ? `${values.month}/${values.year?.slice(2)}`
        : '';
    params.cvv = values.cvv ?? '';

    if (values.isCode && values.code && isValidCoupon) {
      params.coupon_code = values.code;
    }
    params.user_id = getLocalstorage('user_id');
    params.subscription_id = getLocalstorage('subscriptionId');
    const res = await request(submitPaymentParams(params));
    return res.data;
  }

  static async retrieveSubscriptionListWithUserId(userId: string) {
    const res = await request(
      getSubscriptionListWithUserIdRequestParams(userId)
    );
    return (
      res?.data?.subscription?.map((datum: any) => {
        return {
          id: datum.id,
          type: datum.type,
          license: datum.license,
          deleted: datum.is_deleted,
          deleted_at: datum.deleted_at,
          status: datum.status,
          created_at: datum.created_at,
          updated_at: datum.updated_at,
          amount: Number(datum.amount)
        };
      }) ?? []
    );
  }

  static async updatesubscription(subscription_id: number, userId: string) {
    const params: IUpdateSubscriptionParams = {
      user_id: Number(userId),
      subscription_id
    };
    const res = await request(updatesubscriptionParams(params));
    return res;
  }

  static async retrieveLicenseData(
    userId: string
  ): Promise<ILicenseDataResponse> {
    const res = await request(retrieveLicenseDataParams(userId));
    const expiryDate = res.data.data.billing_end_date
      ? new Date(res.data.data.billing_end_date)
      : null;
    const nextBillingDate = expiryDate ? new Date(expiryDate) : null;
    nextBillingDate &&
      new Date(nextBillingDate.setDate(nextBillingDate.getDate() + 1));
    return {
      status: res.data.status,
      message: res.data.message,
      licenseData:
        expiryDate && nextBillingDate
          ? {
              totalLicense: res.data.total_license ?? 10,
              expiryDate,
              nextBillingDate,
              subscription_status: !!res.data.subscription_status
            }
          : null
    };
  }

  static async cancelSubscription(userId: string): Promise<IServiceResponse> {
    const res = await request(cancelSubscriptionParams(userId));
    return res?.data;
  }
}
