import { AnyAction, Reducer } from 'redux';
import { createSelector } from 'reselect';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import uniq from 'lodash/uniq';

import {
  GET_COMPANY_LIST__START,
  GET_COMPANY_LIST__FAILURE,
  GET_COMPANY_LIST__SUCCESS,
  GET_OPERATOR_LIST__START,
  GET_OPERATOR_LIST__FAILURE,
  GET_OPERATOR_LIST__SUCCESS
} from '../actions';
import { IAppState } from '../../store';
import { STATUS } from '../../constant';
import { User } from '../models/user';

export type CompanyById = { [key: string]: User };
export type OperatorById = { [key: string]: User };

export interface ICompanyAndOperatorInfoState {
  companyById: CompanyById;
  companyAllIds: string[];
  operatorById: OperatorById;
  operatorAllIds: string[];
  retrieveCompanyListAPIStatus: STATUS;
  retrieveOperatorListAPIStatus: STATUS;
}

export const defaultState: ICompanyAndOperatorInfoState = {
  companyById: {},
  companyAllIds: [],
  operatorById: {},
  operatorAllIds: [],
  retrieveCompanyListAPIStatus: STATUS.NOT_STARTED,
  retrieveOperatorListAPIStatus: STATUS.NOT_STARTED
};

export const companyAndOperatorInfoReducer: Reducer<ICompanyAndOperatorInfoState> = (
  state: ICompanyAndOperatorInfoState = defaultState,
  action: AnyAction
): ICompanyAndOperatorInfoState => {
  switch (action.type) {
    case GET_COMPANY_LIST__START: {
      return {
        ...state,
        companyAllIds: [],
        companyById: {},
        operatorAllIds: [],
        operatorById: {},
        retrieveCompanyListAPIStatus: STATUS.LOADING
      };
    }
    case GET_COMPANY_LIST__FAILURE: {
      return {
        ...state,
        companyAllIds: [],
        companyById: {},
        operatorAllIds: [],
        operatorById: {},
        retrieveCompanyListAPIStatus: STATUS.FAILURE
      };
    }
    case GET_COMPANY_LIST__SUCCESS: {
      const { companies } = action.payload;
      const byId = keyBy(companies, (datum: User) => {
        return datum.user_id;
      });
      const ids = map(companies, (datum: User) => {
        return datum.user_id;
      });
      return {
        ...state,
        companyById: { ...byId },
        companyAllIds: uniq([...ids]),
        retrieveCompanyListAPIStatus: STATUS.SUCCESS
      };
    }
    case GET_OPERATOR_LIST__START: {
      return {
        ...state,
        retrieveOperatorListAPIStatus: STATUS.LOADING
      };
    }
    case GET_OPERATOR_LIST__FAILURE: {
      return {
        ...state,
        retrieveOperatorListAPIStatus: STATUS.FAILURE
      };
    }
    case GET_OPERATOR_LIST__SUCCESS: {
      const { operators, companyId } = action.payload;
      const operatorsWithCompanyId = operators.map((datum: User) => ({
        ...datum,
        companyId
      }));
      const byId = keyBy(operatorsWithCompanyId, (datum: User) => {
        return datum.user_id;
      });
      const ids = map(operatorsWithCompanyId, (datum: User) => {
        return datum.user_id;
      });
      return {
        ...state,
        operatorById: { ...byId },
        operatorAllIds: uniq([...ids]),
        retrieveOperatorListAPIStatus: STATUS.SUCCESS
      };
    }
    default:
      return state;
  }
};

export const getCompanyById = (state: IAppState) => {
  return state.common.companyAndOperatorInfo.companyById;
};

export const getCompanyAllIds = (state: IAppState) => {
  return state.common.companyAndOperatorInfo.companyAllIds;
};

export const getOperatorById = (state: IAppState) => {
  return state.common.companyAndOperatorInfo.operatorById;
};

export const getOperatorAllIds = (state: IAppState) => {
  return state.common.companyAndOperatorInfo.operatorAllIds;
};

export const getCompanyListAPIStatus = (state: IAppState) => {
  return state.common.companyAndOperatorInfo.retrieveCompanyListAPIStatus;
};

export const getOperatorListAPIStatus = (state: IAppState) => {
  return state.common.companyAndOperatorInfo.retrieveOperatorListAPIStatus;
};

export const getCompanyListSelector = createSelector(
  getCompanyAllIds,
  getCompanyById,
  (companyAllIds: string[], companyById: CompanyById) => {
    if (!companyAllIds.length) {
      return [];
    }

    return map(companyAllIds, (datum) => {
      return companyById[datum];
    }).sort((companyA, companyB) =>
      companyA.name.toLowerCase() > companyB.name.toLowerCase()
        ? 1
        : companyB.name.toLowerCase() > companyA.name.toLowerCase()
        ? -1
        : 0
    );
  }
);

export const getCompanyBasedOperatorListSelector = createSelector(
  getOperatorAllIds,
  getOperatorById,
  (operatorAllIds: string[], operatorById: OperatorById) => {
    if (!operatorAllIds.length) {
      return [];
    }

    return map(operatorAllIds, (datum) => {
      return operatorById[datum];
    }).sort((operatorA, operatorB) =>
      operatorA.name.toLowerCase() > operatorB.name.toLowerCase()
        ? 1
        : operatorB.name.toLowerCase() > operatorA.name.toLowerCase()
        ? -1
        : 0
    );
  }
);
