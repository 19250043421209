import { AnyAction, Reducer } from 'redux';
import { IAppState } from '../../../../store';
import { STATUS } from '../../../../constant';
import {
  SUBMIT_SUPPORT_AND_FEEDBACK_FORM__START,
  SUBMIT_SUPPORT_AND_FEEDBACK_FORM__FAILURE,
  SUBMIT_SUPPORT_AND_FEEDBACK_FORM__SUCCESS
} from '../../actions';

export interface ISupportAndFeedbackPageState {
  submitSupportAndFeedbackFormAPIStatus: STATUS;
}

export const defaultState: ISupportAndFeedbackPageState = {
  submitSupportAndFeedbackFormAPIStatus: STATUS.NOT_STARTED
};

export const supportAndFeedbackPageReducer: Reducer<ISupportAndFeedbackPageState> = (
  state: ISupportAndFeedbackPageState = defaultState,
  action: AnyAction
): ISupportAndFeedbackPageState => {
  switch (action.type) {
    case SUBMIT_SUPPORT_AND_FEEDBACK_FORM__START: {
      return {
        ...state,
        submitSupportAndFeedbackFormAPIStatus: STATUS.LOADING
      };
    }
    case SUBMIT_SUPPORT_AND_FEEDBACK_FORM__FAILURE: {
      return {
        ...state,
        submitSupportAndFeedbackFormAPIStatus: STATUS.FAILURE
      };
    }
    case SUBMIT_SUPPORT_AND_FEEDBACK_FORM__SUCCESS: {
      return {
        ...state,
        submitSupportAndFeedbackFormAPIStatus: STATUS.SUCCESS
      };
    }
    default: {
      return state;
    }
  }
};

export const getSupportAndFeedbackAPIStatus = (state: IAppState) => {
  return state.supportAndFeedback.ui.supportAndFeedbackPage
    .submitSupportAndFeedbackFormAPIStatus;
};
