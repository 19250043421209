import { AnyAction, Reducer } from 'redux';
import { IAppState } from '../../store';
import { STATUS } from '../../constant';
import {
  EDIT_PROFILE__START,
  EDIT_PROFILE__FAILURE,
  EDIT_PROFILE__SUCCESS
} from '../actions';

export interface IEditProfilePageState {
  editProfileAPIStatus: STATUS;
}

export const defaultState: IEditProfilePageState = {
  editProfileAPIStatus: STATUS.NOT_STARTED
};

export const editProfilePageReducer: Reducer<IEditProfilePageState> = (
  state: IEditProfilePageState = defaultState,
  action: AnyAction
): IEditProfilePageState => {
  switch (action.type) {
    case EDIT_PROFILE__START: {
      return {
        ...state,
        editProfileAPIStatus: STATUS.LOADING
      };
    }
    case EDIT_PROFILE__FAILURE: {
      return {
        ...state,
        editProfileAPIStatus: STATUS.FAILURE
      };
    }
    case EDIT_PROFILE__SUCCESS: {
      return {
        ...state,
        editProfileAPIStatus: STATUS.SUCCESS
      };
    }
    default: {
      return state;
    }
  }
};

export const getEditProfileAPIStatus = (state: IAppState) => {
  return state.common.editProfile.editProfileAPIStatus;
};
