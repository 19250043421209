import React, { FunctionComponent } from 'react';
import { Box } from '@chakra-ui/core';
import { User } from '../models/user';
import { isAdmin } from '../../helper';

interface IProps {
  selectedOperatorId?: string;
  user: User;
  operatorList: User[];
  setSelectedOperatorId: (operatorId?: string) => void;
}

export const OperatorDropDown: FunctionComponent<IProps> = ({
  selectedOperatorId,
  user,
  operatorList,
  setSelectedOperatorId
}) => {
  return (
    <Box className="col-auto">
      <select
        className="form-control"
        name="operator"
        value={selectedOperatorId ?? ''}
        onChange={(event) => {
          setSelectedOperatorId(event.target.value ?? undefined);
        }}
      >
        <option value="">Select Operator</option>
        {isAdmin(user.role) ? (
          <option key={user.user_id} value={user.user_id}>
            {user.name} (Self)
          </option>
        ) : null}
        {operatorList.map((datum) => (
          <option key={datum.user_id} value={datum.user_id}>
            {datum.name}
          </option>
        ))}
      </select>
    </Box>
  )
}