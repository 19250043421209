/* istanbul ignore file */
import React, { FunctionComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Box } from '@chakra-ui/core';

import AddIcon from '../../../assets/images/add.png';
import CloseIcon from '../../../assets/images/close.png';
import { IClassDivision } from '../models/classDivision.model';
import { ClassDivisionImage } from '../../../common/components/helpers/class_division_image.component';
import { IProductInCart } from '../models/un-number.model';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  addClassDivision: () => void;
  removeClassDivision: () => void;
  classDivision: IClassDivision;
  unNumbersInCart: IProductInCart[];
  t: any;
}

export const ClassDivisionListItemComponent: FunctionComponent<IProps> = (
  props: IProps
) => {
  const {
    classDivision,
    addClassDivision,
    removeClassDivision,
    unNumbersInCart
  } = props;
  return (
    <Box className="mt-4 ab-bx-3">
      <Box className="p-3">
        <Box className="d-flex align-items-center">
          <Box className="ab-txt">
            <span>{classDivision.class_division}</span>
          </Box>
        </Box>
        <Box className="ab-bottom row class-justify">
          <Box className="col-7">
            <span> {classDivision.description}</span>
          </Box>
          <Box className="col-3">
            <ClassDivisionImage
              className="w-100"
              class_division={classDivision.class_division}
            />
          </Box>
        </Box>
        {unNumbersInCart.find(
          (item: IProductInCart) =>
            item.class_division === classDivision.class_division
        ) ? (
          <i
            className="_close"
            title="Remove Goods"
            onClick={() => {
              removeClassDivision();
            }}
          >
            <img src={CloseIcon} alt="close" />
          </i>
        ) : (
          <i
            className="_add"
            title="Add Goods"
            onClick={() => {
              addClassDivision();
            }}
          >
            <img src={AddIcon} alt="add" />
          </i>
        )}
      </Box>
    </Box>
  );
};

export default ClassDivisionListItemComponent;
