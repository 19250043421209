/* istanbul ignore file */

import { Method } from 'axios';
import request from '../../../services/request.service';
import GlobalConfig from '../../../globalConfig';
import { ILog } from '../models/manifest_log';

interface IServiceProps {
  method: Method;
  headers: Record<string, any>;
  url: string;
  data?: Record<string, any>;
  responseType?: any;
}

export interface IResponse {
  status: number;
  message: string;
}

export interface ILogsResponse extends IResponse {
  logs: ILog[];
  totalCount: number;
  page?: number;
  limit?: number;
}

export interface ILogsParams {
  operator_id: string;
  page: number;
}

export const retrieveLogsParams = (params: ILogsParams): IServiceProps => ({
  method: 'GET',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(
    `mobile/getlogs/${params.operator_id}?page=${params.page}`
  )
});

export class ManifestHisotryServices {
  static async retrieveLogs(params: ILogsParams): Promise<ILogsResponse> {
    const res = await request(retrieveLogsParams(params));
    return {
      status: res.data?.status,
      message: res.data?.message,
      logs: res.data?.logs?.data.length ? res.data?.logs?.data : [],
      totalCount: res.data?.logs.total ?? 0,
      limit: res.data?.logs.per_page,
      page: res.data?.logs.current_page
    };
  }
}
