/* istanbul ignore file */
import React, { FunctionComponent, useEffect } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { RouteComponentProps } from 'react-router-dom';
import { Box } from '@chakra-ui/core';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { IAppState } from '../../../store';
import { IAboutUs } from '../models/about_us.model';
import { STATUS, ROUTES } from '../../../constant';
import { retrieveContent } from '../actions';
import { getAboutUs, getAboutUsAPIStatus } from '../reducers/ui';
import { FullPageLoader } from '../../../common/components/Loader.component';
import Logo2 from '../../../assets/images/logo2.png';
import LeftBanner from '../../../assets/images/leftBanner2.png';
import CMSComponent from '../../../common/components/CMS.component';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  aboutUs: IAboutUs | null;
  retrieveAboutUsAPIStatus: STATUS;
  t: any;
  retrieveContent: typeof retrieveContent;
}

export const AboutUsWithoutLogin: FunctionComponent<IProps> = (
  props: IProps
) => {
  const {
    className,
    aboutUs,
    retrieveAboutUsAPIStatus,
    retrieveContent
  } = props;
  const _retrieveContent = async () => {
    try {
      await retrieveContent();
    } catch (err) {
      console.log(':: _retrieveContent err ', err);
    }
  };

  useEffect(() => {
    _retrieveContent();
  }, []);

  return (
    <Box className={className}>
      {retrieveAboutUsAPIStatus === STATUS.LOADING ? (
        <Box p={'lg'}>
          <FullPageLoader />
        </Box>
      ) : null}
      <Box className="signupAr">
        <Box className="lftImage">
          <a href={ROUTES.HOME}>
            <img src={Logo2} className="innLogo" alt="pdg" />
          </a>
          <img src={LeftBanner} alt="pdg" />
        </Box>
        <Box className="_snInfo cms-content" pt="xl" pb="xl">
          <CMSComponent
            retrieveContentAPIStatus={retrieveAboutUsAPIStatus}
            content={aboutUs?.content ?? ''}
            retrieveContentData={() => {
              _retrieveContent();
            }}
            title="About Us"
          />
        </Box>
      </Box>
    </Box>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: IAppState) => {
  const aboutUs = getAboutUs(state);
  const retrieveAboutUsAPIStatus = getAboutUsAPIStatus(state);
  return {
    aboutUs,
    retrieveAboutUsAPIStatus
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  return bindActionCreators({ retrieveContent }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(withToastManager(AboutUsWithoutLogin)));
