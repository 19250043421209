import React, { StrictMode, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import {
  ToastProvider,
  DefaultToastContainer
} from 'react-toast-notifications';

import Routes from './routing/routes';
import GlobalStyles from './styles/global.style';

import './App.css';
import { CSSReset } from '@chakra-ui/core';
import { IntlProvider } from 'react-intl';
import { getLocalstorage, setLocalstorage } from './helper';
import i18n from './i18n';

const App = () => {
  useEffect(() => {
    const lang = getLocalstorage('lang');
    if (lang) {
      i18n.changeLanguage(lang);
    } else {
      i18n.changeLanguage('au');
      setLocalstorage('lang', 'au');
    }
  }, []);
  return (
    <div className="home-wrapper">
      <CSSReset />
      <GlobalStyles />
      <IntlProvider locale="en-US">
        <BrowserRouter basename="/React">
          <ToastProvider components={{ ToastContainer: DefaultToastContainer }}>
            <StrictMode>
              <Routes />
            </StrictMode>
          </ToastProvider>
        </BrowserRouter>
      </IntlProvider>
    </div>
  );
};

App.displayName = 'App';

export default App;
