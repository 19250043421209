import { createAction } from 'redux-actions';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  LicenseServices,
  ILicensesResponse,
  IAddSubUserParams,
  IAddSubUserResponse,
  IEditProfileParams
} from '../services/license.service';
import { ILicense } from '../models/license.model';

export const RETRIEVE_LICENSES__START = 'pdg.license.list.ui.start';
export const retrieveLicensesStart = createAction(RETRIEVE_LICENSES__START);

export const RETRIEVE_LICENSES__FAILURE = 'pdg.license.list.ui.failure';
export const retrieveLicensesFailure = createAction(
  RETRIEVE_LICENSES__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const RETRIEVE_LICENSES__SUCCESS = 'pdg.license.list.ui.success';
export const retrieveLicensesSuccess = createAction(
  RETRIEVE_LICENSES__SUCCESS,
  (licenses: ILicense[]) => {
    return { licenses };
  }
);

export const retrieveLicenses = (
  userId: string
): ThunkAction<Promise<ILicensesResponse>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(retrieveLicensesStart());
      const res = await LicenseServices.retrieveLicenses(userId);
      if (res.status === 1) {
        dispatch(retrieveLicensesSuccess(res.licenses));
      } else {
        throw res;
      }
      return res;
    } catch (err) {
      dispatch(retrieveLicensesFailure(err));
      throw err;
    }
  };
};

export const ADD_SUB_USER__START = 'pdg.add.subUser.ui.start';
export const addSubUserStart = createAction(ADD_SUB_USER__START);

export const ADD_SUB_USER__FAILURE = 'pdg.add.subUser.ui.failure';
export const addSubUserFailure = createAction(
  ADD_SUB_USER__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const ADD_SUB_USER__SUCCESS = 'pdg.add.subUser.ui.success';
export const addSubUserSuccess = createAction(ADD_SUB_USER__SUCCESS);

export const addSubUser = (
  data: IAddSubUserParams
): ThunkAction<Promise<IAddSubUserResponse>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(addSubUserStart());
      const res = await LicenseServices.addSubUser(data);
      if (res.status === 1) {
        dispatch(addSubUserSuccess());
      } else {
        throw res;
      }
      return res;
    } catch (err) {
      dispatch(addSubUserFailure(err));
      throw err;
    }
  };
};

export const EDIT_PROFILE__START = 'pdg.edit.subUser.profile.ui.start';
export const editProfileStart = createAction(EDIT_PROFILE__START);

export const EDIT_PROFILE__FAILURE = 'pdg.edit.subUser.profile.ui.failure';
export const editProfileFailure = createAction(
  EDIT_PROFILE__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const EDIT_PROFILE__SUCCESS = 'pdg.edit.subUser.profile.ui.success';
export const editProfileSuccess = createAction(EDIT_PROFILE__SUCCESS);

export const editProfile = (
  data: IEditProfileParams
): ThunkAction<Promise<IAddSubUserResponse>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(editProfileStart());
      const res = await LicenseServices.editProfile(data);
      if (res && res.status !== 0) {
        dispatch(editProfileSuccess());
        return res;
      }
      throw res;
    } catch (err) {
      dispatch(editProfileFailure(err));
      throw err;
    }
  };
};

export const DELETE_USER__START = 'pdg.user.delete.ui.start';
export const deleteUserStart = createAction(DELETE_USER__START);

export const DELETE_USER__FAILURE = 'pdg.user.delete.ui.failure';
export const deleteUserFailure = createAction(
  DELETE_USER__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const DELETE_USER__SUCCESS = 'pdg.user.delete.ui.success';
export const deleteUserSuccess = createAction(
  DELETE_USER__SUCCESS,
  (userId: string) => {
    return { userId };
  }
);

export const deleteUser = (
  userId: string
): ThunkAction<Promise<IAddSubUserResponse>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(deleteUserStart());
      const res = await LicenseServices.deleteSubUser(userId);
      if (res.status === 1) {
        dispatch(deleteUserSuccess(userId));
      } else {
        throw res;
      }
      return res;
    } catch (err) {
      dispatch(deleteUserFailure(err));
      throw err;
    }
  };
};
