import { createSelector } from 'reselect';
import map from 'lodash/map';
import {
  getWarehouseTruckAllIds,
  getWarehouseTruckById,
  WarehouseTruckById
} from '../reducers/entity';

export const getWarehouseTruckSelector = createSelector(
  getWarehouseTruckAllIds,
  getWarehouseTruckById,
  (warehouseTruckAllIds: string[], warehouseTruckById: WarehouseTruckById) => {
    if (!warehouseTruckAllIds) {
      return [];
    }

    return map(warehouseTruckAllIds, (datum) => {
      return warehouseTruckById[datum];
    });
  }
);
