/* istanbul ignore file */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Box, Alert } from '@chakra-ui/core';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { IAppState } from '../../../store';
import { getMessage, setNotificationCount } from '../../../helper';
import { FullPageLoader } from '../../../common/components/Loader.component';
import { ErrorAlert } from '../../../common/components/ErrorAlert.component';
import { H2 } from '../../../common/components';
import { INotification } from '../../../common/models/notification';
import { retrieveNotification } from '../../../common/actions';
import { STATUS, ERROR_MESSAGE } from '../../../constant';
import { getNotificationListSelector } from '../../../common/reducers/notification.reducer';
import NotificationListItem from '../components/NotificationListItem.component';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  notifications: INotification[];
  t: any;
  retrieveNotification: typeof retrieveNotification;
}

export const NotificationList: FunctionComponent<IProps> = (props: IProps) => {
  const { className, notifications, retrieveNotification } = props;
  const [notificationAPIStatus, setIsAPIStatus] = useState(STATUS.NOT_STARTED);
  const _retrieveNotification = async () => {
    try {
      setIsAPIStatus(STATUS.LOADING);
      await retrieveNotification();
      setIsAPIStatus(STATUS.SUCCESS);
    } catch (err) {
      setIsAPIStatus(STATUS.FAILURE);
      console.log(':: _retrieveNotification err ', err);
    }
  };

  useEffect(() => {
    _retrieveNotification();
  }, []);

  useEffect(() => {
    if (notifications?.length) {
      setNotificationCount(notifications.length);
    }
  }, [notifications?.length]);

  return (
    <Box className={className}>
      <section className="notification-container">
        <Box className="container">
          <H2>Notifications</H2>
          <Box mt="md">
            {notificationAPIStatus === STATUS.LOADING ? (
              <Box p={'lg'}>
                <FullPageLoader />
              </Box>
            ) : null}
            {notificationAPIStatus === STATUS.FAILURE ? (
              <Box>
                <ErrorAlert
                  errorMessage={getMessage(ERROR_MESSAGE.DATA_FAILURE)}
                  retry={_retrieveNotification}
                />
              </Box>
            ) : null}
            {notificationAPIStatus === STATUS.SUCCESS &&
            (!notifications || !notifications.length) ? (
              <Box mt="md">
                <Alert mb="xs" status="warning">
                  {getMessage(ERROR_MESSAGE.NO_MATCHING_RESULT)}
                </Alert>
              </Box>
            ) : null}
            {notificationAPIStatus === STATUS.SUCCESS &&
            notifications &&
            notifications.length
              ? notifications.map((notification: INotification) => (
                  <NotificationListItem
                    {...props}
                    key={notification.id}
                    notification={notification}
                  />
                ))
              : null}
          </Box>
        </Box>
      </section>
    </Box>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: IAppState) => {
  const notifications = getNotificationListSelector(state);
  return {
    notifications
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  return bindActionCreators({ retrieveNotification }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(withToastManager(withRouter(NotificationList))));
