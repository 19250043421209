/* istanbul ignore file */

import React, { FunctionComponent } from 'react';
import { Alert, AlertIcon, AlertTitle, Box } from '@chakra-ui/core';
import { ErrorOutlineOutlined, RefreshOutlined } from '@material-ui/icons';
import { IconButton } from '../../common/components/buttons';

interface IProps {
  errorMessage?: string;
  retry?: () => void;
}

export const ErrorAlert: FunctionComponent<IProps> = ({
  errorMessage,
  retry
}) => {
  return (
    <Alert status="error">
      <Box className="row align-items-center no-gutters" flex="1">
        <Box className="col-auto">
          <AlertIcon as={ErrorOutlineOutlined} />
        </Box>
        <Box className="col">
          <AlertTitle mr={2}>{errorMessage}</AlertTitle>
        </Box>
        <Box className="col-auto">
          <IconButton
            variant="ghost"
            variantColor="dim"
            size="sm"
            aria-label="Retry"
            fontSize="20px"
            icon={RefreshOutlined}
            onClick={retry}
          />
        </Box>
      </Box>
    </Alert>
  );
};
