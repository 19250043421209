/* istanbul ignore file */
import React, { FunctionComponent, useEffect } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Box } from '@chakra-ui/core';
import { withNamespaces } from 'react-i18next';

import Logo from '../../../assets/images/logo.png';
import ComingSoonImage from '../../../assets/images/coming-soon-2.png';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  t: any;
}

export const ComingSoon: FunctionComponent<IProps> = (props: IProps) => {
  const { className, history } = props;

  useEffect(() => {
    window.onpopstate = () => {
      history.go(1);
    };
  }, []);
  return (
    <div className={className}>
      <Box className="comming-soon">
        <Box className="_snInfo-2">
          <Box className="bgimg">
            <Box className="topleft">
              <img src={Logo} alt="logo" />
            </Box>
            <Box className="middle">
              <img src={ComingSoonImage} alt="comming-soon" />
              <hr />
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default withNamespaces()(withToastManager(withRouter(ComingSoon)));
