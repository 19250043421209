import React, { FunctionComponent } from 'react';
import { Box, useDisclosure } from '@chakra-ui/core';

import DangerousGood from '../../assets/images/dangerous_goods.png';
import Stop from '../../assets/images/stop.png';
import { ClassDivisionImage } from './helpers/class_division_image.component';
import { MixedGoodsInfo } from './MixedGoodsInfo.component';

export interface IProps {
  t: any;
  length: number;
  class_division: string;
  isSafe: boolean;
}
export const SignageDisplay: FunctionComponent<IProps> = ({
  t,
  length,
  class_division,
  isSafe
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <div className="row justify-content-center">
      <div className="col-auto signage-text">
        {isSafe ? (
          <span>{t('SIGNAGE_TO_DISPLAY')}</span>
        ) : (
          <div
            className="row contact-supervisor"
            style={{ textAlign: 'center' }}
          >
            <span className="col-12">{t('CONTACT_SUPERVISOR')}</span>
            <span className="col-12 sub-text">
              {t('YOU_CAN_NOT_TAKE_THIS_LOAD_THROUGH_THE_TUNNEL')}
            </span>
          </div>
        )}
      </div>
      <div className="col-auto">
        {length === 1 ? (
          <ClassDivisionImage
            className="signage-display"
            class_division={class_division}
            isSignage={true}
          />
        ) : isSafe ? (
          <Box
            className="class-division-image"
            onClick={() => {
              onOpen();
            }}
            style={{ cursor: 'pointer' }}
          >
            <img
              src={DangerousGood}
              alt="DangerousGood"
              className="signage-display"
            />
          </Box>
        ) : (
          <Box className="class-division-image">
            <img src={Stop} alt="stop" className="signage-display" />
          </Box>
        )}
      </div>
      {isOpen ? (
        <MixedGoodsInfo
          t={t}
          onClose={() => {
            onClose();
          }}
        />
      ) : null}
    </div>
  );
};
