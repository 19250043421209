export interface ISubscription {
  id: number;
  type: SUBSCRIPTION_TYPE;
  license: number;
  deleted: number;
  deleted_at: string;
  status: number;
  created_at: string;
  updated_at: string;
  amount: number;
}

export enum SUBSCRIPTION_TYPE {
  MONTHLY = 'monthly',
  YEARLY = 'yearly'
}

export interface ILicenseData {
  totalLicense: number;
  expiryDate: Date;
  nextBillingDate: Date;
  subscription_status: boolean;
}
