import { Reducer, combineReducers } from 'redux';
import { IRegisterPageState, registerPageReducer } from './ui';
import { IRegisterInfoState, registerInfoReducer } from './entities';

export interface IRegisterPageUI {
  registerPage: IRegisterPageState;
}

export interface IRegisterPageEntities {
  registerInfo: IRegisterInfoState;
}

export interface IRegisterState {
  ui: IRegisterPageUI;
  entities: IRegisterPageEntities;
}

const ui = combineReducers({
  registerPage: registerPageReducer
});

const entities = combineReducers({
  registerInfo: registerInfoReducer
});

export const registerReducer: Reducer<IRegisterState> = combineReducers({
  ui,
  entities
});
