/* istanbul ignore file */

import { Method } from 'axios';
import request from '../../../services/request.service';
import GlobalConfig from '../../../globalConfig';
import { IProduct, IReport, REPORT_TYPE } from '../models/reports-list.model';
import { IUnNumber } from '../models/un-number.model';
import { AuthRepository } from '../../auth/repositories/auth.repository';
import { IClassDivision } from '../models/classDivision.model';

interface IServiceProps {
  method: Method;
  headers: Record<string, any>;
  url: string;
  data?: Record<string, any>;
  responseType?: any;
}

export interface IUnNumberClassDivisionParams {
  un_no: number;
  class_division_value: string;
  tunnel_code: string;
}

interface ICombinationParams {
  user_id: string;
  un_no: IUnNumberClassDivisionParams[];
}

interface IDeleteReportParams {
  user_id: string;
  report_id: string;
}

export interface IReportUnNumberParams {
  un_no?: string;
  class_value: string;
  class_division_value: string;
  name: string;
  tunnel_code: string;
  type: string;
  image: string[];
  packagingType?: string;
  mesurement?: string;
  quantity?: number;
}

export interface IReportClassNameParams {
  class_value: string;
  name: string;
  image: string[];
}

export interface ICreateReportParams {
  user_id?: string;
  report_name: string;
  report_id?: number;
  is_safe: number;
  product: IReportUnNumberParams[];
  goods_staus: string;
  report_type: REPORT_TYPE;
  tunnel_value: string;
  get_approval: number;
}

export interface ICreateReportByClassNameParams {
  user_id?: string;
  report_name: string;
  report_id?: number;
  is_safe: number;
  goods_staus: string;
  product: IReportClassNameParams[];
  report_type: REPORT_TYPE;
}

export interface IResponse {
  status: number;
  message: string;
}

export interface IUnloadResponse extends IResponse {
  current_status: boolean;
}

export interface ICombinationResponse extends IResponse {
  data?: string[];
  safe_status?: boolean;
  tunnel_value?: string;
}

export interface IReportsParams {
  report_name?: string;
  operatorId: string;
}

export interface IReportsResponse extends IResponse {
  data: IReport[];
}

export interface IUnNumbersParams {
  unNumber?: string;
  unName?: string;
}

export interface IUnNumbersResponse extends IResponse {
  data: IUnNumber[];
}

export interface IReportDetailResponse extends IResponse {
  data: IReport | null;
}

export interface IClassDivisionResponse extends IResponse {
  data: IClassDivision[];
}

export enum MANIFEST_APPROVE_STATUS {
  APPROVED = 'approved',
  REJECTED = 'rejected',
  WAITING = 'waiting'
}
export interface IManifestApproveStatusParams {
  report_id: number;
  status: MANIFEST_APPROVE_STATUS;
  reason: string | null;
}

export const retrieveReportsParams = (
  user_id: string,
  data: any
): IServiceProps => ({
  method: 'GET',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(`mobile/getreport/${user_id}`),
  data
});

export const retrieveReportDetailParams = (reportId: string): IServiceProps => ({
  method: 'GET',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(`mobile/getreportdetail/${reportId}`)
});

export const retrieveClassDivisionsParams = (): IServiceProps => ({
  method: 'GET',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(`mobile/getclasses`)
});

export const retrieveUnNumberBySearchParams = (
  unNumber: string
): IServiceProps => ({
  method: 'GET',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(`mobile/unnumberDetail/${unNumber}`)
});

export const retrieveUnNumberBySearchNameParams = (
  unName: string
): IServiceProps => ({
  method: 'GET',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(`mobile/unnameSearch/${unName}`)
});

export const retrieveCombinationEvaluationParams = (
  data: ICombinationParams
): IServiceProps => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(`mobile/combinationEvaluation`),
  data
});

export const deleteReportParams = (
  data: IDeleteReportParams
): IServiceProps => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(`mobile/deletereport`),
  data
});

export const createReportParams = (
  data: ICreateReportParams
): IServiceProps => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(`mobile/savereport`),
  data
});

export const createReportByClassNameParams = (
  data: ICreateReportByClassNameParams
): IServiceProps => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(`mobile/savereportforclass`),
  data
});

export const updateManifestApproveStatusParams = (
  data: IManifestApproveStatusParams
): IServiceProps => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(`mobile/approvemainifest`),
  data
});

export const unloadProductParams = (
  report_id: number,
  product_id: number
): IServiceProps => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(`mobile/unloadproduct`),
  data: {
    report_id,
    product_id
  }
});

export class ReportsServices {
  static async retrieveReports(
    params: IReportsParams
  ): Promise<IReportsResponse> {
    // const user_id = AuthRepository.retrieveUserId() as string;
    // const res = await ReportsMockServices.retrieveReports();
    const res = await request(
      retrieveReportsParams(params.operatorId, { keyword: params.report_name })
    );
    return {
      status: res.data?.status,
      message: res.data?.message,
      data: res.data?.data
        ? res?.data?.data.map((datum: any) => {
            const product: IProduct[] = [];
            datum.product.forEach((datumProduct: IProduct) => {
              if (
                !product.find(
                  (datumProductFind) =>
                    datumProductFind.un_no === datumProduct.un_no &&
                    datumProductFind.class === datumProduct.class
                )
              ) {
                product.push(datumProduct);
              }
            });
            return {
              ...datum,
              product
            };
          })
        : []
    };
  }

  static async retrieveReportDetail(
    reportId: string
  ): Promise<IReportDetailResponse> {
    const res = await request(retrieveReportDetailParams(reportId));
    const product: IProduct[] = [];
    let data: IReport | null = null;
    if (res?.data?.data?.length) {
      Object.keys(res?.data?.data[0].product).forEach(
        (datumProductKey: any) => {
          if (
            !product.find(
              (datumProductFind) =>
                datumProductFind.un_no ===
                  res?.data?.data[0].product[datumProductKey].un_no &&
                datumProductFind.class ===
                  res?.data?.data[0].product[datumProductKey].class
            )
          ) {
            product.push({
              ...res?.data?.data[0].product[datumProductKey],
              packagingType:
                res?.data?.data[0].product[datumProductKey].packagingtype
            });
          }
        }
      );
      data = {
        ...res?.data?.data[0],
        product
      };
    }
    return {
      status: res?.data?.status,
      message: res?.data?.message,
      data
    };
  }

  static async retrieveUnNumbers(
    params: IUnNumbersParams
  ): Promise<IUnNumbersResponse> {
    const res = await request(
      retrieveUnNumberBySearchParams(params.unNumber as string)
    );
    return {
      status: res.data?.status,
      message: res.data?.message,
      data: res.data?.data ? [res.data.data] : []
    };
  }

  static async retrieveUnNumbersWithName(
    params: IUnNumbersParams
  ): Promise<IUnNumbersResponse> {
    const res = await request(
      retrieveUnNumberBySearchNameParams(params.unName as string)
    );
    return {
      status: res.data?.status,
      message: res.data?.message,
      data: res.data?.data ? res.data.data : {}
    };
  }

  static async retrieveCombinationEvaluation(
    params: IUnNumberClassDivisionParams[]
  ): Promise<ICombinationResponse> {
    const user_id = AuthRepository.retrieveUserId();
    const res = await request(
      retrieveCombinationEvaluationParams({
        user_id: user_id as string,
        un_no: params
      })
    );
    return res?.data;
  }

  static async createReport(
    operatorId: string,
    params: ICreateReportParams
  ): Promise<IResponse> {
    const res = await request(
      createReportParams({
        user_id: operatorId,
        ...params
      })
    );
    return res?.data;
  }

  static async createReportByClassName(
    params: ICreateReportByClassNameParams
  ): Promise<IResponse> {
    const user_id = AuthRepository.retrieveUserId();
    const res = await request(
      createReportByClassNameParams({
        user_id: user_id as string,
        ...params
      })
    );
    return res?.data;
  }

  static async deleteReport(report_id: string): Promise<IResponse> {
    const user_id = AuthRepository.retrieveUserId();
    const res = await request(
      deleteReportParams({
        user_id: user_id as string,
        report_id
      })
    );
    return res?.data;
  }

  static async retrieveClassDivisions(): Promise<IClassDivisionResponse> {
    const res = await request(retrieveClassDivisionsParams());
    console.log(':: res ', res);
    return {
      ...res.data,
      data: res.data.data.map((datum: any) => ({
        ...datum,
        description: datum.description ?? ''
      }))
    };
  }

  static async updateManifestApproveStatus(
    params: IManifestApproveStatusParams
  ): Promise<IResponse> {
    const res = await request(updateManifestApproveStatusParams(params));
    return res?.data;
  }

  static async unloadProduct(
    reportId: number,
    productId: number
  ): Promise<IUnloadResponse> {
    const res = await request(unloadProductParams(reportId, productId));
    return res?.data;
  }
}
