/* istanbul ignore file */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { RouteComponentProps } from 'react-router-dom';
import { Box, Alert } from '@chakra-ui/core';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { STATUS, ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../../constant';
import { IAppState } from '../../../store';
import { retrieveLicenses, deleteUser } from '../actions';
import { getLoggedInUserDetails } from '../../auth/selectors/auth.selectors';
import { User } from '../../../common/models/user';
import {
  getLicenseAPIStatus,
  getAddSubUserAPIStatus,
  getEditProfileAPIStatus,
  getDeleteUserAPIStatus
} from '../reducers/ui';
import { FullPageLoader } from '../../../common/components';
import { ErrorAlert } from '../../../common/components/ErrorAlert.component';
import { getMessage } from '../../../helper';
import { getLicensesSelector } from '../selectors/index.page.selector';
import { ILicense } from '../models/license.model';
import LicenseItems from '../components/License-items.component';
import AddSubUserComponent from '../components/AddSubUser.component';
import { retrieveWarehouseTruck } from '../../warehouseTruckRegistration/actions';
import { getWarehouseTruckAPIStatus } from '../../warehouseTruckRegistration/reducers/ui';
import { getWarehouseTruckSelector } from '../../warehouseTruckRegistration/selectors/index.page.selector';
import { IWarehouseTruck } from '../../warehouseTruckRegistration/models/warehouseTruckRegistration.model';
import { ConfirmAlert } from '../../../common/components';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  t: any;
  user: User;
  licenses: ILicense[] | null;
  retrieveLicenseAPIStatus: STATUS;
  addSubUserAPIStatus: STATUS;
  editProfileAPIStatus: STATUS;
  retrieveWarehouseTruckAPIStatus: STATUS;
  deleteUserAPIStatus: STATUS;
  warehouseTrucks: IWarehouseTruck[];
  retrieveLicenses: typeof retrieveLicenses;
  retrieveWarehouseTruck: typeof retrieveWarehouseTruck;
  deleteUser: typeof deleteUser;
}

export const License: FunctionComponent<IProps> = (props: IProps) => {
  const {
    licenses,
    addSubUserAPIStatus,
    user,
    toastManager,
    retrieveLicenses,
    retrieveWarehouseTruck,
    deleteUser,
    retrieveWarehouseTruckAPIStatus,
    retrieveLicenseAPIStatus,
    editProfileAPIStatus,
    deleteUserAPIStatus
  } = props;
  const [userIdForDeleteUser, setUserIdForDeleteUser] = useState<string | null>(
    null
  );
  const _retrieveWarehouseTruck = async () => {
    try {
      const userId: string =
        user.parent_id && user.parent_id !== 0
          ? user.parent_id.toString()
          : user.user_id;
      await retrieveWarehouseTruck(userId);
    } catch (err) {
      console.log(':: _retrieveWarehouseTruck err ', err);
    }
  };
  const _retrieveLicenses = async () => {
    try {
      const userId: string =
        user.parent_id && user.parent_id !== 0
          ? user.parent_id.toString()
          : user.user_id;
      await retrieveLicenses(userId);
    } catch (err) {
      console.log(':: _retrieveLicenses err ', err);
    }
  };

  const _deleteUser = async (userId: string) => {
    try {
      await deleteUser(userId);
      toastManager.add(
        getMessage(SUCCESS_MESSAGE.DELETE_USER),
        {
          appearance: 'success',
          autoDismiss: true
        },
        () => {
          setUserIdForDeleteUser(null);
        }
      );
    } catch (err) {
      console.log(':: _deleteUser err ', err);
      toastManager.add(getMessage(ERROR_MESSAGE.DELETE_USER), {
        appearance: 'error',
        autoDismiss: true
      });
    }
  };

  useEffect(() => {
    _retrieveLicenses();
    _retrieveWarehouseTruck();
  }, []);

  const [license, setLicense] = useState<ILicense | null>(null);
  return (
    <Box mt="2xl">
      <section className="dbHomeInfo">
        <Box className="container">
          {retrieveLicenseAPIStatus === STATUS.LOADING ||
          addSubUserAPIStatus === STATUS.LOADING ||
          editProfileAPIStatus === STATUS.LOADING ||
          retrieveWarehouseTruckAPIStatus === STATUS.LOADING ||
          deleteUserAPIStatus === STATUS.LOADING ? (
            <Box p={'lg'}>
              <FullPageLoader />
            </Box>
          ) : null}
          {retrieveLicenseAPIStatus === STATUS.FAILURE ? (
            <Box>
              <ErrorAlert
                errorMessage={getMessage(ERROR_MESSAGE.LICENSE_FAILURE)}
                retry={_retrieveLicenses}
              />
            </Box>
          ) : null}
          {retrieveLicenseAPIStatus === STATUS.SUCCESS ? (
            <Box className="table-responsive">
              <table
                className="dbTable"
                width="100%"
                cellSpacing="0"
                cellPadding="0"
              >
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Mobile No.</th>
                    <th>Role</th>
                    <th>License Key</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!licenses || licenses.length === 0 ? (
                    <tr>
                      <td colSpan={6}>
                        <Alert status="warning">
                          {getMessage(ERROR_MESSAGE.NO_MATCHING_RESULT)}
                        </Alert>
                      </td>
                    </tr>
                  ) : null}
                  {licenses && licenses.length
                    ? licenses.map((datum: ILicense, index: number) => (
                        <LicenseItems
                          key={datum.licence}
                          srNo={index + 1}
                          license={datum}
                          user={user}
                          onSelect={() => {
                            setLicense(datum);
                          }}
                          deleteUser={(userId: string) => {
                            setUserIdForDeleteUser(userId);
                          }}
                        />
                      ))
                    : null}
                </tbody>
              </table>
            </Box>
          ) : null}
        </Box>
      </section>
      {license ? (
        <AddSubUserComponent
          {...props}
          license={license}
          retrieveLicenses={() => {
            _retrieveLicenses();
          }}
          toggleView={() => {
            setLicense(null);
          }}
        />
      ) : null}
      {userIdForDeleteUser ? (
        <ConfirmAlert
          title="Delete User"
          description="Do you really want to delete this user ?"
          onCancel={() => setUserIdForDeleteUser(null)}
          onConfirm={() => {
            _deleteUser(userIdForDeleteUser);
          }}
          type={'danger'}
          cancelText="Cancel"
          confirmText="Confirm"
        />
      ) : null}
    </Box>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: IAppState) => {
  const loggedInUserDetails = getLoggedInUserDetails(state);
  const retrieveLicenseAPIStatus = getLicenseAPIStatus(state);
  const licenses = getLicensesSelector(state);
  const addSubUserAPIStatus = getAddSubUserAPIStatus(state);
  const editProfileAPIStatus = getEditProfileAPIStatus(state);
  const deleteUserAPIStatus = getDeleteUserAPIStatus(state);
  const retrieveWarehouseTruckAPIStatus = getWarehouseTruckAPIStatus(state);
  const warehouseTrucks = getWarehouseTruckSelector(state);
  return {
    user: loggedInUserDetails.user,
    addSubUserAPIStatus,
    retrieveLicenseAPIStatus,
    editProfileAPIStatus,
    licenses,
    retrieveWarehouseTruckAPIStatus,
    warehouseTrucks,
    deleteUserAPIStatus
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  return bindActionCreators(
    { retrieveLicenses, retrieveWarehouseTruck, deleteUser },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(withToastManager(License)));
