/* istanbul ignore file */

import React, { FunctionComponent, useState, forwardRef } from 'react';
import styled from '@emotion/styled';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { themeGet } from '@styled-system/theme-get';
import classNames from 'classnames';
import { InputProps } from '@chakra-ui/core';
import { TodayOutlined } from '@material-ui/icons';
import gbLocale from 'date-fns/locale/en-GB/index';

import { Input } from './forms';

import 'react-datepicker/src/stylesheets/datepicker.scss';

interface IProps extends ReactDatePickerProps {
  className?: string;
}

const DatePickerInput = forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    return (
      <Input
        placeholder={props.placeholder}
        onClick={props.onClick}
        value={props.value}
        name={props.name}
        type="text"
        onBlur={props.onBlur}
        isReadOnly={true}
        ref={ref}
      />
    );
  }
);

DatePickerInput.displayName = 'DatePickerInput';

const DatePickerComponent: FunctionComponent<IProps> = ({
  className,
  popperClassName,
  selected,
  placeholderText,
  onChange,
  minDate,
  maxDate,
  disabled,
  isClearable,
  ...rest
}: IProps) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const handleCalendarClose = () => {
    setIsCalendarOpen(false);
  };
  const handleCalendarOpen = () => {
    setIsCalendarOpen(true);
  };

  return (
    <div className={className}>
      <ReactDatePicker
        customInput={<DatePickerInput />}
        showPopperArrow={false}
        placeholderText={placeholderText}
        isClearable={isClearable}
        locale={gbLocale}
        dateFormat="dd MMM yyyy"
        selected={selected}
        onChange={onChange}
        popperClassName={popperClassName}
        popperModifiers={{
          offset: {
            enabled: true
          },
          preventOverflow: {
            enabled: true,
            escapeWithReference: false,
            boundariesElement: 'viewport'
          }
        }}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        {...rest}
        calendarClassName="pdg-date-picker-calendar"
        onCalendarOpen={handleCalendarOpen}
        onCalendarClose={handleCalendarClose}
      />
      {!selected || !isClearable ? (
        <span
          className={classNames({
            'react-datepicker__dropdown-icon': true,
            'react-datepicker--is-open__dropdown-icon': isCalendarOpen
          })}
        >
          <TodayOutlined />
        </span>
      ) : null}
    </div>
  );
};

DatePickerComponent.defaultProps = {
  className: '',
  placeholderText: '',
  minDate: null,
  maxDate: new Date(),
  popperClassName: '',
  isClearable: true
};

export const DatePicker = styled(DatePickerComponent)`
  position: relative;

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker {
    font-family: ${themeGet('fonts.body')};
    font-size: 1rem;
    padding: ${themeGet('space.sm')};
    border: 1px solid ${themeGet('colors.gray.200')};
    box-shadow: ${themeGet('shadows.float')};
  }

  .react-datepicker__year-dropdown,
  .react-datepicker__month-dropdown,
  .react-datepicker__month-year-dropdown {
    background-color: ${themeGet('colors.white')};
    border: 1px solid ${themeGet('colors.gray.200')};
    box-shadow: ${themeGet('shadows.float')};
  }

  .react-datepicker__year-option,
  .react-datepicker__month-option,
  .react-datepicker__month-year-option {
    padding: ${themeGet('space.xs')} 0;

    &:hover {
      background-color: ${themeGet('colors.gray.200')};
    }
  }

  .react-datepicker__month-container {
    border: none;

    .react-datepicker__header {
      background-color: ${themeGet('colors.white')};
      border: none;

      .react-datepicker__current-month,
      .react-datepicker-time__header,
      .react-datepicker-year-header {
        margin-bottom: ${themeGet('space.xs')};
        font-family: ${themeGet('fonts.heading')};
      }

      .react-datepicker__header__dropdown {
        margin-bottom: ${themeGet('space.xs')};
      }

      .react-datepicker__day-name,
      .react-datepicker__time-name {
        color: ${themeGet('colors.grey7')};
      }
    }
  }

  .react-datepicker__day-name,
  .react-datepicker__time-name,
  .react-datepicker__day {
    width: 2.29rem;
    line-height: 2.29rem;
    border-radius: ${themeGet('radii.md')};
  }

  .react-datepicker__day--today,
  .react-datepicker__month-text--today,
  .react-datepicker__quarter-text--today {
    background-color: ${themeGet('colors.primary.50')};
    border-radius: ${themeGet('radii.md')};
    font-weight: normal;
  }

  .react-datepicker__day--disabled,
  .react-datepicker__month-text--disabled,
  .react-datepicker__quarter-text--disabled {
    color: ${themeGet('colors.gray.300')};
  }

  .react-datepicker__day--outside-month {
    color: ${themeGet('colors.gray.500')};
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__day--selected {
    background-color: ${themeGet('colors.primary.default')};
  }

  .react-datepicker__navigation--next {
    border-left-color: ${themeGet('colors.primary.default')};
    top: 26px;
    right: 30px;
  }
  .react-datepicker__navigation--previous {
    border-right-color: ${themeGet('colors.primary.default')};
    top: 26px;
    left: 30px;
  }

  .react-datepicker__dropdown-icon {
    position: absolute;
    right: 10px;
    top: 8px;
    display: inline-block;
    pointer-events: none;
    color: ${themeGet('colors.gray.500')};
  }

  .react-datepicker__close-icon {
    height: 93%;

    &:after {
      font-size: ${themeGet('fontSizes.3xl')};
      padding: ${themeGet('space.2xs')};
      height: ${themeGet('fontSizes.2xl')};
      width: ${themeGet('fontSizes.3xl')};
      border-radius: 6px;
      background: transparent;
      color: ${themeGet('colors.gray.500')};
    }
  }

  .react-datepicker__year-read-view,
  .react-datepicker__month-read-view,
  .react-datepicker__month-year-read-view {
    text-align: left;
    padding: ${themeGet('space.2xs')} ${themeGet('space.sm')};
    border-color: ${themeGet('colors.gray.400')};

    &:hover {
      .react-datepicker__year-read-view--down-arrow,
      .react-datepicker__month-read-view--down-arrow {
        border-top-color: ${themeGet('colors.gray.700')};
      }
    }

    &--down-arrow {
      border-top-color: ${themeGet('colors.gray.500')};
      margin-left: ${themeGet('space.xs')};
      top: ${themeGet('space.xs')};
      border-width: ${themeGet('space.2xs')};
    }
  }

  &.has-error,
  &.error,
  &[aria-invalid='true'] {
    input {
      border-color: ${themeGet('colors.errorFormInputBorder')};
    }
  }
`;
