import { Method } from 'axios';
import GlobalConfig from '../../globalConfig';
// eslint-disable-next-line
import request from '../../services/request.service';
import { IProfileEditorFormValues } from '../components/EditProfile_editorForm.component';
import { AuthRepository } from '../../modules/auth/repositories/auth.repository';
import { IChangePasswordFormValues } from '../components/ChangePassword_editorForm.component';
import { INotification } from '../models/notification';
import { User } from '../models/user';

interface IServiceProps {
  method: Method;
  headers: Record<string, any>;
  url: string;
  data?: Record<string, any>;
  responseType?: any;
}

export interface IResponse {
  status: number;
}

export interface ICompanyListResponse extends IResponse {
  company: User[];
}

export interface IOperatorListResponse extends IResponse {
  operators: User[];
}

export interface IUploadQueryParams {
  fileName: string;
  fileUploadType: string;
  uid: string;
  formData: FormData;
}

export interface IBroadcastMessageParams {
  subject: string;
  message: string;
}

export interface IUploadImage {
  fileReferenceId: string;
  fileUploadedAt: number;
  fileName: string;
  fileType: string;
  isUploaded: boolean;
  size: number;
}

interface IEditProfileParams {
  user_id: string;
  name: string;
  phone_no: string;
  country_id: string;
  image?: string;
  truck?: number;
}

export const unloadAllProductsParams = (report_id: number): IServiceProps => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot('mobile/unloadallproducts'),
  data: {
    report_id
  }
});

export const getCompanyListParams = (): IServiceProps => ({
  method: 'GET',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(`mobile/getCompany`)
});

export const getOperatorListParams = (user_id: string): IServiceProps => ({
  method: 'GET',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(`mobile/getCompanyOperator/${user_id}`)
});

export const submitChangePasswordFormParams = (data = {}): IServiceProps => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot('mobile/changePassword'),
  data
});

export const broadcastMessageParams = (data = {}): IServiceProps => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot('mobile/broadcast_notification'),
  data
});

export const submitEditProfileFormParams = (data = {}): IServiceProps => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot('mobile/updateProfile'),
  data
});

export const retrieveNotificationParams = (userId: string): IServiceProps => ({
  method: 'GET',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(`mobile/getnotification/${userId}`)
});

export const retrieveOpertorsListParams = (): IServiceProps => ({
  method: 'GET',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(`mobile/getOperator`)
});

export const uploadImageParams = ({
  fileName,
  fileUploadType,
  uid,
  formData
}: IUploadQueryParams): IServiceProps => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['MULTIPART_FORM_DATA']),
  url: GlobalConfig.getApiUrlFromRoot('/web'),
  data: formData
});

export class CommonService {
  static async retrieveCompanyList(): Promise<ICompanyListResponse> {
    const res = await request(getCompanyListParams());
    return res.data;
  }
  static async retrieveCompanyBasedOperatorsList(
    companyId: string
  ): Promise<IOperatorListResponse> {
    const res = await request(getOperatorListParams(companyId));
    return res.data;
  }
  static async retrieveNotification(): Promise<INotification[]> {
    const userId = AuthRepository.retrieveUserId();
    if (!userId) {
      return [];
    }
    const res = await request(retrieveNotificationParams(userId as string));
    return res?.data?.data ?? [];
  }

  static async retrieveOpertorsList(): Promise<User[]> {
    const res = await request(retrieveOpertorsListParams());
    return res?.data?.operators ?? [];
  }

  static async uploadImage(
    queryParam: IUploadQueryParams
  ): Promise<IUploadImage> {
    const res = await request(uploadImageParams(queryParam));
    return res.data;
  }

  static async changePassword(values: IChangePasswordFormValues) {
    const res = await request(
      submitChangePasswordFormParams({
        current_password: values.currentPassword,
        password: values.newPassword,
        cpassword: values.confirmPassword,
        user_id: AuthRepository.retrieveUserId()
      })
    );
    return res.data;
  }

  static async editProfile(values: IProfileEditorFormValues) {
    const params: IEditProfileParams = {
      user_id: AuthRepository.retrieveUserId() as string,
      name: values.name,
      phone_no: `${values.phone}`,
      country_id: values.code,
      image: values.newImage,
      truck: values.truck
    };
    if (values.newImage) params.image = values.newImage;
    const res = await request(submitEditProfileFormParams(params));
    return res.data;
  }

  static async broadcastMessage(values: IBroadcastMessageParams) {
    const params = {
      user_id: AuthRepository.retrieveUserId() as string,
      subject: values.subject,
      message: values.message
    };
    const res = await request(broadcastMessageParams(params));
    return res.data;
  }

  static async unloadAllProducts(reportId: number) {
    const res = await request(unloadAllProductsParams(reportId));
    return res.data;
  }
}
