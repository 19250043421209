import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { createAction } from 'redux-actions';

import request from '../../../services/request.service';
import * as apiServices from '../services/api.services';
import { User } from '../../../common/models/user';
import { AuthRepository } from '../repositories/auth.repository';
import { AuthService } from '../../../authorization/services/auth.service';
import { IAppState } from '../../../store';

export const LOGIN__SUCCESS = 'pdg.auth.login.success';
export const LOGIN__ALREADY = 'pdg.auth.login.already';
export const LOGIN__ERROR = 'pdg.auth.login.error';
export const LOGOUT_SUCCESS = 'pdg.auth.logout.success';

export const GENERATE_OTP__LOADING = 'pdg.auth.generate.otp.loading';
export const GENERATE_OTP__SUCCESS = 'pdg.auth.generate.otp.success';
export const GENERATE_OTP__ERROR = 'pdg.auth.generate.otp.error';

export const VERIFY_OTP__LOADING = 'pdg.auth.verify.otp.loading';
export const VERIFY_OTP__SUCCESS = 'pdg.auth.verify.otp.success';
export const VERIFY_OTP__ERROR = 'pdg.auth.verify.otp.error';

export interface AlreadyLoggedInUserId {
  userId: string;
}
export const loginSuccess = createAction(
  LOGIN__SUCCESS,
  (token: string, user: User) => {
    return {
      token,
      user
    };
  }
);

export const loginAlready = createAction(LOGIN__ALREADY);

export const loginError = createAction(LOGIN__ERROR, (error: Error) => {
  return error;
});

export const logoutSuccess = createAction(LOGOUT_SUCCESS);

export const loginWithEmailAndPassword = ({ params }: any = {}): ThunkAction<
  Promise<AlreadyLoggedInUserId | User | null | undefined>,
  IAppState,
  undefined,
  AnyAction
> => {
  return async (dispatch: ThunkDispatch<IAppState, undefined, AnyAction>) => {
    try {
      const response = await AuthService.authenticate(params);
      if (response.status === 1 && response.token && response.user) {
        dispatch(loginSuccess(response.token, response.user));
        return response.user;
      }

      if (
        response.status === 2 &&
        response.message === 'User already login on other device'
      ) {
        dispatch(loginAlready());
        return { userId: response.userId };
      }

      return null;
    } catch (error) {
      dispatch(loginError(error));
      throw error;
    }
  };
};

export const logoutUser = (
  params: any
): ThunkAction<Promise<any>, IAppState, undefined, AnyAction> => {
  return async (dispatch: ThunkDispatch<IAppState, undefined, AnyAction>) => {
    await AuthService.logout(params);
    AuthRepository.clear();
    dispatch(logoutSuccess());
    return true;
  };
};

export const logout = (): ThunkAction<
  boolean,
  IAppState,
  undefined,
  AnyAction
> => {
  return (dispatch: ThunkDispatch<IAppState, undefined, AnyAction>) => {
    AuthRepository.clear();
    dispatch(logoutSuccess());
    return true;
  };
};

export const loginWithStoredDetails = (): ThunkAction<
  Promise<boolean>,
  IAppState,
  undefined,
  AnyAction
> => {
  return async (dispatch: ThunkDispatch<IAppState, undefined, AnyAction>) => {
    try {
      const authenticationDetails = await AuthService.authenticateWithStored();
      if (authenticationDetails) {
        dispatch(
          loginSuccess(authenticationDetails.token, authenticationDetails.user)
        );
        return true;
      } else {
        dispatch(logout());
        return false;
      }
    } catch (error) {
      console.log(':: error ', error);
      dispatch(logout());
      throw error;
    }
  };
};

export const createNewPassword = ({
  params,
  requestMethod = request
}: any = {}) => {
  return requestMethod(apiServices.createNewPasswordParams(params));
};

export const checkTokenValidity = ({
  params,
  requestMethod = request
}: any = {}) => {
  return requestMethod(apiServices.checkTokenValidityParams(params));
};

export const sendResetPasswordLink = ({
  params,
  requestMethod = request
}: any = {}) => requestMethod(apiServices.sendResetPasswordLinkParams(params));

export const sendDeviceToken = (
  token: string
): ThunkAction<Promise<any>, IAppState, undefined, AnyAction> => {
  return async (dispatch: ThunkDispatch<IAppState, undefined, AnyAction>) => {
    try {
      return await AuthService.sendDeviceToken(token);
    } catch (error) {
      console.log(':: error ', error);
      throw error;
    }
  };
};

export const generateOTPLoading = createAction(GENERATE_OTP__LOADING);
export const generateOTPSuccess = createAction(GENERATE_OTP__SUCCESS);

export const generateOTPError = createAction(
  GENERATE_OTP__ERROR,
  (error: Error) => {
    return error;
  }
);

export const generateOTP = (): ThunkAction<
  Promise<boolean>,
  IAppState,
  undefined,
  AnyAction
> => {
  return async (dispatch: ThunkDispatch<IAppState, undefined, AnyAction>) => {
    try {
      dispatch(generateOTPLoading());
      const res = await AuthService.generateOTP();
      if (res.status === 1) {
        dispatch(generateOTPSuccess());
        return true;
      } else {
        dispatch(generateOTPError(Error('OTP not generated')));
        return false;
      }
    } catch (error) {
      dispatch(generateOTPError(error));
      throw error;
    }
  };
};

export const verifyOTPLoading = createAction(VERIFY_OTP__LOADING);
export const verifyOTPSuccess = createAction(VERIFY_OTP__SUCCESS);

export const verifyOTPError = createAction(
  VERIFY_OTP__ERROR,
  (error: Error) => {
    return error;
  }
);

export const verifyOTP = (
  params: any = {}
): ThunkAction<Promise<User>, IAppState, undefined, AnyAction> => {
  return async (dispatch: ThunkDispatch<IAppState, undefined, AnyAction>) => {
    try {
      dispatch(verifyOTPLoading());
      const response = await AuthService.verifyOTP(params);
      dispatch(loginSuccess(response.token, response.user));
      dispatch(verifyOTPSuccess());
      return response.user;
    } catch (error) {
      dispatch(loginError(error));
      dispatch(verifyOTPError(error));
      throw error;
    }
  };
};
