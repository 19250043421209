/* istanbul ignore file */
import React, { FunctionComponent } from 'react';
import EditIcon from '@material-ui/icons/Edit';

import { IWarehouseTruck } from '../models/warehouseTruckRegistration.model';
import { dateAndTimeFormat } from '../../../helper';

export interface IProps {
  warehouseTruck: IWarehouseTruck;
  srNo: number;
  onSelect: () => void;
}

export const WarehouseTruckItems: FunctionComponent<IProps> = (
  props: IProps
) => {
  const { warehouseTruck, srNo, onSelect } = props;
  return (
    <tr>
      <td>{srNo}</td>
      <td>{warehouseTruck.name ? warehouseTruck.name : 'N/A'}</td>
      <td>
        {warehouseTruck.type
          ? warehouseTruck.type === 1
            ? 'Truck'
            : 'Warehouse'
          : 'N/A'}
      </td>
      <td>
        {warehouseTruck.created_at
          ? `${dateAndTimeFormat(new Date(warehouseTruck.created_at))}`
          : 'N/A'}
      </td>
      <td>
        <a
          href="#"
          style={{ color: '#000' }}
          onClick={() => {
            onSelect();
          }}
          title={`Edit ${
            warehouseTruck.type
              ? warehouseTruck.type === 1
                ? 'Truck'
                : 'Warehouse'
              : 'N/A'
          }`}
        >
          <EditIcon />
        </a>
      </td>
    </tr>
  );
};

export default WarehouseTruckItems;
