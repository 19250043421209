/* istanbul ignore file */
import React, { FunctionComponent, useEffect } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Box, Alert } from '@chakra-ui/core';
import { withNamespaces } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import isUndefined from 'lodash/isUndefined';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import queryString from 'query-string';
import { Location } from 'history';

import { STATUS, ERROR_MESSAGE } from '../../../constant';
import { IAppState } from '../../../store';
import { FullPageLoader } from '../../../common/components';
import { ErrorAlert } from '../../../common/components/ErrorAlert.component';
import {
  getMessage,
  getQueryParamValue,
  isAdmin,
  updateQueryString
} from '../../../helper';
import { getLogsAPIStatus, getLogTotalCount } from '../reducers/ui';
import { retrieveLogs, resetData } from '../actions';
import {
  retrieveOpertorsList,
  retrieveCompanyList,
  retrieveCompanyBasedOperatorsList
} from '../../../common/actions';
import { getOperatorListSelector } from '../../../common/reducers/operator.reducer';
import { User, USER_ROLE } from '../../../common/models/user';
import { getLoggedInUserDetails } from '../../auth/selectors/auth.selectors';
import { getLogsListSelector } from '../selectors';
import { ILog } from '../models/manifest_log';
import LogsListItem from '../components/LogsList.component';
import {
  getCompanyBasedOperatorListSelector,
  getCompanyListSelector
} from '../../../common/reducers/company_and_operator.reducer';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  t: any;
  totalCount: number;
  operatorList: User[];
  companyBasedOperators: User[];
  companies: User[];
  logsList: ILog[];
  user: User;
  logsAPIStatus: STATUS;
  retrieveLogs: typeof retrieveLogs;
  resetData: typeof resetData;
  retrieveOpertorsList: typeof retrieveOpertorsList;
  retrieveCompanyList: typeof retrieveCompanyList;
  retrieveCompanyBasedOperatorsList: typeof retrieveCompanyBasedOperatorsList;
}

export interface IQueryString {
  operatorId?: string;
  companyId?: string;
  page: number;
}

export const parseQueryString = (location: Location): IQueryString => {
  const search = location?.search;

  const queryParams = queryString.parse(search);

  const operatorId = getQueryParamValue(queryParams, 'operatorId');
  const companyId = getQueryParamValue(queryParams, 'companyId');
  const pageInParams = getQueryParamValue(queryParams, 'page');
  const page = !isUndefined(pageInParams) ? Number(pageInParams) : 1;

  return {
    page,
    operatorId,
    companyId
  };
};

export const ManifestHistory: FunctionComponent<IProps> = (props: IProps) => {
  const {
    logsAPIStatus,
    user,
    operatorList,
    history,
    location,
    logsList,
    totalCount,
    companyBasedOperators,
    companies,
    retrieveOpertorsList,
    retrieveLogs,
    retrieveCompanyBasedOperatorsList,
    retrieveCompanyList,
    resetData
  } = props;

  const {
    operatorId: operator_id,
    page: currentPage,
    companyId: company_id
  } = useSelector(() => parseQueryString(location));

  const setSelectedOperatorId = (operatorId: string | undefined) => {
    updateQueryString(history, {
      operatorId,
      companyId: company_id,
      page: 1
    });
  };

  const setSelectedCompanyId = (companyId: string | undefined) => {
    updateQueryString(history, {
      companyId,
      operatorId: undefined,
      page: 1
    });
  };

  const setPage = (page: number) => {
    updateQueryString(history, {
      page,
      operatorId: operator_id,
      companyId: company_id
    });
  };

  const _retrieveCompanyList = async () => {
    try {
      await retrieveCompanyList();
    } catch (err) {
      console.log(':: _retrieveCompanyList err ', err);
    }
  };

  const _retrieveCompanyBasedOperatorsList = async () => {
    try {
      if (company_id) {
        await retrieveCompanyBasedOperatorsList(company_id);
      }
    } catch (err) {
      console.log(':: _retrieveCompanyBasedOperatorsList err ', err);
    }
  };

  const _retrieveOpertorsList = async () => {
    try {
      await retrieveOpertorsList();
    } catch (err) {
      console.log(':: _retrieveOpertorsList err ', err);
    }
  };

  const _resetData = async () => {
    await resetData();
  };

  useEffect(() => {
    if (user?.role === USER_ROLE.ADMIN) {
      _retrieveCompanyList();
    }
  }, [user?.role]);

  useEffect(() => {
    _resetData();
    if (user?.role === USER_ROLE.ADMIN && company_id) {
      _retrieveCompanyBasedOperatorsList();
    } else {
      if (
        user.role &&
        user.role !== USER_ROLE.OPERATOR &&
        user.role !== USER_ROLE.ADMIN
      ) {
        _retrieveOpertorsList();
      }
    }
  }, [user?.role, company_id, operator_id]);

  useEffect(() => {
    if (
      !operator_id &&
      user.role &&
      user.role === USER_ROLE.OPERATOR &&
      operator_id !== user.user_id
    ) {
      setSelectedOperatorId(user.user_id);
    }
  }, [user.user_id, operator_id]);

  const _retrieveLogs = async () => {
    try {
      if (operator_id) {
        await retrieveLogs({ operator_id, page: currentPage });
      }
    } catch (err) {
      console.log(':: _retrieveLogs ', err);
    }
  };

  useEffect(() => {
    _retrieveLogs();
  }, [operator_id, currentPage]);

  return (
    <section className="dbPrWid">
      {logsAPIStatus === STATUS.LOADING ? (
        <Box p={'lg'}>
          <FullPageLoader />
        </Box>
      ) : null}
      <Box className="row justify-content-end">
        <Box className="col-auto">
          <Box className="row justify-content-end" mb="xl">
            {user.role === USER_ROLE.ADMIN ? (
              <>
                <Box className="col-auto">
                  <select
                    className="form-control"
                    name="company"
                    value={company_id ?? ''}
                    onChange={(event) => {
                      setSelectedCompanyId(event.target.value ?? undefined);
                    }}
                  >
                    <option value="">Select Company</option>
                    {companies.map((datum) => (
                      <option key={datum.user_id} value={datum.user_id}>
                        {datum.name}
                      </option>
                    ))}
                  </select>
                </Box>
                <Box className="col-auto">
                  <select
                    className="form-control"
                    name="operator"
                    disabled={!company_id}
                    value={operator_id ?? ''}
                    onChange={(event) => {
                      setSelectedOperatorId(event.target.value ?? undefined);
                    }}
                  >
                    <option value="">Select Operator</option>
                    {companyBasedOperators.map((datum) => (
                      <option key={datum.user_id} value={datum.user_id}>
                        {datum.name}
                      </option>
                    ))}
                  </select>
                </Box>
              </>
            ) : user.role !== USER_ROLE.OPERATOR ? (
              <Box className="col-auto">
                <select
                  className="form-control"
                  name="operator"
                  value={operator_id ?? ''}
                  onChange={(event) => {
                    setSelectedOperatorId(event.target.value ?? undefined);
                  }}
                >
                  <option value="">Select Operator</option>
                  {isAdmin(user.role) ? (
                    <option key={user.user_id} value={user.user_id}>
                      {user.name} (Self)
                    </option>
                  ) : null}
                  {operatorList.map((datum) => (
                    <option key={datum.user_id} value={datum.user_id}>
                      {datum.name}
                    </option>
                  ))}
                </select>
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
      <Box className="container">
        {logsAPIStatus === STATUS.FAILURE ? (
          <Box>
            <ErrorAlert
              errorMessage={getMessage(ERROR_MESSAGE.LOGS_LIST)}
              retry={_retrieveLogs}
            />
          </Box>
        ) : null}
        {logsAPIStatus === STATUS.SUCCESS &&
        !logsList.length &&
        (company_id || user.role !== USER_ROLE.ADMIN) &&
        operator_id ? (
          <Box mt="md">
            <Alert mb="xs" status="warning">
              {getMessage(ERROR_MESSAGE.LOGS_LIST_EMPTY)}
            </Alert>
          </Box>
        ) : null}

        {!company_id && !operator_id && user.role === USER_ROLE.ADMIN ? (
          <Alert status="warning">
            {getMessage(ERROR_MESSAGE.NO_COMPANY_FOUND_MANIFEST_HISTORY)}
          </Alert>
        ) : null}

        {(company_id || user.role !== USER_ROLE.ADMIN) && !operator_id ? (
          <Alert status="warning">
            {getMessage(ERROR_MESSAGE.NO_OPERATOR_FOUND_MANIFEST_HISTORY)}
          </Alert>
        ) : null}

        {logsAPIStatus === STATUS.SUCCESS && logsList.length ? (
          <LogsListItem
            totalCount={totalCount}
            logsList={logsList}
            currentPage={currentPage}
            handlePageChange={(page: number) => {
              setPage(page);
            }}
          />
        ) : null}
      </Box>
    </section>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: IAppState) => {
  const logsAPIStatus = getLogsAPIStatus(state);
  const logsList = getLogsListSelector(state);
  const totalCount = getLogTotalCount(state);
  const operatorList = getOperatorListSelector(state);
  const loggedInUserDetails = getLoggedInUserDetails(state);
  const companyBasedOperators = getCompanyBasedOperatorListSelector(state);
  const companies = getCompanyListSelector(state);

  return {
    user: loggedInUserDetails.user,
    logsList,
    operatorList,
    logsAPIStatus,
    totalCount,
    companies,
    companyBasedOperators
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  return bindActionCreators(
    {
      retrieveLogs,
      retrieveOpertorsList,
      retrieveCompanyBasedOperatorsList,
      retrieveCompanyList,
      resetData
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(withToastManager(withRouter(ManifestHistory))));
