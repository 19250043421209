import React, { FunctionComponent } from 'react';
import { Box } from '@chakra-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export interface IProps {
  t: any;
  onClose: () => void;
}
export const SpillInfo: FunctionComponent<IProps> = ({ onClose, t }) => {
  return (
    <div className="_cstModal">
      <div className="_mdBody">
        <Box className="close-icon">
          <span
            onClick={() => {
              onClose();
            }}
          >
            <CloseIcon />
          </span>
        </Box>
        <p className="spill-info-header">{t('EMERGENCY_PROCEDURES')}</p>
        <Box className="spill-info">
          <Box mb="md">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="spill-info-accordion"
              >
                <p>{t('DISCLAIMER')}</p>
              </AccordionSummary>
              <AccordionDetails>
                <Box style={{ width: '100%' }}>
                  <Box className="guide justify-content-between">
                    <p className="col-auto">{t('GUIDE')}</p>
                  </Box>
                  <Box className="spill-info-content">
                    <p>{t('THE_FOLLOWING_INFORMATION')}</p>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>

          <Box mb="md">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="spill-info-accordion"
              >
                <p>{t('HAZARDS_FIRE')}</p>
              </AccordionSummary>
              <AccordionDetails>
                <Box style={{ width: '100%' }}>
                  <Box className="guide justify-content-between">
                    <p className="col-auto">{t('GUIDE')}</p>
                  </Box>
                  <Box className="spill-info-content">
                    <p>{t('WILL_ACCELERATE_BURNING')}</p>
                    <p>{t('MAY_EXPLODE_FROM_HEATING')}</p>
                    <p>{t('MAY_IGNITE_COMBUSTIBLES')}</p>
                    <p>{t('FIRE_MAY_PRODUCE_IRRITATING')}</p>
                    <p>{t('RUNOFF_MAY_PRODUCE_EXPLOSION')}</p>
                    <p>{t('MAY_DECOMPOSE_EXPLOSIVELY')}</p>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>

          <Box mb="md">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="spill-info-accordion"
              >
                <p>{t('HAZARDS_HEALTH_AND_ENVIRONMENT')}</p>
              </AccordionSummary>
              <AccordionDetails>
                <Box style={{ width: '100%' }}>
                  <Box className="guide justify-content-between">
                    <p className="col-auto">{t('GUIDE')}</p>
                  </Box>
                  <Box className="spill-info-content">
                    <p>{t('INHALATION_OR_CANTACT_WITH_VAPOUR')}</p>
                    <p>{t('RUNOFF_FROM_FIRE')}</p>
                    <p>{t('MIXTURES_WITH_COMBUSTIBLE')}</p>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>

          <Box mb="md">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="spill-info-accordion"
              >
                <p>{t('PPE_EMERGENCY_RESPONDERS')}</p>
              </AccordionSummary>
              <AccordionDetails>
                <Box style={{ width: '100%' }}>
                  <Box className="guide justify-content-between">
                    <p className="col-auto">{t('GUIDE')}</p>
                  </Box>
                  <Box className="spill-info-content">
                    <p>{t('WEAR_SCBA_AND_CHEMICAL_SPLASH_SUIT')}</p>
                    <p>{t('STRUCTURAL_FIREFIGHTER')}</p>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>

          <Box mb="md">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="spill-info-accordion"
              >
                <p>{t('FIRST_AID_GENERAL')}</p>
              </AccordionSummary>
              <AccordionDetails>
                <Box style={{ width: '100%' }}>
                  <Box className="guide justify-content-between">
                    <p className="col-auto">{t('GUIDE')}</p>
                  </Box>
                  <Box className="spill-info-content">
                    <p>{t('KEEP_VICTIM_WARM_AND_QUIET')}</p>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>

          <Box mb="md">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="spill-info-accordion"
              >
                <p>{t('FIRST_AID_INHALED_OR_INGESTED')}</p>
              </AccordionSummary>
              <AccordionDetails>
                <Box style={{ width: '100%' }}>
                  <Box className="guide justify-content-between">
                    <p className="col-auto">{t('GUIDE')}</p>
                  </Box>
                  <Box className="spill-info-content">
                    <p>{t('REMOVE_VICTIM_TO_FRESH_AIR')}</p>
                    <p>{t('APPLY_RESCUSCITATION')}</p>
                    <p>{t('INGESTED_CELL_POISONS')}</p>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>

          <Box mb="md">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                className="spill-info-accordion"
              >
                <p>{t('FIRST_AID_EYE_OR_SKIN')}</p>
              </AccordionSummary>
              <AccordionDetails>
                <Box style={{ width: '100%' }}>
                  <Box className="guide justify-content-between">
                    <p className="col-auto">{t('GUIDE')}</p>
                  </Box>
                  <Box className="spill-info-content">
                    <p>{t('EYES_IMMEDIATELY_HOLD_EYES')}</p>
                    <p>{t('SKIN_REMOVE_CONTAMINATED')}</p>
                    <p>{t('IN_CASE_OF_CONTACT_WITH_MATERIAL')}</p>
                    <p>{t('BURNS_IMMERSE_AFFECTED_AREA')}</p>
                    <p>{t('TREAT_FOR_SHOCK')}</p>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </div>
    </div>
  );
};
