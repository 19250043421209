import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Formik, FormikHelpers as FormikActions, FormikProps } from 'formik';
import { RouteComponentProps } from 'react-router-dom';
import { Box } from '@chakra-ui/core';

import { getMessage } from '../../helper';
import { ERROR_MESSAGE, SUCCESS_MESSAGE, STATUS } from '../../constant';
import {
  BroadcastMessageForm,
  IMessageFormValues
} from './BroadcastMessage-form.component';
import { broadcastMessage } from '../actions';
import { IAppState } from '../../store';
import { getBroadcastMessageAPIStatus } from '../reducers/broadcastMessage.reducer';
import { FullPageLoader } from './Loader.component';

export interface IProps extends RouteComponentProps {
  className?: string;
  toggleView: () => void;
  t: any;
  broadcastMessageAPIStatus: STATUS;
  broadcastMessage: typeof broadcastMessage;
  toastManager: any;
}

export class BroadcastMessageComponent extends PureComponent<IProps> {
  static defaultProps: Partial<IProps> = {
    className: ''
  };

  async onFormSubmit(
    values: IMessageFormValues,
    actions: FormikActions<IMessageFormValues>
  ) {
    const { subject, message } = values;
    const { broadcastMessage, toastManager, toggleView } = this.props;
    const params = {
      subject,
      message
    };
    actions.setSubmitting(true);
    try {
      await broadcastMessage(params);
      toggleView();
      actions.setSubmitting(false);
      toastManager.add(getMessage(SUCCESS_MESSAGE.BROADCAST_MESSAGE), {
        appearance: 'success',
        autoDismiss: true
      });
    } catch (err) {
      actions.setSubmitting(false);
      const message =
        err?.errorObj?.message ??
        err?.message ??
        getMessage(ERROR_MESSAGE.BROADCAST_MESSAGE);
      toastManager.add(message, { appearance: 'error', autoDismiss: true });
    }
  }

  validateForm(values: IMessageFormValues) {
    const errors: any = {};
    if (!values.subject) {
      errors.subject = getMessage(ERROR_MESSAGE.required);
    }

    if (!values.message) {
      errors.message = getMessage(ERROR_MESSAGE.required);
    }

    return errors;
  }

  render() {
    const { className, toggleView, t, broadcastMessageAPIStatus } = this.props;

    return (
      <div className={className}>
        {broadcastMessageAPIStatus === STATUS.LOADING ? (
          <Box p={'lg'}>
            <FullPageLoader />
          </Box>
        ) : null}
        <div className="_cstModal">
          <div className="_mdBody">
            <div className="">{t('BROADCAST_MESSAGE')}</div>
            <div className="mt-5">
              <Formik
                validate={this.validateForm.bind(this)}
                initialValues={{
                  subject: '',
                  message: ''
                }}
                validateOnMount
                onSubmit={(
                  values: IMessageFormValues,
                  actions: FormikActions<IMessageFormValues>
                ) => {
                  this.onFormSubmit(values, actions);
                }}
                component={(formikProps: FormikProps<IMessageFormValues>) => {
                  return (
                    <BroadcastMessageForm
                      {...formikProps}
                      {...this.props}
                      {...this.state}
                    />
                  );
                }}
              />
              <div
                className="_close"
                onClick={() => {
                  toggleView();
                }}
              >
                x {t('CLOSE')}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IAppState) => {
  const broadcastMessageAPIStatus = getBroadcastMessageAPIStatus(state);

  return {
    broadcastMessageAPIStatus
  };
};

/* istanbul ignore next */
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ broadcastMessage }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BroadcastMessageComponent);
