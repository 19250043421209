import { Reducer, combineReducers } from 'redux';
import { IForgotPasswordPageState, forgotPasswordPageReducer } from './ui';

export interface IForgotPasswordPageUI {
  forgotPasswordPage: IForgotPasswordPageState;
}

export interface IForgotPasswordState {
  ui: IForgotPasswordPageUI;
}

const ui = combineReducers({
  forgotPasswordPage: forgotPasswordPageReducer
});

export const forgotPasswordReducer: Reducer<IForgotPasswordState> = combineReducers(
  { ui }
);
