import { IReport } from '../../../modules/reports/models/reports-list.model';

export const getHtmlForEmail = (report: IReport) => {
  let classHtml = '';
  report.product.forEach((datum, index) => {
    classHtml += `%0D%0A[${index + 1}] Class: ${datum.class} ${datum.un_no ? `(UN Code: ${datum.un_no})` : datum.name_description ? datum.name_description : ''}%0D%0APackaging: ${datum.packagingType}%0D%0AMeasurement: ${datum.mesurement?.toUpperCase()}%0D%0AQuantity: ${datum.quantity}`;
  });
  return `The following items were entered:%0D%0A${classHtml}%0D%0A%0D%0A%0D%0AThe following items were reported as ${report.goods_staus !== 'dangerous' ? 'SAFE' : 'UNSAFE'}%0D%0A%0D%0A${report.tunnel_value ? `Tunnel Info: ${report.tunnel_value}`: report.goods_staus !== 'dangerous'? `Tunnel Info: Passage forbidden through B, C, D and E`: ''}%0D%0A%0D%0A%0D%0APlease refer to the Dangerous Goods Code for clarification.%0D%0Ahttps://www.ntc.gov.au/heavy-vehicles/safety/australian-dangerous-goods-code/%0D%0A%0D%0APackaged Dangerous of Dangerous Goods (PDG) App.
  `;
};
