import axios, { AxiosRequestConfig } from 'axios';
import HttpError from 'standard-http-error';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import { AuthRepository } from '../modules/auth/repositories/auth.repository';

import GlobalConfig from '../globalConfig';
import omit from 'lodash/omit';

export const throwHttpError = (errorResponse: any) => {
  let response = get(errorResponse, 'response', {
    status: 200,
    statusText: 'Error in Success HTTP Response',
    data: {}
  });

  if (isArray(errorResponse)) {
    response = {
      data: errorResponse[0],
      status: parseInt(errorResponse[0]?.errorCode),
      statusText: errorResponse[0]?.message
    };
  }
  const { data } = response;

  const httpError = new HttpError(response.status, response.statusText, {
    errorObj: data
  });

  throw httpError;
};

export const successResponse = (response: any) => {
  if (response.data instanceof ArrayBuffer || response.data instanceof Blob) {
    return response;
  }

  if (response.data.errors && !response.data.status) {
    try {
      if (response.data.errors?.length) {
        forEach(response.data.errors, (error) => {
          const originalError = error.originalError;
          const errorExtensions = originalError?.extensions;

          let errorMessage = error.message;

          if (errorExtensions.response) {
            errorMessage =
              errorExtensions.response?.body?.message ?? errorMessage;
          } else if (errorExtensions.exception) {
            errorMessage = errorExtensions.exception?.message ?? errorMessage;
          }
          throw errorMessage;
        });
      }
    } catch (error) {}
    throwHttpError(response.data.errors);
  }

  return {
    message: get(response, 'data.message'),
    data: get(response, 'data')
  };
};

export const prepareRequestHeaders = (headers: any = {}) => {
  const modifiedHeaders = { ...headers };

  const token = AuthRepository.retrieveToken();
  const userId = AuthRepository.retrieveUserId();
  modifiedHeaders.language = 'en'; //language;

  if (userId) {
    modifiedHeaders.userId = userId;
  }

  if (token) {
    modifiedHeaders.authorization = `Bearer ${token}`;
  }

  // Back-end needs this header to be supplied with each request

  return modifiedHeaders;
};

export const prepareRESTRequestConfiguration = (
  request: any
): AxiosRequestConfig => {
  const { method, responseType = 'json', data, headers, url } = request;

  let requestHeaders = prepareRequestHeaders(headers);

  if (url && url.includes('login')) {
    requestHeaders = omit(requestHeaders, 'language');
  }

  let requestConfig: AxiosRequestConfig = {
    method,
    url,
    responseType,
    headers: requestHeaders
  };

  if (method === 'GET' && !isEmpty(data)) {
    requestConfig = {
      ...requestConfig,
      params: data
    };
  }

  if (method !== 'GET' && (data instanceof FormData || !isEmpty(data))) {
    requestConfig = {
      ...requestConfig,
      data
    };
  }

  return requestConfig;
};

export default async (request: any, httpService = axios) => {
  const requestConfig = prepareRESTRequestConfiguration(request);

  return httpService.request(requestConfig).then(successResponse, (error) => {
    throwHttpError(error);
  });
};

export const graphQlRequest = async (request: any, httpService = axios) => {
  const { responseType = 'json', headers = {} } = request;
  const { query, variables } = request;

  const requestHeaders = prepareRequestHeaders(headers);

  return httpService
    .request({
      url: GlobalConfig.getApiUrlFromRoot('/web/bff/graphql'),
      method: 'POST',
      responseType,
      headers: requestHeaders,
      data: {
        query,
        ...(variables && { variables })
      }
    })
    .then(successResponse, (error) => {
      throwHttpError(error);
    });
};
