import { createAction } from 'redux-actions';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { DashboardServices } from '../services/dashboard.service';
import { IDashboardStats } from '../model/dashboard.model';

export const RETRIEVE_DASHBOARD_STATS__START = 'pdg.dashboard.stats.ui.start';
export const retrieveDashboardStatsStart = createAction(
  RETRIEVE_DASHBOARD_STATS__START
);

export const RETRIEVE_DASHBOARD_STATS__FAILURE =
  'pdg.dashboard.stats.ui.failure';
export const retrieveDashboardStatsFailure = createAction(
  RETRIEVE_DASHBOARD_STATS__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const RETRIEVE_DASHBOARD_STATS__SUCCESS =
  'pdg.dashboard.stats.ui.success';
export const retrieveDashboardStatsSuccess = createAction(
  RETRIEVE_DASHBOARD_STATS__SUCCESS,
  (dashboardStats: IDashboardStats) => {
    return { dashboardStats };
  }
);

export const retrieveDashboardStats = (
  useId: string
): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(retrieveDashboardStatsStart());
      const res = await DashboardServices.retrieveDashboardStats(useId);
      if (res && res.status !== 0) {
        const dashboardStats: IDashboardStats = {
          total: res.total,
          used: res.used,
          available: res.available
        };
        dispatch(retrieveDashboardStatsSuccess(dashboardStats));
        return res;
      }
      throw res;
    } catch (err) {
      dispatch(retrieveDashboardStatsFailure(err));
      throw err;
    }
  };
};
