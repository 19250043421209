const USER_ID_STORE_KEY = 'auth.userId';
const TOKEN_STORE_KEY = 'auth.token';
const DEVICE_TOKEN_STORE_KEY = 'auth.deviceToken';
const LANG_STORE_KEY = 'lang';

export class AuthRepository {
  static storeUserId(userId: string) {
    localStorage.setItem(USER_ID_STORE_KEY, userId);
  }

  static retrieveUserId(): string | null {
    const data = localStorage.getItem(USER_ID_STORE_KEY);
    if (!data) {
      return null;
    }
    return data;
  }

  static retrieveLanguage(): string {
    const data = localStorage.getItem(LANG_STORE_KEY);
    if (!data) {
      return 'en';
    }
    return data;
  }

  static clearUserId() {
    localStorage.removeItem(USER_ID_STORE_KEY);
  }

  static storeToken(token: string): void {
    localStorage.setItem(TOKEN_STORE_KEY, token);
  }

  static retrieveToken(): string | null {
    const token = localStorage.getItem(TOKEN_STORE_KEY);
    if (!token) {
      return null;
    }
    return token;
  }

  static clearToken() {
    localStorage.removeItem(TOKEN_STORE_KEY);
  }

  static storeDeviceToken(token: string): void {
    localStorage.setItem(DEVICE_TOKEN_STORE_KEY, token);
  }

  static retrieveDeviceToken(): string | null {
    const token = localStorage.getItem(DEVICE_TOKEN_STORE_KEY);
    if (!token) {
      return null;
    }
    return token;
  }

  static clearDeviceToken() {
    localStorage.removeItem(DEVICE_TOKEN_STORE_KEY);
  }

  static clear() {
    AuthRepository.clearToken();
    AuthRepository.clearUserId();
    AuthRepository.clearDeviceToken();
  }
}
