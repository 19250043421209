import { createAction } from 'redux-actions';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { SupportAndFeedbackServices } from '../services/api.service';
import { IFormValues } from '../components/supportAndFeedbackForm.component';

export const SUBMIT_SUPPORT_AND_FEEDBACK_FORM__START =
  'pdg.supporAndFeedback.submit.form.start';
export const submitSupportAndFeedbackFormStart = createAction(
  SUBMIT_SUPPORT_AND_FEEDBACK_FORM__START
);

export const SUBMIT_SUPPORT_AND_FEEDBACK_FORM__FAILURE =
  'pdg.supporAndFeedback.submit.form.failure';
export const submitSupportAndFeedbackFormFailure = createAction(
  SUBMIT_SUPPORT_AND_FEEDBACK_FORM__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const SUBMIT_SUPPORT_AND_FEEDBACK_FORM__SUCCESS =
  'pdg.supporAndFeedback.submit.form.success';
export const submitSupportAndFeedbackFormSuccess = createAction(
  SUBMIT_SUPPORT_AND_FEEDBACK_FORM__SUCCESS
);

export const submitSupportAndFeedbackForm = (
  values: IFormValues
): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(submitSupportAndFeedbackFormStart());
      const termsAndConditions = await SupportAndFeedbackServices.submitSupportAndFeedbackForm(
        values
      );
      dispatch(submitSupportAndFeedbackFormSuccess());
      return termsAndConditions;
    } catch (err) {
      dispatch(submitSupportAndFeedbackFormFailure(err));
      throw err;
    }
  };
};
