import React, { FunctionComponent } from 'react';
import { Box, Button } from '@chakra-ui/core';
import { saveAs } from 'file-saver';
import { themeGet } from '@styled-system/theme-get';
import GetAppIcon from '@material-ui/icons/GetApp';
import styled from '@emotion/styled';
import { Carousel as ImageCarousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

export interface IProps {
  className?: string;
  images: string[];
}

const CarouselComponent: FunctionComponent<IProps> = ({
  className,
  images
}) => {
  const _renderArrowNext = (clickFn: any, hasNext: boolean) => {
    return (
      <Button
        isDisabled={!hasNext}
        onClick={clickFn}
        className={`button__style arrow__style next`}
      >
        <Box as="span" color={'gray.600'} display={'flex'}>
          <ArrowForwardIosIcon color="inherit" fontSize={'small'} />
        </Box>
      </Button>
    );
  };

  const downloadImage = (fileUrl: string) => {
    const fileExt = fileUrl.split('.').pop();
    saveAs(fileUrl, `image.${fileExt}`);
  };

  const _renderArrowPrev = (clickFn: any, hasPrev: boolean) => {
    return (
      <Button
        isDisabled={!hasPrev}
        onClick={clickFn}
        className={`button__style arrow__style prev`}
      >
        <Box as="span" color={'gray.600'} display={'flex'}>
          <ArrowBackIosIcon color="inherit" fontSize={'small'} />
        </Box>
      </Button>
    );
  };

  return (
    <Box className={className}>
      <ImageCarousel
        showThumbs={true}
        showIndicators={false}
        renderArrowPrev={_renderArrowPrev}
        renderArrowNext={_renderArrowNext}
        showStatus={false}
        // renderThumbs={_renderThumbs}
      >
        {images.map((imageURL: string, index: number) => {
          return (
            <Box key={index}>
              <img src={imageURL} alt="carousel" />
              <Button
                className={'button__style download__image'}
                onClick={() => downloadImage(imageURL)}
              >
                <GetAppIcon color="inherit" />
              </Button>
            </Box>
          );
        })}
      </ImageCarousel>
    </Box>
  );
};

export const Carousel = styled(CarouselComponent)`
  .button__style {
    width: ${themeGet('space.xl')};
    height: ${themeGet('space.xl')};
    min-width: ${themeGet('space.xl')};
    min-height: ${themeGet('space.xl')};
    border-radius: ${themeGet('radii.full')};
    padding: 0;
  }
  .arrow__style {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: ${themeGet('colors.primary.100')};
    border: solid 1px ${themeGet('colors.primary.300')};
    top: 50%;
    justify-content: center;
    align-items: center;
    z-index: 1;
    &.next {
      right: 10px;
    }
    &.prev {
      left: 10px;
      svg {
        position: relative;
        left: 3px;
      }
    }
    &:disabled {
      background-color: ${themeGet('colors.white')};
      border: solid 1px ${themeGet('colors.gray.400')};
    }
  }
  .thumbs-wrapper {
    margin: ${themeGet('space.md')} ${themeGet('space.xs')};
  }

  .slide {
    border: solid 1px ${themeGet('colors.gray.400')};
  }

  .thumbs {
    transform: translate3d(0px, 0px, 0px) !important;
    white-space: normal;
  }
  .thumb {
    padding: 0;
    transition: none;
    border: solid 1px ${themeGet('colors.gray.500')};
    &:hover {
      border-color: ${themeGet('colors.green.500')};
    }
    &.selected {
      border-color: ${themeGet('colors.green.500')};
    }
  }
  .download__image {
    position: absolute;
    bottom: 23px;
    color: ${themeGet('colors.gray.600')};
    right: 9px;
    border: solid 1px ${themeGet('colors.gray.300')};
  }
`;
