/* istanbul ignore file */

import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';

import { PrimaryButton } from './index';
import { H3 } from './typography';
import { Box } from '@chakra-ui/core';

interface IProps {
  redirect: () => void;
  heading: string;
  className?: string;
  description: string[];
  buttonLabel: string;
}

const InfoAlert: FunctionComponent<IProps> = ({
  redirect,
  heading,
  description,
  buttonLabel,
  className
}) => {
  const [description1, description2] = description;
  return (
    <Box className={className} textAlign="center">
      <H3 className="info-inner-heading" mb="xl">
        {heading}
      </H3>
      {description1 ? (
        <Box className="info-description1" mt="xl">
          {description1}
        </Box>
      ) : null}
      {description2 ? (
        <Box className="info-description2" mt="xl">
          {description2}
        </Box>
      ) : null}
      <Box mt="xl">
        <PrimaryButton onClick={() => redirect()} isFullWidth>
          {buttonLabel}
        </PrimaryButton>
      </Box>
    </Box>
  );
};

InfoAlert.defaultProps = {
  redirect: () => {},
  heading: '',
  description: ['', ''],
  buttonLabel: 'OK',
  className: ''
};

export const InfoAlertComponent = styled(InfoAlert)``;
