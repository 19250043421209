import { createAction } from 'redux-actions';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  WarehouseTruckServices,
  IWarehouseTruckResponse,
  IResponse,
  IAddWareHouseTruckParams
} from '../services/warehouseTruckRegistration.service';
import { IWarehouseTruck } from '../models/warehouseTruckRegistration.model';

export const RETRIEVE_WAREHOUSE_TRUCK__START =
  'pdg.warehouse.truck.list.ui.start';
export const retrieveWarehouseTruckStart = createAction(
  RETRIEVE_WAREHOUSE_TRUCK__START
);

export const RETRIEVE_WAREHOUSE_TRUCK__FAILURE =
  'pdg.warehouse.truck.list.ui.failure';
export const retrieveWarehouseTruckFailure = createAction(
  RETRIEVE_WAREHOUSE_TRUCK__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const RETRIEVE_WAREHOUSE_TRUCK__SUCCESS =
  'pdg.warehouse.truck.list.ui.success';
export const retrieveWarehouseTruckSuccess = createAction(
  RETRIEVE_WAREHOUSE_TRUCK__SUCCESS,
  (warehouseTruck: IWarehouseTruck[]) => {
    return { warehouseTruck };
  }
);

export const retrieveWarehouseTruck = (
  userId: string
): ThunkAction<Promise<IWarehouseTruckResponse>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(retrieveWarehouseTruckStart());
      const res = await WarehouseTruckServices.retrieveWarehouseTruck(userId);
      if (res.status === 1) {
        dispatch(retrieveWarehouseTruckSuccess(res.warehouseTruck));
      } else {
        throw res;
      }
      return res;
    } catch (err) {
      dispatch(retrieveWarehouseTruckFailure(err));
      throw err;
    }
  };
};

export const ADD_WAREHOUSE_TRUCK__START = 'pdg.warehouse.truck.add.ui.start';
export const addWarehouseTruckStart = createAction(ADD_WAREHOUSE_TRUCK__START);

export const ADD_WAREHOUSE_TRUCK__FAILURE =
  'pdg.warehouse.truck.add.ui.failure';
export const addWarehouseTruckFailure = createAction(
  ADD_WAREHOUSE_TRUCK__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const ADD_WAREHOUSE_TRUCK__SUCCESS =
  'pdg.warehouse.truck.add.ui.success';
export const addWarehouseTruckSuccess = createAction(
  ADD_WAREHOUSE_TRUCK__SUCCESS
);

export const addWarehouseTruck = (
  addWarehouseTruck: IAddWareHouseTruckParams
): ThunkAction<Promise<IResponse>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(addWarehouseTruckStart());
      const res = await WarehouseTruckServices.addWarehouseTruck(
        addWarehouseTruck
      );
      if (res.status === 1) {
        dispatch(addWarehouseTruckSuccess());
      } else {
        throw res;
      }
      return res;
    } catch (err) {
      dispatch(retrieveWarehouseTruckFailure(err));
      throw err;
    }
  };
};
