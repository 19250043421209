/* istanbul ignore file */

import { Method } from 'axios';
import request from '../../../services/request.service';
import GlobalConfig from '../../../globalConfig';
import { IFormValues } from '../components/UpdatePassword.component';

interface IServiceProps {
  method: Method;
  headers: Record<string, any>;
  url: string;
  data?: Record<string, any>;
  responseType?: any;
}

export const submitUpdatePasswordParams = (data = {}): IServiceProps => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot('mobile/updatePassword'),
  data
});

export class UpdatePasswordServices {
  static async updatePassword(data: IFormValues): Promise<any> {
    const res = await request(submitUpdatePasswordParams(data));
    return res.data;
  }
}
