import React, { FunctionComponent } from 'react';
import { FormikProps } from 'formik';
import { Box } from '@chakra-ui/core';

import { IProps } from './BroadcastMessage.component';

export interface IMessageFormValues {
  message: string;
  subject: string;
}

export const BroadcastMessageForm: FunctionComponent<
  FormikProps<IMessageFormValues> & Partial<IProps>
> = ({ values, handleSubmit, errors, touched, setFieldValue, t }) => {
  return (
    <>
      <div className="login-container">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder={t('SUBJECT')}
              name="subject"
              value={values.subject}
              onChange={(event) => {
                setFieldValue('subject', event.target.value);
              }}
            />
            {errors.subject && touched.subject ? (
              <Box className="invalid-feedback">{errors.subject}</Box>
            ) : null}
          </div>
          <Box className="form-group">
            <textarea
              className="form-control _hauto"
              rows={3}
              placeholder={t('MESSAGE')}
              value={values.message ?? ''}
              name="message"
              onChange={(event) => {
                setFieldValue('message', event.target.value);
              }}
            ></textarea>
            {errors.message && touched.message ? (
              <Box className="invalid-feedback">{errors.message}</Box>
            ) : null}
          </Box>
          <div className="form-group">
            <button className="btn btn-primary w-100" type="submit">
              {t('SUBMIT')}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
