import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Formik, FormikHelpers as FormikActions, FormikProps } from 'formik';
import { RouteComponentProps } from 'react-router-dom';

import { getMessage } from '../../../helper';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../../constant';
import {
  AddWarehouseTruckForm,
  IAddWarehouseTruckFormValues
} from './AddWarehouseTruck_editorForm.component';
import { IWarehouseTruck } from '../models/warehouseTruckRegistration.model';
import { IAddWareHouseTruckParams } from '../services/warehouseTruckRegistration.service';
import { addWarehouseTruck } from '../actions';

export interface IProps extends RouteComponentProps {
  className?: string;
  toggleView: () => void;
  retrieveWarehouseTruck: () => void;
  t: any;
  warehouseTruck: IWarehouseTruck | null;
  toastManager: any;
  addWarehouseTruck: typeof addWarehouseTruck;
}

export class AddWarehouseTruckComponent extends PureComponent<IProps> {
  static defaultProps: Partial<IProps> = {
    className: ''
  };

  async onFormSubmit(
    values: IAddWarehouseTruckFormValues,
    actions: FormikActions<IAddWarehouseTruckFormValues>
  ) {
    const {
      toastManager,
      toggleView,
      retrieveWarehouseTruck,
      addWarehouseTruck,
      warehouseTruck
    } = this.props;
    actions.setSubmitting(true);
    try {
      const params: IAddWareHouseTruckParams = {
        name: values.name,
        truckRegistrationImage: values.truckRegistrationImage,
        type: values.type
      };
      if (warehouseTruck?.id) {
        params.id = warehouseTruck?.id;
      }
      await addWarehouseTruck(params);
      retrieveWarehouseTruck();
      toggleView();
      toastManager.add(getMessage(SUCCESS_MESSAGE.ADD_WAREHOUSE_TRUCK), {
        appearance: 'success',
        autoDismiss: true
      });
    } catch (err) {
      const message =
        err?.errorObj?.message ??
        err?.message ??
        getMessage(ERROR_MESSAGE.ADD_WAREHOUSE_TRUCK);
      toastManager.add(message, { appearance: 'error', autoDismiss: true });
    } finally {
      actions.setSubmitting(false);
    }
  }

  validateForm(values: IAddWarehouseTruckFormValues) {
    const errors: any = {};

    if (!values.name) {
      errors.name = getMessage(ERROR_MESSAGE.required);
    }

    if (!values.type) {
      errors.type = getMessage(ERROR_MESSAGE.required);
    }

    return errors;
  }

  render() {
    const { className, t, warehouseTruck } = this.props;
    return (
      <div className={className}>
        <div className="_cstModal">
          <div className="_mdBody">
            <h4 className="_mdheadng">
              {warehouseTruck?.name
                ? t('UPDATE_WAREHOUSE_OR_TRUCK')
                : t('ADD_WAREHOUSE_OR_TRUCK')}
            </h4>
            <div className="mt-5">
              <Formik
                validate={this.validateForm.bind(this)}
                initialValues={{
                  name: warehouseTruck?.name ?? '',
                  truckRegistrationImage: warehouseTruck?.truckRegistrationImage ?? '',
                  type: warehouseTruck?.type ?? 1
                }}
                validateOnMount
                onSubmit={(
                  values: IAddWarehouseTruckFormValues,
                  actions: FormikActions<IAddWarehouseTruckFormValues>
                ) => {
                  this.onFormSubmit(values, actions);
                }}
                component={(
                  formikProps: FormikProps<IAddWarehouseTruckFormValues>
                ) => {
                  return (
                    <AddWarehouseTruckForm
                      {...formikProps}
                      {...this.props}
                      {...this.state}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
/* istanbul ignore next */
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ addWarehouseTruck }, dispatch);
}

export default connect(null, mapDispatchToProps)(AddWarehouseTruckComponent);
