/** @jsx jsx */
import { forwardRef } from 'react';
import styled from '@emotion/styled';
import { css, jsx } from '@emotion/core';
import {
  Text,
  BoxProps,
  Input as ChakraFormInput,
  FormControl as ChakraFormControl,
  FormLabel as ChakraFormLabel,
  FormHelperText as ChakraFormHelperText,
  Textarea as ChakraTextarea,
  Select as ChakraSelect,
  Checkbox as ChakraCheckbox,
  Radio as ChakraRadio,
  InputGroup as ChakraInputGroup,
  InputLeftAddon as ChakraInputLeftAddon,
  InputLeftElement as ChakraInputLeftElement,
  InputRightAddon as ChakraInputRightAddon,
  InputRightElement as ChakraInputRightElement,
  Switch as ChakraSwitch
} from '@chakra-ui/core';
import { themeGet } from '@styled-system/theme-get';

const inputStyle = ({ theme }: any) => {
  return css`
    border: 1px solid ${theme.colors.defaultFormInputBorder};
    background-color: ${theme.colors.formInputBg};
    padding: ${theme.space.xs} ${theme.space.sm};
    color: ${theme.colors.grey9};
    border-radius: ${theme.radii.md};
    height: auto;
    line-height: 1.5;

    &:focus {
      background-color: ${theme.colors.focusedFormInputBg};
      border-color: ${theme.colors.focusedFormInputBorder};
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    }

    &:hover {
      border-color: ${theme.colors.focusedFormInputBorder};
    }

    &:disabled {
      background-color: ${theme.colors.disabledFormInputBg};
      color: ${theme.colors.grey9};
      opacity: 1;
    }

    &:disabled,
    &:disabled:focus,
    &:disabled:hover,
    &[aria-disabled='true'],
    &[aria-disabled='true']:focus,
    &[aria-disabled='true']:hover {
      opacity: 1;
    }

    &.has-error,
    &.error,
    &[aria-invalid='true'] {
      border-color: ${theme.colors.errorFormInputBorder};
      box-shadow: none;
    }

    &.success {
      border-color: ${theme.colors.successFormInputBorder};
    }
  `;
};

export const Label = styled(ChakraFormLabel)`
  font-size: ${themeGet('fontSizes.sm')};
  font-weight: ${themeGet('fontWeights.medium')};
  text-transform: uppercase;
  color: ${themeGet('colors.grey7')};
  padding-bottom: 6px;
`;
Label.displayName = 'Label';

export const Input = styled(ChakraFormInput)`
  ${inputStyle};
`;
Input.displayName = 'Input';

export const Textarea = styled(ChakraTextarea)`
  ${inputStyle};
`;
Textarea.displayName = 'Textarea';

export const Select = styled(ChakraSelect)`
  ${inputStyle};
  padding-right: 2rem;
`;
Select.displayName = 'Select';

export const InputGroup = styled(ChakraInputGroup)``;
InputGroup.displayName = 'InputGroup';

export const InputLeftAddon = styled(ChakraInputLeftAddon)``;
InputLeftAddon.displayName = 'InputLeftAddon';

export const InputRightAddon = styled(ChakraInputRightAddon)``;
InputRightAddon.displayName = 'InputRightAddon';

export const InputLeftElement = styled(ChakraInputLeftElement)`
  width: auto;
  padding: 7px ${themeGet('space.sm')};
`;
InputLeftElement.displayName = 'InputLeftElement';

export const InputRightElement = styled(ChakraInputRightElement)`
  width: auto;
  padding: 7px ${themeGet('space.sm')};
`;
InputRightElement.displayName = 'InputRightElement';

export const FormControl = styled(ChakraFormControl)`
  margin-bottom: ${themeGet('space.md')};

  &:last-of-type {
    margin-bottom: 0;
  }
`;
FormControl.displayName = 'FormControl';

export const FormHelperText = styled(ChakraFormHelperText)``;
FormHelperText.displayName = 'FormHelperText';

export const FormErrorMessage = forwardRef((props: BoxProps, ref) => (
  <Text ref={ref} className="invalid-feedback" {...props} />
));
FormErrorMessage.displayName = 'FormErrorMessage';

export const Checkbox = styled(ChakraCheckbox)``;
Checkbox.displayName = 'Checkbox';

export const Radio = styled(ChakraRadio)``;
Radio.displayName = 'Radio';

export const Switch = styled(ChakraSwitch)`
  div > div {
    box-shadow: ${themeGet('shadows.md')};
  }
`;
Switch.displayName = 'Switch';
