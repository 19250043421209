import { combineReducers } from 'redux';
import {
  changePasswordPageReducer,
  IChangePasswordPageState
} from './changePassword.reducer';
import {
  editProfilePageReducer,
  IEditProfilePageState
} from './editProfile.reducer';
import {
  INotificationPageState,
  notificationPageReducer
} from './notification.reducer';
import {
  IBroadcastMessagePageState,
  broadcastMessagePageReducer
} from './broadcastMessage.reducer';

import {
  ICompanyAndOperatorInfoState,
  companyAndOperatorInfoReducer
} from './company_and_operator.reducer';

import { IOperatorPageState, operatorPageReducer } from './operator.reducer';

export interface ICommonState {
  changePassword: IChangePasswordPageState;
  editProfile: IEditProfilePageState;
  notifications: INotificationPageState;
  broadcastMessage: IBroadcastMessagePageState;
  operators: IOperatorPageState;
  companyAndOperatorInfo: ICompanyAndOperatorInfoState;
}

export const commonReducer = combineReducers<ICommonState>({
  changePassword: changePasswordPageReducer,
  editProfile: editProfilePageReducer,
  notifications: notificationPageReducer,
  broadcastMessage: broadcastMessagePageReducer,
  operators: operatorPageReducer,
  companyAndOperatorInfo: companyAndOperatorInfoReducer
});
