import { ISubscription } from '../../models/subscription.model';
import { AnyAction, Reducer } from 'redux';
import { RETRIEVE_SUBSCRIPTION_LIST__SUCCESS } from '../../actions';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import { IAppState } from '../../../../store';
import { ICoupon } from '../../models/coupon.model';

export type SubscriptionById = { [key: string]: ISubscription };
export type CouponById = { [key: string]: ICoupon };

export interface IRegisterInfoState {
  subscriptionById: SubscriptionById;
  subscriptionAllIds: string[];
  couponById: SubscriptionById;
  couponAllIds: string[];
}

export const defaultState: IRegisterInfoState = {
  subscriptionById: {},
  subscriptionAllIds: [],
  couponById: {},
  couponAllIds: []
};

export const registerInfoReducer: Reducer<IRegisterInfoState> = (
  state: IRegisterInfoState = defaultState,
  action: AnyAction
): IRegisterInfoState => {
  switch (action.type) {
    case RETRIEVE_SUBSCRIPTION_LIST__SUCCESS: {
      const { subscriptionList } = action.payload;
      const byId = keyBy(subscriptionList, (datum) => {
        return datum.id;
      });
      const ids = map(subscriptionList, (datum) => {
        return datum.id;
      });
      return {
        ...state,
        subscriptionById: { ...state.subscriptionById, ...byId },
        subscriptionAllIds: uniq([...state.subscriptionAllIds, ...ids])
      };
    }
    default:
      return state;
  }
};

export const getSubscriptionById = (state: IAppState) => {
  return state.register.entities.registerInfo.subscriptionById;
};

export const getCouponById = (state: IAppState) => {
  return state.register.entities.registerInfo.couponById;
};
