/* istanbul ignore file */

import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';
import { Alert, AlertDescription } from '@chakra-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';
import { ERROR_MESSAGE } from '../../constant';
import { PrimaryUnstyledButton } from './buttons';
import { getMessage } from '../../helper';

interface IProps {
  className?: string;
  message?: string;
  callBack?: () => void;
}

const ErrorStripComponent: FunctionComponent<IProps> = ({
  message = getMessage(ERROR_MESSAGE.SERVER_ERROR),
  className,
  callBack
}) => {
  return (
    <Alert
      padding="md"
      mb="xs"
      status="warning"
      background="warning.50"
      borderWidth={0}
      className={className}
    >
      <AlertDescription>{message}</AlertDescription>
      {callBack ? (
        <PrimaryUnstyledButton className={'refresh-btn'} onClick={callBack}>
          <ReplayIcon color={'inherit'} />
        </PrimaryUnstyledButton>
      ) : null}
    </Alert>
  );
};

/* istanbul ignore next */
export const ErrorStrip = styled(ErrorStripComponent)`
  position: relative;
  .refresh-btn {
    position: absolute;
    right: 0;
    color: ${themeGet('colors.gray.500')};
  }
`;

ErrorStrip.displayName = 'ErrorStrip';
