import { IReport } from '../../models/reports-list.model';
import { AnyAction, Reducer } from 'redux';
import {
  RETRIEVE_REPORT_LIST__START,
  RETRIEVE_REPORT_LIST__SUCCESS,
  RETRIEVE_REPORT_DETAIL__SUCCESS,
  UNLOAD_PRODUCT__SUCCESS
} from '../../actions';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import { IAppState } from '../../../../store';

export type ReportById = { [key: string]: IReport };

export interface IReportsInfoState {
  reportById: ReportById;
  reportAllIds: string[];
}

export const defaultState: IReportsInfoState = {
  reportById: {},
  reportAllIds: []
};

export const reportsInfoReducer: Reducer<IReportsInfoState> = (
  state: IReportsInfoState = defaultState,
  action: AnyAction
): IReportsInfoState => {
  switch (action.type) {
    case RETRIEVE_REPORT_LIST__START: {
      return {
        ...state,
        reportAllIds: defaultState.reportAllIds,
        reportById: defaultState.reportById
      };
    }
    case RETRIEVE_REPORT_LIST__SUCCESS: {
      const { reports } = action.payload;
      const byId = keyBy(reports, (datum: IReport) => {
        return datum.report_id;
      });
      const ids = map(reports, (datum: IReport) => {
        return datum.report_id;
      });
      return {
        ...state,
        reportById: { ...state.reportById, ...byId },
        reportAllIds: uniq([...state.reportAllIds, ...ids])
      };
    }
    case RETRIEVE_REPORT_DETAIL__SUCCESS: {
      const { report } = action.payload;
      if (report?.report_id) {
        const reportById = {
          ...state.reportById,
          [report.report_id]: {
            ...report
          }
        };
        return {
          ...state,
          reportById: { ...state.reportById, ...reportById }
        };
      }
      return state;
    }
    case UNLOAD_PRODUCT__SUCCESS: {
      const { reportId, productId, current_status } = action.payload;
      const report = state.reportById[reportId];
      if (report?.report_id) {
        const reportById = {
          ...state.reportById,
          [reportId]: {
            ...report,
            is_safe: current_status ? 1 : 0,
            product: [
              ...report.product.filter((datum) => datum.id !== productId)
            ]
          }
        };
        return {
          ...state,
          reportById: { ...state.reportById, ...reportById }
        };
      }
      return state;
    }
    default:
      return state;
  }
};

export const getReportById = (state: IAppState) => {
  return state.reports.entities.reportInfo.reportById;
};

export const getReportAllIds = (state: IAppState) => {
  return state.reports.entities.reportInfo.reportAllIds;
};
