import { Reducer, combineReducers } from 'redux';
import { IDashboardPageState, dashboardPageReducer } from './ui';

export interface IDashboardPageUI {
  dashboardPage: IDashboardPageState;
}

export interface IDashboardState {
  ui: IDashboardPageUI;
}

const ui = combineReducers({
  dashboardPage: dashboardPageReducer
});

export const dashboardReducer: Reducer<IDashboardState> = combineReducers({
  ui
});
