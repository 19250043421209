import { createAction } from 'redux-actions';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { AboutUsServices, IAboutUsParams } from '../services/api.service';
import { IAboutUs } from '../models/about_us.model';

export const RETRIEVE_ABOUT_US_CONTENT__START = 'pdg.aboutUs.content.ui.start';
export const retrieveContentStart = createAction(
  RETRIEVE_ABOUT_US_CONTENT__START
);

export const RETRIEVE_ABOUT_US_CONTENT__FAILURE =
  'pdg.aboutUs.content.ui.failure';
export const retrieveContentFailure = createAction(
  RETRIEVE_ABOUT_US_CONTENT__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const RETRIEVE_ABOUT_US_CONTENT__SUCCESS =
  'pdg.aboutUs.content.ui.success';
export const retrieveContentSuccess = createAction(
  RETRIEVE_ABOUT_US_CONTENT__SUCCESS,
  (aboutUs: IAboutUs) => {
    return { aboutUs };
  }
);

export const retrieveContent = (): ThunkAction<
  Promise<IAboutUsParams>,
  {},
  {},
  AnyAction
> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(retrieveContentStart());
      const res = await AboutUsServices.retrieveContent();
      if (res.status === 1) {
        dispatch(retrieveContentSuccess(res.resultData));
      } else {
        throw res;
      }
      return res;
    } catch (err) {
      dispatch(retrieveContentFailure(err));
      throw err;
    }
  };
};
