import { AnyAction, Reducer } from 'redux';
import { IAppState } from '../../../../store';

import {
  RETRIEVE_CURRENT_MANIFEST__SUCCESS,
  UNLOAD_PRODUCT__SUCCESS,
  UNLOAD_ALL_PRODUCTS__SUCCESS,
  RETRIEVE_CURRENT_MANIFEST_DETAIL__START,
  RETRIEVE_CURRENT_MANIFEST__FAILURE,
  RESET_DATA
} from '../../actions';
import { ICurrentManifest } from '../../models/currentManifest';

export interface ICurrentManifestInfoState {
  currentManifest: ICurrentManifest | null;
}

export const defaultState: ICurrentManifestInfoState = {
  currentManifest: null
};

export const currentManifestInfoReducer: Reducer<ICurrentManifestInfoState> = (
  state: ICurrentManifestInfoState = defaultState,
  action: AnyAction
): ICurrentManifestInfoState => {
  switch (action.type) {
    case RETRIEVE_CURRENT_MANIFEST_DETAIL__START: {
      return {
        ...state,
        currentManifest: null
      };
    }
    case RETRIEVE_CURRENT_MANIFEST__FAILURE: {
      return {
        ...state,
        currentManifest: null
      };
    }
    case RETRIEVE_CURRENT_MANIFEST__SUCCESS: {
      const { report } = action.payload;
      return {
        ...state,
        currentManifest: report?.report_id ? report : null
      };
    }
    case UNLOAD_PRODUCT__SUCCESS: {
      const { reportId, productId, current_status } = action.payload;
      const currentManifest = state.currentManifest;
      if (
        currentManifest?.report_id &&
        currentManifest?.report_id === reportId
      ) {
        return {
          ...state,
          currentManifest: {
            ...currentManifest,
            is_safe: current_status ? 1 : 0,
            product: [
              ...currentManifest.product.filter(
                (datum) => datum.id !== productId
              )
            ]
          }
        };
      }
      return state;
    }
    case UNLOAD_ALL_PRODUCTS__SUCCESS: {
      const { reportId, current_status } = action.payload;
      const currentManifest = state.currentManifest;
      if (
        currentManifest?.report_id &&
        currentManifest?.report_id === reportId
      ) {
        return {
          ...state,
          currentManifest: {
            ...currentManifest,
            is_safe: current_status ? 1 : 0,
            product: []
          }
        };
      }
      return state;
    }
    case RESET_DATA: {
      return {
        ...state,
        currentManifest: null
      };
    }
    default:
      return state;
  }
};

export const getCurrentManifest = (state: IAppState) => {
  return state.currentManifest.entities.currentManifestInfo.currentManifest;
};
