/* istanbul ignore file */
import React, { FunctionComponent, useState } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { Box, Alert } from '@chakra-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { STATUS, ERROR_MESSAGE, ROUTES } from '../../../constant';
import { ISubscription, SUBSCRIPTION_TYPE } from '../models/subscription.model';
import { ErrorAlert } from '../../../common/components/ErrorAlert.component';
import { getMessage } from '../../../helper';
import { FullPageLoader } from '../../../common/components/Loader.component';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  retrieveSubscriptionsAPIStatus: STATUS;
  subscriptionList: ISubscription[];
  selectedSubscription: number | null;
  isUpgradeSubscription?: boolean;
  retry: () => void;
  selectSubscription: (selectedSubscription: number) => void;
  t: any;
}

export const SubscriptionComponent: FunctionComponent<IProps> = (
  props: IProps
) => {
  const {
    className,
    t,
    retrieveSubscriptionsAPIStatus,
    subscriptionList,
    isUpgradeSubscription,
    selectedSubscription,
    selectSubscription,
    retry,
    history
  } = props;
  const [subscriptionType, setSubscriptionType] = useState<SUBSCRIPTION_TYPE>(
    SUBSCRIPTION_TYPE.MONTHLY
  );
  return (
    <Box className={className}>
      {retrieveSubscriptionsAPIStatus === STATUS.LOADING ? (
        <Box p={'lg'}>
          <FullPageLoader />
        </Box>
      ) : null}
      {!isUpgradeSubscription ? (
        <h3>{t('REGISTER_SIMPLE_LICENSE_FOR_EVERYONE')}</h3>
      ) : null}
      {/* <Box className="info">
        <img src={Info} alt="Logo" />
      </Box> */}
      <Box className="_tabA">
        <ul>
          <li
            className={
              subscriptionType === SUBSCRIPTION_TYPE.MONTHLY ? 'active' : ''
            }
            onClick={() => {
              setSubscriptionType(SUBSCRIPTION_TYPE.MONTHLY);
            }}
          >
            <a href="#">{t('REGISTER_MONTHLY')}</a>
          </li>
          <li
            className={
              subscriptionType === SUBSCRIPTION_TYPE.YEARLY ? 'active' : ''
            }
            onClick={() => {
              setSubscriptionType(SUBSCRIPTION_TYPE.YEARLY);
            }}
          >
            <a href="#">{t('REGISTER_YEARLY')}</a>
          </li>
        </ul>
      </Box>
      {retrieveSubscriptionsAPIStatus === STATUS.FAILURE ? (
        <Box>
          <ErrorAlert
            errorMessage={getMessage(ERROR_MESSAGE.DATA_FAILURE)}
            retry={retry}
          />
        </Box>
      ) : null}
      {retrieveSubscriptionsAPIStatus === STATUS.SUCCESS &&
      subscriptionList &&
      subscriptionList.filter(
        (datum: ISubscription) => datum.type === subscriptionType
      ).length === 0 ? (
        <Box mt="md">
          <Alert mb="xs" status="warning">
            {isUpgradeSubscription
              ? getMessage(ERROR_MESSAGE.NO_SUBSCRIPTION_FOUND)
              : getMessage(ERROR_MESSAGE.NO_MATCHING_RESULT)}
          </Alert>
        </Box>
      ) : null}
      {retrieveSubscriptionsAPIStatus === STATUS.SUCCESS &&
      subscriptionList &&
      subscriptionList.length ? (
        <Box className="_prInfo">
          <ul className="clearfix">
            {subscriptionList
              .filter((datum: ISubscription) => datum.type === subscriptionType)
              .map((datum: ISubscription) => (
                <li key={datum.id}>
                  <Box className="_prInfoBx">
                    <sup>$</sup>
                    <span>{datum.amount}/</span>
                    <small>mo</small>
                    <Box className="_lc">
                      {`${datum.license} ${t('REGISTER_LICENSE')}`}
                    </Box>
                    <a
                      href="#"
                      className={`${
                        datum.id === selectedSubscription ? 'active' : ''
                      } transition`}
                      onClick={() => {
                        selectSubscription(datum.id);
                        if (!isUpgradeSubscription) {
                          history.push(ROUTES.PAYMENT);
                        }
                      }}
                    >
                      {t(
                        isUpgradeSubscription
                          ? 'UPGRADE'
                          : 'REGISTER_GET_STARTED'
                      )}{' '}
                      - ${datum.amount}
                    </a>
                  </Box>
                </li>
              ))}
          </ul>
        </Box>
      ) : null}
    </Box>
  );
};

export default withToastManager(withRouter(SubscriptionComponent));
