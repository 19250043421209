import { AnyAction, Reducer } from 'redux';
import { IAppState } from '../../store';
import { STATUS } from '../../constant';
import {
  BROADCAST_MESSAGE__START,
  BROADCAST_MESSAGE__FAILURE,
  BROADCAST_MESSAGE__SUCCESS
} from '../actions';

export interface IBroadcastMessagePageState {
  broadcastMessageAPIStatus: STATUS;
}

export const defaultState: IBroadcastMessagePageState = {
  broadcastMessageAPIStatus: STATUS.NOT_STARTED
};

export const broadcastMessagePageReducer: Reducer<IBroadcastMessagePageState> = (
  state: IBroadcastMessagePageState = defaultState,
  action: AnyAction
): IBroadcastMessagePageState => {
  switch (action.type) {
    case BROADCAST_MESSAGE__START: {
      return {
        ...state,
        broadcastMessageAPIStatus: STATUS.LOADING
      };
    }
    case BROADCAST_MESSAGE__FAILURE: {
      return {
        ...state,
        broadcastMessageAPIStatus: STATUS.FAILURE
      };
    }
    case BROADCAST_MESSAGE__SUCCESS: {
      return {
        ...state,
        broadcastMessageAPIStatus: STATUS.SUCCESS
      };
    }
    default: {
      return state;
    }
  }
};

export const getBroadcastMessageAPIStatus = (state: IAppState) => {
  return state.common.broadcastMessage.broadcastMessageAPIStatus;
};
