/* istanbul ignore file */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Box, Alert } from '@chakra-ui/core';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { IAppState } from '../../../store';
import { IReport } from '../models/reports-list.model';
import {
  STATUS,
  ERROR_MESSAGE,
  SUCCESS_MESSAGE,
  ROUTES
} from '../../../constant';
import { getLoggedInUserDetails } from '../../auth/selectors/auth.selectors';
import {
  retrieveReportDetail,
  deleteReport,
  retrieveCombinationEvaluation,
  updateManifestApproveStatus,
  unloadProduct
} from '../actions';
import {
  createUrl,
  getMessage,
  isAdmin,
  updateQueryString
} from '../../../helper';
import { FullPageLoader } from '../../../common/components/Loader.component';
import { ErrorAlert } from '../../../common/components/ErrorAlert.component';
import {
  getReportDetailAPIStatus,
  getUnloadProductAPIStatus
} from '../reducers/ui';
import { getReportDetailSelector } from '../selectors/index.page.selector';
import ReportDetailComponent from '../components/ReportDetail.component';
import {
  IManifestApproveStatusParams,
  IUnNumberClassDivisionParams,
  MANIFEST_APPROVE_STATUS
} from '../services/api.service';
import { User, USER_ROLE } from '../../../common/models/user';
import { retrieveOpertorsList } from '../../../common/actions';
import { getOperatorListSelector } from '../../../common/reducers/operator.reducer';
// import { SelectOperatorForm } from '../components/SelectOperatorForm.component';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  report: IReport | null;
  operatorList: User[];
  retrieveReportDetailAPIStatus: STATUS;
  unloadProductAPIStatus: STATUS;
  user: User;
  t: any;
  retrieveOpertorsList: typeof retrieveOpertorsList;
  retrieveReportDetail: typeof retrieveReportDetail;
  retrieveCombinationEvaluation: typeof retrieveCombinationEvaluation;
  deleteReport: typeof deleteReport;
  updateManifestApproveStatus: typeof updateManifestApproveStatus;
  unloadProduct: typeof unloadProduct;
}

export const ReportDetail: FunctionComponent<IProps> = (props: IProps) => {
  const {
    t,
    className,
    report,
    retrieveReportDetailAPIStatus,
    unloadProductAPIStatus,
    user,
    retrieveReportDetail,
    retrieveOpertorsList,
    updateManifestApproveStatus,
    deleteReport,
    history,
    toastManager,
    match
  } = props;
  const { params } = match;
  const reportId = (params as any).report_id as string;
  const selectedOperatorId = (params as any).operatorId as string;
  const [showError, setShowError] = useState(false);



  const _retrieveReportDetail = async () => {
    try {
      setShowError(false);
      const res: any = await retrieveReportDetail(reportId as string);
      if (
        res.status === 1 &&
        !res.data &&
        (user.role === USER_ROLE.OPERATOR || isAdmin(user.role))
      ) {
        const editReportUrl = createUrl(ROUTES.REPORT_UN_EDIT, {
          report_id: reportId,
          operatorId: selectedOperatorId
        });
        history.push(editReportUrl);
      } else {
        setShowError(true);
      }
    } catch (err) {
      console.log(':: _retrieveReportDetail err ', err);
    }
  };
  useEffect(() => {
    if (selectedOperatorId) {
      _retrieveReportDetail();
    }
  }, [selectedOperatorId]);

  const _deleteReport = async () => {
    try {
      await deleteReport(selectedOperatorId as string);
      toastManager.add(getMessage(SUCCESS_MESSAGE.DELETE_REPORT), {
        appearance: 'success',
        autoDismiss: true
      });
      history.push(ROUTES.REPORTS);
    } catch (err) {
      console.log(':: _deleteReport err ', err);
      toastManager.add(getMessage(ERROR_MESSAGE.REPORT_DELETE), {
        appearance: 'error',
        autoDismiss: true
      });
    }
  };

  const _retrieveCombinationEvaluation = async () => {
    try {
      const params: IUnNumberClassDivisionParams[] =
        report?.product.map((datum) => ({
          un_no: datum.un_no ? Number(datum.un_no) : 0,
          class_division_value: datum.class,
          tunnel_code: datum.tunnel_code ?? ''
        })) ?? [];
      await retrieveCombinationEvaluation(params);
    } catch (err) {
      console.log(
        ':: _retrieveCombinationEvaluation err ',
        retrieveCombinationEvaluation
      );
      toastManager.add(getMessage(ERROR_MESSAGE.CHECK_COMBINATION), {
        appearance: 'error',
        autoDismiss: true
      });
    }
  };
  useEffect(() => {
    _retrieveCombinationEvaluation();
  }, [report?.report_id]);

  const _retrieveOpertorsList = async () => {
    try {
      await retrieveOpertorsList();
    } catch (err) {
      console.log(':: _retrieveOpertorsList err ', err);
    }
  };
  useEffect(() => {
    if (user.role !== USER_ROLE.OPERATOR) {
      _retrieveOpertorsList();
    }
  }, [user.role]);

  const _updateManifestApproveStatus = async (
    params: IManifestApproveStatusParams
  ) => {
    try {
      await updateManifestApproveStatus(params);
      toastManager.add(
        getMessage(SUCCESS_MESSAGE.UPDATE_REPORT),
        {
          appearance: 'success',
          autoDismiss: true
        },
        () => {
          _retrieveReportDetail();
        }
      );
    } catch (err) {
      console.log(':: _updateManifestApproveStatus err ', err);
      toastManager.add(getMessage(ERROR_MESSAGE.REPORT_UPDATE), {
        appearance: 'error',
        autoDismiss: true
      });
    }
  };

  const setSelectedOperatorId = (operatorId: string | undefined) => {
    updateQueryString(history, {
      operatorId
    });
  };

  useEffect(() => {
    if (
      !selectedOperatorId &&
      user.role &&
      user.role === USER_ROLE.OPERATOR
    ) {
      setSelectedOperatorId(user.user_id);
    }
  }, [user.user_id, selectedOperatorId]);

  // useEffect(() => {
  //   if (!report?.product.length && unloadProductAPIStatus === STATUS.SUCCESS) {
  //     const addReportUrl = createUrl(ROUTES.REPORT_UN_EDIT, {
  //       userId: selectedOperatorId as string
  //     });
  //     history.push(addReportUrl);
  //   }
  // }, [report?.product.length]);

  return (
    <Box className={className}>
      <section className="dbPrWid">
        <Box mb="80px" className="row justify-content-end">
          <Box className="col-auto">
            <Box className="row justify-content-end">
              {selectedOperatorId && report?.report_id && report.product.length ? (
                <Box className="col-auto">
                  <button
                    className="btn-primary transition"
                    onClick={() => {
                      const editUrl = createUrl(ROUTES.REPORT_UN_EDIT, {
                        report_id: reportId,
                        operatorId: selectedOperatorId
                      });
                      history.push(editUrl);
                    }}
                  >
                    {t('UPDATE_DG_SAFETY_REQUIREMENT')}
                  </button>
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>
        {retrieveReportDetailAPIStatus === STATUS.LOADING ||
        unloadProductAPIStatus === STATUS.LOADING ? (
          <Box p={'lg'}>
            <FullPageLoader />
          </Box>
        ) : null}
        {selectedOperatorId &&
        retrieveReportDetailAPIStatus === STATUS.FAILURE &&
        !showError ? (
          <Box>
            <ErrorAlert
              errorMessage={getMessage(ERROR_MESSAGE.DATA_FAILURE)}
              retry={_retrieveReportDetail}
            />
          </Box>
        ) : null}
        {console.log('::: report ', report)}
        {selectedOperatorId &&
        retrieveReportDetailAPIStatus === STATUS.SUCCESS &&
        !report &&
        showError ? (
          <Box mt="md">
            <Alert mb="xs" status="warning">
              {getMessage(ERROR_MESSAGE.NO_REPORT_FOUND)}
            </Alert>
          </Box>
        ) : null}

        {!selectedOperatorId ? (
          <Box mt="md">
            <Alert mb="xs" status="warning">
              {getMessage(ERROR_MESSAGE.NO_OPERATOR_FOUND_MANIFEST)}
            </Alert>
          </Box>
        ) : null}
        {selectedOperatorId &&
        retrieveReportDetailAPIStatus === STATUS.SUCCESS &&
        report ? (
          <ReportDetailComponent
            reportData={report as IReport}
            selectedOperatorId={selectedOperatorId}
            handleUpdateManifestApproveStatus={(
              status: MANIFEST_APPROVE_STATUS,
              reason: string | null
            ) => {
              _updateManifestApproveStatus({
                report_id: Number(report.report_id),
                status,
                reason
              });
            }}
            deleteReportData={() => {
              _deleteReport();
            }}
            {...props}
          />
        ) : null}
      </section>
      {/* {!selectedOperatorId ? (
        <SelectOperatorForm
          setSelectedOperatorId={(operatorId: string) => {
            setSelectedOperatorId(operatorId);
          }}
          user={user}
          operatorList={operatorList}
        />
      ) : null} */}
    </Box>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: IAppState) => {
  const loggedInUserDetails = getLoggedInUserDetails(state);
  const report = getReportDetailSelector(state);
  const retrieveReportDetailAPIStatus = getReportDetailAPIStatus(state);
  const operatorList = getOperatorListSelector(state);
  const unloadProductAPIStatus = getUnloadProductAPIStatus(state);
  return {
    operatorList,
    user: loggedInUserDetails.user,
    report,
    retrieveReportDetailAPIStatus,
    unloadProductAPIStatus
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  return bindActionCreators(
    {
      retrieveReportDetail,
      deleteReport,
      retrieveCombinationEvaluation,
      retrieveOpertorsList,
      updateManifestApproveStatus,
      unloadProduct
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(withToastManager(withRouter(ReportDetail))));
