import { createAction } from 'redux-actions';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { UpdatePasswordServices } from '../services/updatePassword.service';
import { IFormValues } from '../components/UpdatePassword.component';

export const UPDATE_PASSWORD__FAILURE = 'pdg.update.password.ui.failure';
export const updatePasswordFailure = createAction(
  UPDATE_PASSWORD__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const UPDATE_PASSWORD__SUCCESS = 'pdg.update.password.ui.success';
export const updatePasswordSuccess = createAction(UPDATE_PASSWORD__SUCCESS);

export const updatePassword = (
  data: IFormValues
): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      const res = await UpdatePasswordServices.updatePassword(data);
      if (res && res && res.status === 1) {
        dispatch(updatePasswordSuccess());
        return res;
      } else {
        dispatch(updatePasswordFailure(res));
        throw res;
      }
    } catch (err) {
      dispatch(updatePasswordFailure(err));
      throw err;
    }
  };
};
