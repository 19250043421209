export interface IUser {
  user_id: string;
  email: string;
  name: string;
  phone_no: string;
  country_id: string;
  licence_id: string;
  is_account_activated?: boolean;
  subscription_status?: boolean;
  parent_id?: number;
  status: number;
  file_name: string;
  role: USER_ROLE;
  truck?: number;
}

export enum USER_ROLE {
  OPERATOR = 'operator',
  COMPANY_ADMIN = 'company admin',
  MAIN = 'main',
  MANAGER = 'manager',
  SUPERVISOR = 'supervisor',
  ADMIN = 'admin'
}
export class User implements IUser {
  user_id: string;
  email: string;
  name: string;
  phone_no: string;
  country_id: string;
  licence_id: string;
  is_account_activated?: boolean;
  subscription_status?: boolean;
  parent_id?: number;
  status: number;
  file_name: string;
  role: USER_ROLE;
  truck?: number;

  constructor(data: IUser) {
    this.user_id = data.user_id;
    this.email = data.email;
    this.name = data.name;
    this.parent_id = data.parent_id;
    this.licence_id = data.licence_id;
    this.phone_no = data.phone_no;
    this.country_id = data.country_id;
    this.is_account_activated = data.is_account_activated;
    this.subscription_status = data.subscription_status;
    this.status = data.status;
    this.file_name = data.file_name;
    this.role = data.role;
    this.truck = data.truck;
  }
}
