import React, { FunctionComponent } from 'react';
import { Box } from '@chakra-ui/core';

import ClassDivision_1 from '../../../assets/images/icons/1.png';
import ClassDivision_1_4 from '../../../assets/images/icons/1_4.png';
import ClassDivision_1_5 from '../../../assets/images/icons/1_5.png';
import ClassDivision_1_6 from '../../../assets/images/icons/1_6.png';
import ClassDivision_2_1_A from '../../../assets/images/icons/2_1_A.png';
import ClassDivision_2_1_B from '../../../assets/images/icons/2_1_B.png';
import ClassDivision_2_2_A from '../../../assets/images/icons/2_2_A.png';
import ClassDivision_2_2_B from '../../../assets/images/icons/2_2_B.png';
import ClassDivision_2_3 from '../../../assets/images/icons/2_3.jpg';
import ClassDivision_2_5 from '../../../assets/images/icons/2_5.png';
import ClassDivision_3_A from '../../../assets/images/icons/3_A.png';
import ClassDivision_3_B from '../../../assets/images/icons/3_B.png';
import ClassDivision_4_1 from '../../../assets/images/icons/4_1.png';
import ClassDivision_4_2 from '../../../assets/images/icons/4_2.png';
import ClassDivision_4_3_A from '../../../assets/images/icons/4_3_A.png';
import ClassDivision_4_3_B from '../../../assets/images/icons/4_3_B.png';
import ClassDivision_5_1 from '../../../assets/images/icons/5_1.png';
import ClassDivision_5_2_A from '../../../assets/images/icons/5_2_A.png';
import ClassDivision_5_2_B from '../../../assets/images/icons/5_2_B.png';
import ClassDivision_6_1 from '../../../assets/images/icons/6_1.jpg';
import ClassDivision_6_2 from '../../../assets/images/icons/6_2.jpg';
import ClassDivision_7_A from '../../../assets/images/icons/7_A.png';
import ClassDivision_7_B from '../../../assets/images/icons/7_B.png';
import ClassDivision_7_C from '../../../assets/images/icons/7_C.png';
import ClassDivision_7_E from '../../../assets/images/icons/7_E.png';
import ClassDivision_8 from '../../../assets/images/icons/8.png';
import ClassDivision_9 from '../../../assets/images/icons/9.png';
import ClassDivision_9_A from '../../../assets/images/icons/9_A.png';

export interface IProps {
  class_division: string;
  className?: string;
  isWithoutLogin?: boolean;
  isSignage?: boolean;
  parentClass?: string;
  isCurrentManifest?: boolean;
}

export const ClassDivisionImage: FunctionComponent<IProps> = ({
  class_division,
  className,
  isWithoutLogin,
  isSignage,
  parentClass,
  isCurrentManifest
}) => {
  const getClassDivisionImage = () => {
    switch (class_division) {
      case '1':
      case '1.1':
      case '1.2':
      case '1.3': {
        return (
          <Box
            className={
              !isWithoutLogin && !isSignage
                ? 'class-division-image'
                : `row justify-content-start ${
                    isSignage ? 'signage-image-container' : ''
                  }`
            }
          >
            <img
              src={ClassDivision_1}
              alt="class division 1"
              className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
            />
          </Box>
        );
      }
      case '1.4': {
        return (
          <Box
            className={
              !isWithoutLogin && !isSignage
                ? 'class-division-image'
                : `row justify-content-start ${
                    isSignage ? 'signage-image-container' : ''
                  }`
            }
          >
            <img
              src={ClassDivision_1_4}
              alt="class division 1.4"
              className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
            />
          </Box>
        );
      }
      case '1.5': {
        return (
          <Box
            className={
              !isWithoutLogin && !isSignage
                ? 'class-division-image'
                : `row justify-content-start ${
                    isSignage ? 'signage-image-container' : ''
                  }`
            }
          >
            <img
              src={ClassDivision_1_5}
              alt="class division 1.5"
              className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
            />
          </Box>
        );
      }
      case '1.6': {
        return (
          <Box
            className={
              !isWithoutLogin && !isSignage
                ? 'class-division-image'
                : `row justify-content-start ${
                    isSignage ? 'signage-image-container' : ''
                  }`
            }
          >
            <img
              src={ClassDivision_1_6}
              alt="class division 1.6"
              className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
            />
          </Box>
        );
      }
      case '2.1': {
        return (
          <Box
            className={
              !isWithoutLogin && !isSignage
                ? 'class-division-image'
                : `row justify-content-start ${
                    isSignage ? 'signage-image-container' : ''
                  }`
            }
          >
            <img
              src={ClassDivision_2_1_A}
              alt="class division 2.1A"
              className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
            />
            {!isCurrentManifest ? (
              <img
                src={ClassDivision_2_1_B}
                alt="class division 2.1B"
                className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
              />
            ) : null}
          </Box>
        );
      }
      case '2.1A': {
        return (
          <Box
            className={
              !isWithoutLogin && !isSignage
                ? 'class-division-image'
                : `row justify-content-start ${
                    isSignage ? 'signage-image-container' : ''
                  }`
            }
          >
            <img
              src={ClassDivision_2_1_A}
              alt="class division 2.1A"
              className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
            />
          </Box>
        );
      }
      case '2.1B': {
        return (
          <Box
            className={
              !isWithoutLogin && !isSignage
                ? 'class-division-image'
                : `row justify-content-start ${
                    isSignage ? 'signage-image-container' : ''
                  }`
            }
          >
            <img
              src={ClassDivision_2_1_B}
              alt="class division 2.1B"
              className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
            />
          </Box>
        );
      }
      case '2.2': {
        return (
          <Box
            className={
              !isWithoutLogin && !isSignage
                ? 'class-division-image'
                : `row justify-content-start ${
                    isSignage ? 'signage-image-container' : ''
                  }`
            }
          >
            <img
              src={ClassDivision_2_2_A}
              alt="class division 2.2A"
              className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
            />
            {!isCurrentManifest ? (
              <img
                src={ClassDivision_2_2_B}
                alt="class division 2.2B"
                className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
              />
            ) : null}
          </Box>
        );
      }
      case '2.2A': {
        return (
          <Box
            className={
              !isWithoutLogin && !isSignage
                ? 'class-division-image'
                : `row justify-content-start ${
                    isSignage ? 'signage-image-container' : ''
                  }`
            }
          >
            <img
              src={ClassDivision_2_2_A}
              alt="class division 2.2A"
              className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
            />
          </Box>
        );
      }
      case '2.2B': {
        return (
          <Box
            className={
              !isWithoutLogin && !isSignage
                ? 'class-division-image'
                : `row justify-content-start ${
                    isSignage ? 'signage-image-container' : ''
                  }`
            }
          >
            <img
              src={ClassDivision_2_2_B}
              alt="class division 2.2B"
              className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
            />
          </Box>
        );
      }
      case '2.3': {
        return (
          <Box
            className={
              !isWithoutLogin && !isSignage
                ? 'class-division-image'
                : `row justify-content-start ${
                    isSignage ? 'signage-image-container' : ''
                  }`
            }
          >
            <img
              src={ClassDivision_2_3}
              alt="class division 2.3"
              className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
            />
          </Box>
        );
      }
      case '2.5': {
        return (
          <Box
            className={
              !isWithoutLogin && !isSignage
                ? 'class-division-image'
                : `row justify-content-start ${
                    isSignage ? 'signage-image-container' : ''
                  }`
            }
          >
            <img
              src={ClassDivision_2_5}
              alt="class division 2.5"
              className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
            />
          </Box>
        );
      }
      case '3': {
        return (
          <Box
            className={
              !isWithoutLogin && !isSignage
                ? 'class-division-image'
                : `row justify-content-start ${
                    isSignage ? 'signage-image-container' : ''
                  }`
            }
          >
            <img
              src={ClassDivision_3_A}
              alt="class division 3A"
              className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
            />
            {!isCurrentManifest ? (
              <img
                src={ClassDivision_3_B}
                alt="class division 3B"
                className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
              />
            ) : null}
          </Box>
        );
      }
      case '3A': {
        return (
          <Box
            className={
              !isWithoutLogin && !isSignage
                ? 'class-division-image'
                : `row justify-content-start ${
                    isSignage ? 'signage-image-container' : ''
                  }`
            }
          >
            <img
              src={ClassDivision_3_A}
              alt="class division 3A"
              className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
            />
          </Box>
        );
      }
      case '3B': {
        return (
          <Box
            className={
              !isWithoutLogin && !isSignage
                ? 'class-division-image'
                : `row justify-content-start ${
                    isSignage ? 'signage-image-container' : ''
                  }`
            }
          >
            <img
              src={ClassDivision_3_B}
              alt="class division 3B"
              className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
            />
          </Box>
        );
      }
      case '4.1': {
        return (
          <Box
            className={
              !isWithoutLogin && !isSignage
                ? 'class-division-image'
                : `row justify-content-start ${
                    isSignage ? 'signage-image-container' : ''
                  }`
            }
          >
            <img
              src={ClassDivision_4_1}
              alt="class division 4.1"
              className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
            />
          </Box>
        );
      }
      case '4.2': {
        return (
          <Box
            className={
              !isWithoutLogin && !isSignage
                ? 'class-division-image'
                : `row justify-content-start ${
                    isSignage ? 'signage-image-container' : ''
                  }`
            }
          >
            <img
              src={ClassDivision_4_2}
              alt="class division 4.2"
              className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
            />
          </Box>
        );
      }
      case '4.3': {
        return (
          <Box
            className={
              !isWithoutLogin && !isSignage
                ? 'class-division-image'
                : `row justify-content-start ${
                    isSignage ? 'signage-image-container' : ''
                  }`
            }
          >
            <img
              src={ClassDivision_4_3_A}
              alt="class division 4.3A"
              className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
            />
            {!isCurrentManifest ? (
              <img
                src={ClassDivision_4_3_B}
                alt="class division 4.3B"
                className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
              />
            ) : null}
          </Box>
        );
      }
      case '4.3A': {
        return (
          <Box
            className={
              !isWithoutLogin && !isSignage
                ? 'class-division-image'
                : `row justify-content-start ${
                    isSignage ? 'signage-image-container' : ''
                  }`
            }
          >
            <img
              src={ClassDivision_4_3_A}
              alt="class division 4.3A"
              className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
            />
          </Box>
        );
      }
      case '4.3B': {
        return (
          <Box
            className={
              !isWithoutLogin && !isSignage
                ? 'class-division-image'
                : `row justify-content-start ${
                    isSignage ? 'signage-image-container' : ''
                  }`
            }
          >
            <img
              src={ClassDivision_4_3_B}
              alt="class division 4.3B"
              className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
            />
          </Box>
        );
      }
      case '5.1': {
        return (
          <Box
            className={
              !isWithoutLogin && !isSignage
                ? 'class-division-image'
                : `row justify-content-start ${
                    isSignage ? 'signage-image-container' : ''
                  }`
            }
          >
            <img
              src={ClassDivision_5_1}
              alt="class division 5.1"
              className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
            />
          </Box>
        );
      }
      case '5.2': {
        return (
          <Box
            className={
              !isWithoutLogin && !isSignage
                ? 'class-division-image'
                : `row justify-content-start ${
                    isSignage ? 'signage-image-container' : ''
                  }`
            }
          >
            <img
              src={ClassDivision_5_2_A}
              alt="class division 5.2A"
              className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
            />
            {!isCurrentManifest ? (
              <img
                src={ClassDivision_5_2_B}
                alt="class division 5.2B"
                className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
              />
            ) : null}
          </Box>
        );
      }
      case '5.2A': {
        return (
          <Box
            className={
              !isWithoutLogin && !isSignage
                ? 'class-division-image'
                : `row justify-content-start ${
                    isSignage ? 'signage-image-container' : ''
                  }`
            }
          >
            <img
              src={ClassDivision_5_2_A}
              alt="class division 5.2A"
              className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
            />
          </Box>
        );
      }
      case '5.2B': {
        return (
          <Box
            className={
              !isWithoutLogin && !isSignage
                ? 'class-division-image'
                : `row justify-content-start ${
                    isSignage ? 'signage-image-container' : ''
                  }`
            }
          >
            <img
              src={ClassDivision_5_2_B}
              alt="class division 5.2B"
              className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
            />
          </Box>
        );
      }
      case '6': {
        return (
          <Box
            className={
              !isWithoutLogin && !isSignage
                ? 'class-division-image'
                : `row justify-content-start ${
                    isSignage ? 'signage-image-container' : ''
                  }`
            }
          >
            <img
              src={ClassDivision_6_1}
              alt="class division 6.1"
              className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
            />
            {!isCurrentManifest ? (
              <img
                src={ClassDivision_6_2}
                alt="class division 6.2"
                className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
              />
            ) : null}
          </Box>
        );
      }
      case '6.1': {
        return (
          <Box
            className={
              !isWithoutLogin && !isSignage
                ? 'class-division-image'
                : `row justify-content-start ${
                    isSignage ? 'signage-image-container' : ''
                  }`
            }
          >
            <img
              src={ClassDivision_6_1}
              alt="class division 6.1"
              className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
            />
          </Box>
        );
      }
      case '6.2': {
        return (
          <Box
            className={
              !isWithoutLogin && !isSignage
                ? 'class-division-image'
                : `row justify-content-start ${
                    isSignage ? 'signage-image-container' : ''
                  }`
            }
          >
            <img
              src={ClassDivision_6_2}
              alt="class division 6.2"
              className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
            />
          </Box>
        );
      }
      case '7A': {
        return (
          <Box
            className={
              !isWithoutLogin && !isSignage
                ? 'class-division-image'
                : `row justify-content-start ${
                    isSignage ? 'signage-image-container' : ''
                  }`
            }
          >
            <img
              src={ClassDivision_7_A}
              alt="class division 7A"
              className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
            />
          </Box>
        );
      }
      case '7B': {
        return (
          <Box
            className={
              !isWithoutLogin && !isSignage
                ? 'class-division-image'
                : `row justify-content-start ${
                    isSignage ? 'signage-image-container' : ''
                  }`
            }
          >
            <img
              src={ClassDivision_7_B}
              alt="class division 7B"
              className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
            />
          </Box>
        );
      }
      case '7C': {
        return (
          <Box
            className={
              !isWithoutLogin && !isSignage
                ? 'class-division-image'
                : `row justify-content-start ${
                    isSignage ? 'signage-image-container' : ''
                  }`
            }
          >
            <img
              src={ClassDivision_7_C}
              alt="class division 7C"
              className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
            />
          </Box>
        );
      }
      case '7E': {
        return (
          <Box
            className={
              !isWithoutLogin && !isSignage
                ? 'class-division-image'
                : `row justify-content-start ${
                    isSignage ? 'signage-image-container' : ''
                  }`
            }
          >
            <img
              src={ClassDivision_7_E}
              alt="class division 7E"
              className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
            />
          </Box>
        );
      }
      case '8': {
        return (
          <Box
            className={
              !isWithoutLogin && !isSignage
                ? 'class-division-image'
                : `row justify-content-start ${
                    isSignage ? 'signage-image-container' : ''
                  }`
            }
          >
            <img
              src={ClassDivision_8}
              alt="class division 8"
              className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
            />
          </Box>
        );
      }
      case '9': {
        return (
          <Box
            className={
              !isWithoutLogin && !isSignage
                ? 'class-division-image'
                : `row justify-content-start ${
                    isSignage ? 'signage-image-container' : ''
                  }`
            }
          >
            <img
              src={ClassDivision_9}
              alt="class division 9"
              className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
            />
          </Box>
        );
      }
      case '9A': {
        return (
          <Box
            className={
              !isWithoutLogin && !isSignage
                ? 'class-division-image'
                : `row justify-content-start ${
                    isSignage ? 'signage-image-container' : ''
                  }`
            }
          >
            <img
              src={ClassDivision_9_A}
              alt="class division 9A"
              className={`${isWithoutLogin ? 'col-auto' : ''} ${className}`}
            />
          </Box>
        );
      }
      default: {
        return null;
      }
    }
  };
  return (
    <Box className={parentClass ?? 'class-division-image'}>
      {getClassDivisionImage()}
    </Box>
  );
};
