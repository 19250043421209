import { Reducer, combineReducers } from 'redux';
import { ILegalsPageState, legalsPageReducer } from './ui';

export interface ILegalsPageUI {
  legalsPage: ILegalsPageState;
}

export interface ILegalsState {
  ui: ILegalsPageUI;
}

const ui = combineReducers({
  legalsPage: legalsPageReducer
});

export const legalsReducer: Reducer<ILegalsState> = combineReducers({
  ui
});
