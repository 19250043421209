import map from 'lodash/map';

import { AuthRepository } from '../../modules/auth/repositories/auth.repository';
import { User } from '../../common/models/user';
import GlobalConfig from '../../globalConfig';
import request from '../../services/request.service';
import { UserAdapter } from '../../common/adapters/user.adapter';
import { getLocalstorage, setLocalstorage } from '../../helper';

export const prepareAuthenticationRequest = (data = {}) => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot('mobile/login'),
  data
});

export const prepareLogoutRequest = (data = {}) => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot('mobile/logout'),
  data
});

export const prepareUserInfoRequest = (user_id: string) => ({
  method: 'GET',
  url: GlobalConfig.getApiUrlFromRoot(`mobile/userDetail/${user_id}`)
});

export const sendDeviceTokenParams = (device_token: string) => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot('mobile/adddevicetoken'),
  data: {
    device_token,
    user_id: AuthRepository.retrieveUserId(),
    device_type: 'web'
  }
});

export const generateOTPParams = (user_id: string) => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot('mobile/sendLogotVerificationCode'),
  data: { user_id }
});

export const verifyOTPParams = (data = {}) => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot('mobile/verifylogout'),
  data
});

export interface IResponse {
  status: number;
  message: string;
}

export interface IAuthResponse extends IResponse {
  token?: string;
  user?: User;
  userId: string;
}

export interface IUserLoginResponse {
  token: string;
  user: User;
  userId: string;
}

export class AuthService {
  private static _computePermissions(permissionsResponse: any): string[] {
    const permissions: string[] = map(
      permissionsResponse ?? [],
      (item: { id: number; name: string }) => {
        return item.name;
      }
    );

    return permissions;
  }

  static async authenticate(params = {}): Promise<IAuthResponse> {
    const response = await request(prepareAuthenticationRequest(params));

    const { data } = response;

    if (
      data.status === 2 &&
      data.message === 'User already login on other device'
    ) {
      setLocalstorage('user_id', data.user_id);

      return {
        status: data.status,
        message: data.message,
        userId: data.user_id
      };
    }

    if (!data || !data.resultData || !data.token) {
      throw new Error('user details not available for logged in user');
    }

    const user: User = UserAdapter.fromServerResponse(data.resultData);

    const { token } = data;

    setLocalstorage('user_id', user.user_id);
    AuthRepository.storeUserId(user.user_id);
    AuthRepository.storeToken(token);

    return {
      status: 1,
      message: 'loggin success',
      token,
      userId: user.user_id,
      user
    };
  }

  static async authenticateWithStored(): Promise<IUserLoginResponse | null> {
    const token: string | null = AuthRepository.retrieveToken();
    const user_id: string | null = AuthRepository.retrieveUserId();

    if (token && user_id) {
      const response = await request(prepareUserInfoRequest(user_id));

      const { data } = response;
      if (!data || !data.status) {
        return null;
      }
      const user: User = UserAdapter.fromServerResponse({
        ...data.resultData,
        subscription_status: !!data.subscription_status
      });
      return {
        token,
        userId: user.user_id,
        user
      };
    }

    return null;
  }

  static async sendDeviceToken(token: string): Promise<any> {
    return await request(sendDeviceTokenParams(token));
  }

  static async logout(params: any): Promise<any> {
    return await request(prepareLogoutRequest(params));
  }

  static async generateOTP(): Promise<IResponse> {
    const userId = getLocalstorage('user_id');
    const response = await request(generateOTPParams(userId));
    return response.data;
  }

  static async verifyOTP(params: any): Promise<IUserLoginResponse> {
    const response = await request(verifyOTPParams(params));
    const { data } = response;

    if (!data || !data.resultData || !data.token) {
      throw new Error('user details not available for logged in user');
    }

    const user: User = UserAdapter.fromServerResponse(data.resultData);

    const { token } = data;
    setLocalstorage('user_id', user.user_id);
    AuthRepository.storeUserId(user.user_id);
    AuthRepository.storeToken(token);

    return {
      token,
      userId: user.user_id,
      user
    };
  }
}
