import { Reducer, combineReducers } from 'redux';
import { IAboutUsPageState, aboutUsPageReducer } from './ui';

export interface IAboutUsPageUI {
  aboutUsPage: IAboutUsPageState;
}

export interface IAboutUsState {
  ui: IAboutUsPageUI;
}

const ui = combineReducers({
  aboutUsPage: aboutUsPageReducer
});

export const aboutUsReducer: Reducer<IAboutUsState> = combineReducers({
  ui
});
