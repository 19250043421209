import { createAction } from 'redux-actions';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  ReportsServices,
  IReportsResponse,
  IReportsParams,
  IUnNumbersParams,
  IUnNumbersResponse,
  ICombinationResponse,
  ICreateReportParams,
  IResponse,
  IReportDetailResponse,
  ICreateReportByClassNameParams,
  IClassDivisionResponse,
  IUnNumberClassDivisionParams,
  IManifestApproveStatusParams,
  IUnloadResponse
} from '../services/api.service';
import { IReport } from '../models/reports-list.model';
import { IUnNumber, IProductInCart } from '../models/un-number.model';
import { IAddImage, IProductValueWithId } from '../reducers/ui';
import {
  IClassDivision,
  IClassDivisionCart
} from '../models/classDivision.model';

export const RETRIEVE_REPORT_LIST__START = 'pdg.report.list.ui.start';
export const retrieveReportsStart = createAction(RETRIEVE_REPORT_LIST__START);

export const RETRIEVE_REPORT_LIST__FAILURE = 'pdg.report.list.ui.failure';
export const retrieveReportsFailure = createAction(
  RETRIEVE_REPORT_LIST__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const RETRIEVE_REPORT_LIST__SUCCESS = 'pdg.report.list.ui.success';
export const retrieveReportsSuccess = createAction(
  RETRIEVE_REPORT_LIST__SUCCESS,
  (reports: IReport[]) => {
    return { reports };
  }
);

export const retrieveReports = (
  params: IReportsParams
): ThunkAction<Promise<IReportsResponse>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(retrieveReportsStart());
      const res = await ReportsServices.retrieveReports(params);
      if (res.status === 1) {
        dispatch(retrieveReportsSuccess(res.data));
      } else {
        throw res;
      }
      return res;
    } catch (err) {
      dispatch(retrieveReportsFailure(err));
      throw err;
    }
  };
};

export const RETRIEVE_REPORT_DETAIL__START = 'pdg.report.detail.ui.start';
export const retrieveReportDetailStart = createAction(
  RETRIEVE_REPORT_DETAIL__START
);

export const RETRIEVE_REPORT_DETAIL__FAILURE = 'pdg.report.detail.ui.failure';
export const retrieveReportDetailFailure = createAction(
  RETRIEVE_REPORT_DETAIL__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const RETRIEVE_REPORT_DETAIL__SUCCESS = 'pdg.report.detail.ui.success';
export const retrieveReportDetailSuccess = createAction(
  RETRIEVE_REPORT_DETAIL__SUCCESS,
  (report: IReport) => {
    return { report };
  }
);

export const retrieveReportDetail = (
  reportId: string
): ThunkAction<Promise<IReportDetailResponse>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(retrieveReportDetailStart());
      const res = await ReportsServices.retrieveReportDetail(reportId);
      if (res.status === 1 || res.data) {
        dispatch(retrieveReportDetailSuccess(res.data as IReport));
      } else {
        throw res;
      }
      return res;
    } catch (err) {
      dispatch(retrieveReportDetailFailure(err));
      throw err;
    }
  };
};

export const RETRIEVE_UN_NUMBER_LIST__START = 'pdg.un.number.list.ui.start';
export const retrieveUnNumbersStart = createAction(
  RETRIEVE_UN_NUMBER_LIST__START
);

export const RETRIEVE_UN_NUMBER_LIST__FAILURE = 'pdg.un.number.list.ui.failure';
export const retrieveUnNumbersFailure = createAction(
  RETRIEVE_UN_NUMBER_LIST__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const RETRIEVE_UN_NUMBER_LIST__SUCCESS = 'pdg.un.number.list.ui.success';
export const retrieveUnNumbersSuccess = createAction(
  RETRIEVE_UN_NUMBER_LIST__SUCCESS,
  (unNumberData: IUnNumber[]) => {
    return { unNumberData };
  }
);

export const retrieveUnNumbers = (
  params: IUnNumbersParams
): ThunkAction<Promise<IUnNumbersResponse>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(retrieveUnNumbersStart());
      const res = await ReportsServices.retrieveUnNumbers(params);
      if (res.status === 1 || res.message === 'Un Number Not Found') {
        dispatch(retrieveUnNumbersSuccess(res.data));
        return res;
      } else {
        throw res;
      }
    } catch (err) {
      dispatch(retrieveUnNumbersFailure(err));
      throw err;
    }
  };
};

export const retrieveUnNumbersWithName = (
  params: IUnNumbersParams
): ThunkAction<Promise<IUnNumbersResponse>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(retrieveUnNumbersStart());
      const res = await ReportsServices.retrieveUnNumbersWithName(params);
      if (res.status === 1 || res.message === 'Un Number Not Found') {
        dispatch(retrieveUnNumbersSuccess(res.data));
        return res;
      } else {
        throw res;
      }
    } catch (err) {
      dispatch(retrieveUnNumbersFailure(err));
      throw err;
    }
  };
};

export const ADD_UN_NUMBERS_INTO_CART = 'pdg.add.un.number.into.cart';
export const addUnNumbersIntoCart = createAction(
  ADD_UN_NUMBERS_INTO_CART,
  (unNumbers: IProductInCart[]) => {
    return { unNumbers };
  }
);

export const ADD_IMAGE_IN_UN_NUMBER_INTO_CART =
  'pdg.add.image.in.un.number.into.cart';
export const addImageInUnNumberIntoCart = createAction(
  ADD_IMAGE_IN_UN_NUMBER_INTO_CART,
  (data: IAddImage) => {
    return data;
  }
);

export const REMOVE_IMAGE_IN_UN_NUMBER_INTO_CART =
  'pdg.remove.image.in.un.number.into.cart';
export const removeImageInUnNumberIntoCart = createAction(
  REMOVE_IMAGE_IN_UN_NUMBER_INTO_CART,
  (data: IAddImage) => {
    return data;
  }
);

export const ADD_PRODUCT_VALUE_IN_UN_NUMBER_INTO_CART =
  'pdg.add.product.value.in.un.number.into.cart';
export const addProductValueInUnNumberIntoCart = createAction(
  ADD_PRODUCT_VALUE_IN_UN_NUMBER_INTO_CART,
  (data: IProductValueWithId) => {
    return data;
  }
);

export const ADD_IMAGE_IN_CLASS_DIVISION_INTO_CART =
  'pdg.add.image.in.class.division.into.cart';
export const addImageInClassDivisionIntoCart = createAction(
  ADD_IMAGE_IN_CLASS_DIVISION_INTO_CART,
  (data: IAddImage) => {
    return data;
  }
);

export const UPDATE_GOODS_IN_THE_CART = 'pdg.update.goods.in.the.cart';
export const updateGoodsInTheCart = createAction(
  UPDATE_GOODS_IN_THE_CART,
  (unNumbersInCart: IProductInCart[]) => {
    return { unNumbersInCart };
  }
);

export const RETRIEVE_COMBINATION_STATUS__START =
  'pdg.check.combination.status.ui.start';
export const retrieveCombinationEvaluationStart = createAction(
  RETRIEVE_COMBINATION_STATUS__START
);

export const RETRIEVE_COMBINATION_STATUS__FAILURE =
  'pdg.check.combination.status.ui.failure';
export const retrieveCombinationEvaluationFailure = createAction(
  RETRIEVE_COMBINATION_STATUS__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const RETRIEVE_COMBINATION_STATUS__SUCCESS =
  'pdg.check.combination.status.ui.success';
export const retrieveCombinationEvaluationSuccess = createAction(
  RETRIEVE_COMBINATION_STATUS__SUCCESS,
  (data: ICombinationResponse) => {
    return data;
  }
);

export const retrieveCombinationEvaluation = (
  unNumbers: IUnNumberClassDivisionParams[]
): ThunkAction<Promise<ICombinationResponse>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(retrieveCombinationEvaluationStart());
      if (unNumbers.length !== 0) {
        const res = await ReportsServices.retrieveCombinationEvaluation(
          unNumbers
        );
        if (res.status === 1) {
          dispatch(retrieveCombinationEvaluationSuccess(res));
          return res;
        } else {
          throw res;
        }
      } else {
        const res = {
          status: 1,
          message: 'success'
        };
        dispatch(retrieveCombinationEvaluationSuccess(res));
        return res;
      }
    } catch (err) {
      dispatch(retrieveCombinationEvaluationFailure(err));
      throw err;
    }
  };
};

export const CREATE_REPORT__START = 'pdg.create.report.ui.start';
export const createReportStart = createAction(CREATE_REPORT__START);

export const CREATE_REPORT__FAILURE = 'pdg.create.report.ui.failure';
export const createReportFailure = createAction(
  CREATE_REPORT__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const CREATE_REPORT__SUCCESS = 'pdg.create.report.ui.success';
export const createReportSuccess = createAction(CREATE_REPORT__SUCCESS);

export const createReport = (
  operatorId: string,
  params: ICreateReportParams
): ThunkAction<Promise<IResponse>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(createReportStart());
      const res = await ReportsServices.createReport(operatorId, params);
      if (res.status === 1) {
        dispatch(createReportSuccess());
        return res;
      } else {
        throw res;
      }
    } catch (err) {
      dispatch(createReportFailure(err));
      throw err;
    }
  };
};

export const DELETE_REPORT__START = 'pdg.report.delete.ui.start';
export const deleteReportStart = createAction(DELETE_REPORT__START);

export const DELETE_REPORT__FAILURE = 'pdg.report.delete.ui.failure';
export const deleteReportFailure = createAction(
  DELETE_REPORT__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const DELETE_REPORT__SUCCESS = 'pdg.report.delete.ui.success';
export const deleteReportSuccess = createAction(DELETE_REPORT__SUCCESS);

export const deleteReport = (
  report_id: string
): ThunkAction<Promise<IResponse>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(deleteReportStart());
      const res = await ReportsServices.deleteReport(report_id);
      if (res.status === 1) {
        dispatch(deleteReportSuccess());
      } else {
        throw res;
      }
      return res;
    } catch (err) {
      dispatch(deleteReportFailure(err));
      throw err;
    }
  };
};

export const UPDATE_GOODS_IN_THE_CART_BY_CLASS_NAME =
  'pdg.update.goods.in.the.cart.by.class.name';
export const updateGoodsInTheCartByClassName = createAction(
  UPDATE_GOODS_IN_THE_CART_BY_CLASS_NAME,
  (classDivisionsInCart: IClassDivisionCart[]) => {
    return { classDivisionsInCart };
  }
);

export const CREATE_REPORT_BY_CLASS_NAME__START =
  'pdg.create.report.by.className.ui.start';
export const createReportByClassNameStart = createAction(
  CREATE_REPORT_BY_CLASS_NAME__START
);

export const CREATE_REPORT_BY_CLASS_NAME__FAILURE =
  'pdg.create.report.by.className.ui.failure';
export const createReportByClassNameFailure = createAction(
  CREATE_REPORT_BY_CLASS_NAME__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const CREATE_REPORT_BY_CLASS_NAME__SUCCESS =
  'pdg.create.report.by.className.ui.success';
export const createReportByClassNameSuccess = createAction(
  CREATE_REPORT_BY_CLASS_NAME__SUCCESS
);

export const createReportByClassName = (
  params: ICreateReportByClassNameParams
): ThunkAction<Promise<IResponse>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(createReportByClassNameStart());
      const res = await ReportsServices.createReportByClassName(params);
      if (res.status === 1) {
        dispatch(createReportByClassNameSuccess());
        return res;
      } else {
        throw res;
      }
    } catch (err) {
      dispatch(createReportByClassNameFailure(err));
      throw err;
    }
  };
};

export const RETRIEVE_CLASS_DIVISION_LIST__START =
  'pdg.get.class.division.list.ui.start';
export const retrieveClassDivisionsStart = createAction(
  RETRIEVE_CLASS_DIVISION_LIST__START
);

export const RETRIEVE_CLASS_DIVISION_LIST__FAILURE =
  'pdg.get.class.division.list.ui.failure';
export const retrieveClassDivisionsFailure = createAction(
  RETRIEVE_CLASS_DIVISION_LIST__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const RETRIEVE_CLASS_DIVISION_LIST__SUCCESS =
  'pdg.get.class.division.list.ui.success';
export const retrieveClassDivisionsSuccess = createAction(
  RETRIEVE_CLASS_DIVISION_LIST__SUCCESS,
  (classDivisions: IClassDivision[]) => {
    return { classDivisions };
  }
);

export const retrieveClassDivisions = (): ThunkAction<
  Promise<IClassDivisionResponse>,
  {},
  {},
  AnyAction
> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(retrieveClassDivisionsStart());
      const res = await ReportsServices.retrieveClassDivisions();
      if (res.status === 1) {
        dispatch(retrieveClassDivisionsSuccess(res.data));
        return res;
      } else {
        throw res;
      }
    } catch (err) {
      dispatch(createReportByClassNameFailure(err));
      throw err;
    }
  };
};

export const RESET_REPORT_STATE = 'pdg.get.reset.report.state.ui.success';
export const resetReportState = createAction(RESET_REPORT_STATE);

export const UPDATE_MANIFEST_APPROVE_STATUS__START =
  'pdg.report.manifest.approve.status.ui.start';
export const updateManifestApproveStatusStart = createAction(
  UPDATE_MANIFEST_APPROVE_STATUS__START
);

export const UPDATE_MANIFEST_APPROVE_STATUS__FAILURE =
  'pdg.report.manifest.approve.status.ui.failure';
export const updateManifestApproveStatusFailure = createAction(
  UPDATE_MANIFEST_APPROVE_STATUS__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const UPDATE_MANIFEST_APPROVE_STATUS__SUCCESS =
  'pdg.report.manifest.approve.status.ui.success';
export const updateManifestApproveStatusSuccess = createAction(
  UPDATE_MANIFEST_APPROVE_STATUS__SUCCESS
);

export const updateManifestApproveStatus = (
  params: IManifestApproveStatusParams
): ThunkAction<Promise<IResponse>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(updateManifestApproveStatusStart());
      const res = await ReportsServices.updateManifestApproveStatus(params);
      if (res.status === 1) {
        dispatch(updateManifestApproveStatusSuccess());
      } else {
        throw res;
      }
      return res;
    } catch (err) {
      dispatch(updateManifestApproveStatusFailure(err));
      throw err;
    }
  };
};

export const UNLOAD_PRODUCT__START = 'pdg.report.unload.product.ui.start';
export const unloadProductStart = createAction(UNLOAD_PRODUCT__START);

export const UNLOAD_PRODUCT__FAILURE = 'pdg.report.unload.product.ui.failure';
export const unloadProductFailure = createAction(
  UNLOAD_PRODUCT__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const UNLOAD_PRODUCT__SUCCESS = 'pdg.report.unload.product.ui.success';
export const unloadProductSuccess = createAction(
  UNLOAD_PRODUCT__SUCCESS,
  (reportId: number, productId: number, current_status: boolean) => {
    return {
      reportId,
      productId,
      current_status
    };
  }
);

export const unloadProduct = (
  reportId: number,
  productId: number
): ThunkAction<Promise<IUnloadResponse>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(unloadProductStart());
      const res = await ReportsServices.unloadProduct(reportId, productId);
      if (res.status === 1) {
        dispatch(unloadProductSuccess(reportId, productId, res.current_status));
      } else {
        throw res;
      }
      return res;
    } catch (err) {
      dispatch(unloadProductFailure(err));
      throw err;
    }
  };
};
