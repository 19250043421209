/* istanbul ignore file */
import React, { FunctionComponent } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Box } from '@chakra-ui/core';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import Logo2 from '../../../assets/images/logo2.png';
import LeftBanner from '../../../assets/images/leftbanner.png';
import VerificationComponent from '../components/verification.component';
import {
  ROUTES,
  STATUS,
  ERROR_MESSAGE,
  SUCCESS_MESSAGE
} from '../../../constant';
import { IAppState } from '../../../store';
import { resendVerificationCode, sendVerificationCode } from '../actions';
import {
  getResendVerificationCodeAPIStatus,
  getSendVerificationCodeAPIStatus
} from '../reducers/ui';
import { getMessage } from '../../../helper';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  t: any;
  resendVerificationCodeAPIStatus: STATUS;
  sendVerificationCodeAPIStatus: STATUS;
  resendVerificationCode: typeof resendVerificationCode;
  sendVerificationCode: typeof sendVerificationCode;
}

export const Verification: FunctionComponent<IProps> = (props: IProps) => {
  const {
    className,
    resendVerificationCode,
    sendVerificationCode,
    toastManager,
    history
  } = props;

  const _resendVerificationCode = async () => {
    try {
      await resendVerificationCode();
      toastManager.add(getMessage(SUCCESS_MESSAGE.RESEND_VERIFICATION_CODE), {
        appearance: 'success',
        autoDismiss: true
      });
    } catch (err) {
      const message =
        err?.errorObj?.message ??
        err?.message ??
        getMessage(ERROR_MESSAGE.RESEND_VERIFICATION_CODE_FAILED);
      toastManager.add(message, { appearance: 'error' });
      console.log(':: _resendVerificationCode err ', err);
    }
  };
  const _sendVerificationCode = async (code: string) => {
    try {
      await sendVerificationCode(code);
      toastManager.add(getMessage(SUCCESS_MESSAGE.VERIFICATION_CODE), {
        appearance: 'success',
        autoDismiss: true
      });
      history.push(ROUTES.SUBSCRIPTION);
    } catch (err) {
      const message =
        err?.errorObj?.message ??
        err?.message ??
        getMessage(ERROR_MESSAGE.VERIFICATION_CODE_INVALID);
      toastManager.add(message, { appearance: 'error', autoDismiss: true });
      console.log(':: _sendVerificationCode err ', err);
    }
  };
  return (
    <div className={className}>
      <Box className="signupAr">
        <Box className="lftImage">
          <a href={ROUTES.HOME}>
            <img src={Logo2} className="innLogo" alt="pdg" />
          </a>
          <img src={LeftBanner} alt="pdg" />
        </Box>
        <Box className="_snInfo">
          <VerificationComponent
            {...props}
            resendVerificationCode={_resendVerificationCode}
            sendVerificationCode={(code: string) => {
              _sendVerificationCode(code);
            }}
          />
        </Box>
      </Box>
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: IAppState) => {
  const resendVerificationCodeAPIStatus = getResendVerificationCodeAPIStatus(
    state
  );
  const sendVerificationCodeAPIStatus = getSendVerificationCodeAPIStatus(state);
  return {
    resendVerificationCodeAPIStatus,
    sendVerificationCodeAPIStatus
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  return bindActionCreators(
    {
      resendVerificationCode,
      sendVerificationCode
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(withToastManager(withRouter(Verification))));
