import React, { FunctionComponent, useState } from 'react';
import { Box } from '@chakra-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import UKFlag from '../../../assets/images/flag-en-uk.png';
import USFlag from '../../../assets/images/flag-en-us.png';
import AUFlag from '../../../assets/images/flag-en-au.png';
import NZLFlag from '../../../assets/images/flag-nzl.png';
import INFlag from '../../../assets/images/flag-en-in.png';
interface IProps {
  selectedLanguage: string;
  setLanguage: (lang: string) => void;
}

export const SelectLanguage: FunctionComponent<IProps> = ({
  selectedLanguage,
  setLanguage
}) => {
  return (
    <>
      <Box className="row justify-content-center flag-list">
        <Box
          className="_dwnArr col-auto"
          onClick={() => {
            setLanguage('en');
          }}
        >
          <i>
            <img
              className={selectedLanguage === 'en' ? 'selected-flag' : ''}
              src={USFlag}
              alt="Logo"
            />
          </i>
        </Box>
        <Box
          className="_dwnArr col-auto"
          onClick={() => {
            setLanguage('au');
          }}
        >
          <i>
            <img
              className={selectedLanguage === 'au' ? 'selected-flag' : ''}
              src={AUFlag}
              alt="Logo"
            />
          </i>
        </Box>
        <Box
          className="_dwnArr col-auto"
          onClick={() => {
            setLanguage('uk');
          }}
        >
          <i>
            <img
              className={selectedLanguage === 'uk' ? 'selected-flag' : ''}
              src={UKFlag}
              alt="Logo"
            />
          </i>
        </Box>
        <Box
          className="_dwnArr col-auto"
          onClick={() => {
            setLanguage('in');
          }}
        >
          <i>
            <img
              className={selectedLanguage === 'in' ? 'selected-flag' : ''}
              src={INFlag}
              alt="Logo"
            />
          </i>
        </Box>
        <Box
          className="_dwnArr col-auto"
          onClick={() => {
            setLanguage('nzl');
          }}
        >
          <i>
            <img
              className={selectedLanguage === 'nzl' ? 'selected-flag' : ''}
              src={NZLFlag}
              alt="Logo"
            />
          </i>
        </Box>
      </Box>
    </>
  );
};

export const SelectLanguageMobile: FunctionComponent<IProps> = ({
  selectedLanguage,
  setLanguage
}) => {
  const [isShowMenu, setIsShowMenu] = useState(false);
  const showSelectedLanguage = () => {
    switch (selectedLanguage) {
      case 'en': {
        return (
          <Box className="_dwnArr">
            <i>
              <img src={USFlag} alt="Logo" />
            </i>{' '}
            <span>US: English</span>
          </Box>
        );
      }
      case 'uk': {
        return (
          <Box className="_dwnArr">
            <i>
              <img src={UKFlag} alt="Logo" />
            </i>{' '}
            <span>UK: English</span>
          </Box>
        );
      }
      case 'au': {
        return (
          <Box className="_dwnArr">
            <i>
              <img src={AUFlag} alt="Logo" />
            </i>{' '}
            <span>AU: English</span>
          </Box>
        );
      }
      case 'in': {
        return (
          <Box className="_dwnArr">
            <i>
              <img src={INFlag} alt="Logo" />
            </i>{' '}
            <span>IN: English</span>
          </Box>
        );
      }
      default: {
        return (
          <Box className="_dwnArr">
            <i>
              <img src={UKFlag} alt="Logo" />
            </i>{' '}
            US: English
          </Box>
        );
      }
    }
  };
  return (
    <>
      <a
        href="#"
        onClick={() => {
          setIsShowMenu(true);
        }}
        style={{
          marginRight: '15px'
        }}
      >
        {showSelectedLanguage()}
      </a>
      <Box className={`mbMenu ${isShowMenu ? 'show' : ''}`}>
        <span
          className="cross"
          onClick={() => {
            setIsShowMenu(false);
          }}
        >
          <CloseIcon />
        </span>
        <ul>
          <li
            onClick={() => {
              setIsShowMenu(false);
              setLanguage('en');
            }}
          >
            <a href="#">
              <i>
                <img
                  className={selectedLanguage === 'en' ? 'selected-flag' : ''}
                  src={USFlag}
                  alt="Logo"
                />
              </i>
            </a>
          </li>
          <li
            onClick={() => {
              setIsShowMenu(false);
              setLanguage('au');
            }}
          >
            <a href="#">
              <i>
                <img
                  className={selectedLanguage === 'au' ? 'selected-flag' : ''}
                  src={AUFlag}
                  alt="Logo"
                />
              </i>
            </a>
          </li>
          <li
            onClick={() => {
              setIsShowMenu(false);
              setLanguage('uk');
            }}
          >
            <a href="#">
              <i>
                <img
                  className={selectedLanguage === 'uk' ? 'selected-flag' : ''}
                  src={UKFlag}
                  alt="Logo"
                />
              </i>
            </a>
          </li>
          <li
            onClick={() => {
              setIsShowMenu(false);
              setLanguage('in');
            }}
          >
            <a href="#">
              <i>
                <img
                  className={selectedLanguage === 'in' ? 'selected-flag' : ''}
                  src={INFlag}
                  alt="Logo"
                />
              </i>
            </a>
          </li>
        </ul>
      </Box>
    </>
  );
};
