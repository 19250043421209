/* istanbul ignore file */
import React, { FunctionComponent, useState, useEffect } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Box, Alert } from '@chakra-ui/core';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { FormikProps, Formik } from 'formik';

import { IAppState } from '../../../store';
import {
  STATUS,
  ERROR_MESSAGE,
  SUCCESS_MESSAGE
} from '../../../constant';
import ClassDivisionListItem from '../components/ClassDivisionListItem.component';
import { H3 } from '../../../common/components';
import { FullPageLoader } from '../../../common/components/Loader.component';
import { ErrorAlert } from '../../../common/components/ErrorAlert.component';
import {
  retrieveUnNumbers,
  updateGoodsInTheCart,
  retrieveCombinationEvaluation,
  addImageInUnNumberIntoCart,
  addProductValueInUnNumberIntoCart,
  removeImageInUnNumberIntoCart,
  createReport,
  resetReportState,
  retrieveUnNumbersWithName
} from '../actions';
import { getMessage } from '../../../helper';
import {
  getUnNumbersAPIStatus,
  getUnNumbersFromCart,
  getCombinationAPIStatus,
  getCombinationData,
  ICombinationData,
  getCreateReportAPIStatus,
  getClassDivisions
} from '../reducers/ui';
import {
  IUnNumberClassDivisionParams,
  IUnNumbersParams
} from '../services/api.service';
import { IProductInCart, IUnNumber } from '../models/un-number.model';
import UnNumberListItem from '../components/UnNumberListItem.component';
import ReportIcon from '../../../assets/images/report.png';
import UnNumberCart from '../components/UnNumberCart.component';
import { retrieveReportDetail } from '../actions';
import { getReportDetailAPIStatus } from '../reducers/ui';
import {
  getReportDetailSelector,
  getUnsSelector
} from '../selectors/index.page.selector';
import { IReport, REPORT_TYPE } from '../models/reports-list.model';
import { IClassDivision } from '../models/classDivision.model';
import { User, USER_ROLE } from '../../../common/models/user';
import { getLoggedInUserDetails } from '../../auth/selectors/auth.selectors';
import { retrieveOpertorsList } from '../../../common/actions';
import { getOperatorListSelector } from '../../../common/reducers/operator.reducer';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  unNumbers: IUnNumber[] | null;
  unNumbersInCart: IProductInCart[];
  classDivisions: IClassDivision[];
  retrieveUnNumbersAPIStatus: STATUS;
  retrieveCombinationAPIStatus: STATUS;
  createReportAPIStatus: STATUS;
  combinationData: ICombinationData;
  report: IReport | null;
  retrieveReportDetailAPIStatus: STATUS;
  user: User;
  operatorList: User[];
  t: any;
  removeImageInUnNumberIntoCart: typeof removeImageInUnNumberIntoCart;
  retrieveOpertorsList: typeof retrieveOpertorsList;
  retrieveUnNumbersWithName: typeof retrieveUnNumbersWithName;
  createReport: typeof createReport;
  addImageInUnNumberIntoCart: typeof addImageInUnNumberIntoCart;
  retrieveUnNumbers: typeof retrieveUnNumbers;
  updateGoodsInTheCart: typeof updateGoodsInTheCart;
  retrieveCombinationEvaluation: typeof retrieveCombinationEvaluation;
  retrieveReportDetail: typeof retrieveReportDetail;
  resetReportState: typeof resetReportState;
  addProductValueInUnNumberIntoCart: typeof addProductValueInUnNumberIntoCart;
}

interface IFormValue {
  searchTerm: string;
  searchType: string;
  packagingType?: string;
  mesurement?: string;
  quantity?: number;
}

interface IProductValues {
  searchType: string;
  packagingType?: string;
  mesurement?: string;
  quantity?: number;
}

export const AddReport: FunctionComponent<IProps> = (props: IProps) => {
  const {
    className,
    unNumbers,
    user,
    retrieveUnNumbersAPIStatus,
    createReportAPIStatus,
    classDivisions,
    retrieveUnNumbers,
    unNumbersInCart,
    retrieveUnNumbersWithName,
    updateGoodsInTheCart,
    retrieveCombinationAPIStatus,
    retrieveReportDetailAPIStatus,
    toastManager,
    retrieveCombinationEvaluation,
    retrieveReportDetail,
    resetReportState,
    retrieveOpertorsList,
    match,
    t
  } = props;
  const { params } = match;
  const reportId = (params as any).report_id;
  const operatorId = (params as any).operatorId as string;
  const initialValue: IProductValues = {
    searchType: 'unno',
    mesurement: undefined,
    packagingType: undefined,
    quantity: undefined
  };
  const [initialProducts, setInitialProducts] = useState('');
  const [productValues, setProductValues] = useState<IProductValues>(
    initialValue
  );
  const [isCart, setIsCart] = useState(false);
  const [showClass, setShowClass] = useState<IClassDivision[]>([]);

  const _resetReportState = async () => {
    try {
      await resetReportState();
    } catch (err) {
      console.log(':: _resetReportState err ', err);
    }
  };
  useEffect(() => {
    return () => {
      _resetReportState();
    };
  }, []);

  const _searchUnNumber = async (unNumber: string, isToggleCart: boolean) => {
    try {
      if (unNumber) {
        const params: IUnNumbersParams = {
          unNumber
        };
        if (isToggleCart) setIsCart(false);
        const res: any = await retrieveUnNumbers(params);
        return res.data;
      }
    } catch (err) {
      console.log(':: _searchUnNumber err ', err);
    }
  };
  const _searchChemicalName = async (unName: string, isToggleCart: boolean) => {
    try {
      if (unName.length < 3) return null;
      if (unName) {
        const params: IUnNumbersParams = {
          unName
        };
        if (isToggleCart) setIsCart(false);
        const res: any = await retrieveUnNumbersWithName(params);
        return res.data;
      }
    } catch (err) {
      console.log(':: _searchUnNumber err ', err);
    }
  };
  const _searchClassName = async (unName: string, isToggleCart: boolean) => {
    try {
      if (unName) {
        const classes = classDivisions.filter((datum) =>
          datum.class_division === unName
        );
        if (isToggleCart) setIsCart(false);
        setShowClass([...classes]);
      }
    } catch (err) {
      console.log(':: _searchUnNumber err ', err);
    }
  };

  const _searchProduct = async (values: IFormValue, isToggleCart: boolean) => {
    setProductValues({
      searchType: values.searchType,
      mesurement: values.mesurement,
      packagingType: values.packagingType,
      quantity: values.quantity
    });
    if (values.searchType === 'unno') {
      _searchUnNumber(values.searchTerm, isToggleCart);
    } else if (values.searchType === 'chemical') {
      _searchChemicalName(values.searchTerm, isToggleCart);
    } else {
      _searchClassName(values.searchTerm, isToggleCart);
    }
  };

  const _addUnNumberIntoCart = async (unNumber: IProductInCart) => {
    try {
      const unNumbers = unNumbersInCart;
      unNumbers.push(unNumber);
      await updateGoodsInTheCart(unNumbers);
      toastManager.add(getMessage(SUCCESS_MESSAGE.CART_UPDATE), {
        appearance: 'success',
        autoDismiss: true
      });
    } catch (err) {
      console.log(':: _addUnNumberIntoCart err ', err);
      toastManager.add(getMessage(ERROR_MESSAGE.CART_UPDATE), {
        appearance: 'error',
        autoDismiss: true
      });
    }
  };
  const _removeUnNumberFromCart = async (
    unNumber: IProductInCart,
    isCart?: boolean
  ) => {
    try {
      let unNumbers: IProductInCart[] = [];
      if (unNumber.un_no && unNumber.un_no !== '') {
        unNumbers = unNumbersInCart.filter(
          (item: IProductInCart) =>
            !!(!item.un_no && item.un_no === '') ||
            !!(item.un_no !== unNumber.un_no)
        );
      } else {
        unNumbers = unNumbersInCart.filter(
          (item: IProductInCart) =>
            !!(item.un_no && item.un_no !== '') ||
            !!(item.class_division_value !== unNumber.class_division_value)
        );
      }
      await updateGoodsInTheCart(unNumbers);
      if (isCart) {
        const params: IUnNumberClassDivisionParams[] = unNumbers.map(
          (datum: IProductInCart) => ({
            un_no: datum.un_no ? Number(datum.un_no) : 0,
            class_division_value: datum.class_division_value,
            tunnel_code: datum.tunnel_code ?? '',
            type: datum.type
          })
        );
        await retrieveCombinationEvaluation(params);
      }
    } catch (err) {
      console.log(':: _removeUnNumberFromCart err ', err);
      if (isCart) {
        toastManager.add(getMessage(ERROR_MESSAGE.CHECK_COMBINATION), {
          appearance: 'error',
          autoDismiss: true
        });
      } else {
        toastManager.add(getMessage(ERROR_MESSAGE.CART_UPDATE), {
          appearance: 'error',
          autoDismiss: true
        });
      }
    }
  };

  const _addUnNumbersIntoCart = async (unNumbers: IProductInCart[]) => {
    try {
      await updateGoodsInTheCart(unNumbers);
    } catch (err) {
      console.log(':: _addUnNumbersIntoCart err ', err);
      toastManager.add(getMessage(ERROR_MESSAGE.CART_UPDATE), {
        appearance: 'error',
        autoDismiss: true
      });
    }
  };

  const _retrieveReportDetail = async () => {
    try {
      const productsData: IProductInCart[] = [];
      const res: any = await retrieveReportDetail(reportId);
      if (res?.data?.product?.length) {
        const products = res?.data?.product ?? [];
        for (let i = 0; i < products.length; i++) {
          if (products[i].type === 'unno') {
            const unData = await _searchUnNumber(products[i].un_no, false);
            if (
              unData?.length &&
              !productsData.find((datum) => datum.un_no === unData[0].un_no)
            ) {
              await productsData.push({
                class_division_value: unData[0].class_division_value,
                name_description: unData[0].name_description,
                class_division: unData[0].class_division,
                un_no: unData[0].un_no,
                tunnel_code: unData[0].tunnel_code,
                type: products[i].type,
                images: products[i].images.map(
                  (datum: { img: string }) => datum.img
                ),
                packagingType: products[i].packagingType,
                mesurement: products[i].mesurement,
                quantity: products[i].quantity
              });
            }
          } else if (products[i].type === 'class') {
            const index = classDivisions.findIndex(
              (datumClass: IClassDivision) =>
                !!(datumClass.class_division === products[i].class)
            );
            if (index !== -1) {
              await productsData.push({
                ...classDivisions[index],
                type: products[i].type,
                name_description: products[i].name_description,
                tunnel_code: products[i].tunnel_code,
                images: products[i].images.map(
                  (datum: { img: string }) => datum.img
                ),
                packagingType: products[i].packagingType,
                mesurement: products[i].mesurement,
                quantity: products[i].quantity
              });
            }
          } else {
            const unData = await _searchChemicalName(
              products[i].name_description,
              false
            );
            if (
              unData?.length &&
              !productsData.find((datum) => datum.un_no === unData[0].un_no)
            ) {
              await productsData.push({
                type: products[i].type,
                class_division_value: unData[0].class_division_value,
                name_description: unData[0].name_description,
                class_division: unData[0].class_division,
                un_no: unData[0].un_no,
                tunnel_code: unData[0].tunnel_code,
                images: products[i].images.map(
                  (datum: { img: string }) => datum.img
                ),
                packagingType: products[i].packagingType,
                mesurement: products[i].mesurement,
                quantity: products[i].quantity
              });
            }
          }
        }
        await _addUnNumbersIntoCart(productsData);
        if (!initialProducts) {
          setInitialProducts(JSON.stringify(productsData));
        }
        setIsCart(true);
      }
    } catch (err) {
      console.log(':: _retrieveReportDetail err ', err);
    }
  };

  useEffect(() => {
    if (reportId) {
      _retrieveReportDetail();
    }
  }, [reportId]);

  const _retrieveOpertorsList = async () => {
    try {
      await retrieveOpertorsList();
    } catch (err) {
      console.log(':: _retrieveOpertorsList err ', err);
    }
  };

  useEffect(() => {
    if (user.role !== USER_ROLE.OPERATOR) {
      _retrieveOpertorsList();
    }
  }, [user.role]);

  return (
    <Box className={className}>
      <section className="dbPrWid">
        {/* {user.role !== USER_ROLE.OPERATOR ? (
          <Box className="row justify-content-end" mb="md">
            <Box className="col-auto">
              <select
                className="form-control"
                name="operator"
                value={operatorId}
                onChange={(event) => {
                  if (event.target.value) {
                    const reportDetailUrl = createUrl(
                      ROUTES.CHECK_DANGEROUS_GOODS_SAFETY_REQUIREMENTS,
                      undefined,
                      {
                        operatorId: event.target.value
                      }
                    );
                    history.push(reportDetailUrl);
                  } else {
                    const reportDetailUrl = createUrl(
                      ROUTES.CHECK_DANGEROUS_GOODS_SAFETY_REQUIREMENTS
                    );
                    history.push(reportDetailUrl);
                  }
                }}
              >
                <option value="">Select Operator</option>
                {user.role === USER_ROLE.MAIN ? (
                  <option key={user.user_id} value={user.user_id}>
                    {user.name} (Self)
                  </option>
                ) : null}
                {operatorList.map((datum) => (
                  <option key={datum.user_id} value={datum.user_id}>
                    {datum.name}
                  </option>
                ))}
              </select>
            </Box>
          </Box>
        ) : null} */}
        <Box className="container">
          <Formik
            enableReinitialize
            initialValues={{
              searchTerm: '',
              searchType: productValues.searchType,
              mesurement: productValues.mesurement ?? '',
              packagingType: productValues.packagingType ?? '',
              quantity: productValues.quantity
            }}
            onSubmit={(values: IFormValue) => {
              _searchProduct(values, true);
            }}
            component={(formikProps: FormikProps<IFormValue>) => {
              return (
                <Box
                  className="row jusity-content-between"
                  as="form"
                  onSubmit={formikProps.handleSubmit}
                >
                  <Box className="col-12 form-group">
                    <select
                      className="form-control"
                      name="searchType"
                      value={formikProps.values.searchType}
                      onChange={(event) => {
                        formikProps.setFieldValue(
                          'searchType',
                          event.target.value
                        );
                      }}
                    >
                      <option value="unno">UN Number</option>
                      <option value="class">Class Name</option>
                      <option value="chemical">Chemical Name</option>
                    </select>
                  </Box>
                  <Box className="col-sm-12 col-md-5 col-lg-5 form-group">
                    {formikProps.values.searchType === 'class' ? (
                      <select
                        className="form-control"
                        name="searchTerm"
                        value={formikProps.values.searchTerm ?? ''}
                        onChange={(event) => {
                          formikProps.setFieldValue(
                            'searchTerm',
                            event.target.value
                          );
                        }}
                        style={{ width: '100%', height: '46px' }}
                      >
                        <option value="">Select Class</option>
                        {classDivisions.map((datum) => (
                          <option
                            value={datum.class_division}
                            key={datum.class_division}
                          >
                            Class: {datum.class_division}-{datum.description}
                          </option>
                        ))}
                      </select>
                    ) : null}
                    {formikProps.values.searchType !== 'class' ? (
                      <input
                        type="text"
                        className="form-control"
                        value={formikProps.values.searchTerm}
                        maxLength={
                          formikProps.values.searchType === 'unno' ? 4 : 20
                        }
                        placeholder={`Enter ${
                          formikProps.values.searchType === 'unno'
                            ? 'UN Number'
                            : 'Chemical Name'
                        }`}
                        onChange={(event) => {
                          formikProps.setFieldValue(
                            'searchTerm',
                            event.target.value
                          );
                        }}
                        style={{ width: '100%', padding: '22px' }}
                      />
                    ) : null}
                  </Box>
                  <Box className="col-sm-12 col-md-5 col-lg-5 form-group">
                    <select
                      className="form-control"
                      name="packagingType"
                      value={formikProps.values.packagingType ?? ''}
                      onChange={(event) => {
                        formikProps.setFieldValue(
                          'packagingType',
                          event.target.value
                        );
                      }}
                      style={{ width: '100%', height: '46px' }}
                    >
                      <option value="">Select Packaging</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                    </select>
                  </Box>
                  <Box className="col-sm-12 col-md-2 col-lg-2 form-group">
                    <Box
                      onClick={() => {
                        setIsCart(!isCart);
                      }}
                      title="Goods"
                      className="d-flex justify-content-start align-items-center db-bx-2"
                      style={{ width: '100%', padding: '10px' }}
                    >
                      <Box className="mr-2">
                        <img src={ReportIcon} alt="icon" />
                      </Box>
                      <span>{unNumbersInCart.length}</span>
                    </Box>
                  </Box>
                  <Box className="col-sm-12 col-md-5 col-lg-5 form-group">
                    <select
                      className="form-control"
                      name="mesurement"
                      value={formikProps.values.mesurement ?? ''}
                      onChange={(event) => {
                        formikProps.setFieldValue(
                          'mesurement',
                          event.target.value
                        );
                      }}
                      style={{ width: '100%', height: '46px' }}
                    >
                      <option value="">Select Measurement</option>
                      <option value="ltr">Ltr</option>
                      <option value="kg">KG</option>
                    </select>
                  </Box>
                  <Box className="col-sm-12 col-md-5 col-lg-5 form-group">
                    <input
                      type="number"
                      className="form-control"
                      value={formikProps.values.quantity ?? ''}
                      maxLength={4}
                      min="0"
                      placeholder="Enter Quantity"
                      onChange={(event) => {
                        formikProps.setFieldValue(
                          'quantity',
                          event.target.value
                        );
                      }}
                      style={{ width: '100%', padding: '22px' }}
                    />
                  </Box>
                  <Box className="col-sm-12 col-md-2 col-lg-2">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      onSubmit={() => {
                        _searchProduct(formikProps.values, true);
                      }}
                      style={{ width: '100%' }}
                    >
                      {t('SEARCH')}
                    </button>
                  </Box>
                </Box>
              );
            }}
          />
          <Box mt="md">
            {retrieveUnNumbersAPIStatus === STATUS.LOADING ||
            retrieveCombinationAPIStatus === STATUS.LOADING ||
            retrieveReportDetailAPIStatus === STATUS.LOADING ||
            createReportAPIStatus === STATUS.LOADING ? (
              <Box>
                <FullPageLoader />
              </Box>
            ) : null}
            {!isCart &&
            retrieveUnNumbersAPIStatus === STATUS.FAILURE &&
            productValues.searchType !== 'class' ? (
              <Box>
                <ErrorAlert
                  errorMessage={getMessage(ERROR_MESSAGE.DATA_FAILURE)}
                />
              </Box>
            ) : null}
            {!isCart &&
            productValues.searchType === 'class' &&
            (!showClass || !showClass.length) ? (
              <Box mt="md">
                <Alert mb="xs" status="warning">
                  {getMessage(ERROR_MESSAGE.NO_MATCHING_RESULT)}
                </Alert>
              </Box>
            ) : null}
            {!isCart &&
            retrieveUnNumbersAPIStatus === STATUS.SUCCESS &&
            productValues.searchType !== 'class' &&
            (!unNumbers || !unNumbers.length) ? (
              <Box mt="md">
                <Alert mb="xs" status="warning">
                  {getMessage(ERROR_MESSAGE.NO_MATCHING_RESULT)}
                </Alert>
              </Box>
            ) : null}
            {!isCart &&
            showClass &&
            showClass.length &&
            productValues.searchType === 'class'
              ? showClass.map((datum: IClassDivision) => (
                  <>
                    <hr />
                    <H3 pt="md">Search Result</H3>
                    <ClassDivisionListItem
                      {...props}
                      key={`cart-${datum.class_division}`}
                      classDivision={datum}
                      addClassDivision={() => {
                        _addUnNumberIntoCart({
                          class_division_value: datum.class_division_value,
                          name_description: datum.description,
                          class_division: datum.class_division,
                          un_no: '',
                          tunnel_code: '',
                          type: productValues.searchType,
                          images: [],
                          packagingType: productValues.packagingType,
                          mesurement: productValues.mesurement,
                          quantity: productValues.quantity
                        });
                      }}
                      removeClassDivision={() => {
                        _removeUnNumberFromCart({
                          class_division_value: datum.class_division_value,
                          name_description: datum.description,
                          class_division: datum.class_division,
                          un_no: '',
                          tunnel_code: '',
                          type: productValues.searchType,
                          images: [],
                          packagingType: productValues.packagingType,
                          mesurement: productValues.mesurement,
                          quantity: productValues.quantity
                        });
                      }}
                    />
                  </>
                ))
              : null}
            {!isCart &&
            retrieveUnNumbersAPIStatus === STATUS.SUCCESS &&
            unNumbers &&
            unNumbers.length &&
            productValues.searchType !== 'class' ? (
              <>
                <hr />
                <H3 pt="md">Search Result</H3>
                {unNumbers.map((datum) => (
                  <UnNumberListItem
                    key={`un-number-${datum.un_no}`}
                    addUnNumberIntoCart={() => {
                      _addUnNumberIntoCart({
                        class_division_value: datum.class_division_value,
                        name_description: datum.name_description,
                        class_division: datum.class_division,
                        un_no: datum.un_no,
                        tunnel_code: datum.tunnel_code,
                        type: productValues.searchType,
                        images: [],
                        packagingType: productValues.packagingType,
                        mesurement: productValues.mesurement,
                        quantity: productValues.quantity
                      });
                    }}
                    removeUnNumberFromCart={() => {
                      _removeUnNumberFromCart({
                        class_division_value: datum.class_division_value,
                        class_division: datum.class_division,
                        name_description: datum.name_description,
                        un_no: datum.un_no,
                        tunnel_code: datum.tunnel_code,
                        type: productValues.searchType,
                        images: [],
                        packagingType: productValues.packagingType,
                        mesurement: productValues.mesurement,
                        quantity: productValues.quantity
                      });
                    }}
                    unNumberDetail={datum as IUnNumber}
                    {...props}
                  />
                ))}
              </>
            ) : null}
            {isCart ? (
              <UnNumberCart
                removeUnNumberFromCart={(unNumber: IProductInCart) => {
                  _removeUnNumberFromCart(unNumber, true);
                }}
                initialProducts={initialProducts}
                operatorId={operatorId}
                report_type={REPORT_TYPE.UN_NUMBER}
                {...props}
              />
            ) : null}
          </Box>
        </Box>
      </section>
    </Box>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: IAppState) => {
  const unNumbers = getUnsSelector(state);
  const retrieveUnNumbersAPIStatus = getUnNumbersAPIStatus(state);
  const unNumbersInCart = getUnNumbersFromCart(state);
  const retrieveCombinationAPIStatus = getCombinationAPIStatus(state);
  const combinationData = getCombinationData(state);
  const createReportAPIStatus = getCreateReportAPIStatus(state);
  const report = getReportDetailSelector(state);
  const retrieveReportDetailAPIStatus = getReportDetailAPIStatus(state);
  const classDivisions = getClassDivisions(state);
  const loggedInUserDetails = getLoggedInUserDetails(state);
  const operatorList = getOperatorListSelector(state);
  return {
    classDivisions,
    operatorList,
    user: loggedInUserDetails.user,
    report,
    retrieveReportDetailAPIStatus,
    createReportAPIStatus,
    retrieveCombinationAPIStatus,
    combinationData,
    unNumbersInCart,
    retrieveUnNumbersAPIStatus,
    unNumbers
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  return bindActionCreators(
    {
      retrieveUnNumbers,
      updateGoodsInTheCart,
      addImageInUnNumberIntoCart,
      retrieveCombinationEvaluation,
      retrieveReportDetail,
      createReport,
      retrieveUnNumbersWithName,
      resetReportState,
      addProductValueInUnNumberIntoCart,
      retrieveOpertorsList,
      removeImageInUnNumberIntoCart
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(withToastManager(withRouter(AddReport))));
