import { createAction } from 'redux-actions';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { ForgotPasswordServices } from '../services/forgotPassword.service';

export const FORGOT_PASSWORD__FAILURE = 'pdg.forgot.password.ui.failure';
export const forgotPasswordFailure = createAction(
  FORGOT_PASSWORD__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const FORGOT_PASSWORD__SUCCESS = 'pdg.forgot.password.ui.success';
export const forgotPasswordSuccess = createAction(
  FORGOT_PASSWORD__SUCCESS,
  (email: string, licenseKey: string) => {
    return { email, licenseKey };
  }
);

export const forgotPassword = (
  email: string,
  licenseKey: string
): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      const res = await ForgotPasswordServices.forgotPassword(
        email,
        licenseKey
      );
      if (res && res && res.status === 1) {
        dispatch(forgotPasswordSuccess(email, licenseKey));
        return res;
      } else {
        dispatch(forgotPasswordFailure(res));
        throw res;
      }
    } catch (err) {
      dispatch(forgotPasswordFailure(err));
      throw err;
    }
  };
};
