/* istanbul ignore file */

import React, { PureComponent, FunctionComponent } from 'react';
import { Search } from '@material-ui/icons';
import styled from '@emotion/styled';
import { FormikProps, Formik } from 'formik';
import { Box, Text } from '@chakra-ui/core';
import { themeGet } from '@styled-system/theme-get';

import { Input, InputGroup, FormControl, InputRightElement } from './forms'

export interface IFormValues {
  searchTerm: string;
}

interface IProps {
  className?: string;
  placeholder?: string;
  maxLength?: number;
  searchTerm: string | null | undefined;
  onSearchFormSubmit: (values: IFormValues) => void;
  onSearchFormReset: () => void;
}

const SearchForm: FunctionComponent<
  FormikProps<IFormValues> & Partial<IProps>
> = ({
  placeholder,
  maxLength,
  values,
  setFieldValue,
  handleBlur,
  handleChange,
  handleSubmit,
  className,
  submitForm
}) => {
  return (
    <Box as="form" onSubmit={handleSubmit as any} className={className}>
      <FormControl>
        <InputGroup>
          <Input
            placeholder={placeholder ?? ''}
            name="searchTerm"
            value={values.searchTerm}
            type="text"
            onBlur={handleBlur}
            onChange={handleChange}
            backgroundColor="transparent"
            maxLength={4}
            className="search-input"
            borderRadius="5rem !important"
            width="200px"
            color="#FFF !important"
          />
          <InputRightElement width="4.5rem" mt="-1px" py="2xs" zIndex={0}>
            <Text as="span" color="gray.500">
              <Search />
            </Text>
          </InputRightElement>
        </InputGroup>
      </FormControl>
    </Box>
  );
};

class SearchComponent extends PureComponent<IProps> {
  render() {
    const { className, onSearchFormSubmit, onSearchFormReset } = this.props;
    return (
      <div className={className}>
        <Formik
          initialValues={{
            searchTerm: this.props.searchTerm ?? ''
          }}
          enableReinitialize
          onSubmit={(values: IFormValues) => {
            if (values.searchTerm) {
              onSearchFormSubmit(values);
            } else {
              onSearchFormReset();
            }
          }}
          component={(formikProps: FormikProps<IFormValues>) => {
            return <SearchForm {...formikProps} {...this.props} />;
          }}
        />
      </div>
    );
  }
}

export default styled(SearchComponent)`
  .search-input {
    padding-right: ${themeGet('space.2xl')};
  }
`;
