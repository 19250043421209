import styled from '@emotion/styled';
import {
  Accordion as ChakraAccordion,
  AccordionItem as ChakraAccordionItem,
  AccordionHeader as ChakraAccordionHeader,
  AccordionPanel as ChakraAccordionPanel,
  AccordionIcon as ChakraAccordionIcon
} from '@chakra-ui/core';
import { themeGet } from '@styled-system/theme-get';

export const Accordion = styled(ChakraAccordion)``;
export const AccordionItem = styled(ChakraAccordionItem)`
  margin-bottom: ${themeGet('space.sm')};
  border-top-width: 0;
  border-bottom-width: 0;
`;
export const AccordionHeader = styled(ChakraAccordionHeader)`
  text-align: left;
  padding: ${themeGet('space.sm')};
  background-color: ${themeGet('colors.white')};
  border: none;

  &:hover,
  &:focus,
  &:active {
    background: ${themeGet('colors.white')};
    border: none;
  }

  &[aria-expanded='true'] {
    &:hover,
    &:focus {
      border-color: none;
    }

    &:active {
      border-color: none;
    }

    & + div {
      box-shadow: none;
    }
  }
`;
export const AccordionPanel = styled(ChakraAccordionPanel)`
  padding: ${themeGet('space.sm')};
  border: none;
`;
export const AccordionIcon = styled(ChakraAccordionIcon)``;
