import { AnyAction, Reducer } from 'redux';
import { IAppState } from '../../../../store';
import { STATUS } from '../../../../constant';
import {
  RETRIEVE_LICENSES__START,
  RETRIEVE_LICENSES__FAILURE,
  RETRIEVE_LICENSES__SUCCESS,
  DELETE_USER__START,
  DELETE_USER__SUCCESS,
  DELETE_USER__FAILURE,
  ADD_SUB_USER__START,
  ADD_SUB_USER__FAILURE,
  ADD_SUB_USER__SUCCESS,
  EDIT_PROFILE__START,
  EDIT_PROFILE__FAILURE,
  EDIT_PROFILE__SUCCESS
} from '../../actions';

export interface ILicensesPageState {
  retrieveLicenseAPIStatus: STATUS;
  addSubUserAPIStatus: STATUS;
  editProfileAPIStatus: STATUS;
  deleteUserAPIStatus: STATUS;
}

export const defaultState: ILicensesPageState = {
  retrieveLicenseAPIStatus: STATUS.NOT_STARTED,
  addSubUserAPIStatus: STATUS.NOT_STARTED,
  editProfileAPIStatus: STATUS.NOT_STARTED,
  deleteUserAPIStatus: STATUS.NOT_STARTED
};

export const licensesPageReducer: Reducer<ILicensesPageState> = (
  state: ILicensesPageState = defaultState,
  action: AnyAction
): ILicensesPageState => {
  switch (action.type) {
    case RETRIEVE_LICENSES__START: {
      return {
        ...state,
        retrieveLicenseAPIStatus: STATUS.LOADING
      };
    }
    case RETRIEVE_LICENSES__FAILURE: {
      return {
        ...state,
        retrieveLicenseAPIStatus: STATUS.FAILURE
      };
    }
    case RETRIEVE_LICENSES__SUCCESS: {
      return {
        ...state,
        retrieveLicenseAPIStatus: STATUS.SUCCESS
      };
    }
    case ADD_SUB_USER__START: {
      return {
        ...state,
        addSubUserAPIStatus: STATUS.LOADING
      };
    }
    case ADD_SUB_USER__FAILURE: {
      return {
        ...state,
        addSubUserAPIStatus: STATUS.FAILURE
      };
    }
    case ADD_SUB_USER__SUCCESS: {
      return {
        ...state,
        addSubUserAPIStatus: STATUS.SUCCESS
      };
    }
    case EDIT_PROFILE__START: {
      return {
        ...state,
        editProfileAPIStatus: STATUS.LOADING
      };
    }
    case EDIT_PROFILE__FAILURE: {
      return {
        ...state,
        editProfileAPIStatus: STATUS.FAILURE
      };
    }
    case EDIT_PROFILE__SUCCESS: {
      return {
        ...state,
        editProfileAPIStatus: STATUS.SUCCESS
      };
    }
    case DELETE_USER__START: {
      return {
        ...state,
        deleteUserAPIStatus: STATUS.LOADING
      };
    }
    case DELETE_USER__FAILURE: {
      return {
        ...state,
        deleteUserAPIStatus: STATUS.FAILURE
      };
    }
    case DELETE_USER__SUCCESS: {
      return {
        ...state,
        deleteUserAPIStatus: STATUS.SUCCESS
      };
    }
    default: {
      return state;
    }
  }
};

export const getLicenseAPIStatus = (state: IAppState) => {
  return state.license.ui.licensesPage.retrieveLicenseAPIStatus;
};

export const getAddSubUserAPIStatus = (state: IAppState) => {
  return state.license.ui.licensesPage.addSubUserAPIStatus;
};

export const getEditProfileAPIStatus = (state: IAppState) => {
  return state.license.ui.licensesPage.editProfileAPIStatus;
};

export const getDeleteUserAPIStatus = (state: IAppState) => {
  return state.license.ui.licensesPage.deleteUserAPIStatus;
};
