/* istanbul ignore file */
import React, { FunctionComponent } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { connect } from 'react-redux';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Box } from '@chakra-ui/core';
import { withNamespaces } from 'react-i18next';

import Logo2 from '../../assets/images/logo2.png';
import IdentityHoverIcon from '../../assets/images/dg-identity.png';
import IdentityIcon from '../../assets/images/dg-identity-white.png';
import SafetyHoverIcon from '../../assets/images/dg-safety.png';
import SafetyIcon from '../../assets/images/dg-safety-white.png';
import LicenseHoverIcon from '../../assets/images/license.png';
import LicenseIcon from '../../assets/images/license-white.png';
import ManifestHisotryHoverIcon from '../../assets/images/manifest-history.png';
import ManifestHisotryIcon from '../../assets/images/manifest-history-white.png';
import ManifestHoverIcon from '../../assets/images/manifest.png';
import ManifestIcon from '../../assets/images/manifest-white.png';
import { ROUTES } from './../../constant';
import { IAppState } from '../../store';
import { getLoggedInUserDetails } from '../../modules/auth/selectors/auth.selectors';
import { User, USER_ROLE } from '../models/user';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  user: User;
  isShowMenu: boolean;
  t: any;
}

export const SideBar: FunctionComponent<IProps> = (props: IProps) => {
  const {
    className,
    location: { pathname },
    t,
    user,
    isShowMenu
  } = props;
  return (
    <Box className={className}>
      <Box
        className={`leftBar ${isShowMenu ? 'leftBar-show' : 'leftBar-hide'}`}
      >
        <Box className="db_logo">
          <Link to="#">
            <img src={Logo2} alt="Logo" />
          </Link>
        </Box>
        <Box>
          <ul>
            <li>
              <Link
                to={ROUTES.MANIFEST}
                className={`transition ${
                  ROUTES.MANIFEST === pathname ? 'active' : ''
                }`}
              >
                <img src={ManifestIcon} alt="Icon" className="imgBlock" />
                <img src={ManifestHoverIcon} alt="Icon" className="imgNone" />
                <span>{t('CURRENT_MANIFEST')}</span>
              </Link>
            </li>
            {!user.role ||
            (user.role !== USER_ROLE.OPERATOR &&
              user.role !== USER_ROLE.ADMIN) ? (
              <li>
                <Link
                  to={ROUTES.LICENSE}
                  className={`transition ${
                    ROUTES.LICENSE === pathname ? 'active' : ''
                  }`}
                >
                  <img src={LicenseIcon} alt="Icon" className="imgBlock" />
                  <img src={LicenseHoverIcon} alt="Icon" className="imgNone" />
                  <span>{t('LICENSE')}</span>
                </Link>
              </li>
            ) : null}
            {/* {!user.role || user.role !== USER_ROLE.OPERATOR ? (
              <li>
                <Link
                  to={ROUTES.ALERT}
                  className={`transition ${
                    ROUTES.LICENSE === pathname ? 'active' : ''
                  }`}
                >
                  <img src={LicenseIcon} alt="Icon" className="imgBlock" />
                  <img src={LicenseHoverIcon} alt="Icon" className="imgNone" />
                  <span>{t('ALERT')}</span>
                </Link>
              </li>
            ) : null} */}
            {user.role !== USER_ROLE.ADMIN ? (
              <li>
                <Link
                  to={ROUTES.REPORTS}
                  className={`transition ${
                    pathname.includes(
                      ROUTES.REPORTS
                    )
                      ? 'active'
                      : ''
                  }`}
                >
                  <img src={SafetyIcon} alt="Icon" className="imgBlock" />
                  <img src={SafetyHoverIcon} alt="Icon" className="imgNone" />
                  <span>{t('CHECK_DANGEROUS_GOODS_SAFETY_REQUIREMENTS')}</span>
                </Link>
              </li>
            ) : null}
            {user.role !== USER_ROLE.ADMIN ? (
              <li>
                <Link
                  to={ROUTES.CHECK_MANIFEST_HISTORY}
                  className={`transition ${
                    pathname.includes(ROUTES.CHECK_MANIFEST_HISTORY)
                      ? 'active'
                      : ''
                  }`}
                >
                  <img
                    src={ManifestHisotryIcon}
                    alt="Icon"
                    className="imgBlock"
                  />
                  <img
                    src={ManifestHisotryHoverIcon}
                    alt="Icon"
                    className="imgNone"
                  />
                  <span>{t('CHECK_MANIFEST_HISTORY')}</span>
                </Link>
              </li>
            ) : null}
            <li>
              <Link
                to={ROUTES.EMERGENCY_IDENTIFY_DANGERIUS_GOODS_LABELS}
                className={`transition ${
                  pathname.includes(
                    ROUTES.EMERGENCY_IDENTIFY_DANGERIUS_GOODS_LABELS
                  )
                    ? 'active'
                    : ''
                }`}
              >
                <img src={IdentityIcon} alt="Icon" className="imgBlock" />
                <img src={IdentityHoverIcon} alt="Icon" className="imgNone" />
                <span>{t('EMERGENCY_IDENTIFY_DANGERIUS_GOODS_LABELS')}</span>
              </Link>
            </li>
            {/* <li>
              <Link
                to={ROUTES.GUIDE}
                className={`transition ${
                  pathname.includes(ROUTES.GUIDE) ? 'active' : ''
                }`}
              >
                <img src={UserGuideIcon} alt="Icon" className="imgBlock" />
                <img src={UserGuideHoverIcon} alt="Icon" className="imgNone" />
                <span>{t('SIDEBAR_GUIDE')}</span>
              </Link>
            </li> */}
          </ul>
        </Box>
      </Box>
    </Box>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: IAppState) => {
  const loggedInUserDetails = getLoggedInUserDetails(state);
  return {
    user: loggedInUserDetails.user
  };
};

export default connect(
  mapStateToProps,
  null
)(withNamespaces()(withToastManager(SideBar)));
