import memoize from 'lodash/memoize';

import config from '../globalConfig';

export type RequestHeaders = { [key: string]: string };

export enum ContentType {
  JSON = 'JSON',
  FORM_URL_ENCODED = 'FORM_URL_ENCODED',
  MULTIPART_FORM_DATA = 'MULTIPART_FORM_DATA'
}

const HEADERS: { [key: string]: RequestHeaders } = {
  [ContentType.JSON]: {
    'Content-Type': 'application/json'
  },
  [ContentType.FORM_URL_ENCODED]: {
    'Content-Type': 'application/x-www-form-urlencoded'
  },
  [ContentType.MULTIPART_FORM_DATA]: {
    'Content-Type': 'multipart/form-data'
  }
};

export const prepareHeaders = (headersFor: string[]): RequestHeaders => {
  let requestHeaders: RequestHeaders = {};
  headersFor.forEach((datum) => {
    const header = HEADERS[datum];
    requestHeaders = {
      ...requestHeaders,
      ...header
    };
  });
  return requestHeaders;
};

export const getAPIRootURLWithProtocol = memoize(
  (configAPIRoot: string): string => {
    if (!configAPIRoot) {
      return '';
    }

    const url = new URL(configAPIRoot, window.location.origin);
    return url.toString();
  }
);

export const getApiUrlFromRoot = (url: string) => {
  return getAPIRootURLWithProtocol(config.API_ROOT) + url;
};

export const getHeaders = (headersFor: string[]): RequestHeaders => {
  return prepareHeaders(headersFor);
};
