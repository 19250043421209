const colors: any = {
  transparent: 'transparent',
  current: 'currentColor',
  black: '#000',
  white: '#fff',

  whiteAlpha: {
    50: 'rgba(255, 255, 255, 0.04)',
    100: 'rgba(255, 255, 255, 0.06)',
    200: 'rgba(255, 255, 255, 0.08)',
    300: 'rgba(255, 255, 255, 0.16)',
    400: 'rgba(255, 255, 255, 0.24)',
    500: 'rgba(255, 255, 255, 0.36)',
    600: 'rgba(255, 255, 255, 0.48)',
    700: 'rgba(255, 255, 255, 0.64)',
    800: 'rgba(255, 255, 255, 0.80)',
    900: 'rgba(255, 255, 255, 0.92)'
  },

  blackAlpha: {
    50: 'rgba(0, 0, 0, 0.04)',
    100: 'rgba(0, 0, 0, 0.06)',
    200: 'rgba(0, 0, 0, 0.08)',
    300: 'rgba(0, 0, 0, 0.16)',
    400: 'rgba(0, 0, 0, 0.24)',
    500: 'rgba(0, 0, 0, 0.36)',
    600: 'rgba(0, 0, 0, 0.48)',
    700: 'rgba(0, 0, 0, 0.64)',
    800: 'rgba(0, 0, 0, 0.80)',
    900: 'rgba(0, 0, 0, 0.92)'
  },

  primaryAlpha: {
    50: 'rgba(34, 189, 189, 0.05)',
    100: 'rgba(34, 189, 189, 0.1)',
    200: 'rgba(34, 189, 189, 0.2)',
    300: 'rgba(34, 189, 189, 0.3)',
    400: 'rgba(34, 189, 189, 0.4)',
    500: 'rgba(34, 189, 189, 0.5)',
    600: 'rgba(34, 189, 189, 0.6)',
    700: 'rgba(34, 189, 189, 0.7)',
    800: 'rgba(34, 189, 189, 0.8)',
    900: 'rgba(34, 189, 189, 0.9)'
  },

  primary: {
    50: '#e9f8f8',
    100: '#bdebeb',
    200: '#a7e5e5',
    300: '#7ad7d7',
    400: '#4ecaca',
    500: '#22bdbd', // default
    600: '#1b9797',
    700: '#147171',
    800: '#0e4c4c',
    900: '#031313',
    default: '#22bdbd'
  },

  secondary: {
    50: '#ecedef',
    100: '#d8dbde',
    200: '#b1b6bd',
    300: '#8b929c',
    400: '#646d7b',
    500: '#3d495a', // default
    600: '#313a48',
    700: '#252c36',
    800: '#181d24',
    900: '#0c0f12',
    default: '#3d495a'
  },

  gray: {
    50: '#fcfcfc',
    100: '#f9f9f9',
    200: '#edeeef',
    300: '#e4e6e8',
    400: '#cacdd1',
    500: '#a3a5a7',
    600: '#7a7b7d',
    700: '#656769',
    800: '#464849',
    900: '#252628',
    default: '#a3a5a7'
  },

  red: {
    50: '#ffeaea',
    100: '#fed5d5',
    200: '#fdabab',
    300: '#fd8282',
    400: '#fc5858',
    500: '#fb2e2e', // default
    600: '#c92525',
    700: '#971c1c',
    800: '#641212',
    900: '#320909',
    default: '#fb2e2e'
  },

  orange: {
    50: '#fdf4e8',
    100: '#fae8d1',
    200: '#f5d1a3',
    300: '#f0ba75',
    400: '#eba347',
    500: '#e68c19', // default
    600: '#b87014',
    700: '#8a540f',
    800: '#5c380a',
    900: '#2e1c05',
    default: '#e68c19'
  },

  yellow: {
    50: '#fffff0',
    100: '#fefcbf',
    200: '#faf089',
    300: '#f6e05e',
    400: '#ecc94b',
    500: '#d69e2e',
    600: '#b7791f',
    700: '#975a16',
    800: '#744210',
    900: '#5F370E'
  },

  green: {
    50: '#eef8f0',
    100: '#dcf1e1',
    200: '#b9e3c3',
    300: '#96d6a5',
    400: '#73c887',
    500: '#50ba69', // default
    600: '#409554',
    700: '#30703f',
    800: '#204a2a',
    900: '#102515',
    default: '#50ba69'
  },

  teal: {
    50: '#E6FFFA',
    100: '#B2F5EA',
    200: '#81E6D9',
    300: '#4FD1C5',
    400: '#38B2AC',
    500: '#319795',
    600: '#2C7A7B',
    700: '#285E61',
    800: '#234E52',
    900: '#1D4044'
  },

  blue: {
    50: '#ebf8ff',
    100: '#ceedff',
    200: '#90cdf4',
    300: '#63b3ed',
    400: '#4299e1',
    500: '#3182ce',
    600: '#2a69ac',
    700: '#1e4e8c',
    800: '#153e75',
    900: '#1a365d'
  },

  cyan: {
    50: '#EDFDFD',
    100: '#C4F1F9',
    200: '#9DECF9',
    300: '#76E4F7',
    400: '#0BC5EA',
    500: '#00B5D8',
    600: '#00A3C4',
    700: '#0987A0',
    800: '#086F83',
    900: '#065666'
  },

  purple: {
    50: '#faf5ff',
    100: '#e9d8fd',
    200: '#d6bcfa',
    300: '#b794f4',
    400: '#9f7aea',
    500: '#805ad5',
    600: '#6b46c1',
    700: '#553c9a',
    800: '#44337a',
    900: '#322659'
  },

  pink: {
    50: '#fff5f7',
    100: '#fed7e2',
    200: '#fbb6ce',
    300: '#f687b3',
    400: '#ed64a6',
    500: '#d53f8c',
    600: '#b83280',
    700: '#97266d',
    800: '#702459',
    900: '#521B41'
  }
};

colors.danger = colors.red;
colors.success = colors.green;
colors.warning = colors.orange;
colors.dim = colors.gray;

export default colors;
