/* istanbul ignore file */
import React, { FunctionComponent, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Box } from '@chakra-ui/core';

import { IProduct, IReport } from '../models/reports-list.model';
import { createUrl } from '../../../helper';
// import Share from '../../../assets/images/share.png';
import { ROUTES } from '../../../constant';
import SafeIcon from '../../../assets/images/safe.png';
import UnsafeIcon from '../../../assets/images/unsafe.png';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  report: IReport;
  selectedOperatorId: string
  t: any;
}

export const ReportListItemComponent: FunctionComponent<IProps> = (
  props: IProps
) => {
  const { report, t, history, selectedOperatorId } = props;
  const [isShowMore, setIsShowMore] = useState(false);
  return (
    <Box
      className={`mt-4 ab-bx-3 p-3 report-item`}
      onClick={() => {
        history.push(
          createUrl(ROUTES.REPORT_DETAIL, {
            report_id: report.report_id,
            operatorId: selectedOperatorId
          })
        );
      }}
    >
      <Box className="sfus-pos">
        {report.is_safe ? <img src={SafeIcon} alt="SafeIcon" /> : <img src={UnsafeIcon} alt='UnsafeIcon' />}
      </Box>
      <Box className="d-flex row justify-content-between flex-wrap">
        <Box className="col-8 align-items-left">
          <h6>{report.report_name}</h6>
        </Box>
        <Box
          className="d-flex col-4 flex-wrap rmt-10 justify-content-end"
          pr="3xl"
        >
          {report.product.map((unNumber: IProduct, index: number) => {
            if (unNumber.un_no && index < 4) {
              return (
                <button key={unNumber.un_no} className="db-btn mr-2 transition">
                  {t('UN')} {unNumber.un_no}
                </button>
              );
            } else if (unNumber.class && (index < 4 || isShowMore)) {
              return (
                <button key={unNumber.class} className="db-btn mr-2 transition">
                  {t('CLASS')} {unNumber.class}
                </button>
              );
            } else return null;
          })}
          {report.product.length > 4 ? (
            <button
              className="db-btn mr-2 transition"
              onClick={(event) => {
                event.stopPropagation();
                setIsShowMore(!isShowMore);
              }}
            >
              {isShowMore ? 'Show Less' : 'Show more...'}
            </button>
          ) : null}
        </Box>
      </Box>
      <Box className="ab-bottom">
        <Box className="d-flex align-items-center justify-content-between flex-wrap">
          <Box>
            <span>
              {t('CREATED_BY')}: <strong>{report.user_name}</strong>
            </span>
          </Box>
          {/* <Box className="d-flex justify-content-start align-items-center">
            <img src={Share} alt="image" />
            <span className="ml-2">Share</span>
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
};

export default ReportListItemComponent;