/* istanbul ignore file */
import React, { FunctionComponent, useEffect } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { RouteComponentProps } from 'react-router-dom';
import { Box } from '@chakra-ui/core';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import Logo2 from '../../../assets/images/logo2.png';
import LeftBanner from '../../../assets/images/leftbanner.png';
import SubscriptionComponent from '../components/subscription.component';
import { ROUTES, STATUS } from '../../../constant';
import { IAppState } from '../../../store';
import { retrieveSubscription, selectSubscription } from '../actions';
import { getSubscriptionSelector } from '../selectors/index-page.selector';
import { ISubscription } from '../models/subscription.model';
import {
  getSubscriptionsAPIStatus,
  getSelectedSubscription
} from '../reducers/ui';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  subscriptionList: ISubscription[];
  retrieveSubscriptionsAPIStatus: STATUS;
  t: any;
  selectedSubscription: number | null;
  retrieveSubscription: typeof retrieveSubscription;
  selectSubscription: typeof selectSubscription;
}

export const Subscription: FunctionComponent<IProps> = (props: IProps) => {
  const { className, retrieveSubscription, selectSubscription } = props;
  const _retrieveSubscription = async () => {
    try {
      await retrieveSubscription();
    } catch (err) {
      console.log(':: _retrieveSubscription err ', err);
    }
  };

  useEffect(() => {
    _retrieveSubscription();
  }, []);

  return (
    <div className={className}>
      <Box className="signupAr">
        <Box className="lftImage">
          <a href={ROUTES.HOME}>
            <img src={Logo2} className="innLogo" alt="pdg" />
          </a>
          <img src={LeftBanner} alt="pdg" />
        </Box>
        <Box className="_snInfo">
          <SubscriptionComponent
            {...props}
            selectSubscription={(selectedSubscription: number) => {
              selectSubscription(selectedSubscription);
            }}
            retry={() => {
              _retrieveSubscription();
            }}
          />
        </Box>
      </Box>
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: IAppState) => {
  const subscriptionList = getSubscriptionSelector(state);
  const selectedSubscription = getSelectedSubscription(state);
  const retrieveSubscriptionsAPIStatus = getSubscriptionsAPIStatus(state);
  return {
    subscriptionList,
    selectedSubscription,
    retrieveSubscriptionsAPIStatus
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  return bindActionCreators(
    { retrieveSubscription, selectSubscription },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(withToastManager(Subscription)));
