export enum REPORT_TYPE {
  UN_NUMBER = 1,
  CLASS = 2,
  UN_BY_NAME = 3
}

export interface IReport {
  report_id: string;
  report_name: string;
  is_safe: number;
  product: IProduct[];
  created_at: Date;
  user_name: string;
  goods_staus: string;
  report_type: REPORT_TYPE;
  tunnel_value?: string;
  pdf: string | null;
  approvedby: string | null;
  approvestatus: string;
  reason: string | null;
}

export interface IProduct {
  un_no: string;
  class: string;
  name_description: string;
  un_no_name: string;
  tunnel_code?: string;
  id: number;
  images: string[];
  packagingType?: string;
  mesurement?: string;
  quantity?: string;
}
