import { Theme, ObjectOrArray } from 'styled-system';
import * as CSS from 'csstype';

import sizes, { baseSizes } from './sizes';
import typography from './typography';
import themeColors from './colors';
import icons from './icon-paths';

const breakpoints: string[] | number[] | any = [
  '576px',
  '768px',
  '992px',
  '1200px'
];
// aliases
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

const swatches: ObjectOrArray<CSS.ColorProperty> = {
  seagreen: '#22bdbd',
  seagreener: '#41B763',
  seablue: '#15adad',
  green: '#50ba69',
  black: '#101315',
  navy: '#0e1d32',
  grey10: '#333',
  grey9: themeColors.gray[900],
  grey8: themeColors.gray[800],
  grey7: themeColors.gray[700],
  grey6: themeColors.gray[600],
  grey5: themeColors.gray[500],
  grey4: themeColors.gray[400],
  grey3: themeColors.gray[300],
  grey2: themeColors.gray[200],
  grey1: themeColors.gray[100],
  white: '#ffffff'
};

let colors: ObjectOrArray<CSS.ColorProperty> = {
  ...swatches,
  ...themeColors,
  active: swatches.seagreener,
  primaryAction: swatches.seagreen,
  primaryActionFocus: swatches.seablue
};

colors = {
  ...colors,
  defaultFormInput: (colors as any).grey2,
  focusedFormInputBackground: (colors as any).grey1,
  disabledFormInputBg: (colors as any).gray[100],
  successFormInputBorder: (colors as any).success,
  errorFormInputBorder: (colors as any).danger.default,
  defaultFormInputBorder: themeColors.gray[400],
  focusedFormInputBorder: (colors as any).gray[400]
};

const space: ObjectOrArray<number | string> = {
  ...baseSizes
};

space['2xs'] = space[1];
space['xs'] = space[2];
space['sm'] = space[3];
space['md'] = space[4];
space['lg'] = space[6];
space['xl'] = space[8];
space['2xl'] = space[10];
space['3xl'] = space[12];
space['4xl'] = space[16];

const radii: ObjectOrArray<CSS.BorderRadiusProperty<{}>> = {
  // chakra values
  none: '0',
  sm: '0.125rem',
  md: '0.25rem',
  lg: '0.5rem',
  xl: '0.75rem',
  '2xl': '1rem',
  '3xl': '1.25rem',
  full: '9999px'
};

const shadows: ObjectOrArray<CSS.BoxShadowProperty> = {
  sm: `0 1px 3px 0 rgba(0, 0, 0, 0.01), 0 1px 2px 0 rgba(0, 0, 0, 0.06)`,
  md: '0 4px 6px -1px rgba(0, 0, 0, 0.01), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  lg:
    '0 10px 15px -3px rgba(0, 0, 0, 0.01), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
  xl:
    '0 20px 25px -5px rgba(0, 0, 0, 0.01), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
  '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
  outline: '0 0 0 3px rgba(66, 153, 225, 0.6)',
  float: '0 2px 8px 0 rgba(14, 29, 50, 0.15)',
  inner: 'inset 0 2px 4px 0 rgba(0,0,0,0.06)',
  none: 'none'
};

const forms = {
  input: {
    borderRadius: radii.md,
    fontSize: typography.fontSizes.regular
  },
  select: {
    borderRadius: radii.md
  },
  textarea: {
    borderRadius: radii.md
  },
  label: {},
  radio: {},
  checkbox: {}
};

export interface ITheme extends Theme {
  forms: any;
  opacity: any;
  zIndices: any;
  icons: any;
}

const zIndices = {
  hide: -1,
  auto: 'auto',
  base: 0,
  docked: 10,
  dropdown: 1000,
  sticky: 1100,
  banner: 1200,
  overlay: 1300,
  modal: 1400,
  popover: 1500,
  skipLink: 1600,
  toast: 1700,
  tooltip: 1800
};

const opacity = {
  '0': '0',
  '20%': '0.2',
  '40%': '0.4',
  '60%': '0.6',
  '80%': '0.8',
  '100%': '1'
};

const borders = {
  none: 0,
  '1px': '1px solid',
  '2px': '2px solid',
  '4px': '4px solid'
};

const theme: ITheme = {
  breakpoints,
  colors,
  radii,
  space,
  shadows,
  opacity,
  borders,
  zIndices,
  sizes,
  ...typography,
  forms,
  icons: {
    ...icons
  }
};

export default theme;
