/* istanbul ignore file */
import React, {
  FunctionComponent,
  RefObject,
  createRef,
  useEffect
} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { withToastManager } from 'react-toast-notifications';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Box } from '@chakra-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import { FormikProps, FormikHelpers as FormikActions, Formik } from 'formik';
import {
  ERROR_MESSAGE,
  ISD_CODE,
  PHONE_NUMBER_LENGTH,
  STATUS
} from '../../../constant';
import {
  emailValidator,
  phoneNumberValidator,
  getMessage
} from '../../../helper';
import { User } from '../../../common/models/user';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  nextStep: () => void;
  submitForm: (values: IFormValues) => void;
  submitSupportAndFeedbackFormAPIStatus: STATUS;
  user: User;
  t: any;
}

export interface IFormValues {
  fullName?: string | null;
  email?: string | null;
  comments?: string | null;
  phone?: string | null;
  code?: string | null;
  file?: string | null;
  fileUrl?: string | null;
}

export const SubmitForm: FunctionComponent<
  FormikProps<IFormValues> & Partial<IProps>
> = ({
  handleSubmit,
  touched,
  errors,
  values,
  setFieldValue,
  resetForm,
  t,
  submitSupportAndFeedbackFormAPIStatus
}) => {
  const _downloadLinkRef: RefObject<HTMLInputElement> = createRef();
  const uploadFile = (file: any) => {
    const reader = new FileReader();
    reader.onload = (evt) => {
      setFieldValue('file', evt.target?.result);
    };
    reader.readAsDataURL(file);
  };
  useEffect(() => {
    if (submitSupportAndFeedbackFormAPIStatus === STATUS.SUCCESS) {
      resetForm();
    }
  }, [submitSupportAndFeedbackFormAPIStatus]);
  return (
    <form onSubmit={handleSubmit}>
      <Box className="row mt-3">
        <Box className="col-12">
          <input
            type="email"
            name="email"
            className="form-control form-group"
            disabled
            value="Email To: info@pdgapp.com.au"
          />
        </Box>
        <Box className="col-12">
          <input
            type="text"
            name="fullName"
            className="form-control form-group"
            placeholder={t('SUPPORT_AND_FEEDBACK_FULL_NAME')}
            value={values.fullName ?? ''}
            onChange={(event) => {
              setFieldValue('fullName', event.target.value);
            }}
          />
          {errors.fullName && touched.fullName ? (
            <Box className="invalid-feedback">{errors.fullName}</Box>
          ) : null}
        </Box>
        <Box className="col-12">
          <input
            type="text"
            name="email"
            className="form-control form-group"
            placeholder={t('SUPPORT_AND_FEEDBACK_EMAIL')}
            value={values.email ?? ''}
            onChange={(event) => {
              setFieldValue('email', event.target.value);
            }}
          />
          {errors.email && touched.email ? (
            <Box className="invalid-feedback">{errors.email}</Box>
          ) : null}
        </Box>
        <Box className="col-12 col-lg-3">
          <select
            className="form-control form-group"
            name="code"
            onChange={(event) => {
              setFieldValue('code', event.target.value);
            }}
          >
            {ISD_CODE.map((datum) => (
              <option value={datum} key={datum}>
                {datum}
              </option>
            ))}
          </select>
          {errors.code && touched.code ? (
            <Box className="invalid-feedback">{errors.code}</Box>
          ) : null}
        </Box>
        <Box className="col-12 col-lg-9">
          <input
            type="number"
            name="phone"
            className="form-control form-group"
            placeholder={t('SUPPORT_AND_FEEDBACK_PHONE_NUMBER')}
            value={values.phone ?? ''}
            onChange={(event) => {
              const number = event.target.value.match(/\d/g)?.join('');
              const phone =
                number && number.length > PHONE_NUMBER_LENGTH
                  ? values.phone
                  : number;
              setFieldValue('phone', phone);
            }}
          />
          {errors.phone && touched.phone ? (
            <Box className="invalid-feedback">{errors.phone}</Box>
          ) : null}
        </Box>
      </Box>
      <Box className="row">
        <Box className="form-group col-12">
          {!values.file ? (
            <>
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => {
                  _downloadLinkRef.current?.click();
                }}
              >
                {t('SUPPORT_AND_FEEDBACK_BROWSE')}
              </button>
              <input
                type="file"
                ref={_downloadLinkRef}
                onChange={(event) => {
                  if (event.target.files?.length) {
                    uploadFile(event.target.files[0]);
                  }
                }}
                style={{ display: 'none' }}
              />
            </>
          ) : (
            <Box className="_filePreview">
              <a href={values.file} download>
                <DescriptionIcon /> File uploaded
              </a>
              {'  '}
              <i
                onClick={() => {
                  setFieldValue('file', undefined);
                }}
              >
                <CloseIcon />
              </i>
            </Box>
          )}
          {errors.file && touched.file ? (
            <Box className="invalid-feedback">{errors.file}</Box>
          ) : null}
        </Box>
      </Box>
      <Box className="form-group">
        <textarea
          className="form-control _hauto"
          rows={3}
          placeholder={t('SUPPORT_AND_FEEDBACK_COMMENTS')}
          value={values.comments ?? ''}
          name="comments"
          onChange={(event) => {
            setFieldValue('comments', event.target.value);
          }}
        ></textarea>
        {errors.comments && touched.comments ? (
          <Box className="invalid-feedback">{errors.comments}</Box>
        ) : null}
      </Box>
      <Box className="text-center mt-4">
        <button className="btn btn-primary" type="submit">
          {t('SUBMIT')}
        </button>
      </Box>
    </form>
  );
};

export const SupportAndFeedbackForm: FunctionComponent<IProps> = (
  props: IProps
) => {
  const {
    className,
    submitForm,
    t,
    user,
    submitSupportAndFeedbackFormAPIStatus
  } = props;
  const _validateFilterForm = (values: IFormValues) => {
    const errors: any = {};
    if (!values.fullName) {
      errors.fullName = getMessage(ERROR_MESSAGE.required);
    }

    if (!values.email) {
      errors.email = getMessage(ERROR_MESSAGE.required);
    } else if (!emailValidator(values.email)) {
      errors.email = getMessage(ERROR_MESSAGE.email);
    }

    if (!values.comments) {
      errors.comments = getMessage(ERROR_MESSAGE.required);
    }

    if (!values.code) {
      errors.code = getMessage(ERROR_MESSAGE.required);
    }

    if (!values.phone) {
      errors.phone = getMessage(ERROR_MESSAGE.required);
    } else if (!phoneNumberValidator(values.phone)) {
      errors.password = getMessage(ERROR_MESSAGE.phone);
    }

    return errors;
  };

  const _onFilterFormSubmit = async (
    values: IFormValues,
    actions: FormikActions<IFormValues>
  ) => {
    actions.setSubmitting(true);
    try {
      await submitForm(values);
      actions.setSubmitting(false);
    } catch (err) {
      actions.setSubmitting(false);
    }
  };
  return (
    <Box className={className}>
      <Formik
        validate={(values: IFormValues) => _validateFilterForm(values)}
        enableReinitialize
        initialValues={{
          fullName: user.name,
          file: '',
          comments: '',
          phone: user.phone_no,
          code: user.country_id,
          email: user.email
        }}
        onSubmit={(
          values: IFormValues,
          actions: FormikActions<IFormValues>
        ) => {
          _onFilterFormSubmit(values, actions);
        }}
        component={(formikProps: FormikProps<IFormValues>) => {
          return (
            <SubmitForm
              {...formikProps}
              t={t}
              submitSupportAndFeedbackFormAPIStatus={
                submitSupportAndFeedbackFormAPIStatus
              }
            />
          );
        }}
      />
    </Box>
  );
};

export default withToastManager(withRouter(SupportAndFeedbackForm));
