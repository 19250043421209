import React, { Suspense, useState } from 'react';
import styled from '@emotion/styled';
import { Box, Skeleton } from '@chakra-ui/core';
import { ErrorBoundary } from './Page-error-boundry';
import { Route, Switch } from 'react-router-dom';

import { H1 } from '../common/components';
import SideBar from '../common/components/Sidebar.component';
import Header from '../common/components/Header.component';
import {
  Breadcrumb,
  BreadcrumbItem
} from '../common/components/navigation/breadcrumb.component';
import { PermissionDenied } from '../common/components';
import { ROUTES } from '../constant';
import AboutUs from '../modules/about_us/pages/about_us.page';
import TermsAndConditions from '../modules/tramsAndConditions/pages/termsAndConditions.page';
import Legals from '../modules/legal/pages/legals.page.component';
import SupportAndFeedback from '../modules/supportsAndFeedback/pages/supportAndFeedback.page';
import License from '../modules/license/pages/index.page';
import AddReport from '../modules/reports/pages/add-report.page';
import ReportDetail from '../modules/reports/pages/report-detail.page';
import NotificationList from '../modules/notification/pages/notifications-list.page.component';
import Guide from '../modules/guide/pages/Guide.page';
import EmergencyGuide from '../modules/emergencyGuide/pages/Guide.page';
import UpgradeSubscription from '../modules/register/pages/upgrade-subscription.page';
import ComingSoon from '../modules/comingSoon/pages/comingsoon.page';
import WarehouseTruck from '../modules/warehouseTruckRegistration/pages/index.page';
import ManifestHistory from '../modules/manifest_history/pages/index.page';
import CurrentManifest from '../modules/currentManifest/pages/index.page';
import Reports from '../modules/reports/pages/reports-list.page.component';

export const LoadingComponent = () => {
  return (
    <>
      <Skeleton width="40%">
        <Breadcrumb>
          <BreadcrumbItem isCurrentPage>
            <span>Loading...</span>
          </BreadcrumbItem>
        </Breadcrumb>
      </Skeleton>
      <Skeleton width="30%">
        <Box className="page-header">
          <H1>Loading...</H1>
        </Box>
      </Skeleton>
    </>
  );
};

const ProtectedRoutes = (props: any) => {
  const [isShowMenu, setIsShowMenu] = useState(false);
  return (
    <Box className={props.className}>
      <SideBar isShowMenu={isShowMenu} {...props} />
      <div className="dbWidget">
        <Header
          isShowMenu={isShowMenu}
          setIsShowMenu={() => {
            setIsShowMenu(!isShowMenu);
          }}
          {...props}
        />
        <ErrorBoundary>
          <Suspense fallback={<LoadingComponent />}>
            <Switch>
              <Route path={ROUTES.MANIFEST} exact>
                <CurrentManifest />
              </Route>
              <Route path={ROUTES.ABOUT_US} exact>
                <AboutUs />
              </Route>
              <Route path={ROUTES.TERMS_AND_CONDITIONS} exact>
                <TermsAndConditions />
              </Route>
              <Route path={ROUTES.LEGALS} exact>
                <Legals />
              </Route>
              <Route path={ROUTES.SUPPORT_FEEDBACK} exact>
                <SupportAndFeedback />
              </Route>
              <Route path={ROUTES.LICENSE} exact>
                <License />
              </Route>
              <Route path={ROUTES.ADD_REPORT} exact>
                <AddReport />
              </Route>
              <Route path={ROUTES.REPORTS} exact>
                <Reports />
              </Route>
              <Route path={ROUTES.REPORT_DETAIL} exact>
                <ReportDetail />
              </Route>
              <Route path={ROUTES.REPORT_UN_EDIT} exact>
                <AddReport />
              </Route>
              <Route path={ROUTES.UPGRADE_SUBSCRIPTION_LIST} exact>
                <UpgradeSubscription />
              </Route>
              <Route path={ROUTES.NOTIFICATION} exact>
                <NotificationList />
              </Route>
              <Route path={ROUTES.GUIDE} exact>
                <Guide />
              </Route>
              {/* <Route
                path={ROUTES.CHECK_DANGEROUS_GOODS_SAFETY_REQUIREMENTS}
                exact
              >
                <ReportDetail />
              </Route> */}
              <Route path={ROUTES.CHECK_MANIFEST_HISTORY} exact>
                <ManifestHistory />
              </Route>
              <Route
                path={ROUTES.EMERGENCY_IDENTIFY_DANGERIUS_GOODS_LABELS}
                exact
              >
                <EmergencyGuide />
              </Route>
              <Route path={ROUTES.WAREHOUSE_AND_TRUCK_REGISTRATION} exact>
                <WarehouseTruck />
              </Route>
              <Route path={ROUTES.ALERT} exact>
                <ComingSoon />
              </Route>
              <Route path={ROUTES.PERMISSION_DENIED} exact>
                <PermissionDenied />
              </Route>
            </Switch>
          </Suspense>
        </ErrorBoundary>
      </div>
    </Box>
  );
};

export default styled(ProtectedRoutes)``;
