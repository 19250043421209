import { AnyAction, Reducer } from 'redux';
import {
  RETRIEVE_LOGS_LIST__SUCCESS,
  RETRIEVE_LOGS_LIST__START,
  RESET_DATA
} from '../../actions';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import { IAppState } from '../../../../store';
import { ILog } from '../../models/manifest_log';

export type LogById = { [key: number]: ILog };

export interface IManifestHistoryInfoState {
  logById: LogById;
  logAllIds: number[];
}

export const defaultState: IManifestHistoryInfoState = {
  logById: {},
  logAllIds: []
};

export const manifestHistoryInfoReducer: Reducer<IManifestHistoryInfoState> = (
  state: IManifestHistoryInfoState = defaultState,
  action: AnyAction
): IManifestHistoryInfoState => {
  switch (action.type) {
    case RETRIEVE_LOGS_LIST__START: {
      return {
        ...state,
        logAllIds: defaultState.logAllIds
      };
    }
    case RETRIEVE_LOGS_LIST__SUCCESS: {
      const { logs } = action.payload;
      const byId = keyBy(logs, (datum) => {
        return datum.id;
      });
      const ids = map(logs, (datum) => {
        return datum.id;
      });
      return {
        ...state,
        logById: { ...state.logById, ...byId },
        logAllIds: uniq([...state.logAllIds, ...ids])
      };
    }
    case RESET_DATA: {
      return {
        ...state,
        logById: defaultState.logById,
        logAllIds: defaultState.logAllIds
      };
    }
    default:
      return state;
  }
};

export const getLogsById = (state: IAppState) => {
  return state.manifestHistory.entities.manifestHistoryInfo.logById;
};

export const getLogsAllIds = (state: IAppState) => {
  return state.manifestHistory.entities.manifestHistoryInfo.logAllIds;
};
