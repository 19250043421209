/* istanbul ignore file */
import React, { FunctionComponent } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { RouteComponentProps } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';

import Image1 from '../../../assets/images/guide/1.png';
import Image2 from '../../../assets/images/guide/2.png';
import Image3 from '../../../assets/images/guide/3.png';
import Image4 from '../../../assets/images/guide/4.png';
import Image5 from '../../../assets/images/guide/5.png';
import Image6 from '../../../assets/images/guide/6.png';
import Image7 from '../../../assets/images/guide/7.png';
import Image8 from '../../../assets/images/guide/8.png';
import Image9 from '../../../assets/images/guide/9.png';
import Image10 from '../../../assets/images/guide/10.png';

interface IProps extends RouteComponentProps {
  className: string;
}

export const Guide: FunctionComponent<IProps> = () => {
  return (
    <section className="dbPrWid">
      <Carousel showArrows={true}>
        <div className="p-5">
          <img src={Image1} alt="guide" />
        </div>
        <div className="p-5">
          <img src={Image2} alt="guide" />
        </div>
        <div className="p-5">
          <img src={Image3} alt="guide" />
        </div>
        <div className="p-5">
          <img src={Image4} alt="guide" />
        </div>
        <div className="p-5">
          <img src={Image5} alt="guide" />
        </div>
        <div className="p-5">
          <img src={Image6} alt="guide" />
        </div>
        <div className="p-5">
          <img src={Image7} alt="guide" />
        </div>
        <div className="p-5">
          <img src={Image8} alt="guide" />
        </div>
        <div className="p-5">
          <img src={Image9} alt="guide" />
        </div>
        <div className="p-5">
          <img src={Image10} alt="guide" />
        </div>
      </Carousel>
    </section>
  );
};

export default withNamespaces()(withToastManager(Guide));
