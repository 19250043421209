/* istanbul ignore file */

import { Method } from 'axios';
import request from '../../../services/request.service';
import GlobalConfig from '../../../globalConfig';

interface IServiceProps {
  method: Method;
  headers: Record<string, any>;
  url: string;
  data?: Record<string, any>;
  responseType?: any;
}

export const submitForgotPasswordParams = (data = {}): IServiceProps => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot('mobile/forgotpassword'),
  data
});

export class ForgotPasswordServices {
  static async forgotPassword(
    email: string,
    license_key: string
  ): Promise<any> {
    const res = await request(
      submitForgotPasswordParams({ email, license_key })
    );
    return res.data;
  }
}
