import React, { FunctionComponent } from 'react';
import { FormikProps } from 'formik';
import { Box } from '@chakra-ui/core';

import { IProps } from '../pages/ForgotPassword.page';

export interface IFormValues {
  email: string;
  licenseKey: string;
}

export const ForgotPasswordForm: FunctionComponent<
  FormikProps<IFormValues> & Partial<IProps>
> = ({ values, handleSubmit, errors, touched, setFieldValue, t }) => {
  return (
    <>
      <div className="login-container">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              placeholder={t('LOGIN_EMAIL')}
              name="email"
              value={values.email}
              onChange={(event) => {
                setFieldValue('email', event.target.value);
              }}
            />
            {errors.email && touched.email ? (
              <Box className="invalid-feedback">{errors.email}</Box>
            ) : null}
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              placeholder={t('LOGIN_LICENSE')}
              name="licenseKey"
              value={values.licenseKey}
              onChange={(event) => {
                setFieldValue('licenseKey', event.target.value);
              }}
            />
            {errors.licenseKey && touched.licenseKey ? (
              <Box className="invalid-feedback">{errors.licenseKey}</Box>
            ) : null}
          </div>
          <div className="form-group">
            <button className="btn btn-primary w-100" type="submit">
              {t('SUBMIT')}
            </button>
          </div>
          {/* <div className="mt-3 text-center">
            <span>
              {t('ALREADY_HAVE_AN_ACCOUNT')}
              <a
                className="linkTxt"
                href="#"
                onClick={() => {
                  toggleView && toggleView(1);
                }}
              >
                {t('LOGIN')}
              </a>
            </span>
          </div> */}
        </form>
      </div>
    </>
  );
};
