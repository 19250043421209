import { AnyAction, Reducer } from 'redux';
import { IAppState } from '../../../../store';
import map from 'lodash/map';
import { STATUS } from '../../../../constant';
import {
  RETRIEVE_SUBSCRIPTION_LIST__SUCCESS,
  RETRIEVE_SUBSCRIPTION_LIST__FAILURE,
  RETRIEVE_SUBSCRIPTION_LIST__START,
  SUBMIT_COUPON_LIST__SUCCESS,
  SUBMIT_COUPON_LIST__START,
  SUBMIT_COUPON_LIST__FAILURE,
  SELECT_SUBSCRIPTION,
  SUBMIT_REGISTRATION__START,
  SUBMIT_REGISTRATION__FAILURE,
  SUBMIT_REGISTRATION__SUCCESS,
  RESEND_VERIFICATION_CODE__START,
  RESEND_VERIFICATION_CODE__FAILURE,
  RESEND_VERIFICATION_CODE__SUCCESS,
  SEND_VERIFICATION_CODE__START,
  SEND_VERIFICATION_CODE__FAILURE,
  SEND_VERIFICATION_CODE__SUCCESS,
  SUBMIT_PAYMENT__SUCCESS,
  SUBMIT_PAYMENT__FAILURE,
  SUBMIT_PAYMENT__START,
  UPDATE_SUBSCRIPTION__START,
  UPDATE_SUBSCRIPTION__FAILURE,
  UPDATE_SUBSCRIPTION__SUCCESS,
  RETRIEVE_LICENSE_DATA__START,
  RETRIEVE_LICENSE_DATA__FAILURE,
  RETRIEVE_LICENSE_DATA__SUCCESS,
  CANCEL_SUBSCRIPTION__START,
  CANCEL_SUBSCRIPTION__FAILURE,
  CANCEL_SUBSCRIPTION__SUCCESS
} from '../../actions';
import { setLocalstorage } from '../../../../helper';
import { ICoupon } from '../../models/coupon.model';
import { ILicenseData } from '../../models/subscription.model';

export interface IRegisterPageState {
  subscriptionList: string[];
  coupon: ICoupon | null;
  selectedSubscription: number | null;
  retrieveSubscriptionListAPIStatus: STATUS;
  retrieveCouponListAPIStatus: STATUS;
  createUserAPIStatus: STATUS;
  resendVerificationCodeAPIStatus: STATUS;
  sendVerificationCodeAPIStatus: STATUS;
  updateSubscriptionAPIStatus: STATUS;
  paymentAPIStatus: STATUS;
  retrieveLicenseDataAPIStatus: STATUS;
  cancelSubscriptionAPIStatus: STATUS;
  licenseData: ILicenseData | null;
}

export const defaultState: IRegisterPageState = {
  coupon: null,
  subscriptionList: [],
  selectedSubscription: null,
  licenseData: null,
  paymentAPIStatus: STATUS.NOT_STARTED,
  retrieveSubscriptionListAPIStatus: STATUS.NOT_STARTED,
  retrieveCouponListAPIStatus: STATUS.NOT_STARTED,
  createUserAPIStatus: STATUS.NOT_STARTED,
  resendVerificationCodeAPIStatus: STATUS.NOT_STARTED,
  sendVerificationCodeAPIStatus: STATUS.NOT_STARTED,
  updateSubscriptionAPIStatus: STATUS.NOT_STARTED,
  retrieveLicenseDataAPIStatus: STATUS.NOT_STARTED,
  cancelSubscriptionAPIStatus: STATUS.NOT_STARTED
};

export const registerPageReducer: Reducer<IRegisterPageState> = (
  state: IRegisterPageState = defaultState,
  action: AnyAction
): IRegisterPageState => {
  switch (action.type) {
    case RETRIEVE_SUBSCRIPTION_LIST__SUCCESS: {
      const { subscriptionList } = action.payload;
      const subscriptionListIds = map(subscriptionList, (datum) => {
        return datum.id;
      });
      return {
        ...state,
        subscriptionList: subscriptionListIds,
        retrieveSubscriptionListAPIStatus: STATUS.SUCCESS
      };
    }
    case RETRIEVE_SUBSCRIPTION_LIST__START: {
      return {
        ...state,
        subscriptionList: defaultState.subscriptionList,
        retrieveSubscriptionListAPIStatus: STATUS.LOADING
      };
    }
    case RETRIEVE_SUBSCRIPTION_LIST__FAILURE: {
      return {
        ...state,
        subscriptionList: defaultState.subscriptionList,
        retrieveSubscriptionListAPIStatus: STATUS.FAILURE
      };
    }
    case SUBMIT_COUPON_LIST__SUCCESS: {
      const { coupon } = action.payload;
      return {
        ...state,
        coupon,
        retrieveCouponListAPIStatus: STATUS.SUCCESS
      };
    }
    case SUBMIT_COUPON_LIST__START: {
      return {
        ...state,
        coupon: defaultState.coupon,
        retrieveCouponListAPIStatus: STATUS.LOADING
      };
    }
    case SUBMIT_COUPON_LIST__FAILURE: {
      return {
        ...state,
        coupon: defaultState.coupon,
        retrieveCouponListAPIStatus: STATUS.FAILURE
      };
    }
    case SELECT_SUBSCRIPTION: {
      const { subscriptionId } = action.payload;
      setLocalstorage('subscriptionId', subscriptionId);
      return {
        ...state,
        selectedSubscription: subscriptionId
      };
    }
    case SUBMIT_REGISTRATION__START: {
      return {
        ...state,
        createUserAPIStatus: STATUS.LOADING
      };
    }
    case SUBMIT_REGISTRATION__FAILURE: {
      return {
        ...state,
        createUserAPIStatus: STATUS.FAILURE
      };
    }
    case SUBMIT_REGISTRATION__SUCCESS: {
      return {
        ...state,
        createUserAPIStatus: STATUS.SUCCESS
      };
    }
    case RESEND_VERIFICATION_CODE__START: {
      return {
        ...state,
        resendVerificationCodeAPIStatus: STATUS.LOADING
      };
    }
    case RESEND_VERIFICATION_CODE__FAILURE: {
      return {
        ...state,
        resendVerificationCodeAPIStatus: STATUS.FAILURE
      };
    }
    case RESEND_VERIFICATION_CODE__SUCCESS: {
      return {
        ...state,
        resendVerificationCodeAPIStatus: STATUS.SUCCESS
      };
    }
    case SEND_VERIFICATION_CODE__START: {
      return {
        ...state,
        sendVerificationCodeAPIStatus: STATUS.LOADING
      };
    }
    case SEND_VERIFICATION_CODE__FAILURE: {
      return {
        ...state,
        sendVerificationCodeAPIStatus: STATUS.FAILURE
      };
    }
    case SEND_VERIFICATION_CODE__SUCCESS: {
      return {
        ...state,
        sendVerificationCodeAPIStatus: STATUS.SUCCESS
      };
    }

    case SUBMIT_PAYMENT__START: {
      return {
        ...state,
        paymentAPIStatus: STATUS.LOADING
      };
    }
    case SUBMIT_PAYMENT__FAILURE: {
      return {
        ...state,
        paymentAPIStatus: STATUS.FAILURE
      };
    }
    case SUBMIT_PAYMENT__SUCCESS: {
      return {
        ...state,
        paymentAPIStatus: STATUS.SUCCESS
      };
    }
    case UPDATE_SUBSCRIPTION__START: {
      return {
        ...state,
        updateSubscriptionAPIStatus: STATUS.LOADING
      };
    }
    case UPDATE_SUBSCRIPTION__FAILURE: {
      return {
        ...state,
        updateSubscriptionAPIStatus: STATUS.FAILURE
      };
    }
    case UPDATE_SUBSCRIPTION__SUCCESS: {
      return {
        ...state,
        updateSubscriptionAPIStatus: STATUS.SUCCESS
      };
    }
    case RETRIEVE_LICENSE_DATA__START: {
      return {
        ...state,
        licenseData: defaultState.licenseData,
        retrieveLicenseDataAPIStatus: STATUS.LOADING
      };
    }
    case RETRIEVE_LICENSE_DATA__FAILURE: {
      return {
        ...state,
        licenseData: defaultState.licenseData,
        retrieveLicenseDataAPIStatus: STATUS.FAILURE
      };
    }
    case RETRIEVE_LICENSE_DATA__SUCCESS: {
      const { licenseData } = action.payload;
      return {
        ...state,
        licenseData,
        retrieveLicenseDataAPIStatus: STATUS.SUCCESS
      };
    }
    case CANCEL_SUBSCRIPTION__START: {
      return {
        ...state,
        cancelSubscriptionAPIStatus: STATUS.LOADING
      };
    }
    case CANCEL_SUBSCRIPTION__FAILURE: {
      return {
        ...state,
        cancelSubscriptionAPIStatus: STATUS.FAILURE
      };
    }
    case CANCEL_SUBSCRIPTION__SUCCESS: {
      return {
        ...state,
        licenseData: defaultState.licenseData,
        cancelSubscriptionAPIStatus: STATUS.SUCCESS
      };
    }
    default:
      return state;
  }
};

export const getCurrentPageSubscriptions = (state: IAppState) => {
  return state.register.ui.registerPage.subscriptionList;
};

export const getSubscriptionsAPIStatus = (state: IAppState) => {
  return state.register.ui.registerPage.retrieveSubscriptionListAPIStatus;
};

export const getAppliedCoupon = (state: IAppState) => {
  return state.register.ui.registerPage.coupon;
};

export const getCouponsAPIStatus = (state: IAppState) => {
  return state.register.ui.registerPage.retrieveCouponListAPIStatus;
};

export const getSelectedSubscription = (state: IAppState) => {
  return state.register.ui.registerPage.selectedSubscription;
};

export const getCreateUserAPIStatus = (state: IAppState) => {
  return state.register.ui.registerPage.createUserAPIStatus;
};

export const getResendVerificationCodeAPIStatus = (state: IAppState) => {
  return state.register.ui.registerPage.resendVerificationCodeAPIStatus;
};

export const getSendVerificationCodeAPIStatus = (state: IAppState) => {
  return state.register.ui.registerPage.sendVerificationCodeAPIStatus;
};

export const getPaymentAPIStatus = (state: IAppState) => {
  return state.register.ui.registerPage.paymentAPIStatus;
};

export const getUpdateSubscriptionAPIStatus = (state: IAppState) => {
  return state.register.ui.registerPage.updateSubscriptionAPIStatus;
};

export const getLicenseData = (state: IAppState) => {
  return state.register.ui.registerPage.licenseData;
};

export const getLicenseDataAPIStatus = (state: IAppState) => {
  return state.register.ui.registerPage.retrieveLicenseDataAPIStatus;
};

export const getCancelSubscriptionAPIStatus = (state: IAppState) => {
  return state.register.ui.registerPage.cancelSubscriptionAPIStatus;
};
