import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Formik, FormikHelpers as FormikActions, FormikProps } from 'formik';
import { RouteComponentProps } from 'react-router-dom';
import { Box } from '@chakra-ui/core';

import {
  getLocalstorage,
  emailValidator,
  phoneNumberValidator,
  changeLanguage,
  getMessage
} from '../../helper';
import { ERROR_MESSAGE, SUCCESS_MESSAGE, STATUS } from '../../constant';
import {
  EditProfileForm,
  IProfileEditorFormValues
} from './EditProfile_editorForm.component';
import { editProfile } from '../actions';
import { User } from '../models/user';
import { loginWithStoredDetails } from '../../modules/auth/actions/auth.action';
import { getEditProfileAPIStatus } from '../reducers/editProfile.reducer';
import { IAppState } from '../../store';
import { FullPageLoader } from './Loader.component';
import { IWarehouseTruck } from '../../modules/warehouseTruckRegistration/models/warehouseTruckRegistration.model';
import { getWarehouseTruckSelector } from '../../modules/warehouseTruckRegistration/selectors/index.page.selector';
import { retrieveWarehouseTruck } from '../../modules/warehouseTruckRegistration/actions';

export interface IProps extends RouteComponentProps {
  className?: string;
  toggleView: () => void;
  t: any;
  editProfile: typeof editProfile;
  loginWithStoredDetails: typeof loginWithStoredDetails;
  retrieveWarehouseTruck: typeof retrieveWarehouseTruck;
  user: User;
  toastManager: any;
  editProfileAPIStatus: STATUS;
  warehouseTrucks: IWarehouseTruck[];
}

export class EditProfileComponent extends PureComponent<IProps> {
  static defaultProps: Partial<IProps> = {
    className: ''
  };

  componentDidMount() {
    this._retrieveWarehouseTruck();
  }

  async onFormSubmit(
    values: IProfileEditorFormValues,
    actions: FormikActions<IProfileEditorFormValues>
  ) {
    const {
      editProfile,
      toastManager,
      toggleView,
      loginWithStoredDetails
    } = this.props;
    actions.setSubmitting(true);
    try {
      if (values.language) {
        changeLanguage(values.language);
      }
      await editProfile(values);
      await loginWithStoredDetails();
      toggleView();
      actions.setSubmitting(false);
      toastManager.add(
        SUCCESS_MESSAGE.EDIT_PROFILE[
          values.language === 'en'
            ? 'en'
            : values.language === 'au'
            ? 'au'
            : 'uk'
        ],
        {
          appearance: 'success',
          autoDismiss: true
        }
      );
    } catch (err) {
      actions.setSubmitting(false);
      const message =
        err?.errorObj?.message ??
        err?.message ??
        getMessage(ERROR_MESSAGE.EDIT_PROFILE);
      toastManager.add(message, { appearance: 'error', autoDismiss: true });
    }
  }

  validateForm(values: IProfileEditorFormValues) {
    const errors: any = {};

    if (!values.name) {
      errors.name = getMessage(ERROR_MESSAGE.required);
    }

    if (!values.email) {
      errors.email = getMessage(ERROR_MESSAGE.required);
    } else if (!emailValidator(values.email)) {
      errors.email = getMessage(ERROR_MESSAGE.email);
    }

    if (!values.code) {
      errors.code = getMessage(ERROR_MESSAGE.required);
    }

    if (!values.phone) {
      errors.phone = getMessage(ERROR_MESSAGE.required);
    } else if (!phoneNumberValidator(values.phone)) {
      errors.phone = getMessage(ERROR_MESSAGE.phone);
    }

    if (!values.language) {
      errors.language = getMessage(ERROR_MESSAGE.required);
    }

    return errors;
  }

  async _retrieveWarehouseTruck() {
    try {
      const userId: string =
        this.props.user.parent_id && this.props.user.parent_id !== 0
          ? this.props.user.parent_id.toString()
          : this.props.user.user_id;
      await this.props.retrieveWarehouseTruck(userId);
    } catch (err) {
      console.log(':: _retrieveWarehouseTruck err ', err);
    }
  }

  render() {
    const { className, t, user, editProfileAPIStatus } = this.props;
    return (
      <div className={className}>
        {editProfileAPIStatus === STATUS.LOADING ? (
          <Box p={'lg'}>
            <FullPageLoader />
          </Box>
        ) : null}
        <div className="_cstModal">
          <div className="_mdBody">
            <h4 className="_mdheadng">{t('UPDATE_PROFILE')}</h4>
            <h6>
              <b>{t('LICENSE_KEY')}:</b> {user.licence_id}
            </h6>
            <div className="mt-5">
              <Formik
                validate={this.validateForm.bind(this)}
                initialValues={{
                  name: user.name,
                  email: user.email,
                  code: user.country_id,
                  phone: user.phone_no,
                  role: user.role,
                  language: getLocalstorage('lang'),
                  image: user.file_name,
                  truck: user.truck
                }}
                validateOnMount
                onSubmit={(
                  values: IProfileEditorFormValues,
                  actions: FormikActions<IProfileEditorFormValues>
                ) => {
                  this.onFormSubmit(values, actions);
                }}
                component={(
                  formikProps: FormikProps<IProfileEditorFormValues>
                ) => {
                  return (
                    <EditProfileForm
                      {...formikProps}
                      {...this.props}
                      {...this.state}
                    />
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IAppState) => {
  const editProfileAPIStatus = getEditProfileAPIStatus(state);
  const warehouseTrucks = getWarehouseTruckSelector(state);

  return {
    editProfileAPIStatus,
    warehouseTrucks
  };
};

/* istanbul ignore next */
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      editProfile,
      loginWithStoredDetails,
      retrieveWarehouseTruck
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProfileComponent);
