/* istanbul ignore file */
import React, { FunctionComponent } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { RouteComponentProps } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { Box, Text, Link } from '@chakra-ui/core';

import { ClassDivisionImage } from '../../../common/components/helpers/guide_class_division.component';

interface IProps extends RouteComponentProps {
  className: string;
}

interface IGuide {
  classesLabel: string | null;
  classes: string | null;
  description: string;
  url: string;
}

export const Guide: FunctionComponent<IProps> = () => {
  const guideData: IGuide[] = [
    {
      classesLabel: null,
      classes: null,
      description: 'VEHICLE FIRE',
      url: 'https://admin.pdgapp.com.au/guidepdf/GUIDE0.pdf'
    },
    {
      classesLabel: '1.1, 1.2, 1.3, 1.5',
      classes: '1_1.5',
      description: 'EXPLOSIVE',
      url: 'https://admin.pdgapp.com.au/guidepdf/GUIDE112.pdf'
    },
    {
      classesLabel: '1.4, 1.6',
      classes: '1.4_1.6',
      description: 'EXPLOSIVE',
      url: 'https://admin.pdgapp.com.au/guidepdf/GUIDE114.pdf'
    },
    {
      classesLabel: '1.4S',
      classes: '1.4',
      description: 'EXPLOSIVE',
      url: 'https://admin.pdgapp.com.au/guidepdf/GUIDE114S.pdf'
    },
    {
      classesLabel: '2.1',
      classes: '2.1',
      description: 'FLAMMABLE COMPRESSED GAS',
      url: 'https://admin.pdgapp.com.au/guidepdf/GUIDE118.pdf'
    },
    {
      classesLabel: '2.2',
      classes: '2.2',
      description: 'NON-FLAMMABLE COMPRESSED GAS',
      url: 'https://admin.pdgapp.com.au/guidepdf/GUIDE121.pdf'
    },
    {
      classesLabel: '2.3',
      classes: '2.3',
      description: 'GASES TOXIC AND OR CORROSIVE',
      url: 'https://admin.pdgapp.com.au/guidepdf/GUIDE117.pdf'
    },
    {
      classesLabel: '2.3 SUB-CLASS 8',
      classes: '2.3',
      description: 'GASES - CHLORINE',
      url: 'https://admin.pdgapp.com.au/guidepdf/GUIDE124.pdf'
    },
    {
      classesLabel: '3',
      classes: '3',
      description: 'FLAMMABLE LIQUID',
      url: 'https://admin.pdgapp.com.au/guidepdf/GUIDE127.pdf'
    },
    {
      classesLabel: '4.1',
      classes: '4.1',
      description: 'FLAMMABLE SOLIDS',
      url: 'https://admin.pdgapp.com.au/guidepdf/GUIDE134.pdf'
    },
    {
      classesLabel: '4.2',
      classes: '4.2',
      description: 'SPONTANEUOSLY COMBUSTIBLE',
      url: 'https://admin.pdgapp.com.au/guidepdf/GUIDE136.pdf'
    },
    {
      classesLabel: '4.3',
      classes: '4.3',
      description: 'DANGEROUS WHEN WET',
      url: 'https://admin.pdgapp.com.au/guidepdf/GUIDE139.pdf'
    },
    {
      classesLabel: '5.1',
      classes: '5.1',
      description: 'OXIDIZING AGENTS',
      url: 'https://admin.pdgapp.com.au/guidepdf/GUIDE143.pdf'
    },
    {
      classesLabel: '5.2',
      classes: '5.2',
      description: 'ORGANIC PEROXIDE',
      url: 'https://admin.pdgapp.com.au/guidepdf/GUIDE148.pdf'
    },
    {
      classesLabel: '6.1',
      classes: '6.1',
      description: 'TOXIC',
      url: 'https://admin.pdgapp.com.au/guidepdf/GUIDE153.pdf'
    },
    {
      classesLabel: '6.2',
      classes: '6.2',
      description: 'INFECTOUS SUBSTANCE',
      url: 'https://admin.pdgapp.com.au/guidepdf/GUIDE158.pdf'
    },
    {
      classesLabel: '7',
      classes: '7',
      description: 'RADIOACTIVE',
      url: 'https://admin.pdgapp.com.au/guidepdf/GUIDE163.pdf'
    },
    {
      classesLabel: '8',
      classes: '8',
      description: 'CORROSIVE',
      url: 'https://admin.pdgapp.com.au/guidepdf/GUIDE1538.pdf'
    },
    {
      classesLabel: '9',
      classes: '9',
      description: 'MIXED LOAD',
      url: 'https://admin.pdgapp.com.au/guidepdf/GUIDE111.pdf'
    },
    {
      classesLabel: '9',
      classes: '9',
      description: 'POLYMERIC BEADS',
      url: 'https://admin.pdgapp.com.au/guidepdf/GUIDE133.pdf'
    },
    {
      classesLabel: '9',
      classes: '9',
      description: 'LITHIUM ION BATTERIES',
      url: 'https://admin.pdgapp.com.au/guidepdf/GUIDE147.pdf'
    },
    {
      classesLabel: '9',
      classes: '9',
      description: 'NICKEL METAL HYDROXIDE BATTERIES',
      url: 'https://admin.pdgapp.com.au/guidepdf/GUIDE171.pdf'
    },
    {
      classesLabel: null,
      classes: null,
      description: 'MARINE POLLUTANT',
      url: 'https://admin.pdgapp.com.au/guidepdf/GUIDE171M.pdf'
    },
    {
      classesLabel: null,
      classes: null,
      description: 'IRRITANT',
      url: 'https://admin.pdgapp.com.au/guidepdf/GUIDE159.pdf'
    }
  ];
  return (
    <section className="dbPrWid">
      <Box className="container">
        <Box className="row justify-content-between">
          {guideData.map((datum: IGuide, index: number) => (
            <Box className="col-6" key={index}>
              <Box className="ab-bx-emergency-guide" p="lg">
                <Link href={datum.url} target="_blank">
                  <Box className="row justify-content-between">
                    <Box className="col-5">
                      <Text fontSize="medium" fontWeight="bold" color="#DC143C">
                        {datum.description}
                      </Text>
                      <Text fontSize="small" fontWeight="bold" color="#000">
                        {datum.classesLabel}
                      </Text>
                    </Box>
                    {datum.classes ? (
                      <Box className="col-7">
                        <ClassDivisionImage
                          className="signage-display col-auto"
                          class_division={datum.classes}
                        />
                      </Box>
                    ) : null}
                  </Box>
                </Link>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </section>
  );
};

export default withNamespaces()(withToastManager(Guide));
