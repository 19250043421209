import { AnyAction, Reducer } from 'redux';
import { IAppState } from '../../../../store';
import { STATUS } from '../../../../constant';
import {
  FORGOT_PASSWORD__SUCCESS,
  FORGOT_PASSWORD__FAILURE
} from '../../actions';

export interface IForgotPasswordPageState {
  forgotPasswordAPIStatus: STATUS;
  email: string;
  licenseKey: string;
}

export const defaultState: IForgotPasswordPageState = {
  forgotPasswordAPIStatus: STATUS.NOT_STARTED,
  email: '',
  licenseKey: ''
};

export const forgotPasswordPageReducer: Reducer<IForgotPasswordPageState> = (
  state: IForgotPasswordPageState = defaultState,
  action: AnyAction
): IForgotPasswordPageState => {
  switch (action.type) {
    case FORGOT_PASSWORD__FAILURE: {
      return {
        ...state,
        email: defaultState.email,
        forgotPasswordAPIStatus: STATUS.FAILURE
      };
    }
    case FORGOT_PASSWORD__SUCCESS: {
      const { email, licenseKey } = action.payload;
      return {
        ...state,
        email,
        licenseKey,
        forgotPasswordAPIStatus: STATUS.SUCCESS
      };
    }
    default: {
      return state;
    }
  }
};

export const getForgotPasswordAPIStatus = (state: IAppState) => {
  return state.forgotPassword.ui.forgotPasswordPage.forgotPasswordAPIStatus;
};

export const getForgotPasswordEmail = (state: IAppState) => {
  return state.forgotPassword.ui.forgotPasswordPage.email;
};

export const getForgotPasswordLicenseKey = (state: IAppState) => {
  return state.forgotPassword.ui.forgotPasswordPage.licenseKey;
};
