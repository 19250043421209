/* istanbul ignore file */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Location } from 'history';
import { Box, Alert } from '@chakra-ui/core';
import { withNamespaces } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { IAppState } from '../../../store';
import { IReport } from '../models/reports-list.model';
import { STATUS, ERROR_MESSAGE, ROUTES } from '../../../constant';
import { retrieveReports } from '../actions';
import { retrieveOpertorsList } from '../../../common/actions';
import { createUrl, getLocalstorage, getQueryParamValue, updateQueryString } from '../../../helper';
import { FullPageLoader } from '../../../common/components/Loader.component';
import { ErrorAlert } from '../../../common/components/ErrorAlert.component';
import { getReportsAPIStatus } from '../reducers/ui';
import { getReportsSelector } from '../selectors/index.page.selector';
import ReportListItem from '../components/ReportListItem.component';
import { IReportsParams } from '../services/api.service';
import { USER_ROLE, User } from '../../../common/models/user';
import { getLoggedInUserDetails } from '../../auth/selectors/auth.selectors';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody
} from '../../../common/components/containers/modal.component';
import queryString from 'query-string';
import { getOperatorListSelector } from '../../../common/reducers/operator.reducer';
import { OperatorDropDown } from '../../../common/components/OperatorDropDown.component';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  reports: IReport[] | null;
  retrieveReportsAPIStatus: STATUS;
  t: any;
  user: User;
  operatorList: User[];
  retrieveReports: typeof retrieveReports;
  retrieveOpertorsList: typeof retrieveOpertorsList;
}

export interface IQueryString {
  operatorId?: string;
}

export const parseQueryString = (location: Location): IQueryString => {
  const search = location?.search;

  const queryParams = queryString.parse(search);

  const operatorId = getQueryParamValue(queryParams, 'operatorId');

  return {
    operatorId
  };
};

export const ReportList: FunctionComponent<IProps> = (props: IProps) => {
  const {
    className,
    reports,
    retrieveReportsAPIStatus,
    history,
    user,
    operatorList,
    location,
    retrieveReports,
    retrieveOpertorsList,
    t
  } = props;
  const { operatorId: selectedOperatorId } = useSelector(() =>
    parseQueryString(location)
  );
  
  const [
    isSubscriptionExpiredPopupOpen,
    setIsSubscriptionExpiredPopupOpen
  ] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const lang = getLocalstorage('lang');

  const _retrieveReports = async (operatorId?: string) => {
    try {
     if (operatorId) {
      const params: IReportsParams = {
        operatorId
      };
      await retrieveReports(params);
     }
    } catch (err) {
      console.log(':: _retrieveReports err ', err);
    }
  };

  const setSelectedOperatorId = (operatorId: string | undefined) => {
    updateQueryString(history, {
      operatorId
    });
  };

  useEffect(() => {
    if (
      !selectedOperatorId &&
      user.role &&
      user.role === USER_ROLE.OPERATOR &&
      selectedOperatorId !== user.user_id
    ) {
      setSelectedOperatorId(user.user_id);
    }
  }, [user.user_id, selectedOperatorId]);

  const _retrieveOpertorsList = async () => {
    try {
      await retrieveOpertorsList();
    } catch (err) {
      console.log(':: _retrieveOpertorsList err ', err);
    }
  };

  // const getRenderCreateReportModal = () => {
  //   const addReportUrl = createUrl(ROUTES.ADD_REPORT, {
  //     operatorId: selectedOperatorId
  //   });
  //   return (
  //     <Modal isOpen={isOpen} size="6xl" onClose={() => setIsOpen(false)}>
  //       <ModalOverlay />
  //       <ModalContent>
  //         <ModalBody>
  //           <Box className="row justify-content-between">
  //             <Box className="col-lg-4 col-12">
  //               <PrimaryButton
  //                 backgroundColor="#DC143C"
  //                 className="create-report-btn"
  //                 color="#fff"
  //                 onClick={() => {
  //                   history.push(addReportUrl);
  //                   setIsOpen(false);
  //                 }}
  //                 width="100%"
  //               >
  //                 Create Report using Class or Division
  //               </PrimaryButton>
  //             </Box>
  //             <Box className="col-lg-4 col-12">
  //               <PrimaryButton
  //                 backgroundColor="#DC143C"
  //                 className="create-report-btn"
  //                 color="#fff"
  //                 onClick={() => {
  //                   history.push(addReportUrl);
  //                   setIsOpen(false);
  //                 }}
  //                 width="100%"
  //               >
  //                 Create Report using UN Number
  //               </PrimaryButton>
  //             </Box>
  //             <Box className="col-lg-4 col-12">
  //               <PrimaryButton
  //                 backgroundColor="#DC143C"
  //                 className="create-report-btn"
  //                 color="#fff"
  //                 onClick={() => {
  //                   history.push(addReportUrl);
  //                   setIsOpen(false);
  //                 }}
  //                 width="100%"
  //               >
  //                 Create Report with Name
  //               </PrimaryButton>
  //             </Box>
  //           </Box>
  //         </ModalBody>
  //       </ModalContent>
  //     </Modal>
  //   );
  // };

  const getRenderSubscriptionModal = () => {
    return (
      <Modal
        isOpen={isSubscriptionExpiredPopupOpen}
        size="5xl"
        onClose={() => setIsSubscriptionExpiredPopupOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Box className="row justify-content-between">
              <Box className="col-12" style={{ fontWeight: 'bold' }}>
                {t('YOUR_SUBSCRIPTION_ENDED')}
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  };

  useEffect(() => {
    if (user.role !== USER_ROLE.OPERATOR) {
      _retrieveOpertorsList();
    }
  }, [user.role]);

  useEffect(() => {
    _retrieveReports(selectedOperatorId);
  }, [selectedOperatorId]);

  const getFilterReport = (reportList: IReport[], searchTermText?: string) => {
    if (!searchTermText) return reportList;

    return reportList
      .filter((report: IReport) => report.report_name.toLowerCase().includes(searchTermText.toLowerCase()))
  }
  return (
    <Box className={className}>
      <section className="dbPrWid">
        <Box className="container">
          <Box className="row align-items-center">
            <Box className="col-12 col-lg-6">
              <Box className='db-bx-1'>
                <input
                  type="text"
                  value={searchTerm}
                  placeholder="Enter Manifest Name"
                  disabled={!selectedOperatorId}
                  onChange={(event) => {
                    setSearchTerm(event.target.value);
                  }}
                />
              </Box>
            </Box>
            <Box className="col-12 col-lg-3 add-new-report">
              <button
                className="btn-primary btn-14 w-100"
                disabled={!selectedOperatorId}
                onClick={() => {
                  const addReportUrl = createUrl(ROUTES.ADD_REPORT, {
                    operatorId: selectedOperatorId
                  });
                  history.push(addReportUrl);
                  // if (user.subscription_status) {
                  //   setIsOpen(true);
                  // } else {
                  //   setIsSubscriptionExpiredPopupOpen(true);
                  // }
                }}
              >
                {t('ADD_NEW_REPORT')}
              </button>
            </Box>
            {user.role !== USER_ROLE.OPERATOR ? (
              <OperatorDropDown
                setSelectedOperatorId={(operatorId?: string) => {
                  setSelectedOperatorId(operatorId);
                }}
                operatorList={operatorList}
                user={user}
                selectedOperatorId={selectedOperatorId}
              />
            ) : null}
          </Box>
          <Box mt="md">
            {retrieveReportsAPIStatus === STATUS.LOADING ? (
              <Box p={'lg'}>
                <FullPageLoader />
              </Box>
            ) : null}
            {retrieveReportsAPIStatus === STATUS.FAILURE ? (
              <Box>
                <ErrorAlert
                  errorMessage={
                    ERROR_MESSAGE.DATA_FAILURE[
                      lang === 'en' ? 'en' : lang === 'au' ? 'au' : 'uk'
                    ]
                  }
                  retry={() => {
                    _retrieveReports(selectedOperatorId)
                  }}
                />
              </Box>
            ) : null}
            {retrieveReportsAPIStatus === STATUS.SUCCESS &&
            (!reports || !reports.length) ? (
              <Box mt="md">
                <Alert mb="xs" status="warning">
                  {
                    ERROR_MESSAGE.NO_MATCHING_RESULT[
                      lang === 'en' ? 'en' : lang === 'au' ? 'au' : 'uk'
                    ]
                  }
                </Alert>
              </Box>
            ) : null}
            {retrieveReportsAPIStatus === STATUS.SUCCESS &&
            selectedOperatorId &&
            reports &&
            reports.length
              ? getFilterReport(reports, searchTerm)
                  .map((report: IReport) => (
                    <ReportListItem
                      key={report.report_id}
                      report={report}
                      selectedOperatorId={selectedOperatorId as string}
                      {...props}
                    />
                  ))
              : null}
          </Box>
        </Box>
      </section>


      {/* {isOpen ? getRenderCreateReportModal() : null} */}

      {isSubscriptionExpiredPopupOpen ? getRenderSubscriptionModal() : null}
    </Box>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: IAppState) => {
  const reports = getReportsSelector(state);
  const retrieveReportsAPIStatus = getReportsAPIStatus(state);
  const loggedInUserDetails = getLoggedInUserDetails(state);
  const operatorList = getOperatorListSelector(state);
  return {
    reports,
    operatorList,
    retrieveReportsAPIStatus,
    user: loggedInUserDetails.user
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  return bindActionCreators({ retrieveReports, retrieveOpertorsList }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(withToastManager(withRouter(ReportList))));