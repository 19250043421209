import React from 'react';
import { Global, css } from '@emotion/core';
import { withTheme } from 'emotion-theming';

const GlobalStyles = withTheme(({ theme }) => (
  <Global
    styles={css`
      html,
      body {
        height: 100%;
        font-size: 14px;
        color: ${theme.colors.gray[900]};
      }

      * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
      }

      *:focus {
        outline: none;
      }

      body {
        margin: 0;
        background-color: #fff;
      }

      .error-message,
      .invalid-feedback {
        display: block;
        color: ${theme.colors.danger.default};
        font-size: ${theme.fontSizes.sm};
        padding-top: ${theme.space['2xs']};
      }

      .page-header {
        margin-bottom: ${theme.fontSizes['2xl']};

        .sub-heading {
          display: block;
          font-size: ${theme.fontSizes.xl};
          color: ${theme.colors.grey7};
          margin: ${theme.space.xs} 0;
        }
      }

      .page-content {
        background: ${theme.colors.white};
        box-shadow: ${theme.shadows.sm};

        .page-section {
          .page-section-title {
            color: ${theme.colors.primaryAction};
            margin-bottom: ${theme.space.xl};
          }
        }

        .actions-container {
          border-top: solid 1px ${theme.colors.gray[200]};
          bottom: 0;
          background: ${theme.colors.white};
        }
      }

      .text-uppercase {
        text-transform: uppercase;
      }

      .text-truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .sticky-actions-container {
        position: sticky;
        bottom: 0;
        border-top: solid 1px ${theme.colors.gray[200]};
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
        background: ${theme.colors.white};
        padding: ${theme.space.md} ${theme.space.xl};
        margin: -${theme.space.xl};
        margin-top: ${theme.space.xl};
      }

      a {
        color: ${theme.colors.primary.default};

        &:hover,
        &:focus {
          color: ${theme.colors.primary[400]};
        }

        &:active {
          color: ${theme.colors.primary[200]};
        }

        &.secondary {
          color: ${theme.colors.gray[700]};

          &:hover,
          &:focus {
            color: ${theme.colors.secondary[500]};
          }

          &:active {
            color: ${theme.colors.secondary[200]};
          }
        }
      }

      ul {
        list-style: none;
      }

      #root {
        height: 100%;
      }

      .error {
        font-size: 12px;
        color: #e70000;
      }

      .pac-container {
        z-index: 1400;
      }

      .table {
        &__column-heading {
          color: ${theme.colors.grey7};
          font-size: ${theme.fontSizes.sm};
          font-weight: bold;
          text-transform: uppercase;
        }
      }

      .react-toast-notifications {
        &__container {
          z-index: 10000 !important;
        }

        &__toast__content {
          word-break: break-word;
          overflow-wrap: break-word;
          max-width: 300px;
        }
      }
    `}
  />
));

export default GlobalStyles;
