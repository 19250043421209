/* istanbul ignore file */

import React, { PureComponent, ReactElement } from 'react';
import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';
import { SortableHandle } from 'react-sortable-hoc';
import { Box, Text } from '@chakra-ui/core';
import { Menu } from '@material-ui/icons';

import {
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  AccordionIcon
} from '../../common/components/containers/accordion.component';
import { Switch } from '../../common/components/forms';

const DragHandle = SortableHandle(() => <Menu />);

interface IProps {
  children: ReactElement;
  title: string;
  className?: string;
  isActive: boolean;
  toggleSwitch: (id: string, isActive: boolean) => void;
  id: string;
  isDisabled?: boolean;
  canNotBeToggled?: boolean;
  isUpdated?: boolean;
}

class AccordionComponent extends PureComponent<IProps> {
  toggleSwitchButton = () => {
    const { toggleSwitch, id, isActive } = this.props;
    toggleSwitch(id, !isActive);
  };

  render() {
    const {
      children,
      title,
      className,
      isActive,
      isDisabled,
      isUpdated,
      canNotBeToggled
    } = this.props;
    return (
      <div className={className}>
        <AccordionItem>
          <AccordionHeader>
            <Box width="full">
              <Box className="row justify-content-between align-items-center">
                <Box className="col-auto">
                  <span className="draggable">
                    <DragHandle /> {isUpdated ? <span className="dot" /> : ''}
                  </span>
                  <Text as="span" className="heading" fontWeight="bold" ml="sm">
                    {title}
                  </Text>
                </Box>
                <Box
                  className="col-auto"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <span className="switch-action">
                    <Switch
                      color="green"
                      isChecked={!!isActive}
                      isDisabled={isDisabled || canNotBeToggled}
                      onChange={this.toggleSwitchButton}
                      mr="sm"
                      pb={0}
                      mb="-4px"
                    />
                  </span>
                </Box>
              </Box>
            </Box>
            <AccordionIcon className={'arrow-icon'} />
          </AccordionHeader>
          <AccordionPanel>{children}</AccordionPanel>
        </AccordionItem>
      </div>
    );
  }
}

export const Accordion = styled(AccordionComponent)`
  .arrow-icon {
    position: relative;
    z-index: 1;
  }
  .draggable {
    cursor: move;
    position: relative;
    .dot {
      display: inline-block;
      background: ${themeGet('colors.seagreen')};
      height: 10px;
      width: 10px;
      position: absolute;
      border-radius: 50%;
      left: 13px;
      top: -2px;
    }
  }

  .heading {
    color: ${themeGet('colors.secondary.default')};
    padding-left: ${themeGet('space.sm')};
    font-weight: bold;
  }
`;
