import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import { Box, Text, BoxProps } from '@chakra-ui/core';
import { themeGet } from '@styled-system/theme-get';

import { PrimaryButton as CustomPrimaryButton } from '../components/buttons';

export * from './typography';

export const Content: FunctionComponent<BoxProps> = () => {
  return (
    <Box
      bg="white"
      margin="xl"
      paddingY="xl"
      paddingX="lg"
      boxShadow="sm"
      borderRadius="md"
    />
  );
};

export const P: FunctionComponent<BoxProps> = (props: BoxProps) => {
  return <Text as="p" {...props} />;
};

export const Caption = styled.span`
  font-size: ${themeGet('fontSizes.xs')};
`;

export const Description = styled.span`
  font-size: ${themeGet('fontSizes.xs')};
  font-weight: ${themeGet('fontWeights.medium')};
`;

export const Tag = styled.span`
  font-size: ${themeGet('fontSizes.xs')};
  font-weight: ${themeGet('fontWeights.medium')};
  text-transform: uppercase;
`;

export const Row = styled.div<{ justify?: string; align?: string }>`
  display: flex;
  justify-content: ${({ justify }) => justify || 'flex-start'};
  align-items: ${({ align }) => align || 'flex-start'};
`;

export const Error = styled.div`
  background: #ffe0e0;
  padding: 10px;
  border: solid 1px ${themeGet('colors.danger.default')};
  font-size: 14px;
  margin: 20px;
  font-weight: ${themeGet('fontWeights.medium')};
`;

export const PrimaryButton = styled(CustomPrimaryButton)``;

PrimaryButton.displayName = 'Button';
