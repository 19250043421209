import React, {
  FunctionComponent,
  RefObject,
  createRef,
  useState
} from 'react';
import { FormikProps } from 'formik';
import { Box } from '@chakra-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { IProps } from './EditProfile.component';
import {
  ISD_CODE,
  PHONE_NUMBER_LENGTH,
  ALLOW_PROFILE_PIC_EXTENSION,
  ERROR_MESSAGE
} from '../../constant';
import Camera from '../../assets/images/camera.png';
import MessageIcon from '../../assets/images/message-icon.png';
import PhoneIcon from '../../assets/images/phone-icon.png';
import UserIcon from '../../assets/images/user-icon.png';
import { getLocalstorage, getMessage } from '../../helper';
import ProfileIcon from '../../assets/images/profile.png';
import UKFlag from '../../assets/images/flag-en-uk.png';
import USFlag from '../../assets/images/flag-en-us.png';
import AUFlag from '../../assets/images/flag-en-au.png';
import NZLFlag from '../../assets/images/flag-nzl.png';
import INFlag from '../../assets/images/flag-en-in.png';
import { USER_ROLE } from '../models/user';

export interface IProfileEditorFormValues {
  image: string;
  name: string;
  email: string;
  role: string;
  code: string;
  phone: string;
  language: string;
  truck?: number;
  newImage?: string;
}

export const EditProfileForm: FunctionComponent<
  FormikProps<IProfileEditorFormValues> & Partial<IProps>
> = ({
  values,
  handleSubmit,
  warehouseTrucks,
  errors,
  touched,
  setFieldValue,
  t,
  toggleView,
  toastManager
}) => {
  const _downloadLinkRef: RefObject<HTMLInputElement> = createRef();
  const lang = getLocalstorage('lang');
  const [language, setLanguage] = useState(lang);
  const [isOpen, setIsOpen] = useState(false);
  const showSelectedLanguage = (selectedLanguage: string) => {
    switch (selectedLanguage) {
      case 'en': {
        return (
          <Box className="_dwnArrEditForm">
            <i>
              <img src={USFlag} alt="Logo" />
            </i>{' '}
            US: English
            <i style={{ float: 'right' }}>
              <KeyboardArrowDownIcon />
            </i>
          </Box>
        );
      }
      case 'uk': {
        return (
          <Box className="_dwnArrEditForm">
            <i>
              <img src={UKFlag} alt="Logo" />
            </i>{' '}
            UK: English
            <i style={{ float: 'right' }}>
              <KeyboardArrowDownIcon />
            </i>
          </Box>
        );
      }
      case 'au': {
        return (
          <Box className="_dwnArrEditForm">
            <i>
              <img src={AUFlag} alt="Logo" />
            </i>{' '}
            AU: English
            <i style={{ float: 'right' }}>
              <KeyboardArrowDownIcon />
            </i>
          </Box>
        );
      }
      case 'nzl': {
        return (
          <Box className="_dwnArrEditForm">
            <i>
              <img src={NZLFlag} alt="Logo" />
            </i>{' '}
            NZL: English
            <i style={{ float: 'right' }}>
              <KeyboardArrowDownIcon />
            </i>
          </Box>
        );
      }
      case 'in': {
        return (
          <Box className="_dwnArrEditForm">
            <i>
              <img src={INFlag} alt="Logo" />
            </i>{' '}
            IN: English
            <i style={{ float: 'right' }}>
              <KeyboardArrowDownIcon />
            </i>
          </Box>
        );
      }
      default: {
        return (
          <Box className="_dwnArrEditForm">
            <i>
              <img src={UKFlag} alt="Logo" />
            </i>{' '}
            US: English
            <i style={{ float: 'right' }}>
              <KeyboardArrowDownIcon />
            </i>
          </Box>
        );
      }
    }
  };
  const uploadImage = (file: any) => {
    const rex = /(?:\.([^.]+))?$/;
    const extension = rex.exec(file.name);
    if (
      extension &&
      extension.length &&
      ALLOW_PROFILE_PIC_EXTENSION.includes(extension[1])
    ) {
      const reader = new FileReader();
      reader.onload = (evt) => {
        setFieldValue('newImage', evt.target?.result);
      };
      reader.readAsDataURL(file);
    } else {
      toastManager.add(getMessage(ERROR_MESSAGE.PROFILE_PIC_INVALID), {
        appearance: 'error',
        autoDismiss: true
      });
    }
  };
  return (
    <Box onSubmit={handleSubmit}>
      <Box className="_mdprofile mt-4">
        <img src={values.newImage ?? values.image ?? ProfileIcon} alt="user" />
        <i>
          <img
            src={Camera}
            alt="upload"
            onClick={() => {
              _downloadLinkRef.current?.click();
            }}
          />
        </i>
        <input
          type="file"
          ref={_downloadLinkRef}
          onChange={(event) => {
            if (event.target.files?.length) {
              uploadImage(event.target.files[0]);
            }
          }}
          style={{ display: 'none' }}
        />
        {errors.newImage && touched.newImage ? (
          <Box className="invalid-feedback">{errors.newImage}</Box>
        ) : null}
      </Box>
      <Box className="mt-4 update_form">
        <form>
          <Box className="form-group">
            <input
              type="text"
              className="form-control"
              name="name"
              value={values.name}
              onChange={(event) => {
                setFieldValue('name', event.target.value);
              }}
            />
            <i className="_Ficon">
              <img src={UserIcon} alt="icon" />
            </i>
            {errors.name && touched.name ? (
              <Box className="invalid-feedback">{errors.name}</Box>
            ) : null}
          </Box>
          <Box className="form-group">
            <input
              type="text"
              className="form-control"
              name="email"
              disabled
              value={values.email}
              onChange={(event) => {
                setFieldValue('email', event.target.value);
              }}
            />
            <i className="_Ficon">
              <img src={MessageIcon} alt="icon" />
            </i>
            {errors.email && touched.email ? (
              <Box className="invalid-feedback">{errors.email}</Box>
            ) : null}
          </Box>
          <Box className="row _mdChange">
            <Box className="col-12 col-lg-3">
              <Box className="form-group">
                <select
                  className="form-control"
                  name="code"
                  value={values.code}
                  onChange={(event) => {
                    setFieldValue('code', event.target.value);
                  }}
                >
                  {ISD_CODE.map((datum) => (
                    <option value={datum} key={datum}>
                      {datum}
                    </option>
                  ))}
                </select>
                {errors.code && touched.code ? (
                  <Box className="invalid-feedback">{errors.code}</Box>
                ) : null}
              </Box>
            </Box>
            <Box className="col-12 col-lg-9">
              <Box className="form-group">
                <input
                  type="number"
                  className="form-control"
                  name="phone"
                  value={values.phone}
                  required
                  onChange={(event) => {
                    const number = event.target.value.match(/\d/g)?.join('');
                    const phone =
                      number && number.length > PHONE_NUMBER_LENGTH
                        ? values.phone
                        : number;
                    setFieldValue('phone', phone);
                  }}
                />
                <i className="_Ficon">
                  <img src={PhoneIcon} alt="icon" />
                </i>
                {errors.phone ? (
                  <Box className="invalid-feedback">{errors.phone}</Box>
                ) : null}
              </Box>
            </Box>
          </Box>
          <Box className="form-group">
            <select
              className="form-control"
              name="role"
              disabled
              value={values.role}
              onChange={(event) => {
                setFieldValue('role', event.target.value);
                if (event.target.value !== 'operator') {
                  setFieldValue('truck', null);
                }
              }}
            >
              <option value="">Select Role</option>
              <option value={USER_ROLE.COMPANY_ADMIN}>Company Owner</option>
              <option value={USER_ROLE.MANAGER}>Manager</option>
              <option value={USER_ROLE.SUPERVISOR}>Supervisor</option>
              <option value={USER_ROLE.OPERATOR}>Operator</option>
              <option value={USER_ROLE.ADMIN}>ADMIN</option>
            </select>
            {errors.role && touched.role ? (
              <Box className="invalid-feedback">{errors.role}</Box>
            ) : null}
          </Box>
          {values.role === USER_ROLE.OPERATOR ? (
            <Box className="form-group">
              <select
                className="form-control"
                name="truck"
                value={values.truck}
                onChange={(event) => {
                  setFieldValue('truck', event.target.value);
                }}
              >
                <option value="">Select Warehouse or truck</option>
                {warehouseTrucks?.map((datum) => (
                  <option value={datum.id} key={datum.id}>{`${
                    datum.type === 1 ? 'Truck' : 'Warehouse'
                  } - ${datum.name}`}</option>
                ))}
              </select>
              {errors.truck && touched.truck ? (
                <Box className="invalid-feedback">{errors.truck}</Box>
              ) : null}
            </Box>
          ) : null}
          {console.log(':::: language ?? lang ', language, ' ?? ', lang)}
          <Box className="form-group">
            <div onClick={() => setIsOpen(!isOpen)}>
              {showSelectedLanguage(language ?? lang)}
            </div>
            {isOpen ? (
              <ul className="_lgAreaEditForm">
                <li
                  onClick={() => {
                    setFieldValue('language', 'au');
                    setLanguage('au');
                    setIsOpen(false);
                  }}
                >
                  <i>
                    <img src={AUFlag} alt="Logo" />
                  </i>{' '}
                  <a href="#">AU: English</a>
                </li>
                <li
                  onClick={() => {
                    setFieldValue('language', 'en');
                    setLanguage('en');
                    setIsOpen(false);
                  }}
                >
                  <i>
                    <img src={USFlag} alt="Logo" />
                  </i>{' '}
                  <a href="#">US: English</a>
                </li>
                <li
                  onClick={() => {
                    setFieldValue('language', 'uk');
                    setLanguage('uk');
                    setIsOpen(false);
                  }}
                >
                  <i>
                    <img src={UKFlag} alt="Logo" />
                  </i>{' '}
                  <a href="#">UK: English</a>
                </li>
                <li
                  onClick={() => {
                    setFieldValue('language', 'in');
                    setLanguage('in');
                    setIsOpen(false);
                  }}
                >
                  <i>
                    <img src={INFlag} alt="Logo" />
                  </i>{' '}
                  <a href="#">IN: English</a>
                </li>
                <li
                  onClick={() => {
                    setFieldValue('language', 'nzl');
                    setLanguage('nzl');
                    setIsOpen(false);
                  }}
                >
                  <i>
                    <img src={NZLFlag} alt="Logo" />
                  </i>{' '}
                  <a href="#">NZL: English</a>
                </li>
              </ul>
            ) : null}
            {errors.language && touched.language ? (
              <Box className="invalid-feedback">{errors.language}</Box>
            ) : null}
          </Box>
          <Box className="form-group mt-4">
            <button className="btn btn-primary w-100" type="submit">
              {t('UPDATE')}
            </button>
          </Box>
        </form>
        <Box
          className="_close"
          onClick={() => {
            toggleView && toggleView();
          }}
        >
          x {t('CLOSE')}
        </Box>
      </Box>
    </Box>
  );
};
