import { Reducer, combineReducers } from 'redux';
import { IWarehouseTruckPageState, warehouseTruckPageReducer } from './ui';
import { IWarehouseTruckInfoState, warehouseTruckInfoReducer } from './entity';

export interface IWarehouseTruckPageUI {
  warehouseTruckPage: IWarehouseTruckPageState;
}

export interface IWarehouseTruckEntity {
  warehouseTruckInfo: IWarehouseTruckInfoState;
}

export interface IWarehouseTruckState {
  ui: IWarehouseTruckPageUI;
  entities: IWarehouseTruckEntity;
}

const ui = combineReducers({
  warehouseTruckPage: warehouseTruckPageReducer
});

const entities = combineReducers({
  warehouseTruckInfo: warehouseTruckInfoReducer
});

export const warehouseTruckReducer: Reducer<IWarehouseTruckState> = combineReducers(
  {
    ui,
    entities
  }
);
