/* istanbul ignore file */
import React, { FunctionComponent, RefObject, createRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Box } from '@chakra-ui/core';

import { getMessage, transformName } from '../../../helper';
import CloseIcon from '../../../assets/images/close.png';
import { ClassDivisionImage } from '../../../common/components/helpers/class_division_image.component';
import Camera from '../../../assets/images/camera-2.png';
import { IProductInCart } from '../models/un-number.model';
import { H1 } from '../../../common/components';
import { ALLOW_PROFILE_PIC_EXTENSION, ERROR_MESSAGE } from '../../../constant';
import { IProductValue } from '../reducers/ui';
import ClearIcon from '@material-ui/icons/Clear';
// import { EPGLinks } from '../../../common/components/helpers/epg_link.component';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  unNumberInCart: IProductInCart;
  removeUnNumber: () => void;
  addImage: (image: string) => void;
  removeImage: (image: string) => void;
  addProductValue: (productValue: IProductValue) => void;
  t: any;
}

export const UnNumberCartItemComponent: FunctionComponent<IProps> = (
  props: IProps
) => {
  const {
    t,
    toastManager,
    unNumberInCart,
    removeUnNumber,
    removeImage,
    addImage,
    addProductValue
  } = props;
  const _downloadLinkRef: RefObject<HTMLInputElement> = createRef();
  const uploadImage = (file: any) => {
    const rex = /(?:\.([^.]+))?$/;
    const extension = rex.exec(file.name);
    if (
      extension &&
      extension.length &&
      ALLOW_PROFILE_PIC_EXTENSION.includes(extension[1])
    ) {
      const reader = new FileReader();
      reader.onload = (evt) => {
        evt.target?.result && addImage(evt.target.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      toastManager.add(getMessage(ERROR_MESSAGE.PROFILE_PIC_INVALID), {
        appearance: 'error',
        autoDismiss: true
      });
    }
  };
  return (
    <Box className="col-12 col-lg-6">
      <Box className="ab-bx-2 p-3">
        <Box className="row">
          <Box
            onClick={() => {
              removeUnNumber();
            }}
            className="sc-close"
            title="Remove Goods"
          >
            <img src={CloseIcon} alt="close" />
          </Box>
          <Box className="col-12 col-lg-9">
            <Box className="" mb="4xl">
              <Box className="mr-3" mb="md">
                <H1 className="cart-class-division">
                  <strong>
                    {t('CLASS_DIVISION')}:
                    {unNumberInCart.class_division ??
                      unNumberInCart.class_division_value}
                  </strong>
                </H1>
              </Box>
              <Box>
                <p>
                  <strong>
                    {transformName(100, unNumberInCart.name_description)}
                  </strong>
                </p>
                <p>
                  <strong>
                    {t('UN')} {unNumberInCart.un_no}
                  </strong>
                </p>
              </Box>
            </Box>
            <Box className="mt-5 update_form">
              <form>
                <Box className="form-group">
                  <select
                    className="form-control product-value"
                    name="packagingType"
                    value={unNumberInCart.packagingType ?? ''}
                    onChange={(event) => {
                      addProductValue({
                        mesurement: unNumberInCart.mesurement,
                        quantity: unNumberInCart.quantity,
                        packagingType: event.target.value
                      });
                    }}
                    required
                    style={{ width: '100%', height: '46px' }}
                  >
                    <option value="">Select Packaging</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </Box>
                <Box className="form-group">
                  <select
                    className="form-control product-value"
                    name="mesurement"
                    value={unNumberInCart.mesurement?.toLowerCase() ?? ''}
                    onChange={(event) => {
                      addProductValue({
                        quantity: unNumberInCart.quantity,
                        packagingType: unNumberInCart.packagingType,
                        mesurement: event.target.value
                      });
                    }}
                    style={{ width: '100%', height: '46px' }}
                  >
                    <option value="">Select Measurement</option>
                    <option value="ltr">Ltr</option>
                    <option value="kg">KG</option>
                  </select>
                </Box>
                <Box className="form-group">
                  <input
                    type="number"
                    className="form-control product-value"
                    value={unNumberInCart.quantity ?? ''}
                    maxLength={4}
                    min="0"
                    placeholder="Enter Quantity"
                    onChange={(event) => {
                      addProductValue({
                        mesurement: unNumberInCart.mesurement,
                        packagingType: unNumberInCart.packagingType,
                        quantity: event.target.value
                          ? Number(event.target.value)
                          : undefined
                      });
                    }}
                    style={{ width: '100%', padding: '22px' }}
                  />
                </Box>
              </form>
            </Box>
            <Box className="mt-5">
              <Box className="d-flex justify-content-start align-items-center rmt-10">
                {unNumberInCart.images.length !== 3 ? (
                  <Box className="trkImg2 mr-2">
                    <img
                      src={Camera}
                      alt="upload"
                      onClick={() => {
                        _downloadLinkRef.current?.click();
                      }}
                    />
                    <input
                      type="file"
                      ref={_downloadLinkRef}
                      onChange={(event) => {
                        if (event.target.files?.length) {
                          uploadImage(event.target.files[0]);
                        }
                      }}
                      style={{ display: 'none' }}
                    />
                  </Box>
                ) : null}
                {unNumberInCart.images.length !== 0 &&
                  unNumberInCart.images.map((image: string, index: number) => (
                    <Box className="trkImg2 mr-2" key={index}>
                      <Box
                        onClick={() => {
                          removeImage(image);
                        }}
                        className="sc-close-file"
                        title="Remove Goods"
                      >
                        <ClearIcon />
                      </Box>
                      <img src={image} alt="product" />
                    </Box>
                  ))}
              </Box>
            </Box>
          </Box>
          <Box className="col-12 col-lg-3" mt="md">
            <ClassDivisionImage
              className="w-100"
              class_division={unNumberInCart.class_division_value}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UnNumberCartItemComponent;
