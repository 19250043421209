import { AnyAction, Reducer } from 'redux';
import { IAppState } from '../../../../store';
import { STATUS } from '../../../../constant';
import { IAboutUs } from '../../models/about_us.model';
import {
  RETRIEVE_ABOUT_US_CONTENT__START,
  RETRIEVE_ABOUT_US_CONTENT__FAILURE,
  RETRIEVE_ABOUT_US_CONTENT__SUCCESS
} from '../../actions';

export interface IAboutUsPageState {
  aboutUs: IAboutUs | null;
  retrieveAboutUsAPIStatus: STATUS;
}

export const defaultState: IAboutUsPageState = {
  aboutUs: null,
  retrieveAboutUsAPIStatus: STATUS.NOT_STARTED
};

export const aboutUsPageReducer: Reducer<IAboutUsPageState> = (
  state: IAboutUsPageState = defaultState,
  action: AnyAction
): IAboutUsPageState => {
  switch (action.type) {
    case RETRIEVE_ABOUT_US_CONTENT__START: {
      return {
        ...state,
        aboutUs: defaultState.aboutUs,
        retrieveAboutUsAPIStatus: STATUS.LOADING
      };
    }
    case RETRIEVE_ABOUT_US_CONTENT__FAILURE: {
      return {
        ...state,
        aboutUs: defaultState.aboutUs,
        retrieveAboutUsAPIStatus: STATUS.FAILURE
      };
    }
    case RETRIEVE_ABOUT_US_CONTENT__SUCCESS: {
      const { aboutUs } = action.payload;
      return {
        ...state,
        aboutUs,
        retrieveAboutUsAPIStatus: STATUS.SUCCESS
      };
    }
    default: {
      return state;
    }
  }
};

export const getAboutUs = (state: IAppState) => {
  return state.aboutUs.ui.aboutUsPage.aboutUs;
};
export const getAboutUsAPIStatus = (state: IAppState) => {
  return state.aboutUs.ui.aboutUsPage.retrieveAboutUsAPIStatus;
};
