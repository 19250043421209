import React, { FunctionComponent, useState } from 'react';
import { FormikProps } from 'formik';
import { Box } from '@chakra-ui/core';
import Delete from '@material-ui/icons/Delete';

import { IProps } from './AddWarehouseTruck.component';
import { getMessage } from '../../../helper';
import Logo from '../../../assets/images/logo.png';
import {
  ALLOW_TRUCK_REGISTRATION_EXTENSION,
  ERROR_MESSAGE
} from '../../../constant';
import { ConfirmAlert } from '../../../common/components';

export interface IAddWarehouseTruckFormValues {
  name: string;
  type: number;
  truckRegistrationImage?: string;
}

export const AddWarehouseTruckForm: FunctionComponent<
  FormikProps<IAddWarehouseTruckFormValues> & Partial<IProps>
> = ({
  values,
  handleSubmit,
  errors,
  touched,
  setFieldValue,
  warehouseTruck,
  t,
  toggleView,
  toastManager
}) => {
  const [isOpenConfirmDeleteModel, setIsOpenConfirmDeleteModel] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(!!values.truckRegistrationImage);
  const uploadFile = (file: any) => {
    const rex = /(?:\.([^.]+))?$/;
    const extension = rex.exec(file.name);
    if (
      extension &&
      extension.length &&
      ALLOW_TRUCK_REGISTRATION_EXTENSION.includes(extension[1])
    ) {
      const reader = new FileReader();
      reader.onload = (evt) => {
        setFieldValue('truckRegistrationImage', evt.target?.result);
      };
      reader.readAsDataURL(file);
    } else {
      toastManager.add(getMessage(ERROR_MESSAGE.TRUCK_REGISTRATION_INVALID), {
        appearance: 'error',
        autoDismiss: true
      });
    }
  };
  return (
    <Box onSubmit={handleSubmit}>
      <Box className="mt-4 update_form">
        <form>
          <Box className="form-group">
            <select
              className="form-control"
              name="type"
              value={values.type}
              onChange={(event) => {
                setFieldValue('type', Number(event.target.value));
              }}
            >
              <option value={1} key={1}>
                Truck
              </option>
              <option value={2} key={2}>
                Warehouse
              </option>
            </select>
            {errors.type && touched.type ? (
              <Box className="invalid-feedback">{errors.type}</Box>
            ) : null}
          </Box>
          <Box className="form-group">
            <input
              type="text"
              className="form-control"
              name="name"
              placeholder={
                values.type === 1 ? 'Registration Number' : 'Warehouse Name'
              }
              value={values.name}
              onChange={(event) => {
                setFieldValue('name', event.target.value);
              }}
            />
            {errors.name && touched.name ? (
              <Box className="invalid-feedback">{errors.name}</Box>
            ) : null}
          </Box>
          {values.type === 1 ? (
            <Box className="form-group">
              {!values.truckRegistrationImage ? (
                <>
                  <label htmlFor="custom-file-upload" className="filupp">
                    <span className="filupp-file-name js-value">
                      {t('TRUCK_REGISTRATION')}
                    </span>
                    <input
                      type="file"
                      name="attachment-file"
                      id="custom-file-upload"
                      onChange={(event) => {
                        if (event.target.files?.length) {
                          uploadFile(event.target.files[0]);
                        }
                      }}
                    />
                  </label>
                </>
              ) : (
                <Box className="_filePreview_track_registration">
                  
                    <img
                      src={Logo}
                      className="rotate"
                      alt="logo"
                      style={{ display: !isImageLoaded ? 'none' : 'block' }}
                    />
                    <img
                      className='w-100'
                      src={values.truckRegistrationImage}
                      alt={t('TRUCK_REGISTRATION')}
                      onLoad={() => { setIsImageLoaded(false) }}
                      style={{ display: isImageLoaded ? 'none' : 'block' }}
                    />
                  <Box className='row justify-content-end'>
                    <button
                      className="btn btn-primary col-auto"
                      type="button"
                      onClick={() => {
                        setIsOpenConfirmDeleteModel(true);
                      }}
                    >
                      Delete {' '} <Delete />
                    </button>
                  </Box>
                </Box>
              )}
              {errors.name && touched.name ? (
                <Box className="invalid-feedback">{errors.name}</Box>
              ) : null}
            </Box>
          ) : null}
          <Box className="form-group mt-4">
            <button className="btn btn-primary w-100" type="submit">
              {warehouseTruck?.id ? t('UPDATE') : t('SUBMIT')}
            </button>
          </Box>
        </form>
        <Box
          className="_close"
          onClick={() => {
            toggleView && toggleView();
          }}
        >
          x {t('CLOSE')}
        </Box>
      </Box>
      {isOpenConfirmDeleteModel && (
        <ConfirmAlert
          title={'Delete Truck Registration Image Confirm'}
          description={'Do you really want to unload this Truck Registration Image?'}
          onCancel={() => setIsOpenConfirmDeleteModel(false)}
          onConfirm={() => {
            setFieldValue('truckRegistrationImage', undefined);
            setIsOpenConfirmDeleteModel(false)
          }}
          type={'danger'}
          cancelText="No"
          confirmText="Yes"
        />
      )}
    </Box>
  );
};
