import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { bindActionCreators } from 'redux';

import { AuthProvider, IAuthContext } from './auth.context';
import { IAppState } from '../store';
import { User } from '../common/models/user';
import {
  logout,
  loginWithStoredDetails,
  sendDeviceToken
} from '../modules/auth/actions/auth.action';
import { getLoggedInUserDetails } from '../modules/auth/selectors/auth.selectors';
import { AuthStatus } from '../modules/auth/models/auth-status';
import { FullPageLoader } from '../common/components';
import { retrieveNotification } from '../common/actions';

interface IProps {
  status: AuthStatus;
  user: null | User;
  permissions: string[];
  logout: typeof logout;
  loginWithStored: typeof loginWithStoredDetails;
  sendDeviceToken: typeof sendDeviceToken;
  retrieveNotification: typeof retrieveNotification;
}

export class AuthContainer extends PureComponent<IProps> {
  componentDidMount() {
    ((this.props.loginWithStored() as unknown) as Promise<any>).catch(() => {
      // ignore error for validating stored token
    });
  }

  logout = () => {
    this.props.logout();
  };

  render() {
    const authContext: IAuthContext = {
      status: this.props.status,
      user: this.props.user
    };

    return (
      <AuthProvider value={authContext}>
        {this.props.status !== AuthStatus.UNKNOWN ? (
          this.props.children
        ) : (
          <FullPageLoader />
        )}
      </AuthProvider>
    );
  }
}

const mapStateToProps = (state: IAppState) => {
  const loggedInUserDetails = getLoggedInUserDetails(state);

  return {
    status: loggedInUserDetails.status,
    user: loggedInUserDetails.user
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  return bindActionCreators(
    {
      loginWithStored: loginWithStoredDetails,
      logout: logout,
      sendDeviceToken,
      retrieveNotification
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthContainer) as any;
