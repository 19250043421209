import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Formik, FormikHelpers as FormikActions, FormikProps } from 'formik';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { emailValidator, getMessage, setLocalstorage } from '../../../helper';
import { ERROR_MESSAGE, ROUTES } from '../../../constant';
import { loginWithEmailAndPassword } from '../actions/auth.action';
import { LoginForm, IFormValues } from '../components/login-form.component';
import Logo3 from '../../../assets/images/logo3.png';
import { AuthRepository } from '../repositories/auth.repository';
import { USER_ROLE } from '../../../common/models/user';
import { GenerateOTP } from './genrateOTP.page';

export interface IProps extends RouteComponentProps {
  className?: string;
  toastManager: any;
  toggleView: (modalNum: number | null) => void;
  t: any;
  loginWithEmailAndPassword: typeof loginWithEmailAndPassword;
}

export interface IState {
  step: number;
}

export class Login extends PureComponent<IProps> {
  state: IState = {
    step: 1
  };
  static defaultProps: Partial<IProps> = {
    className: ''
  };

  async onFormSubmit(values: IFormValues, actions: FormikActions<IFormValues>) {
    const { password, email, license: licence_key } = values;
    const {
      loginWithEmailAndPassword,
      history,
      toggleView,
      toastManager
    } = this.props;

    const fcmToken = Math.random().toString();
    AuthRepository.storeDeviceToken(fcmToken);
    const params = {
      email,
      password,
      licence_key,
      device_token: fcmToken,
      device_type: 'web'
    };
    actions.setSubmitting(true);
    try {
      const user: any = await loginWithEmailAndPassword({ params });
      if (user) {
        if (user.userId) {
          setLocalstorage('email', email);
          this.setState({
            step: 2
          });
        } else {
          actions.setSubmitting(false);
          toggleView(null);
          if (user && user.role && user.role !== USER_ROLE.ADMIN) {
            history.push(ROUTES.REPORTS);
          } else {
            history.push(ROUTES.MANIFEST);
          }
        }
      } else {
        toastManager.add('Something went worng. Please try again later', {
          appearance: 'error',
          autoDismiss: true
        });
      }
    } catch (err) {
      console.log(':: login err ', err);
      let message = '';

      if (err && err.code === 400) {
        if (err?.errorObj?.message) {
          message = err?.errorObj?.message;
        } else if (err?.message) {
          message = err?.message;
        } else {
          message = getMessage(ERROR_MESSAGE.SIGN_IN);
        }
      } else {
        message = getMessage(ERROR_MESSAGE.SIGN_IN);
      }
      toastManager.add(message, { appearance: 'error', autoDismiss: true });
      actions.setSubmitting(false);
    }
  }

  validateForm(values: IFormValues) {
    const errors: any = {};
    if (!values.email) {
      errors.email = getMessage(ERROR_MESSAGE.required);
    } else if (!emailValidator(values.email)) {
      errors.email = getMessage(ERROR_MESSAGE.email);
    }

    if (!values.password) {
      errors.password = getMessage(ERROR_MESSAGE.required);
    }

    if (!values.license) {
      errors.license = getMessage(ERROR_MESSAGE.required);
    }

    return errors;
  }

  onEmailChange() {
    this.setState({
      userNotFound: false
    });
  }

  render() {
    const { className, toggleView } = this.props;

    return (
      <div className={className}>
        <div className="_cstModal">
          <div className="_mdBody">
            <div className="text-center">
              <img src={Logo3} alt="logo" />
            </div>
            <div className="mt-5">
              {this.state.step === 1 ? (
                <Formik
                  validate={this.validateForm.bind(this)}
                  initialValues={{ email: '', password: '', license: '' }}
                  validateOnMount
                  onSubmit={(
                    values: IFormValues,
                    actions: FormikActions<IFormValues>
                  ) => {
                    this.onFormSubmit(values, actions);
                  }}
                  component={(formikProps: FormikProps<IFormValues>) => {
                    return (
                      <LoginForm
                        {...formikProps}
                        {...this.props}
                        onEmailChange={this.onEmailChange.bind(this)}
                      />
                    );
                  }}
                />
              ) : null}
              {this.state.step === 2 ? (
                <GenerateOTP
                  {...this.props}
                  backToLogin={() => {
                    this.setState({
                      step: 1
                    });
                  }}
                />
              ) : null}
              <div
                className="_close"
                onClick={() => {
                  toggleView(null);
                }}
              >
                x Close
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      loginWithEmailAndPassword
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(withRouter(Login));
