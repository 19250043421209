/* istanbul ignore file */
import React, { FunctionComponent } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import { createUrl } from '../../../helper';
import { ghsList, IGHS } from '../../../common/components/utils/ghs';
import { GHSImage } from '../../../common/components/helpers/ghs_image.component';
import { ROUTES } from '../../../constant';
import { Header } from '../../../common/components/helpers/header.component';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  t: any;
}

export const GHS: FunctionComponent<IProps> = (props: IProps) => {
  const { history } = props;

  return (
    <div>
      <section className="inn-bnr container-fluid header-with-login">
        <Header isShowSecondLogo={false} {...props} />
      </section>

      <section className="inSectn">
        <div className="inn-container">
          <div className="mt-104">
            <div className="unBox p-4">
              <div className="row">
                {ghsList.map((datum: IGHS) => (
                  <div
                    className="col-12 col-lg-6 mt-3"
                    key={datum.code}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      const ghsUrl = createUrl(ROUTES.GHS_DETAIL, {
                        id: datum.code
                      });
                      history.push(ghsUrl);
                    }}
                  >
                    <div className="ghs-bx">
                      <div className="d-flex align-items-center">
                        <GHSImage ghs={datum.code} />
                        <div className="ml-3">
                          <h5>
                            <strong>{datum.name}</strong>
                          </h5>
                          <span className="d-block db-txt-2">
                            <strong>{datum.code}</strong>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default withNamespaces()(withToastManager(withRouter(GHS)));
