/* istanbul ignore file */
import React, { FunctionComponent } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Box } from '@chakra-ui/core';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import Logo2 from '../../../assets/images/logo2.png';
import LeftBanner from '../../../assets/images/leftbanner.png';

import RegisterComponent, {
  IRegisterFormValues
} from '../components/register.component';
import {
  ROUTES,
  STATUS,
  ERROR_MESSAGE,
  SUCCESS_MESSAGE
} from '../../../constant';
import { IAppState } from '../../../store';
import { submitRegistration, retrieveSubscription } from '../actions';
import {
  getCreateUserAPIStatus,
  getSubscriptionsAPIStatus
} from '../reducers/ui';
import { getSelectedSubscriptionSelector } from '../selectors/index-page.selector';
import { ISubscription } from '../models/subscription.model';
import { getMessage } from '../../../helper';
import { ErrorAlert } from '../../../common/components/ErrorAlert.component';
import { FullPageLoader } from '../../../common/components/Loader.component';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  createUserAPIStatus: STATUS;
  retrieveSubscriptionsAPIStatus: STATUS;
  t: any;
  selectedSubscription: ISubscription;
  submitRegistration: typeof submitRegistration;
  retrieveSubscription: typeof retrieveSubscription;
}

export const Register: FunctionComponent<IProps> = (props: IProps) => {
  const {
    className,
    submitRegistration,
    retrieveSubscription,
    retrieveSubscriptionsAPIStatus,
    history,
    toastManager
  } = props;
  const _retrieveSubscription = async () => {
    try {
      await retrieveSubscription();
    } catch (err) {
      console.log(':: _retrieveSubscription err ', err);
    }
  };

  // useEffect(() => {
  //   _retrieveSubscription();
  // }, []);
  const _submitRegistration = async (values: IRegisterFormValues) => {
    try {
      await submitRegistration(values);
      history.push(ROUTES.VERIFICATION);
      toastManager.add(getMessage(SUCCESS_MESSAGE.CRETE_USER), {
        appearance: 'success',
        autoDismiss: true
      });
    } catch (err) {
      let message = getMessage(ERROR_MESSAGE.RESEND_VERIFICATION_CODE_FAILED);
      if (err?.errorObj?.message) {
        message = err?.errorObj?.message;
      } else if (
        err?.message &&
        typeof err?.message === 'string' &&
        err?.message
      ) {
        message = err?.message;
      } else if (
        err?.message &&
        typeof err?.message === 'object' &&
        err?.message.length
      ) {
        message = err?.message[0];
      }
      toastManager.add(message, { appearance: 'error', autoDismiss: true });
      console.log(':: _submitRegistration err ', err);
    }
  };
  return (
    <div className={className}>
      {retrieveSubscriptionsAPIStatus === STATUS.LOADING ? (
        <Box p={'lg'}>
          <FullPageLoader />
        </Box>
      ) : null}
      <Box className="signupAr">
        <Box className="lftImage">
          <a href={ROUTES.HOME}>
            <img src={Logo2} className="innLogo" alt="PDG" />
          </a>
          <img src={LeftBanner} alt="PDG banner" />
          {/* {retrieveSubscriptionsAPIStatus === STATUS.SUCCESS &&
          selectedSubscription ? (
            <Box className="_prInfoBx _slPlan">
              <sup>$</sup>
              <span>{selectedSubscription.amount}/</span>
              <small>mo</small>
              <Box className="_lc">
                {`${selectedSubscription.license} ${t('REGISTER_LICENSE')}`}
              </Box>
            </Box>
          ) : null} */}
        </Box>
        <Box className="_snInfo">
          <RegisterComponent
            {...props}
            submitRegistration={(values: IRegisterFormValues) => {
              _submitRegistration(values);
            }}
          />
        </Box>
      </Box>
      {retrieveSubscriptionsAPIStatus === STATUS.FAILURE ? (
        <Box>
          <ErrorAlert
            errorMessage={getMessage(ERROR_MESSAGE.DATA_FAILURE)}
            retry={_retrieveSubscription}
          />
        </Box>
      ) : null}
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: IAppState) => {
  const createUserAPIStatus = getCreateUserAPIStatus(state);
  const selectedSubscription = getSelectedSubscriptionSelector(state);
  const retrieveSubscriptionsAPIStatus = getSubscriptionsAPIStatus(state);
  return {
    createUserAPIStatus,
    selectedSubscription,
    retrieveSubscriptionsAPIStatus
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  return bindActionCreators(
    {
      submitRegistration,
      retrieveSubscription
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(withToastManager(withRouter(Register))));
