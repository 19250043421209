/* istanbul ignore file */

import { Method } from 'axios';
import request from '../../../services/request.service';
import GlobalConfig from '../../../globalConfig';
import { ITermsAndConditions } from '../models/termsAndConditions.model';

interface IServiceProps {
  method: Method;
  headers: Record<string, any>;
  url: string;
  data?: Record<string, any>;
  responseType?: any;
}

export interface ITermsAndConditionsResponse {
  status: number;
  message: string;
  resultData: ITermsAndConditions;
}

export const retrieveContentParams = (lang: string): IServiceProps => ({
  method: 'GET',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(`mobile/cms/privacy-policy/${lang}`)
});

export class TermsAndConditionServices {
  static async retrieveContent(): Promise<ITermsAndConditionsResponse> {
    const lang = 'en'; //getLocalstorage('lang');
    const res = await request(retrieveContentParams(lang));
    return res?.data;
  }
}
