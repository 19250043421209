/* istanbul ignore file */

import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';
import isArray from 'lodash/isArray';
import { NavLink } from 'react-router-dom';
import { Box, Text } from '@chakra-ui/core';
import { ArrowRightOutlined } from '@material-ui/icons';

interface IChildrenRoute {
  id: string;
  name: string;
  link: string;
  permission?: string[];
}

interface IProps {
  className?: string;
  module: {
    id: string;
    name: string;
    link: string;
    icon: FunctionComponent;
    children?: IChildrenRoute[];
    childrenLink?: string | string[];
    permission?: string[];
  };
}

const MenuAccordionComponent: FunctionComponent<IProps> = ({
  className,
  module
}) => {
  const { icon: Icon, children } = module;
  return (
    <div key={module.id} className={className}>
      <NavLink
        className="parent"
        exact={module.link === '/'}
        to={module.link}
        isActive={(match, { pathname }) => {
          if (
            isArray(children) &&
            children.findIndex((item) => pathname.includes(item.link)) >= 0
          ) {
            return true;
          } else if (match) {
            return true;
          } else {
            return false;
          }
        }}
      >
        <Box
          as="span"
          className="icon-wrapper"
          display="inline-block"
          marginRight="xs"
        >
          <Icon />
        </Box>
        {module.name}
      </NavLink>
      {module.children?.length ? (
        <div className="child-wrapper">
          {module.children.map((child: any) => (
            <NavLink
              key={child.id}
              className="parent child"
              exact={module.link === '/'}
              to={child.link}
            >
              <Box
                as="span"
                className="icon-wrapper"
                display="inline-block"
                marginRight="xs"
              ></Box>
              {child.name}
              <Text position="absolute" right="xs" top="10px">
                <ArrowRightOutlined />
              </Text>
            </NavLink>
          ))}
        </div>
      ) : null}
    </div>
  );
};

MenuAccordionComponent.defaultProps = {
  className: ''
};

export const MenuAccordion = styled(MenuAccordionComponent)`
  .child-wrapper {
    display: none;
  }

  .parent {
    color: ${themeGet('colors.gray.500')};
    font-weight: ${themeGet('fontWeights.bold')};
    padding: ${themeGet('space.sm')} ${themeGet('space.sm')};
    display: block;
    cursor: pointer;
    border: none;
    background: transparent;
    width: 100%;
    text-align: left;
    position: relative;
    display: flex;
    align-items: center;

    .icon-wrapper {
      font-size: 0.1rem; /* For Alignment */
    }

    &:hover {
      color: ${themeGet('colors.white')};
      text-decoration: none;
      background-color: rgba(61, 73, 90, 0.75);

      .icon-wrapper {
        color: ${themeGet('colors.success.default')};
      }
    }

    &.active {
      background-color: rgba(61, 73, 90, 0.75);
      color: ${themeGet('colors.white')};
      position: relative;

      + .child-wrapper {
        display: block;
      }

      .accordion-arrow {
        transform: rotateZ(90deg);
      }

      .icon-wrapper {
        color: ${themeGet('colors.success.default')};
      }

      &:before {
        content: '';
        position: absolute;
        width: 4px;
        height: 100%;
        left: 0;
        background-image: linear-gradient(to bottom, #41b763, #22bdbd);
      }
    }

    &.child {
      &.active {
        background-color: rgba(61, 73, 90, 0.25);
        &:before {
          display: none;
        }
      }
    }
  }

  .sub-link {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;

    li.child {
      padding: 12px 0 12px 20px;
      border-bottom: solid 1px #1f314b;
      color: ${themeGet('colors.white')};
      font-weight: ${themeGet('fontWeights.bold')};

      a {
        display: block;
        position: relative;
        color: ${themeGet('colors.grey7')};
        &:hover {
          text-decoration: none;
        }
      }
      .active {
        color: ${themeGet('colors.white')};
        .active-arrow {
          display: block;
        }
      }
    }
    &.expand {
      max-height: 300px;
    }
  }

  .accordion-arrow {
    position: absolute;
    right: 11px;
    top: 16px;
    transition: transform 0.3s;
  }
  .active-arrow {
    display: none;
    position: absolute;
    right: 9px;
    top: 0;
  }
`;
