/* istanbul ignore file */

import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';

import { Content, PrimaryButton } from './Common.component';

interface IProps {
  className?: string;
}

const ErrorViewComponent: FunctionComponent<IProps> = ({ className }) => {
  return (
    <Content className={className}>
      <div className="error-view">
        <h2>Internal server error</h2>
        <p>Uh-oh, something went wrong here</p>
        <PrimaryButton onClick={() => window.location.reload(false)}>
          Retry again
        </PrimaryButton>
      </div>
    </Content>
  );
};

ErrorViewComponent.defaultProps = {
  className: ''
};

export const ErrorView = styled(ErrorViewComponent)`
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 150px;
  h2 {
    font-size: ${themeGet('fontSizes.lg')};
    font-weight: ${themeGet('fontWeights.medium')};
    padding: 20px 0;
  }
  p {
    font-size: ${themeGet('fontSizes.sm')};
    margin-bottom: 25px;
  }
  .error-view {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
