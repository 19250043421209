import { Reducer, combineReducers } from 'redux';
import { IReportsPageState, reportsPageReducer } from './ui';
import { IReportsInfoState, reportsInfoReducer } from './entity';
import { IUnsInfoState, unsInfoReducer } from './entity/unNumber';

export interface IReportsPageUI {
  reportsPage: IReportsPageState;
}

export interface IReportsEntity {
  reportInfo: IReportsInfoState;
  unInfo: IUnsInfoState;
}

export interface IReportsState {
  ui: IReportsPageUI;
  entities: IReportsEntity;
}

const ui = combineReducers({
  reportsPage: reportsPageReducer
});

const entities = combineReducers({
  reportInfo: reportsInfoReducer,
  unInfo: unsInfoReducer
});

export const reportsReducer: Reducer<IReportsState> = combineReducers({
  ui,
  entities
});
