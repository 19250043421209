import { Reducer, combineReducers } from 'redux';
import { IManifestHistoryPageState, mainfestHistoryPageReducer } from './ui';
import {
  IManifestHistoryInfoState,
  manifestHistoryInfoReducer
} from './entity';

export interface IManifestHistoryPageUI {
  manifestHistoryPage: IManifestHistoryPageState;
}

export interface IManifestHistoryEntity {
  manifestHistoryInfo: IManifestHistoryInfoState;
}

export interface IManifestHistoryState {
  ui: IManifestHistoryPageUI;
  entities: IManifestHistoryEntity;
}

const ui = combineReducers({
  manifestHistoryPage: mainfestHistoryPageReducer
});

const entities = combineReducers({
  manifestHistoryInfo: manifestHistoryInfoReducer
});

export const manifestHistoryReducer: Reducer<IManifestHistoryState> = combineReducers(
  {
    ui,
    entities
  }
);
