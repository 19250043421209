/* istanbul ignore file */
import React, { FunctionComponent, useEffect } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { RouteComponentProps } from 'react-router-dom';
import { Box } from '@chakra-ui/core';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { IAppState } from '../../../store';
import { ILegals } from '../models/legals.model';
import { STATUS } from '../../../constant';
import { retrieveContent } from '../actions';
import { getLegals, getLegalsAPIStatus } from '../reducers/ui';
import { FullPageLoader } from '../../../common/components/Loader.component';
import CMSComponent from '../../../common/components/CMS.component';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  legals: ILegals | null;
  retrieveLegalsAPIStatus: STATUS;
  t: any;
  retrieveContent: typeof retrieveContent;
}

export const Legals: FunctionComponent<IProps> = (props: IProps) => {
  const {
    className,
    legals,
    retrieveLegalsAPIStatus,
    t,
    retrieveContent
  } = props;
  const _retrieveContent = async () => {
    try {
      await retrieveContent();
    } catch (err) {
      console.log(':: _retrieveContent err ', err);
    }
  };

  useEffect(() => {
    _retrieveContent();
  }, []);

  return (
    <Box className={className}>
      {retrieveLegalsAPIStatus === STATUS.LOADING ? (
        <Box p={'lg'}>
          <FullPageLoader />
        </Box>
      ) : null}

      <section className="aboutUs">
        <Box className="container">
          <Box className="_abBox">
            <CMSComponent
              retrieveContentAPIStatus={retrieveLegalsAPIStatus}
              content={legals?.content ?? ''}
              retrieveContentData={() => {
                _retrieveContent();
              }}
              title={t('LEGAL')}
            />
          </Box>
        </Box>
      </section>
    </Box>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: IAppState) => {
  const legals = getLegals(state);
  const retrieveLegalsAPIStatus = getLegalsAPIStatus(state);
  return {
    legals,
    retrieveLegalsAPIStatus
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  return bindActionCreators({ retrieveContent }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(withToastManager(Legals)));
