import styled from '@emotion/styled';
import {
  Modal as ChakraModal,
  ModalOverlay as ChakraModalOverlay,
  ModalContent as ChakraModalContent,
  ModalHeader as ChakraModalHeader,
  ModalCloseButton as ChakraModalCloseButton,
  ModalBody as ChakraModalBody,
  ModalFooter as ChakraModalFooter
} from '@chakra-ui/core';
import { themeGet } from '@styled-system/theme-get';

export const Modal = styled(ChakraModal)``;
export const ModalOverlay = styled(ChakraModalOverlay)``;
export const ModalContent = styled(ChakraModalContent)`
  padding: ${themeGet('space.xl')};
  border-radius: ${themeGet('radii.xl')};
  box-shadow: ${themeGet('shadows.2xl')};
`;
export const ModalHeader = styled(ChakraModalHeader)`
  padding: 0;
  margin-bottom: ${themeGet('space.md')};
  text-align: center;
`;
export const ModalCloseButton = styled(ChakraModalCloseButton)`
  top: ${themeGet('space.lg')};
  right: ${themeGet('space.lg')};
`;
export const ModalBody = styled(ChakraModalBody)`
  padding: 0;
`;
export const ModalFooter = styled(ChakraModalFooter)``;
