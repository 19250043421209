import { AnyAction, Reducer } from 'redux';
import { IAppState } from '../../../../store';
import { STATUS } from '../../../../constant';
import {
  RETRIEVE_DASHBOARD_STATS__START,
  RETRIEVE_DASHBOARD_STATS__FAILURE,
  RETRIEVE_DASHBOARD_STATS__SUCCESS
} from '../../actions';
import { IDashboardStats } from '../../model/dashboard.model';

export interface IDashboardPageState {
  dashboardStatsAPIStatus: STATUS;
  dashboardStats: IDashboardStats | null;
}

export const defaultState: IDashboardPageState = {
  dashboardStats: null,
  dashboardStatsAPIStatus: STATUS.NOT_STARTED
};

export const dashboardPageReducer: Reducer<IDashboardPageState> = (
  state: IDashboardPageState = defaultState,
  action: AnyAction
): IDashboardPageState => {
  switch (action.type) {
    case RETRIEVE_DASHBOARD_STATS__START: {
      return {
        ...state,
        dashboardStats: defaultState.dashboardStats,
        dashboardStatsAPIStatus: STATUS.LOADING
      };
    }
    case RETRIEVE_DASHBOARD_STATS__FAILURE: {
      return {
        ...state,
        dashboardStats: defaultState.dashboardStats,
        dashboardStatsAPIStatus: STATUS.FAILURE
      };
    }
    case RETRIEVE_DASHBOARD_STATS__SUCCESS: {
      const { dashboardStats } = action.payload;
      return {
        ...state,
        dashboardStats,
        dashboardStatsAPIStatus: STATUS.SUCCESS
      };
    }
    default: {
      return state;
    }
  }
};

export const getDashboardStatsAPIStatus = (state: IAppState) => {
  return state.dashboard.ui.dashboardPage.dashboardStatsAPIStatus;
};
export const getDashboardStats = (state: IAppState) => {
  return state.dashboard.ui.dashboardPage.dashboardStats;
};
