import { Reducer, combineReducers } from 'redux';
import { ICurrentManifestPageState, currentManifestPageReducer } from './ui';
import {
  ICurrentManifestInfoState,
  currentManifestInfoReducer
} from './entity';

export interface ICurrentManifestPageUI {
  currentManifestPage: ICurrentManifestPageState;
}

export interface ICurrentManifestEntity {
  currentManifestInfo: ICurrentManifestInfoState;
}

export interface ICurrentManifestState {
  ui: ICurrentManifestPageUI;
  entities: ICurrentManifestEntity;
}

const ui = combineReducers({
  currentManifestPage: currentManifestPageReducer
});

const entities = combineReducers({
  currentManifestInfo: currentManifestInfoReducer
});

export const currentManifestReducer: Reducer<ICurrentManifestState> = combineReducers(
  {
    ui,
    entities
  }
);
