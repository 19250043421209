/* istanbul ignore file */
import React, { FunctionComponent, useEffect } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Box, Alert } from '@chakra-ui/core';
import { withNamespaces } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Location } from 'history';
import queryString from 'query-string';
import { ThunkDispatch } from 'redux-thunk';

import { STATUS, ERROR_MESSAGE } from '../../../constant';
import { IAppState } from '../../../store';
import {
  retrieveCompanyList,
  retrieveCompanyBasedOperatorsList,
  retrieveOpertorsList
} from '../../../common/actions';
import {
  getCompanyListAPIStatus,
  getOperatorListAPIStatus
} from '../../../common/reducers/company_and_operator.reducer';
import { FullPageLoader } from '../../../common/components';
import { ErrorAlert } from '../../../common/components/ErrorAlert.component';
import {
  getMessage,
  getQueryParamValue,
  isAdmin,
  updateQueryString
} from '../../../helper';
import { User, USER_ROLE } from '../../../common/models/user';
import {
  getCompanyListSelector,
  getCompanyBasedOperatorListSelector
} from '../../../common/reducers/company_and_operator.reducer';
import { getOperatorListSelector } from '../../../common/reducers/operator.reducer';
import { getLoggedInUserDetails } from '../../auth/selectors/auth.selectors';
import { getCurrentManifestAPIStatus } from '../reducers/ui';
import {
  retrieveCurrentManifest,
  unloadProduct,
  unloadAllProducts,
  resetData
} from '../actions';
import { getCurrentManifest } from '../reducers/entity';
import { ICurrentManifest } from '../models/currentManifest';
import CurrentManifestComponent from '../components/currentManifest.component';

export interface IProps extends RouteComponentProps {
  classname: string;
  toastManager: any;
  t: any;
  user: User;
  companies: User[];
  companyBasedOperators: User[];
  operatorList: User[];
  retrieveCompanyListAPIStatus: STATUS;
  retrieveOperatorListAPIStatus: STATUS;
  retrieveCurrentManifestAPIStatus: STATUS;
  currentManifest: ICurrentManifest;
  retrieveCurrentManifest: typeof retrieveCurrentManifest;
  retrieveCompanyList: typeof retrieveCompanyList;
  retrieveCompanyBasedOperatorsList: typeof retrieveCompanyBasedOperatorsList;
  retrieveOpertorsList: typeof retrieveOpertorsList;
  unloadProduct: typeof unloadProduct;
  unloadAllProducts: typeof unloadAllProducts;
  resetData: typeof resetData;
}

export interface IQueryString {
  operatorId?: string;
  companyId?: string;
}

export const parseQueryString = (location: Location): IQueryString => {
  const search = location?.search;

  const queryParams = queryString.parse(search);

  const operatorId = getQueryParamValue(queryParams, 'operatorId');
  const companyId = getQueryParamValue(queryParams, 'companyId');

  return {
    operatorId,
    companyId
  };
};

export const CurrentManifest: FunctionComponent<IProps> = (props: IProps) => {
  const {
    companies,
    companyBasedOperators,
    operatorList,
    toastManager,
    location,
    history,
    currentManifest,
    retrieveCurrentManifestAPIStatus,
    user,
    t,
    resetData,
    retrieveCompanyList,
    retrieveCompanyBasedOperatorsList,
    retrieveCurrentManifest,
    unloadProduct,
    unloadAllProducts,
    retrieveOpertorsList,
    retrieveCompanyListAPIStatus,
    retrieveOperatorListAPIStatus
  } = props;
  const { operatorId: operator_id, companyId: company_id } = useSelector(() =>
    parseQueryString(location)
  );

  const setSelectedOperatorId = (operatorId: string | undefined) => {
    updateQueryString(history, {
      operatorId,
      companyId: company_id
    });
  };

  const setSelectedCompanyId = (companyId: string | undefined) => {
    updateQueryString(history, {
      companyId
    });
  };

  const _retrieveCurrentManifest = async () => {
    try {
      if (operator_id) {
        await retrieveCurrentManifest(operator_id);
      }
    } catch (err) {
      console.log(':: _retrieveCurrentManifest err ', err);
    }
  };

  useEffect(() => {
    if (operator_id) {
      _retrieveCurrentManifest();
    }
  }, [operator_id]);

  const _retrieveCompanyList = async () => {
    try {
      await retrieveCompanyList();
    } catch (err) {
      console.log(':: _retrieveCompanyList err ', err);
    }
  };

  const _retrieveCompanyBasedOperatorsList = async () => {
    try {
      if (company_id) {
        await retrieveCompanyBasedOperatorsList(company_id);
      }
    } catch (err) {
      console.log(':: _retrieveCompanyBasedOperatorsList err ', err);
    }
  };

  const _retrieveOpertorsList = async () => {
    try {
      await retrieveOpertorsList();
    } catch (err) {
      console.log(':: _retrieveOpertorsList err ', err);
    }
  };

  const _resetData = async () => {
    await resetData();
  };

  useEffect(() => {
    if (user?.role === USER_ROLE.ADMIN) {
      _retrieveCompanyList();
    }
  }, [user?.role]);

  useEffect(() => {
    _resetData();
    if (user?.role === USER_ROLE.ADMIN && company_id) {
      _retrieveCompanyBasedOperatorsList();
    } else {
      if (
        user.role &&
        user.role !== USER_ROLE.OPERATOR &&
        user.role !== USER_ROLE.ADMIN
      ) {
        _retrieveOpertorsList();
      }
    }
  }, [user?.role, company_id, operator_id]);

  useEffect(() => {
    if (
      !operator_id &&
      user.role &&
      user.role === USER_ROLE.OPERATOR &&
      operator_id !== user.user_id
    ) {
      setSelectedOperatorId(user.user_id);
    }
  }, [user.user_id, operator_id]);

  return (
    <Box mt="2xl">
      <section className="currentManifestInfo">
        <Box className="row justify-content-end" mb="xl">
          {user.role === USER_ROLE.ADMIN ? (
            <>
              <Box className="col-auto">
                <select
                  className="form-control"
                  name="company"
                  value={company_id ?? ''}
                  onChange={(event) => {
                    setSelectedCompanyId(event.target.value ?? undefined);
                  }}
                >
                  <option value="">Select Company</option>
                  {companies.map((datum) => (
                    <option key={datum.user_id} value={datum.user_id}>
                      {datum.name}
                    </option>
                  ))}
                </select>
              </Box>
              <Box className="col-auto">
                <select
                  className="form-control"
                  name="operator"
                  disabled={!company_id}
                  value={operator_id ?? ''}
                  onChange={(event) => {
                    setSelectedOperatorId(event.target.value ?? undefined);
                  }}
                >
                  <option value="">Select Operator</option>
                  {companyBasedOperators.map((datum) => (
                    <option key={datum.user_id} value={datum.user_id}>
                      {datum.name}
                    </option>
                  ))}
                </select>
              </Box>
            </>
          ) : user.role !== USER_ROLE.OPERATOR ? (
            <Box className="col-auto">
              <select
                className="form-control"
                name="operator"
                value={operator_id ?? ''}
                onChange={(event) => {
                  setSelectedOperatorId(event.target.value ?? undefined);
                }}
              >
                <option value="">Select Operator</option>
                {isAdmin(user.role) ? (
                  <option key={user.user_id} value={user.user_id}>
                    {user.name} (Self)
                  </option>
                ) : null}
                {operatorList.map((datum) => (
                  <option key={datum.user_id} value={datum.user_id}>
                    {datum.name}
                  </option>
                ))}
              </select>
            </Box>
          ) : null}
        </Box>

        <Box className="container">
          {retrieveOperatorListAPIStatus === STATUS.LOADING ||
          retrieveCompanyListAPIStatus === STATUS.LOADING ||
          retrieveCurrentManifestAPIStatus === STATUS.LOADING ? (
            <Box p={'lg'}>
              <FullPageLoader />
            </Box>
          ) : null}

          {retrieveCurrentManifestAPIStatus === STATUS.FAILURE ? (
            <Box>
              <ErrorAlert
                errorMessage={getMessage(
                  ERROR_MESSAGE.CURRENT_MANIFEST_FAILURE
                )}
                retry={_retrieveCurrentManifest}
              />
            </Box>
          ) : null}

          {retrieveCompanyListAPIStatus === STATUS.FAILURE ? (
            <Box>
              <ErrorAlert
                errorMessage={getMessage(ERROR_MESSAGE.COMPANY_LIST_FAILURE)}
                retry={_retrieveCompanyList}
              />
            </Box>
          ) : null}

          {retrieveOperatorListAPIStatus === STATUS.FAILURE ? (
            <Box>
              <ErrorAlert
                errorMessage={getMessage(
                  ERROR_MESSAGE.COMPANY_BASED_OPERATORS_LIST_FAILURE
                )}
                retry={_retrieveCompanyBasedOperatorsList}
              />
            </Box>
          ) : null}

          {retrieveCurrentManifestAPIStatus === STATUS.SUCCESS &&
          !currentManifest?.report_id &&
          (company_id || user.role !== USER_ROLE.ADMIN) &&
          operator_id ? (
            <Alert status="warning">
              {getMessage(ERROR_MESSAGE.NO_MANIFEST_FOUND)}
            </Alert>
          ) : null}

          {!company_id && !operator_id && user.role === USER_ROLE.ADMIN ? (
            <Alert status="warning">
              {getMessage(ERROR_MESSAGE.NO_COMPANY_FOUND_MANIFEST_HISTORY)}
            </Alert>
          ) : null}

          {(company_id || user.role !== USER_ROLE.ADMIN) && !operator_id ? (
            <Alert status="warning">
              {getMessage(ERROR_MESSAGE.NO_OPERATOR_FOUND_MANIFEST)}
            </Alert>
          ) : null}

          {retrieveCurrentManifestAPIStatus === STATUS.SUCCESS &&
          currentManifest?.report_id ? (
            <CurrentManifestComponent
              user={user}
              operator_id={operator_id}
              currentManifest={currentManifest}
              unloadProduct={unloadProduct}
              toastManager={toastManager}
              unloadAllProducts={unloadAllProducts}
              t={t}
            />
          ) : null}
        </Box>
      </section>
    </Box>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: IAppState) => {
  const loggedInUserDetails = getLoggedInUserDetails(state);
  const currentManifest = getCurrentManifest(state);
  const retrieveCompanyListAPIStatus = getCompanyListAPIStatus(state);
  const retrieveOperatorListAPIStatus = getOperatorListAPIStatus(state);
  const retrieveCurrentManifestAPIStatus = getCurrentManifestAPIStatus(state);
  const companies = getCompanyListSelector(state);
  const companyBasedOperators = getCompanyBasedOperatorListSelector(state);
  const operatorList = getOperatorListSelector(state);
  return {
    user: loggedInUserDetails.user,
    retrieveCurrentManifestAPIStatus,
    retrieveCompanyListAPIStatus,
    retrieveOperatorListAPIStatus,
    companies,
    companyBasedOperators,
    operatorList,
    currentManifest
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  return bindActionCreators(
    {
      retrieveCompanyBasedOperatorsList,
      retrieveCompanyList,
      retrieveCurrentManifest,
      retrieveOpertorsList,
      unloadProduct,
      unloadAllProducts,
      resetData
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(withToastManager(withRouter(CurrentManifest))));
