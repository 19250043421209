import { AnyAction, Reducer } from 'redux';
import { IAppState } from '../../../../store';
import { STATUS } from '../../../../constant';
import {
  RETRIEVE_WAREHOUSE_TRUCK__START,
  RETRIEVE_WAREHOUSE_TRUCK__FAILURE,
  RETRIEVE_WAREHOUSE_TRUCK__SUCCESS,
  ADD_WAREHOUSE_TRUCK__START,
  ADD_WAREHOUSE_TRUCK__FAILURE,
  ADD_WAREHOUSE_TRUCK__SUCCESS
} from '../../actions';

export interface IWarehouseTruckPageState {
  retrieveWarehouseTruckAPIStatus: STATUS;
  addEditWarehouseTruckAPIStatus: STATUS;
}

export const defaultState: IWarehouseTruckPageState = {
  retrieveWarehouseTruckAPIStatus: STATUS.NOT_STARTED,
  addEditWarehouseTruckAPIStatus: STATUS.NOT_STARTED
};

export const warehouseTruckPageReducer: Reducer<IWarehouseTruckPageState> = (
  state: IWarehouseTruckPageState = defaultState,
  action: AnyAction
): IWarehouseTruckPageState => {
  switch (action.type) {
    case RETRIEVE_WAREHOUSE_TRUCK__START: {
      return {
        ...state,
        retrieveWarehouseTruckAPIStatus: STATUS.LOADING
      };
    }
    case RETRIEVE_WAREHOUSE_TRUCK__FAILURE: {
      return {
        ...state,
        retrieveWarehouseTruckAPIStatus: STATUS.FAILURE
      };
    }
    case RETRIEVE_WAREHOUSE_TRUCK__SUCCESS: {
      return {
        ...state,
        retrieveWarehouseTruckAPIStatus: STATUS.SUCCESS
      };
    }
    case ADD_WAREHOUSE_TRUCK__START: {
      return {
        ...state,
        addEditWarehouseTruckAPIStatus: STATUS.LOADING
      };
    }
    case ADD_WAREHOUSE_TRUCK__FAILURE: {
      return {
        ...state,
        addEditWarehouseTruckAPIStatus: STATUS.FAILURE
      };
    }
    case ADD_WAREHOUSE_TRUCK__SUCCESS: {
      return {
        ...state,
        addEditWarehouseTruckAPIStatus: STATUS.SUCCESS
      };
    }
    default: {
      return state;
    }
  }
};

export const getWarehouseTruckAPIStatus = (state: IAppState) => {
  return state.warehouseTruck.ui.warehouseTruckPage
    .retrieveWarehouseTruckAPIStatus;
};

export const getAddEditWarehouseTruckAPIStatus = (state: IAppState) => {
  return state.warehouseTruck.ui.warehouseTruckPage
    .addEditWarehouseTruckAPIStatus;
};
