import { AnyAction, Reducer } from 'redux';
import keyBy from 'lodash/keyBy';
import { createSelector } from 'reselect';
import map from 'lodash/map';

import { IAppState } from '../../store';
import { OPERATOR_LIST__SUCCESS } from '../actions';
import { User } from '../models/user';

export type OperatorById = { [key: string]: User };

export interface IOperatorPageState {
  byId: OperatorById;
  allIds: string[];
}

export const defaultState: IOperatorPageState = {
  byId: {},
  allIds: []
};

export const operatorPageReducer: Reducer<IOperatorPageState> = (
  state: IOperatorPageState = defaultState,
  action: AnyAction
): IOperatorPageState => {
  switch (action.type) {
    case OPERATOR_LIST__SUCCESS: {
      const { operatorsList } = action.payload;
      const byId = keyBy(operatorsList, (operator: User) => operator.user_id);
      const allIds = operatorsList.map((operator: User) => operator.user_id);
      return {
        byId,
        allIds
      };
    }
    default: {
      return state;
    }
  }
};

export const getOperatorByIds = (state: IAppState) => {
  return state.common.operators.byId;
};

export const getOperatorAllIds = (state: IAppState) => {
  return state.common.operators.allIds;
};

export const getOperatorListSelector = createSelector(
  getOperatorAllIds,
  getOperatorByIds,
  (allIds: string[], byId: OperatorById): User[] => {
    if (!allIds || !allIds.length) {
      return [];
    }
    return map(allIds, (datum) => {
      return byId[datum];
    }).sort((operatorA, operatorB) =>
      operatorA.name.toLowerCase() > operatorB.name.toLowerCase()
        ? 1
        : operatorB.name.toLowerCase() > operatorA.name.toLowerCase()
        ? -1
        : 0
    );
  }
);
