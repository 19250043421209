/* istanbul ignore file */
import React, { FunctionComponent } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { RouteComponentProps } from 'react-router-dom';
import { Box } from '@chakra-ui/core';
import { ROUTES } from '../../../constant';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  t: any;
}

export const HomePageFooter: FunctionComponent<IProps> = (props: IProps) => {
  const { className, t } = props;
  return (
    <Box className={className}>
      <footer>
        <Box className="container">
          <Box className="text-center">
            <span className="ftTxt">
              {t('HOME_PAGE_END_USER_LICENSE_AGREEMENT')}
            </span>
          </Box>
          <Box className="mt-3 text-center">
            <span className="ftTxt2">
              © {new Date().getFullYear()}{' '}
              {t('HOME_PAGE_PACKAGED_DANGEROUS_GOODS_GUIDE_PATENT_PENDING')}
            </span>
          </Box>
          <Box className="mt-3">
            <ul>
              <li>
                <a href={ROUTES.ABOUT_US_WITHOUT_LOGIN} className="transition">
                  {t('HOME_PAGE_ABOUT_US')}
                </a>
              </li>
              <li>
                <a href={ROUTES.LEGALS_WITHOUT_LOGIN} className="transition">
                  {t('HOME_PAGE_LEGAL')}
                </a>
              </li>
              <li>
                <a
                  href={ROUTES.TERMS_AND_CONDITIONS_WITHOUT_LOGIN}
                  className="transition"
                >
                  {t('HOME_PAGE_TERMS_AND_POLICY')}
                </a>
              </li>
              <li>
                <a
                  href={ROUTES.SUPPORT_FEEDBACK_WITHOUT_LOGIN}
                  className="transition"
                >
                  {t('HOME_PAGE_CONTACT_US')}
                </a>
              </li>
            </ul>
          </Box>
        </Box>
      </footer>
    </Box>
  );
};

export default withToastManager(HomePageFooter);
