import { Reducer, combineReducers } from 'redux';
import { ILicensesPageState, licensesPageReducer } from './ui';
import { ILicensesInfoState, licensesInfoReducer } from './entity';

export interface ILicensesPageUI {
  licensesPage: ILicensesPageState;
}

export interface ILicensesEntity {
  licenseInfo: ILicensesInfoState;
}

export interface ILicensesState {
  ui: ILicensesPageUI;
  entities: ILicensesEntity;
}

const ui = combineReducers({
  licensesPage: licensesPageReducer
});

const entities = combineReducers({
  licenseInfo: licensesInfoReducer
});

export const licensesReducer: Reducer<ILicensesState> = combineReducers({
  ui,
  entities
});
