import { AnyAction, Reducer } from 'redux';
import {
  RETRIEVE_UN_NUMBER_LIST__START,
  RETRIEVE_UN_NUMBER_LIST__SUCCESS
} from '../../actions';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import { IAppState } from '../../../../store';
import { IUnNumber } from '../../models/un-number.model';

export type UnById = { [key: string]: IUnNumber };

export interface IUnsInfoState {
  unByNo: UnById;
  unAllNos: string[];
}

export const defaultState: IUnsInfoState = {
  unByNo: {},
  unAllNos: []
};

export const unsInfoReducer: Reducer<IUnsInfoState> = (
  state: IUnsInfoState = defaultState,
  action: AnyAction
): IUnsInfoState => {
  switch (action.type) {
    case RETRIEVE_UN_NUMBER_LIST__START: {
      return {
        ...state,
        unAllNos: defaultState.unAllNos,
        unByNo: defaultState.unByNo
      };
    }
    case RETRIEVE_UN_NUMBER_LIST__SUCCESS: {
      const { unNumberData } = action.payload;
      const byNo = keyBy(unNumberData, (datum: IUnNumber) => {
        return datum.un_no;
      });
      const nos = map(unNumberData, (datum: IUnNumber) => {
        return datum.un_no;
      });
      return {
        ...state,
        unByNo: { ...state.unByNo, ...byNo },
        unAllNos: uniq([...state.unAllNos, ...nos])
      };
    }
    default:
      return state;
  }
};

export const getUnByNo = (state: IAppState) => {
  return state.reports.entities.unInfo.unByNo;
};

export const getUnAllNos = (state: IAppState) => {
  return state.reports.entities.unInfo.unAllNos;
};
