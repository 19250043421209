import { createSelector } from 'reselect';
import { IAppState } from '../../../store';
import { AuthStatus } from '../models/auth-status';
import { User } from '../../../common/models/user';

export const loggedInUserSelector = (state: IAppState) => {
  return state.auth.user;
};

export const tokenForLoggedInUserSelector = (state: IAppState) => {
  return state.auth.token;
};

export const statusSelector = (state: IAppState) => {
  return state.auth.status;
};

export const getLoggedInUserDetails = createSelector(
  statusSelector,
  loggedInUserSelector,
  tokenForLoggedInUserSelector,
  (status: AuthStatus, user: null | User, token: null | string) => {
    return {
      status,
      user,
      token
    };
  }
);
