/* istanbul ignore file */
import React, { FunctionComponent } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Box, Text } from '@chakra-ui/core';
import { INotification } from '../../../common/models/notification';
import { createUrl, notificationFormat, timeFormat } from '../../../helper';
import { ROUTES } from '../../../constant';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  notification: INotification;
  t: any;
}

export const NotificationListItemComponent: FunctionComponent<IProps> = (
  props: IProps
) => {
  const { notification, t, history } = props;
  return (
    <Box>
      <Box className="p-3">
        <Box
          className="d-flex align-items-center justify-content-start ab-top row "
          onClick={() => {
            if (notification.subject === 'Manifest Approval') {
              const manifestUrl = createUrl(
                ROUTES.REPORT_DETAIL,
                undefined,
                {
                  operatorId: notification.sender_id
                }
              );
              history.push(manifestUrl);
            }
          }}
          style={{ cursor: 'pointer' }}
        >
          <Box className="col-9">
            <Text fontWeight="bold">
              {t('SUBJECT')}: {notification.subject}
            </Text>
            <span>{notification.message}</span>
          </Box>
          <Box className="col-3">
            <span>
              {notificationFormat(new Date(notification.created_at))} | {timeFormat(new Date(notification.created_at))}
            </span>
          </Box>
          <Box className="col-2">
            <span>
              {notification.subject === 'Manifest Approval' ? 'Action' : null}
            </span>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationListItemComponent;
