/* istanbul ignore file */

import React, { FunctionComponent, RefObject, createRef } from 'react';
import { Box } from '@chakra-ui/core';

import { Button, TernaryOutlineButton } from './buttons';
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter
} from './popovers/alert.component';

interface IProps {
  title?: string;
  description?: string;
  cancelText?: string;
  confirmText?: string;
  type?: 'success' | 'warning' | 'danger';
  onConfirm: () => void;
  onCancel: () => void;
  highlightConfirmAction?: boolean;
}

export const ConfirmAlert: FunctionComponent<IProps> = ({
  title,
  description,
  cancelText,
  confirmText,
  onConfirm,
  onCancel,
  type,
  highlightConfirmAction
}) => {
  const cancelRef: RefObject<HTMLElement> = createRef();
  const confirmRef: RefObject<HTMLElement> = createRef();
  return (
    <AlertDialog
      isOpen={true}
      leastDestructiveRef={highlightConfirmAction ? confirmRef : cancelRef}
    >
      <AlertDialogOverlay />
      <AlertDialogContent style={{ top: '15vh' }}>
        <AlertDialogHeader fontSize="lg" fontWeight="bold" textAlign="center">
          {title}
        </AlertDialogHeader>
        <AlertDialogBody textAlign="center">{description}</AlertDialogBody>
        <AlertDialogFooter background="none">
          <Box className="row" flex="1">
            <Box className="col">
              <TernaryOutlineButton
                ref={cancelRef}
                isFullWidth={true}
                onClick={onCancel}
              >
                {cancelText}
              </TernaryOutlineButton>
            </Box>
            <Box className="col">
              <Button
                variantColor={type}
                ref={confirmRef}
                isFullWidth={true}
                onClick={onConfirm}
              >
                {confirmText}
              </Button>
            </Box>
          </Box>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

ConfirmAlert.defaultProps = {
  title: 'Do you want to cancel?',
  description: 'Changes made to the form will be lost if you cancel.',
  cancelText: 'Cancel',
  confirmText: 'Yes',
  type: 'success',
  highlightConfirmAction: false
};
