/* istanbul ignore file */

import React, { FunctionComponent } from 'react';
import { Box } from '@chakra-ui/core';
import Logo from '../../assets/images/logo.png';

interface IProps {
  className?: string;
}
export const FullPageLoader: FunctionComponent<IProps> = ({ className }) => {
  return (
    <Box className={className}>
      <Box className="_loader">
        <Box className="_ldBody">
          <img src={Logo} className="rotate" alt="logo" />
        </Box>
      </Box>
    </Box>
  );
};
