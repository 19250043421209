/* istanbul ignore file */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Box } from '@chakra-ui/core';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import Logo2 from '../../../assets/images/logo2.png';
import LeftBanner from '../../../assets/images/leftbanner.png';
import PaymentComponent, {
  IPaymentFormValues
} from '../components/payment.component';
import {
  ROUTES,
  STATUS,
  ERROR_MESSAGE,
  SUCCESS_MESSAGE
} from '../../../constant';
import { IAppState } from '../../../store';
import {
  retrieveSubscription,
  submitPayment,
  submitCouponCode
} from '../actions';
import {
  getSubscriptionsAPIStatus,
  getAppliedCoupon,
  getPaymentAPIStatus
} from '../reducers/ui';
import { getSelectedSubscriptionSelector } from '../selectors/index-page.selector';
import { ISubscription } from '../models/subscription.model';
import { getMessage } from '../../../helper';
import { ErrorAlert } from '../../../common/components/ErrorAlert.component';
import { FullPageLoader } from '../../../common/components/Loader.component';
import { ICoupon, ICouponType } from '../models/coupon.model';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  retrieveSubscriptionsAPIStatus: STATUS;
  paymentAPIStatus: STATUS;
  t: any;
  selectedSubscription: ISubscription;
  appliedCoupon: ICoupon | null;
  retrieveSubscription: typeof retrieveSubscription;
  submitPayment: typeof submitPayment;
  submitCouponCode: typeof submitCouponCode;
}

export const Payment: FunctionComponent<IProps> = (props: IProps) => {
  const {
    className,
    retrieveSubscription,
    selectedSubscription,
    retrieveSubscriptionsAPIStatus,
    paymentAPIStatus,
    submitPayment,
    submitCouponCode,
    toastManager,
    appliedCoupon,
    history,
    t
  } = props;
  const [isApplied, setIsApplied] = useState<boolean | null>(null);
  const [isValidCoupon, setIsValidCoupon] = useState<boolean | null>(null);
  const [code, setCode] = useState('');
  const _retrieveSubscription = async () => {
    try {
      await retrieveSubscription();
    } catch (err) {
      console.log(':: _retrieveSubscription err ', err);
    }
  };

  const _submitCouponCode = async (coupon_code: string) => {
    try {
      if (code === coupon_code) {
        toastManager.add(getMessage(SUCCESS_MESSAGE.COUPON_ALREADY_APPLIED), {
          appearance: 'success',
          autoDismiss: true
        });
      } else {
        await submitCouponCode(coupon_code);
        setCode(coupon_code);
        setIsValidCoupon(true);
      }
    } catch (err) {
      setIsValidCoupon(false);
      toastManager.add(getMessage(ERROR_MESSAGE.COUPON_INVALID), {
        appearance: 'error',
        autoDismiss: true
      });
      console.log(':: _submitCouponCode err ', err);
    }
  };

  const _submitPayment = async (values: IPaymentFormValues) => {
    try {
      await submitPayment(values, isValidCoupon);
      history.push(ROUTES.HOME);
      toastManager.add(getMessage(SUCCESS_MESSAGE.PAYMENT_SUCCESSFUL), {
        appearance: 'success',
        autoDismiss: true
      });
    } catch (err) {
      let message = '';
      if (err && err.code === 400) {
        if (err?.errorObj?.message) {
          message = err?.errorObj?.message;
        } else if (err?.message) {
          message = err?.message;
        } else {
          message = getMessage(ERROR_MESSAGE.INVALID_CARD_DETAIL);
        }
      } else {
        message = getMessage(ERROR_MESSAGE.INVALID_CARD_DETAIL);
      }
      toastManager.add(message, { appearance: 'error', autoDismiss: true });
      console.log(':: _submitPayment ', err);
    }
  };
  useEffect(() => {
    _retrieveSubscription();
  }, []);

  const calculateAmount = (amount: number) => {
    if (appliedCoupon) {
      let appliedAmount;
      if (appliedCoupon.type === ICouponType.AMOUNT) {
        appliedAmount = amount - appliedCoupon.value;
      } else {
        appliedAmount = amount - (amount * appliedCoupon.value) / 100;
      }
      if (appliedAmount >= 0) {
        setIsApplied(true);
        toastManager.add(getMessage(SUCCESS_MESSAGE.COUPON_APPLIED), {
          appearance: 'success',
          autoDismiss: true
        });
        return appliedAmount;
      } else {
        setIsApplied(false);
        toastManager.add(
          getMessage(ERROR_MESSAGE.COUPON_VALUE_MORE_THAN_SUBSCRIPTION),
          {
            appearance: 'error',
            autoDismiss: true
          }
        );
        return amount;
      }
    }
    return amount;
  };
  const [amount, setAmount] = useState<number | undefined>(undefined);
  useEffect(() => {
    if (selectedSubscription && selectedSubscription.amount) {
      const calculatedAmount = appliedCoupon
        ? calculateAmount(selectedSubscription.amount)
        : selectedSubscription.amount;
      setAmount(calculatedAmount);
    }
  }, [
    selectedSubscription && selectedSubscription.amount,
    appliedCoupon && appliedCoupon.id
  ]);

  return (
    <div className={className}>
      {retrieveSubscriptionsAPIStatus === STATUS.LOADING ||
      paymentAPIStatus === STATUS.LOADING ? (
        <Box p={'lg'}>
          <FullPageLoader />
        </Box>
      ) : null}
      <Box className="signupAr">
        <Box className="lftImage">
          <a href={ROUTES.HOME}>
            <img src={Logo2} className="innLogo" alt="pdg" />
          </a>
          <img src={LeftBanner} alt="pdg banner" />
          {retrieveSubscriptionsAPIStatus === STATUS.SUCCESS &&
          selectedSubscription ? (
            <Box className="_prInfoBx _slPlan">
              <sup>$</sup>
              <span>{amount}/</span>
              <small>mo</small>
              <Box className="_lc">
                {`${selectedSubscription.license} ${t('REGISTER_LICENSE')}`}
              </Box>
            </Box>
          ) : null}
        </Box>
        <Box className="_snInfo">
          <PaymentComponent
            {...props}
            isApplied={isApplied}
            amount={amount}
            submitPayment={(values: IPaymentFormValues) => {
              _submitPayment(values);
            }}
            submitCouponCode={(coupon_code: string) => {
              _submitCouponCode(coupon_code);
            }}
          />
        </Box>
      </Box>
      {retrieveSubscriptionsAPIStatus === STATUS.FAILURE ? (
        <Box>
          <ErrorAlert
            errorMessage={getMessage(ERROR_MESSAGE.DATA_FAILURE)}
            retry={_retrieveSubscription}
          />
        </Box>
      ) : null}
    </div>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: IAppState) => {
  const selectedSubscription = getSelectedSubscriptionSelector(state);
  const retrieveSubscriptionsAPIStatus = getSubscriptionsAPIStatus(state);
  const appliedCoupon = getAppliedCoupon(state);
  const paymentAPIStatus = getPaymentAPIStatus(state);
  return {
    selectedSubscription,
    retrieveSubscriptionsAPIStatus,
    paymentAPIStatus,
    appliedCoupon
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  return bindActionCreators(
    {
      retrieveSubscription,
      submitCouponCode,
      submitPayment
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(withToastManager(withRouter(Payment))));
