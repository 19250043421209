/* istanbul ignore file */

import { Method } from 'axios';
import request from '../../../services/request.service';
import GlobalConfig from '../../../globalConfig';
import { ILicense } from '../models/license.model';

interface IServiceProps {
  method: Method;
  headers: Record<string, any>;
  url: string;
  data?: Record<string, any>;
  responseType?: any;
}

export interface ILicensesResponse {
  status: number;
  message: string;
  licenses: ILicense[];
}

export interface IAddSubUserResponse {
  status: number;
  message: string;
}

export interface IAddSubUserParams {
  licence_key: string;
  email?: string;
  name: string;
  phone_no: string;
  country_id: string;
  user_id: string;
  role?: string;
  truck?: number;
}

export interface IEditProfileParams {
  user_id: string;
  name: string;
  phone_no: string;
  country_id: string;
  role?: string;
  truck?: number;
}

export const retrieveLicensesParams = (userId: string): IServiceProps => ({
  method: 'GET',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(`mobile/getlicence/${userId}`)
});

export const addSubUserParams = (data: IAddSubUserParams): IServiceProps => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(`mobile/subuserRegister`),
  data
});

export const deleteSubUserParams = (user_id: string): IServiceProps => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(`mobile/deleteuser`),
  data: {
    user_id
  }
});

export const submitEditProfileFormParams = (data = {}): IServiceProps => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot('mobile/updateProfile'),
  data
});

const transformData = (data: any) => {
  const model = {
    licence: data.licence,
    is_assigned: data.is_assigned,
    created_at: data.created_at,
    user: data.name
      ? {
          name: data.name,
          email: data.email,
          phone_no: data.phone_no,
          country_id: data.country_id,
          user_id: data.user_id,
          role: data.role,
          truck: data.truck
        }
      : null
  };
  return model;
};

export class LicenseServices {
  static async retrieveLicenses(userId: string): Promise<ILicensesResponse> {
    const res = await request(retrieveLicensesParams(userId));
    return {
      status: res.data.status,
      message: res.data.message,
      licenses: res.data?.data.map((datum: any) => transformData(datum))
    };
  }

  static async addSubUser(
    params: IAddSubUserParams
  ): Promise<IAddSubUserResponse> {
    const res = await request(addSubUserParams(params));
    return {
      status: res.data.status,
      message: res.data.message
    };
  }

  static async deleteSubUser(userId: string): Promise<IAddSubUserResponse> {
    const res = await request(deleteSubUserParams(userId));
    return {
      status: res.data.status,
      message: res.data.message
    };
  }

  static async editProfile(
    params: IEditProfileParams
  ): Promise<IAddSubUserResponse> {
    const res = await request(submitEditProfileFormParams(params));
    return res.data;
  }
}
