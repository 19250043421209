/* istanbul ignore file */

import { createContext } from 'react';

import { User } from '../common/models/user';
import { AuthStatus } from '../modules/auth/models/auth-status';

export interface IAuthContext {
  status: AuthStatus;
  user: null | User;
}

export const AuthContext = createContext<IAuthContext>({
  status: AuthStatus.UNKNOWN, // to check if authenticated or not
  user: null
});

export const AuthProvider = AuthContext.Provider;
