import React, { FunctionComponent } from 'react';
import { Box } from '@chakra-ui/core';

import GHS01 from '../../../assets/images/icons/GHS/GHS01.png';
import GHS02 from '../../../assets/images/icons/GHS/GHS02.png';
import GHS03 from '../../../assets/images/icons/GHS/GHS03.png';
import GHS04 from '../../../assets/images/icons/GHS/GHS04.png';
import GHS05 from '../../../assets/images/icons/GHS/GHS05.png';
import GHS06 from '../../../assets/images/icons/GHS/GHS06.png';
import GHS07 from '../../../assets/images/icons/GHS/GHS07.png';
import GHS08 from '../../../assets/images/icons/GHS/GHS08.png';
import GHS09 from '../../../assets/images/icons/GHS/GHS09.png';

export interface IProps {
  ghs: string;
  className?: string;
  isWithoutLogin?: boolean;
  parentClass?: string;
}

export const GHSImage: FunctionComponent<IProps> = ({
  ghs,
  className,
  isWithoutLogin,
  parentClass
}) => {
  const getGHSImage = () => {
    switch (ghs) {
      case 'GHS01': {
        return (
          <Box
            className={
              !isWithoutLogin
                ? 'class-division-image'
                : 'row justify-content-start'
            }
          >
            <img src={GHS01} alt="GHS 01" className={className} />
          </Box>
        );
      }
      case 'GHS02': {
        return (
          <Box
            className={
              !isWithoutLogin
                ? 'class-division-image'
                : 'row justify-content-start'
            }
          >
            <img src={GHS02} alt="GHS 02" className={className} />
          </Box>
        );
      }
      case 'GHS03': {
        return (
          <Box
            className={
              !isWithoutLogin
                ? 'class-division-image'
                : 'row justify-content-start'
            }
          >
            <img src={GHS03} alt="GHS 03" className={className} />
          </Box>
        );
      }
      case 'GHS04': {
        return (
          <Box
            className={
              !isWithoutLogin
                ? 'class-division-image'
                : 'row justify-content-start'
            }
          >
            <img src={GHS04} alt="GHS 04" className={className} />
          </Box>
        );
      }
      case 'GHS05': {
        return (
          <Box
            className={
              !isWithoutLogin
                ? 'class-division-image'
                : 'row justify-content-start'
            }
          >
            <img src={GHS05} alt="GHS 05" className={className} />
          </Box>
        );
      }
      case 'GHS06': {
        return (
          <Box
            className={
              !isWithoutLogin
                ? 'class-division-image'
                : 'row justify-content-start'
            }
          >
            <img src={GHS06} alt="GHS 06" className={className} />
          </Box>
        );
      }
      case 'GHS07': {
        return (
          <Box
            className={
              !isWithoutLogin
                ? 'class-division-image'
                : 'row justify-content-start'
            }
          >
            <img src={GHS07} alt="GHS 07" className={className} />
          </Box>
        );
      }
      case 'GHS08': {
        return (
          <Box
            className={
              !isWithoutLogin
                ? 'class-division-image'
                : 'row justify-content-start'
            }
          >
            <img src={GHS08} alt="GHS 08" className={className} />
          </Box>
        );
      }
      case 'GHS09': {
        return (
          <Box
            className={
              !isWithoutLogin
                ? 'class-division-image'
                : 'row justify-content-start'
            }
          >
            <img src={GHS09} alt="GHS 09" className={className} />
          </Box>
        );
      }
      default: {
        return null;
      }
    }
  };
  return (
    <Box className={parentClass ?? 'class-division-image'}>{getGHSImage()}</Box>
  );
};
