import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';

import translationEN from './common/languages/en.json';
import translationZHCN from './common/languages/zh-cn.json';
import translationAU from './common/languages/au.json';
import translationIN from './common/languages/in.json';
import translationUK from './common/languages/uk.json';
import translationNZL from './common/languages/nzl.json';

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  ch: {
    translation: translationZHCN
  },
  au: {
    translation: translationAU
  },
  uk: {
    translation: translationUK
  },
  nzl: {
    translation: translationNZL
  },
  in: {
    translation: translationIN
  }
};

i18n
  .use(detector)
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
