/* istanbul ignore file */

import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { H4 } from './typography';

interface IProps {
  className?: string;
  defaultIndex: number;
  onSelect: (index: any) => void;
  data: Array<{
    key: string;
    value: string;
    count?: number;
  }>;
}

export const StyledTabs = styled(Tabs)`
  flex: 1;

  .react-tabs {
    -webkit-tap-highlight-color: transparent;
  }

  .react-tabs__tab-list {
    padding: 0;
    background: ${themeGet('colors.grey3')};
    background-image: linear-gradient(to top, #f3f3f4, #fcfcfc);
  }

  .react-tabs__tab {
    display: inline-block;
    border: none;
    border-bottom: none;
    position: relative;
    list-style: none;
    padding: ${themeGet('space.xs')} ${themeGet('space.xl')};
    cursor: pointer;
    background-image: linear-gradient(to bottom, #f3f3f4, #fcfcfc);
    color: ${themeGet('colors.grey7')};
    border: 1px solid ${themeGet('colors.gray.300')};
    vertical-align: bottom;

    &:not(.react-tabs__tab--selected):hover,
    &:not(.react-tabs__tab--selected):focus {
      color: ${themeGet('colors.primary.default')};
      background: ${themeGet('colors.primary.50')};
      border-color: ${themeGet('colors.primary.200')};
    }

    &:not(.react-tabs__tab--selected):active {
      color: ${themeGet('colors.primary.default')};
      background: ${themeGet('colors.primary.100')};
      border-color: ${themeGet('colors.primary.300')};
    }
  }

  .react-tabs__tab--selected {
    background: ${themeGet('colors.white')};
    color: ${themeGet('colors.primary.default')};
    position: relative;
    border-left-width: 0;
    border-right-width: 0;
    border-bottom-color: ${themeGet('colors.white')};
    padding-top: ${themeGet('space.sm')};
    padding-bottom: ${themeGet('space.2xs')};
    box-shadow: 0 -3px 3px 0 rgba(34, 189, 189, 0.3);

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background: ${themeGet('colors.primary.default')};
      top: -1px;
      left: 0;
    }
  }

  .react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
  }

  .react-tabs__tab:focus {
    box-shadow: 0 0 5px hsl(208, 99%, 50%);
    border-color: hsl(208, 99%, 50%);
    outline: none;
  }

  .react-tabs__tab:focus:after {
    content: '';
    position: absolute;
    height: 5px;
    left: -4px;
    right: -4px;
    bottom: -5px;
    background: #fff;
  }

  .react-tabs-row {
    margin: 0;
    padding: 0;
  }

  .react-tabs__tab-panel {
    display: none;
  }

  .react-tabs__tab-panel--selected {
    display: block;
  }
`;

const AxiomTabsComponent: FunctionComponent<IProps> = ({
  className,
  defaultIndex,
  onSelect,
  data
}: IProps) => {
  return (
    <div className={className}>
      <StyledTabs onSelect={onSelect} selectedIndex={defaultIndex}>
        <TabList>
          {data.map((tab) => (
            <Tab key={tab.key}>
              <H4>
                {tab.value}
                {tab.count || tab.count === 0 ? (
                  <span>{` (${tab.count})`}</span>
                ) : null}
              </H4>
            </Tab>
          ))}
        </TabList>
        {data.map((tab) => (
          <TabPanel key={tab.key} />
        ))}
      </StyledTabs>
    </div>
  );
};

AxiomTabsComponent.defaultProps = {
  className: '',
  defaultIndex: 0,
  onSelect: () => {},
  data: []
};

export const TabsComponent = styled(AxiomTabsComponent)``;
