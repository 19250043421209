import { createAction } from 'redux-actions';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

import { ICurrentManifest } from '../models/currentManifest';
import {
  CurrentManifestServices,
  ICurrentManifestResponse
} from '../services/api.service';
import {
  IUnloadResponse,
  ReportsServices
} from '../../reports/services/api.service';
import { CommonService } from '../../../common/services/common.service';

export const RETRIEVE_CURRENT_MANIFEST_DETAIL__START = 'pdg.report.detail.ui.start';
export const retrieveCurrentManifestStart = createAction(
  RETRIEVE_CURRENT_MANIFEST_DETAIL__START
);

export const RETRIEVE_CURRENT_MANIFEST__FAILURE = 'pdg.report.detail.ui.failure';
export const retrieveCurrentManifestFailure = createAction(
  RETRIEVE_CURRENT_MANIFEST__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const RETRIEVE_CURRENT_MANIFEST__SUCCESS = 'pdg.report.detail.ui.success';
export const retrieveCurrentManifestSuccess = createAction(
  RETRIEVE_CURRENT_MANIFEST__SUCCESS,
  (report: ICurrentManifest | null) => {
    return { report };
  }
);

export const retrieveCurrentManifest = (
  operatorId: string
): ThunkAction<Promise<ICurrentManifestResponse>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(retrieveCurrentManifestStart());
      const res = await CurrentManifestServices.retrieveCurrentManifest(
        operatorId
      );
      dispatch(retrieveCurrentManifestSuccess(res.data as ICurrentManifest | null));
      return res;
    } catch (err) {
      dispatch(retrieveCurrentManifestFailure(err));
      throw err;
    }
  };
};

export const UNLOAD_PRODUCT__START =
  'pdg.currentManifest.unload.product.ui.start';
export const unloadProductStart = createAction(UNLOAD_PRODUCT__START);

export const UNLOAD_PRODUCT__FAILURE =
  'pdg.currentManifest.unload.product.ui.failure';
export const unloadProductFailure = createAction(
  UNLOAD_PRODUCT__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const UNLOAD_PRODUCT__SUCCESS =
  'pdg.currentManifest.unload.product.ui.success';
export const unloadProductSuccess = createAction(
  UNLOAD_PRODUCT__SUCCESS,
  (reportId: number, productId: number, current_status: boolean) => {
    return {
      reportId,
      productId,
      current_status
    };
  }
);

export const unloadProduct = (
  reportId: number,
  productId: number
): ThunkAction<Promise<IUnloadResponse>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(unloadProductStart());
      const res = await ReportsServices.unloadProduct(reportId, productId);
      if (res.status === 1) {
        dispatch(unloadProductSuccess(reportId, productId, res.current_status));
      } else {
        throw res;
      }
      return res;
    } catch (err) {
      dispatch(unloadProductFailure(err));
      throw err;
    }
  };
};

export const UNLOAD_ALL_PRODUCTS__START =
  'pdg.currentManifest.unload.all.products.ui.start';
export const unloadAllProductsStart = createAction(UNLOAD_ALL_PRODUCTS__START);

export const UNLOAD_ALL_PRODUCTS__FAILURE =
  'pdg.currentManifest.unload.all.products.ui.failure';
export const unloadAllProductsFailure = createAction(
  UNLOAD_ALL_PRODUCTS__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const UNLOAD_ALL_PRODUCTS__SUCCESS =
  'pdg.currentManifest.unload.all.products.ui.success';
export const unloadAllProductsSuccess = createAction(
  UNLOAD_ALL_PRODUCTS__SUCCESS,
  (reportId: number, current_status: boolean) => {
    return {
      reportId,
      current_status
    };
  }
);

export const unloadAllProducts = (
  reportId: number
): ThunkAction<Promise<IUnloadResponse>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(unloadAllProductsStart());
      const res = await CommonService.unloadAllProducts(reportId);
      if (res.status === 1) {
        dispatch(unloadAllProductsSuccess(reportId, res.current_status));
      } else {
        throw res;
      }
      return res;
    } catch (err) {
      dispatch(unloadAllProductsFailure(err));
      throw err;
    }
  };
};

export const RESET_DATA = 'pdg.currentManifest.reset.data.start';
export const resetData = createAction(RESET_DATA);
