/* istanbul ignore file */
import React, { FunctionComponent } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { RouteComponentProps } from 'react-router-dom';
import { Box } from '@chakra-ui/core';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { IAppState } from '../../../store';
import {
  ROUTES,
  STATUS,
  ERROR_MESSAGE,
  SUCCESS_MESSAGE
} from '../../../constant';
import { submitSupportAndFeedbackForm } from '../actions';
import { getMessage } from '../../../helper';
import { FullPageLoader } from '../../../common/components/Loader.component';
import { getSupportAndFeedbackAPIStatus } from '../reducers/ui';
import SupportAndFeedbackForm, {
  IFormValues
} from '../components/supportAndFeedbackForm.component';
import Logo2 from '../../../assets/images/logo2.png';
import LeftBanner from '../../../assets/images/leftBanner2.png';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  submitSupportAndFeedbackFormAPIStatus: STATUS;
  submitSupportAndFeedbackForm: typeof submitSupportAndFeedbackForm;
  t: any;
}

export const SupportAndFeedbackWithoutLogin: FunctionComponent<IProps> = (
  props: IProps
) => {
  const {
    className,
    submitSupportAndFeedbackFormAPIStatus,
    submitSupportAndFeedbackForm,
    t,
    toastManager
  } = props;
  const _submitSupportAndFeedbackForm = async (values: IFormValues) => {
    try {
      await submitSupportAndFeedbackForm(values);
      toastManager.add(getMessage(SUCCESS_MESSAGE.SUBMIT_SUPPORT_FEEDBACK), {
        appearance: 'success',
        autoDismiss: true
      });
    } catch (err) {
      console.log(':: _retrieveContent err ', err);
      toastManager.add(getMessage(ERROR_MESSAGE.SUBMIT_SUPPORT_FEEDBACK), {
        appearance: 'error',
        autoDismiss: true
      });
    }
  };

  return (
    <Box className={className}>
      {submitSupportAndFeedbackFormAPIStatus === STATUS.LOADING ? (
        <Box p={'lg'}>
          <FullPageLoader />
        </Box>
      ) : null}
      <Box className="signupAr">
        <Box className="lftImage">
          <a href={ROUTES.HOME}>
            <img src={Logo2} className="innLogo" alt="pdg" />
          </a>
          <img src={LeftBanner} alt="pdg" />
        </Box>
        <Box className="_snInfo" pt="xl" pb="xl">
          <Box className="container">
            <Box className="_abBox">
              <h3>{t('CONTACT_US')}</h3>
              <SupportAndFeedbackForm
                {...props}
                submitForm={(values: IFormValues) => {
                  _submitSupportAndFeedbackForm(values);
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: IAppState) => {
  const submitSupportAndFeedbackFormAPIStatus = getSupportAndFeedbackAPIStatus(
    state
  );
  return {
    submitSupportAndFeedbackFormAPIStatus
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  return bindActionCreators({ submitSupportAndFeedbackForm }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(withToastManager(SupportAndFeedbackWithoutLogin)));
