/** @jsx jsx */
import { forwardRef } from 'react';
import {
  Button,
  ButtonProps,
  IconButton,
  ButtonGroup,
  Menu,
  MenuButton,
  MenuList,
  MenuItem
} from '@chakra-ui/core';
import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';
import { css, jsx } from '@emotion/core';

interface IProps extends ButtonProps {
  size?: 'xs' | 'sm' | 'lg';
}

const StyledButton = Button;

const gridActionStyle = ({ theme }: any) => {
  return css`
    font-size: ${theme.fontSizes.sm};
    border-radius: ${theme.radii.full};
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    min-width: auto;
  `;
};

const StyledIconButton = styled(IconButton)``;

const TransparentIconButton = styled(StyledIconButton)`
  background: transparent;
`;

const StyledButtonGroup = styled(ButtonGroup)``;

export {
  StyledButton as Button,
  StyledIconButton as IconButton,
  TransparentIconButton,
  StyledButtonGroup as ButtonGroup
};

export const PrimaryButton = forwardRef((props: ButtonProps, ref) => (
  <StyledButton ref={ref} {...props} />
));

export const SecondaryButton = forwardRef((props: ButtonProps, ref) => (
  <StyledButton ref={ref} variantColor="secondary" {...props} />
));

export const SuccessButton = forwardRef((props: ButtonProps, ref) => (
  <StyledButton ref={ref} variantColor="success" {...props} />
));

export const DangerButton = forwardRef((props: ButtonProps, ref) => (
  <StyledButton ref={ref} variantColor="danger" {...props} />
));

export const WarningButton = forwardRef((props: ButtonProps, ref) => (
  <StyledButton ref={ref} variantColor="warning" {...props} />
));

export const PrimaryOutlineButton = forwardRef((props: ButtonProps, ref) => (
  <StyledButton ref={ref} variantColor="primary" {...props} variant="outline" />
));

export const SecondaryOutlineButton = forwardRef((props: ButtonProps, ref) => (
  <StyledButton
    ref={ref}
    variantColor="secondary"
    {...props}
    variant="outline"
  />
));

export const TernaryOutlineButton = forwardRef((props: ButtonProps, ref) => (
  <StyledButton ref={ref} variantColor="gray" {...props} variant="outline" />
));

export const SuccessOutlineButton = forwardRef((props: ButtonProps, ref) => (
  <StyledButton ref={ref} variantColor="success" {...props} variant="outline" />
));

export const DangerOutlineButton = forwardRef((props: ButtonProps, ref) => (
  <StyledButton ref={ref} variantColor="danger" {...props} variant="outline" />
));

export const WarningOutlineButton = forwardRef((props: ButtonProps, ref) => (
  <StyledButton ref={ref} variantColor="warning" {...props} variant="outline" />
));

export const OutlineButton = forwardRef((props: ButtonProps, ref) => (
  <StyledButton ref={ref} {...props} variant="outline" />
));

export const PrimaryGhostButton = forwardRef((props: ButtonProps, ref) => (
  <StyledButton ref={ref} variantColor="primary" {...props} variant="ghost" />
));

export const SecondaryGhostButton = forwardRef((props: ButtonProps, ref) => (
  <StyledButton ref={ref} variantColor="secondary" {...props} variant="ghost" />
));

export const SuccessGhostButton = forwardRef((props: ButtonProps, ref) => (
  <StyledButton ref={ref} variantColor="success" {...props} variant="ghost" />
));

export const DangerGhostButton = forwardRef((props: ButtonProps, ref) => (
  <StyledButton ref={ref} variantColor="danger" {...props} variant="ghost" />
));

export const WarningGhostButton = forwardRef((props: ButtonProps, ref) => (
  <StyledButton ref={ref} variantColor="warning" {...props} variant="ghost" />
));

export const PrimaryUnstyledButton = forwardRef((props: ButtonProps, ref) => (
  <StyledButton
    ref={ref}
    variantColor="primary"
    {...props}
    variant="unstyled"
  />
));

export const GridAction = styled(SecondaryOutlineButton)`
  ${gridActionStyle};
`;

export const GridDangerAction = styled(DangerOutlineButton)`
  ${gridActionStyle};
`;

export const GridPositiveAction = styled(SuccessOutlineButton)`
  ${gridActionStyle};
`;

const inputStyle = ({ theme }: any) => {
  return css`
    border: 1px solid ${theme.colors.defaultFormInputBorder};
    background-color: ${theme.colors.formInputBg};
    padding: ${theme.space.xs} ${theme.space.sm};
    color: ${theme.colors.grey9};
    border-radius: ${theme.radii.md};
    height: auto;
    line-height: 1.5;

    &:focus {
      background-color: ${theme.colors.focusedFormInputBg};
      border-color: ${theme.colors.focusedFormInputBorder};
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    }

    &:disabled {
      background-color: ${theme.colors.disabledFormInputBg};
      opacity: 1;
    }
  `;
};

const StyledMenu = styled(Menu)``;
const StyledMenuButton = styled(MenuButton)`
  ${inputStyle}
`;
const StyledMenuList = styled(MenuList)`
  border: 1px solid ${themeGet('colors.focusedFormInputBorder')};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  padding: ${themeGet('space.2xs')} 0;
`;
const StyledMenuItem = styled(MenuItem)`
  padding: ${themeGet('space.sm')} ${themeGet('space.sm')};

  &:hover,
  &:focus {
    background-color: ${themeGet('colors.primary.50')};
  }

  &:active {
    background-color: ${themeGet('colors.primary.100')};
  }

  &:not(:last-of-type) {
    border-bottom: solid 1px ${themeGet('colors.gray.400')};
  }
`;

export {
  StyledMenu as Menu,
  StyledMenuButton as MenuButton,
  StyledMenuList as MenuList,
  StyledMenuItem as MenuItem
};
