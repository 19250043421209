import { Reducer, combineReducers } from 'redux';
import {
  ITermsAndConditionsPageState,
  termsAndConditionsPageReducer
} from './ui';

export interface ITermsAndConditionsPageUI {
  termsAndConditionsPage: ITermsAndConditionsPageState;
}

export interface ITermsAndConditionsState {
  ui: ITermsAndConditionsPageUI;
}

const ui = combineReducers({
  termsAndConditionsPage: termsAndConditionsPageReducer
});

export const termsAndConditionsReducer: Reducer<ITermsAndConditionsState> = combineReducers(
  { ui }
);
