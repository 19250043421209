import { AnyAction, Reducer } from 'redux';
import { IAppState } from '../../../../store';
import { STATUS } from '../../../../constant';
import { ILegals } from '../../models/legals.model';
import {
  RETRIEVE_ABOUT_US_CONTENT__START,
  RETRIEVE_ABOUT_US_CONTENT__FAILURE,
  RETRIEVE_ABOUT_US_CONTENT__SUCCESS
} from '../../actions';

export interface ILegalsPageState {
  legals: ILegals | null;
  retrieveLegalsAPIStatus: STATUS;
}

export const defaultState: ILegalsPageState = {
  legals: null,
  retrieveLegalsAPIStatus: STATUS.NOT_STARTED
};

export const legalsPageReducer: Reducer<ILegalsPageState> = (
  state: ILegalsPageState = defaultState,
  action: AnyAction
): ILegalsPageState => {
  switch (action.type) {
    case RETRIEVE_ABOUT_US_CONTENT__START: {
      return {
        ...state,
        legals: defaultState.legals,
        retrieveLegalsAPIStatus: STATUS.LOADING
      };
    }
    case RETRIEVE_ABOUT_US_CONTENT__FAILURE: {
      return {
        ...state,
        legals: defaultState.legals,
        retrieveLegalsAPIStatus: STATUS.FAILURE
      };
    }
    case RETRIEVE_ABOUT_US_CONTENT__SUCCESS: {
      const { legals } = action.payload;
      return {
        ...state,
        legals,
        retrieveLegalsAPIStatus: STATUS.SUCCESS
      };
    }
    default: {
      return state;
    }
  }
};

export const getLegals = (state: IAppState) => {
  return state.legals.ui.legalsPage.legals;
};
export const getLegalsAPIStatus = (state: IAppState) => {
  return state.legals.ui.legalsPage.retrieveLegalsAPIStatus;
};
