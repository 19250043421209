import React, { FunctionComponent } from 'react';
import { Box } from '@chakra-ui/core';

import Classes1 from '../../../assets/images/icons/Classes/1.png';
import Classes2_1 from '../../../assets/images/icons/Classes/2_1.png';
import Classes2_2 from '../../../assets/images/icons/Classes/2_2.png';
import Classes2_3 from '../../../assets/images/icons/Classes/2_3.png';
import Classes3 from '../../../assets/images/icons/Classes/3.png';
import Classes4_1 from '../../../assets/images/icons/Classes/4_1.png';
import Classes4_2 from '../../../assets/images/icons/Classes/4_2.png';
import Classes4_3 from '../../../assets/images/icons/Classes/4_3.png';
import Classes5_1 from '../../../assets/images/icons/Classes/5_1.png';
import Classes5_2 from '../../../assets/images/icons/Classes/5_2.png';
import Classes6_1 from '../../../assets/images/icons/Classes/6_1.png';
import Classes6_2 from '../../../assets/images/icons/Classes/6_2.png';
import Classes7 from '../../../assets/images/icons/Classes/7.png';
import Classes8 from '../../../assets/images/icons/Classes/8.png';
import Classes9 from '../../../assets/images/icons/Classes/9.png';

export interface IProps {
  class_division: string;
  className?: string;
}

export const ClassDivisionImage: FunctionComponent<IProps> = ({
  class_division,
  className
}) => {
  const getClassDivisonImage = () => {
    switch (class_division) {
      case '1': {
        return (
          <Box className="row justify-content-start">
            <img
              src={Classes1}
              alt="class 1"
              className={`col-auto ${className}`}
            />
          </Box>
        );
      }
      case '2': {
        return (
          <Box className="row justify-content-start">
            <img
              src={Classes2_1}
              alt="class 2.1"
              className={`col-auto ${className}`}
            />
          </Box>
        );
      }
      case '2_others': {
        return (
          <Box className="row justify-content-start" style={{ width: '200px' }}>
            <img
              src={Classes2_2}
              alt="class 2.3"
              className={`col-auto ${className}`}
            />
            <img
              src={Classes2_3}
              alt="class 2.3"
              className={`col-auto ${className}`}
            />
          </Box>
        );
      }
      case '3': {
        return (
          <Box className="row justify-content-start">
            <img
              src={Classes3}
              alt="class 3"
              className={`col-auto ${className}`}
            />
          </Box>
        );
      }
      case '4': {
        return (
          <Box className="row justify-content-start">
            <img
              src={Classes4_1}
              alt="class 4.1"
              className={`col-auto ${className}`}
            />
          </Box>
        );
      }
      case '4_others': {
        return (
          <Box className="row justify-content-start" style={{ width: '200px' }}>
            <img
              src={Classes4_2}
              alt="class 4.2"
              className={`col-auto ${className}`}
            />
            <img
              src={Classes4_3}
              alt="class 4.2"
              className={`col-auto ${className}`}
            />
          </Box>
        );
      }
      case '5': {
        return (
          <Box className="row justify-content-start">
            <img
              src={Classes5_1}
              alt="class 5.1"
              className={`col-auto ${className}`}
            />
          </Box>
        );
      }
      case '5_others': {
        return (
          <Box className="row justify-content-start">
            <img
              src={Classes5_2}
              alt="class 5.2"
              className={`col-auto ${className}`}
            />
          </Box>
        );
      }
      case '6': {
        return (
          <Box className="row justify-content-start">
            <img
              src={Classes6_1}
              alt="class 6.1"
              className={`col-auto ${className}`}
            />
          </Box>
        );
      }
      case '6_others': {
        return (
          <Box className="row justify-content-start">
            <img
              src={Classes6_2}
              alt="class 6.2"
              className={`col-auto ${className}`}
            />
          </Box>
        );
      }
      case '7': {
        return (
          <Box className="row justify-content-start">
            <img
              src={Classes7}
              alt="class 7"
              className={`col-auto ${className}`}
            />
          </Box>
        );
      }
      case '8': {
        return (
          <Box className="row justify-content-start">
            <img
              src={Classes8}
              alt="class 8"
              className={`col-auto ${className}`}
            />
          </Box>
        );
      }
      case '9': {
        return (
          <Box className="row justify-content-start">
            <img
              src={Classes9}
              alt="class 9"
              className={`col-auto ${className}`}
            />
          </Box>
        );
      }
      default: {
        return null;
      }
    }
  };
  return <Box className="class-division-image">{getClassDivisonImage()}</Box>;
};
