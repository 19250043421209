/* istanbul ignore file */
import React, { FunctionComponent, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Box } from '@chakra-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import AddIcon from '../../../assets/images/add.png';
import CloseIcon from '../../../assets/images/close.png';
import { IProductInCart, IUnNumber } from '../models/un-number.model';
import UnNumberDetail from '../components/UnNumberDetail.component';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  addUnNumberIntoCart: () => void;
  removeUnNumberFromCart: () => void;
  unNumberDetail: IUnNumber;
  unNumbersInCart: IProductInCart[];
  t: any;
}

export const UnNumberListItemComponent: FunctionComponent<IProps> = (
  props: IProps
) => {
  const {
    className,
    unNumberDetail,
    addUnNumberIntoCart,
    removeUnNumberFromCart,
    unNumbersInCart
  } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <Box className={`${className} mt-4 ab-bx-3`}>
      <Box className="p-3">
        <Box className="d-flex align-items-center justify-content-start">
          <Box className="ab-txt">
            <span>
              {unNumberDetail.un_no}, {unNumberDetail.class_division_value}
            </span>
          </Box>
        </Box>
        <Box className="ab-bottom">
          <Box
            className="show-more row justify-content-between"
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
          >
            <span className="col-11"> {unNumberDetail.name_description}</span>
            <span className="col-1">
              {!isExpanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </span>
          </Box>
        </Box>
        {isExpanded ? <UnNumberDetail {...props} /> : null}
        {unNumbersInCart.find(
          (item: IProductInCart) => item.un_no === unNumberDetail.un_no
        ) ? (
          <i
            className="_close"
            title="Remove Goods"
            onClick={() => {
              removeUnNumberFromCart();
            }}
          >
            <img src={CloseIcon} alt="close" />
          </i>
        ) : (
          <i
            className="_add"
            title="Add Goods"
            onClick={() => {
              addUnNumberIntoCart();
            }}
          >
            <img src={AddIcon} alt="add" />
          </i>
        )}
      </Box>
    </Box>
  );
};

export default UnNumberListItemComponent;
