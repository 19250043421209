/* istanbul ignore file */
import React, { FunctionComponent } from 'react';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';

import { ILicense } from '../models/license.model';
import { User, USER_ROLE } from '../../../common/models/user';
import { isAdmin } from '../../../helper';

export interface IProps {
  license: ILicense;
  user: User;
  srNo: number;
  onSelect: () => void;
  deleteUser: (userId: string) => void;
}

export const LicenseItems: FunctionComponent<IProps> = (props: IProps) => {
  const { license, srNo, user, onSelect, deleteUser } = props;
  const checkIsEdit = () => {
    if (isAdmin(license.user?.role)) return false;
    if (
      license.user?.role === USER_ROLE.MANAGER &&
      user.role === USER_ROLE.SUPERVISOR
    )
      return false;
    return true;
  };
  const showAction = () => {
    if (license.user) {
      if (license.user.email === user.email) {
        return 'Me';
      } else {
        return (
          <>
            <a
              href="#"
              style={{ color: '#000' }}
              onClick={() => {
                onSelect();
              }}
              title="Edit Sub User"
            >
              <EditIcon />
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a
              href="#"
              style={{ color: '#000' }}
              onClick={() => {
                deleteUser(license.user?.user_id as string);
              }}
              title="Delete Sub User"
            >
              <CloseIcon />
            </a>
          </>
        );
      }
    } else {
      return (
        <a
          href="#"
          style={{ color: '#000' }}
          onClick={() => {
            onSelect();
          }}
          title="Add Sub User"
        >
          <PersonAddIcon />
        </a>
      );
    }
  };

  const getUserRole = (role: string) => {
    if (role === USER_ROLE.MAIN) {
      return USER_ROLE.COMPANY_ADMIN;
    }
     return role;
  }
  return (
    <tr>
      <td>{srNo}</td>
      <td>{license.user ? license.user.name : 'N/A'}</td>
      <td>{license.user ? license.user.email : 'N/A'}</td>
      <td>
        {license.user
          ? `${license.user.country_id} - ${license.user.phone_no}`
          : 'N/A'}
      </td>
      <td>{getUserRole(license.user?.role ?? 'N/A').toUpperCase()}</td>
      <td>{license.licence}</td>
      <td>{checkIsEdit() ? showAction() : null}</td>
    </tr>
  );
};

export default LicenseItems;
