import { createAction } from 'redux-actions';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  ILogsParams,
  ILogsResponse,
  ManifestHisotryServices
} from '../services/api.service';

export const RETRIEVE_LOGS_LIST__START = 'pdg.logs.list.ui.start';
export const retrieveLogsStart = createAction(RETRIEVE_LOGS_LIST__START);

export const RETRIEVE_LOGS_LIST__FAILURE = 'pdg.logs.list.ui.failure';
export const retrieveLogsFailure = createAction(
  RETRIEVE_LOGS_LIST__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const RETRIEVE_LOGS_LIST__SUCCESS = 'pdg.logs.list.ui.success';
export const retrieveLogsSuccess = createAction(
  RETRIEVE_LOGS_LIST__SUCCESS,
  (logsResponse: ILogsResponse) => {
    return logsResponse;
  }
);

export const retrieveLogs = (
  params: ILogsParams
): ThunkAction<Promise<ILogsResponse>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(retrieveLogsStart());
      const res = await ManifestHisotryServices.retrieveLogs(params);
      if (res.status === 1) {
        dispatch(retrieveLogsSuccess(res));
      } else {
        throw res;
      }
      return res;
    } catch (err) {
      dispatch(retrieveLogsFailure(err));
      throw err;
    }
  };
};

export const RESET_DATA = 'pdg.manifestHistory.reset.data.start';
export const resetData = createAction(RESET_DATA);
