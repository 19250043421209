/* istanbul ignore file */
import React, { FunctionComponent } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Box } from '@chakra-ui/core';
import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  ghsList,
  IGHS,
  IQuestionsAndPoints
} from '../../../common/components/utils/ghs';
import { GHSImage } from '../../../common/components/helpers/ghs_image.component';
import { Header } from '../../../common/components/helpers/header.component';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  t: any;
}

export const GHSDetail: FunctionComponent<IProps> = (props: IProps) => {
  const { match } = props;
  const { params } = match;
  const code = (params as any).id;
  const ghsDataByCode = useSelector(() =>
    ghsList.find((datum: IGHS) => code && datum.code === code)
  );

  return (
    <div>
      <section className="inn-bnr container-fluid header-with-login">
        <Header isShowSecondLogo={false} {...props} />
      </section>

      {ghsDataByCode && ghsDataByCode.code ? (
        <section className="inSectn">
          <div className="inn-container">
            <div className="mt-104">
              <div className="unBox p-4">
                <div className="d-flex align-items-center">
                  <GHSImage ghs={ghsDataByCode.code} />
                  <div className="ml-3">
                    <h5>
                      <strong>{ghsDataByCode.name}</strong>
                    </h5>
                    <span className="d-block db-txt-2">
                      <strong>{ghsDataByCode.code}</strong>
                    </span>
                  </div>
                </div>
                <div className="mt-3">
                  <span className="web-txt">
                    {ghsDataByCode.mainDescription}
                  </span>
                  {ghsDataByCode.mainDescription2 ? (
                    <span className="web-txt">
                      {ghsDataByCode.mainDescription2}
                    </span>
                  ) : null}
                  {ghsDataByCode.description.length ? (
                    <ul className="mt-4 in-ord">
                      {ghsDataByCode.description.map(
                        (datum: string, index: number) => (
                          <li key={index}>{datum}</li>
                        )
                      )}
                    </ul>
                  ) : null}
                  {ghsDataByCode.questionsAndPoints.map(
                    (datum: IQuestionsAndPoints, index: number) => (
                      <Box key={index}>
                        <h6 className="mt-3">
                          <strong>{datum.question}</strong>
                        </h6>
                        <ul className="mt-3 in-ord">
                          {datum.points.map(
                            (datumPoint: string, indexPoint: number) => (
                              <li key={indexPoint}>{datumPoint}</li>
                            )
                          )}
                        </ul>
                      </Box>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </div>
  );
};

export default withNamespaces()(withToastManager(withRouter(GHSDetail)));
