/* istanbul ignore file */
import React, { FunctionComponent, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Box } from '@chakra-ui/core';

import { ClassDivisionImage } from '../../../common/components/helpers/class_division_image.component';
import { IProduct, IReport } from '../models/reports-list.model';
import { H1 } from '../../../common/components';
import TruckIcon from '../../../assets/images/truck.png';
import { unloadProduct } from '../actions';
import { getMessage } from '../../../helper';
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from '../../../constant';
import { ConfirmAlert } from '../../../common/components';

// import { EPGLinks } from '../../../common/components/helpers/epg_link.component';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  product: IProduct;
  t: any;
  reportData: IReport;
  unloadProduct: typeof unloadProduct;
}

export const ProductListComponent: FunctionComponent<IProps> = (
  props: IProps
) => {
  const [isUnload, setIsunload] = useState(false);
  const { t, product, reportData, toastManager, unloadProduct } = props;
  const _unloadProduct = async () => {
    try {
      await unloadProduct(Number(reportData.report_id), product.id);
      toastManager.add(getMessage(SUCCESS_MESSAGE.UNLOAD_PRODUCT), {
        appearance: 'success',
        autoDismiss: true
      });
    } catch (err) {
      console.log(':: _unloadProduct err ', err);
      toastManager.add(getMessage(ERROR_MESSAGE.UNLOAD_PRODUCT), {
        appearance: 'error',
        autoDismiss: true
      });
    } finally {
      setIsunload(false);
    }
  };
  return (
    <Box className="col-12 col-lg-6">
      <Box className="ab-bx-4 p-3">
        <Box className="row">
          <Box className="col-12 col-lg-9">
            <Box className="" mb="4xl">
              <Box className="mr-3" mb="md">
                <H1 className="cart-class-division">
                  <strong>
                    {t('CLASS_DIVISION')}: {product.class}
                  </strong>
                </H1>
              </Box>
              <Box>
                <p>
                  <strong>
                    {product.un_no_name ?? product.name_description}
                  </strong>
                </p>
                {product.un_no ? (
                  <p>
                    <strong>
                      {t('UN')} {product.un_no}
                    </strong>
                  </p>
                ) : null}
              </Box>
            </Box>
            <Box className="mt-5">
              <p>
                <strong>
                  {t('PACKAGING')}: {product.packagingType}
                </strong>
              </p>
              <p>
                <strong>
                  {t('MESUREMENT')}: {product.mesurement}
                </strong>
              </p>
              <p>
                <strong>
                  {t('QUANTITY')}: {product.quantity}
                </strong>
              </p>
            </Box>
            <Box className="mt-5">
              <Box className="d-flex justify-content-start align-items-center rmt-10">
                {product.images &&
                  product.images.length !== 0 &&
                  product.images.map((image: any, index: number) => (
                    <Box className="trkImg2 mr-2" key={index}>
                      <img src={image.img} alt="product" />
                    </Box>
                  ))}
              </Box>
            </Box>
          </Box>
          <Box className="col-12 col-lg-3" mt="md" height="350px">
            <ClassDivisionImage
              className="w-100"
              class_division={product.class}
            />
            <Box className="unload-container">
              <span
                onClick={() => {
                  setIsunload(true);
                }}
              >
                <img src={TruckIcon} alt="unload good" title="Unload Good" />
              </span>
            </Box>
          </Box>
        </Box>
      </Box>
      {isUnload ? (
        <ConfirmAlert
          title={'Unload Good Confirmation'}
          description={'Do you really want to unload these goods?'}
          onCancel={() => setIsunload(false)}
          onConfirm={() => {
            _unloadProduct();
          }}
          type={'danger'}
          cancelText="No"
          confirmText="Yes"
        />
      ) : null}
    </Box>
  );
};

export default ProductListComponent;
