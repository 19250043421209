import {
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbItem as ChakraBreadcrumbItem,
  BreadcrumbLink as ChakraBreadcrumbLink,
  BreadcrumbSeparator as ChakraBreadcrumbSeparator
} from '@chakra-ui/core';
import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';

export const Breadcrumb = styled(ChakraBreadcrumb)`
  margin-bottom: ${themeGet('space.sm')};
`;
export const BreadcrumbItem = styled(ChakraBreadcrumbItem)`
  text-transform: uppercase;
  font-family: ${themeGet('fonts.heading')};
  font-size: ${themeGet('fontSizes.sm')};
  font-weight: bold;
  color: ${themeGet('colors.gray.700')};

  .MuiSvgIcon-root {
    font-size: ${themeGet('fontSizes.lg')};
    position: relative;
    top: -1px;
  }
`;
export const BreadcrumbLink = styled(ChakraBreadcrumbLink)`
  &:hover,
  &:focus {
    text-decoration: none;
    color: ${themeGet('colors.primary.600')};
  }
`;
export const BreadcrumbSeparator = styled(ChakraBreadcrumbSeparator)`
  color: ${themeGet('colors.gray.500')};
`;
