import React, { FunctionComponent, useState } from 'react';
import { Box } from '@chakra-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { MANIFEST_APPROVE_STATUS } from '../services/api.service';
import { ERROR_MESSAGE } from '../../../constant';
import { getMessage } from '../../../helper';

export interface IProps {
  t: any;
  status: MANIFEST_APPROVE_STATUS;
  toastManager: any;
  handleSubmit: (reason: string | null) => void;
  onClose: () => void;
}
export const ApproveAndRejectPopup: FunctionComponent<IProps> = ({
  onClose,
  handleSubmit,
  toastManager,
  status,
  t
}) => {
  const [reason, setReason] = useState('');
  const _handleSubmit = () => {
    if (reason === '' && status === MANIFEST_APPROVE_STATUS.REJECTED) {
      toastManager.add(getMessage(ERROR_MESSAGE.REASON_FIELD), {
        appearance: 'error',
        autoDismiss: true
      });
    } else {
      handleSubmit(reason !== '' ? reason : null);
    }
  };
  return (
    <div className="_cstModal">
      <div className="_mdBody">
        <form
          onSubmit={() => {
            _handleSubmit();
          }}
        >
          <Box className="close-icon">
            <span
              onClick={() => {
                onClose();
              }}
            >
              <CloseIcon />
            </span>
          </Box>
          <Box>
            <label>
              Reason
              {status === MANIFEST_APPROVE_STATUS.APPROVED ? ' (Optional)' : ''}
            </label>
            <input
              type="text"
              required={status === MANIFEST_APPROVE_STATUS.REJECTED}
              className="form-control"
              value={reason}
              placeholder="Enter Reason"
              style={{ width: '100%', padding: '22px' }}
              onChange={(event) => {
                setReason(event.target.value ?? '');
              }}
            />
          </Box>
          <Box className="form-group mt-4">
            <button
              className="btn btn-primary w-100"
              type="submit"
              onClick={() => {
                _handleSubmit();
              }}
            >
              {t('SUBMIT')}
            </button>
          </Box>
        </form>
      </div>
    </div>
  );
};
