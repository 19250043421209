import React, { StrictMode } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'emotion-theming';
import 'core-js/es/map';
import 'core-js/es/set';

import AuthContainer from './authorization/AuthContainer';
import App from './App';
import { store } from './store';
import theme from './theme';

import './i18n';
import './styles/global.styles.scss';
// import './firebase-messaging-sw';

if (process.env.NODE_ENV !== 'production') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render/dist/no-classes-transpile/umd/whyDidYouRender.min.js'); // eslint-disable-line
  whyDidYouRender(React, {
    trackAllPureComponents: false
  });
}

render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <AuthContainer>
          <App />
        </AuthContainer>
      </Provider>
    </ThemeProvider>
  </StrictMode>,
  document.getElementById('root')
);
