import { AnyAction, Reducer } from 'redux';
import { IAppState } from '../../../../store';
import { STATUS } from '../../../../constant';
import {
  RETRIEVE_LOGS_LIST__START,
  RETRIEVE_LOGS_LIST__FAILURE,
  RETRIEVE_LOGS_LIST__SUCCESS,
  RESET_DATA
} from '../../actions';

export interface IManifestHistoryPageState {
  totalCount: number;
  retrieveLogsAPIStatus: STATUS;
}

export const defaultState: IManifestHistoryPageState = {
  totalCount: 0,
  retrieveLogsAPIStatus: STATUS.NOT_STARTED
};

export const mainfestHistoryPageReducer: Reducer<IManifestHistoryPageState> = (
  state: IManifestHistoryPageState = defaultState,
  action: AnyAction
): IManifestHistoryPageState => {
  switch (action.type) {
    case RETRIEVE_LOGS_LIST__START: {
      return {
        ...state,
        totalCount: defaultState.totalCount,
        retrieveLogsAPIStatus: STATUS.LOADING
      };
    }
    case RETRIEVE_LOGS_LIST__FAILURE: {
      return {
        ...state,
        totalCount: defaultState.totalCount,
        retrieveLogsAPIStatus: STATUS.FAILURE
      };
    }
    case RETRIEVE_LOGS_LIST__SUCCESS: {
      const { totalCount } = action.payload;
      return {
        ...state,
        totalCount,
        retrieveLogsAPIStatus: STATUS.SUCCESS
      };
    }
    case RESET_DATA: {
      return {
        ...state,
        retrieveLogsAPIStatus: defaultState.retrieveLogsAPIStatus
      };
    }
    default: {
      return state;
    }
  }
};

export const getLogsAPIStatus = (state: IAppState) => {
  return state.manifestHistory.ui.manifestHistoryPage.retrieveLogsAPIStatus;
};
export const getLogTotalCount = (state: IAppState) => {
  return state.manifestHistory.ui.manifestHistoryPage.totalCount;
};
