import styled from '@emotion/styled';
import {
  AlertDialog as ChakraAlertDialog,
  AlertDialogOverlay as ChakraAlertDialogOverlay,
  AlertDialogContent as ChakraAlertDialogContent,
  AlertDialogHeader as ChakraAlertDialogHeader,
  AlertDialogBody as ChakraAlertDialogBody,
  AlertDialogFooter as ChakraAlertDialogFooter
} from '@chakra-ui/core';
import { themeGet } from '@styled-system/theme-get';

export const AlertDialog = styled(ChakraAlertDialog)``;
export const AlertDialogOverlay = styled(ChakraAlertDialogOverlay)``;
export const AlertDialogContent = styled(ChakraAlertDialogContent)`
  padding: ${themeGet('space.xl')};
  box-shadow: ${themeGet('shadows.lg')};
  border-radius: ${themeGet('radii.lg')};
`;
export const AlertDialogHeader = styled(ChakraAlertDialogHeader)`
  padding: 0;
  margin-bottom: ${themeGet('space.sm')};
`;
export const AlertDialogBody = styled(ChakraAlertDialogBody)`
  padding: 0;
`;
export const AlertDialogFooter = styled(ChakraAlertDialogFooter)`
  padding: 0;
  margin-top: ${themeGet('space.xl')};
`;
