import React, { FunctionComponent } from 'react';
import { FormikProps } from 'formik';
import { Box } from '@chakra-ui/core';

import { IProps } from './ChangePassword.component';

export interface IChangePasswordFormValues {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export const ChangePasswordForm: FunctionComponent<
  FormikProps<IChangePasswordFormValues> & Partial<IProps>
> = ({ values, handleSubmit, errors, touched, setFieldValue, t }) => {
  return (
    <>
      <div className="login-container">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              placeholder={t('CURRENT_PASSWORD')}
              name="currentPassword"
              value={values.currentPassword}
              onChange={(event) => {
                setFieldValue('currentPassword', event.target.value);
              }}
            />
            {errors.currentPassword && touched.currentPassword ? (
              <Box className="invalid-feedback">{errors.currentPassword}</Box>
            ) : null}
          </div>
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              placeholder={t('NEW_PASSWORD')}
              name="newPassword"
              value={values.newPassword}
              onChange={(event) => {
                setFieldValue('newPassword', event.target.value);
              }}
            />
            {errors.newPassword && touched.newPassword ? (
              <Box className="invalid-feedback">{errors.newPassword}</Box>
            ) : null}
          </div>
          <div className="form-group">
            <input
              type="password"
              className="form-control"
              placeholder={t('CONFIRM_PASSWORD')}
              name="confirmPassword"
              value={values.confirmPassword}
              onChange={(event) => {
                setFieldValue('confirmPassword', event.target.value);
              }}
            />
            {errors.confirmPassword && touched.confirmPassword ? (
              <Box className="invalid-feedback">{errors.confirmPassword}</Box>
            ) : null}
          </div>
          <div className="form-group">
            <button className="btn btn-primary w-100" type="submit">
              {t('UPDATE_PASSWORD')}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
