import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { createAction } from 'redux-actions';
import {
  CommonService,
  IBroadcastMessageParams,
  IUploadQueryParams,
  ICompanyListResponse,
  IOperatorListResponse
} from '../services/common.service';
import GlobalConfig from '../../globalConfig';
import { prepareRequestHeaders } from '../../services/request.service';
import { IChangePasswordFormValues } from '../components/ChangePassword_editorForm.component';
import { IProfileEditorFormValues } from '../components/EditProfile_editorForm.component';
import { INotification } from '../models/notification';
import { User } from '../models/user';

export const GET_COMPANY_LIST__START =
  'pdg.currentManifest.get.company.list.start';
export const retrieveCompanyListStart = createAction(GET_COMPANY_LIST__START);

export const GET_COMPANY_LIST__FAILURE =
  'pdg.currentManifest.get.company.list.failure';
export const retrieveCompanyListFailure = createAction(
  GET_COMPANY_LIST__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const GET_COMPANY_LIST__SUCCESS =
  'pdg.currentManifest.get.company.list.success';
export const retrieveCompanyListSuccess = createAction(
  GET_COMPANY_LIST__SUCCESS,
  (companies: User[]) => ({
    companies
  })
);

export const retrieveCompanyList = (): ThunkAction<
  Promise<ICompanyListResponse>,
  {},
  {},
  AnyAction
> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(retrieveCompanyListStart());
      const companyListResponse = await CommonService.retrieveCompanyList();
      if (companyListResponse.status === 1) {
        dispatch(retrieveCompanyListSuccess(companyListResponse.company));
      }
      return companyListResponse;
    } catch (err) {
      dispatch(retrieveCompanyListFailure(err));
      throw err;
    }
  };
};

export const GET_OPERATOR_LIST__START =
  'pdg.currentManifest.get.operator.list.start';
export const retrieveCompanyBasedOperatorsListStart = createAction(
  GET_OPERATOR_LIST__START
);

export const GET_OPERATOR_LIST__FAILURE =
  'pdg.currentManifest.get.operator.list.failure';
export const retrieveCompanyBasedOperatorsListFailure = createAction(
  GET_OPERATOR_LIST__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const GET_OPERATOR_LIST__SUCCESS =
  'pdg.currentManifest.get.operator.list.success';
export const retrieveCompanyBasedOperatorsListSuccess = createAction(
  GET_OPERATOR_LIST__SUCCESS,
  (operators: User[], companyId: string) => ({
    operators,
    companyId
  })
);

export const retrieveCompanyBasedOperatorsList = (
  companyId: string
): ThunkAction<Promise<IOperatorListResponse>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(retrieveCompanyBasedOperatorsListStart());
      const operatorListResponse = await CommonService.retrieveCompanyBasedOperatorsList(
        companyId
      );
      if (operatorListResponse.status === 1) {
        dispatch(
          retrieveCompanyBasedOperatorsListSuccess(
            operatorListResponse.operators,
            companyId
          )
        );
      }
      return operatorListResponse;
    } catch (err) {
      dispatch(retrieveCompanyBasedOperatorsListFailure(err));
      throw err;
    }
  };
};

export const uploadImage = async (params: IUploadQueryParams) => {
  return await CommonService.uploadImage(params);
};

export const getImageURL = async (
  referenceId: string,
  asDownloadable = false
) => {
  const apiEndpoint = GlobalConfig.getApiUrlFromRoot(
    `/web/file/v1/admin/file/download`
  );
  const apiEndpointURL = new URL(apiEndpoint);

  apiEndpointURL.searchParams.append('referenceId', referenceId);

  if (asDownloadable) {
    apiEndpointURL.searchParams.append('downloadType', 'attachment');
  }

  const response = await fetch(apiEndpointURL.toString(), {
    headers: prepareRequestHeaders({})
  });

  return response.redirected ? response.url : '';
};

export const CHANGE_PASSWORD__START = 'pdg.change.password.ui.start';
export const changePasswordStart = createAction(CHANGE_PASSWORD__START);

export const CHANGE_PASSWORD__FAILURE = 'pdg.change.password.ui.failure';
export const changePasswordFailure = createAction(
  CHANGE_PASSWORD__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const CHANGE_PASSWORD__SUCCESS = 'pdg.change.password.ui.success';
export const changePasswordSuccess = createAction(CHANGE_PASSWORD__SUCCESS);

export const changePassword = (
  data: IChangePasswordFormValues
): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(changePasswordStart());
      const res = await CommonService.changePassword(data);
      if (res && res.status !== 0) {
        dispatch(changePasswordSuccess());
        return res;
      }
      throw res;
    } catch (err) {
      dispatch(changePasswordFailure(err));
      throw err;
    }
  };
};

export const EDIT_PROFILE__START = 'pdg.edit.profile.ui.start';
export const editProfileStart = createAction(EDIT_PROFILE__START);

export const EDIT_PROFILE__FAILURE = 'pdg.edit.profile.ui.failure';
export const editProfileFailure = createAction(
  EDIT_PROFILE__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const EDIT_PROFILE__SUCCESS = 'pdg.edit.profile.ui.success';
export const editProfileSuccess = createAction(
  EDIT_PROFILE__SUCCESS,
  (data: IProfileEditorFormValues) => {
    return { data };
  }
);

export const editProfile = (
  data: IProfileEditorFormValues
): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(editProfileStart());
      const res = await CommonService.editProfile(data);
      if (res && res.status !== 0) {
        dispatch(editProfileSuccess(data));
        return res;
      }
      throw res;
    } catch (err) {
      dispatch(editProfileFailure(err));
      throw err;
    }
  };
};

export const NOTIFICATION__SUCCESS = 'pdg.notification.ui.success';
export const retrieveNotificationSuccess = createAction(
  NOTIFICATION__SUCCESS,
  (notifications: INotification[]) => {
    return { notifications };
  }
);

export const retrieveNotification = (): ThunkAction<
  Promise<INotification[]>,
  {},
  {},
  AnyAction
> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      const notifications = await CommonService.retrieveNotification();
      dispatch(retrieveNotificationSuccess(notifications));
      return notifications;
    } catch (err) {
      throw err;
    }
  };
};

export const BROADCAST_MESSAGE__START = 'pdg.change.password.ui.start';
export const broadcastMessageStart = createAction(BROADCAST_MESSAGE__START);

export const BROADCAST_MESSAGE__FAILURE = 'pdg.change.password.ui.failure';
export const broadcastMessageFailure = createAction(
  BROADCAST_MESSAGE__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const BROADCAST_MESSAGE__SUCCESS = 'pdg.change.password.ui.success';
export const broadcastMessageSuccess = createAction(BROADCAST_MESSAGE__SUCCESS);

export const broadcastMessage = (
  data: IBroadcastMessageParams
): ThunkAction<Promise<any>, {}, {}, AnyAction> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(broadcastMessageStart());
      const res = await CommonService.broadcastMessage(data);
      if (res && res.status !== 0) {
        dispatch(broadcastMessageSuccess());
        return res;
      }
      throw res;
    } catch (err) {
      dispatch(broadcastMessageFailure(err));
      throw err;
    }
  };
};

export const OPERATOR_LIST__START = 'pdg.operator.list.ui.start';
export const retrieveOpertorsListStart = createAction(OPERATOR_LIST__START);

export const OPERATOR_LIST__FAILURE = 'pdg.operator.list.ui.failure';
export const retrieveOpertorsListFailure = createAction(
  OPERATOR_LIST__FAILURE,
  (error: Error) => {
    return error;
  }
);

export const OPERATOR_LIST__SUCCESS = 'pdg.operator.list.ui.success';
export const retrieveOpertorsListSuccess = createAction(
  OPERATOR_LIST__SUCCESS,
  (operatorsList: User[]) => {
    return { operatorsList };
  }
);

export const retrieveOpertorsList = (): ThunkAction<
  Promise<User[]>,
  {},
  {},
  AnyAction
> => {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    try {
      dispatch(retrieveOpertorsListStart());
      const operatorsList: User[] = await CommonService.retrieveOpertorsList();
      dispatch(retrieveOpertorsListSuccess(operatorsList));
      return operatorsList;
    } catch (err) {
      dispatch(retrieveOpertorsListFailure(err));
      throw err;
    }
  };
};
