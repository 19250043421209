import {
  getHeaders,
  getApiUrlFromRoot,
  RequestHeaders
} from './utils/service.utils';

export enum Environment {
  LOCAL = 'local',
  DEV = 'dev',
  QA = 'qa',
  PROD = 'prod',
  MOCK = 'mock'
}

export interface IConfig {
  currentEnvironment: Environment;
  API_ROOT: string;
  googleMapKey: string;
  getApiUrlFromRoot: (relativePath: string) => string;
  getHeaders: (headersFor: string[]) => RequestHeaders;
}

const config: IConfig = {
  currentEnvironment: process.env.REACT_APP_API_BASE_URL as Environment,
  API_ROOT: 'https://admin.pdgapp.com.au',
  // API_ROOT: '//63bd53595e82.ngrok.io',
  googleMapKey: process.env.REACT_APP_GOOGLE_MAPS_KEY ?? '',
  getApiUrlFromRoot,
  getHeaders
};

export default config;
