import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Formik, FormikHelpers as FormikActions, FormikProps } from 'formik';
import { RouteComponentProps } from 'react-router-dom';
import { Box } from '@chakra-ui/core';

import { passwordValidator, getMessage } from '../../helper';
import { ERROR_MESSAGE, SUCCESS_MESSAGE, STATUS } from '../../constant';
import {
  ChangePasswordForm,
  IChangePasswordFormValues
} from './ChangePassword_editorForm.component';
import { changePassword } from '../actions';
import { IAppState } from '../../store';
import { getChangePasswordAPIStatus } from '../reducers/changePassword.reducer';
import { FullPageLoader } from './Loader.component';

export interface IProps extends RouteComponentProps {
  className?: string;
  toggleView: () => void;
  t: any;
  changePasswordAPIStatus: STATUS;
  changePassword: typeof changePassword;
  toastManager: any;
}

export class ChangePasswordComponent extends PureComponent<IProps> {
  static defaultProps: Partial<IProps> = {
    className: ''
  };

  async onFormSubmit(
    values: IChangePasswordFormValues,
    actions: FormikActions<IChangePasswordFormValues>
  ) {
    const { currentPassword, newPassword, confirmPassword } = values;
    const { changePassword, toastManager, toggleView } = this.props;
    const params = {
      newPassword,
      currentPassword,
      confirmPassword
    };
    actions.setSubmitting(true);
    try {
      await changePassword(params);
      toggleView();
      actions.setSubmitting(false);
      toastManager.add(getMessage(SUCCESS_MESSAGE.CHANGE_PASSWORD), {
        appearance: 'success',
        autoDismiss: true
      });
    } catch (err) {
      actions.setSubmitting(false);
      const message =
        err?.errorObj?.message ??
        err?.message ??
        getMessage(ERROR_MESSAGE.CHANGE_PASSWORD);
      toastManager.add(message, { appearance: 'error', autoDismiss: true });
    }
  }

  validateForm(values: IChangePasswordFormValues) {
    const errors: any = {};
    if (!values.currentPassword) {
      errors.currentPassword = getMessage(ERROR_MESSAGE.required);
    }

    if (!values.newPassword) {
      errors.newPassword = getMessage(ERROR_MESSAGE.required);
    } else if (!passwordValidator(values.newPassword)) {
      errors.newPassword = getMessage(ERROR_MESSAGE.password);
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = getMessage(ERROR_MESSAGE.required);
    }
    if (
      values.newPassword &&
      values.confirmPassword &&
      values.confirmPassword !== values.newPassword
    ) {
      errors.confirmPassword = getMessage(
        ERROR_MESSAGE.CHANGE_PASSWORD_CONFIRM_PASSWORD
      );
    }

    return errors;
  }

  render() {
    const { className, toggleView, t, changePasswordAPIStatus } = this.props;

    return (
      <div className={className}>
        {changePasswordAPIStatus === STATUS.LOADING ? (
          <Box p={'lg'}>
            <FullPageLoader />
          </Box>
        ) : null}
        <div className="_cstModal">
          <div className="_mdBody">
            <div className="">{t('CHANGE_PASSWORD')}</div>
            <div className="mt-5">
              <Formik
                validate={this.validateForm.bind(this)}
                initialValues={{
                  currentPassword: '',
                  newPassword: '',
                  confirmPassword: ''
                }}
                validateOnMount
                onSubmit={(
                  values: IChangePasswordFormValues,
                  actions: FormikActions<IChangePasswordFormValues>
                ) => {
                  this.onFormSubmit(values, actions);
                }}
                component={(
                  formikProps: FormikProps<IChangePasswordFormValues>
                ) => {
                  return (
                    <ChangePasswordForm
                      {...formikProps}
                      {...this.props}
                      {...this.state}
                    />
                  );
                }}
              />
              <div
                className="_close"
                onClick={() => {
                  toggleView();
                }}
              >
                x {t('CLOSE')}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IAppState) => {
  const changePasswordAPIStatus = getChangePasswordAPIStatus(state);

  return {
    changePasswordAPIStatus
  };
};

/* istanbul ignore next */
function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ changePassword }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordComponent);
