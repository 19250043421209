/* istanbul ignore file */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { RouteComponentProps } from 'react-router-dom';
import { Box, Alert, useDisclosure } from '@chakra-ui/core';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { STATUS, ERROR_MESSAGE } from '../../../constant';
import { IAppState } from '../../../store';
import { retrieveWarehouseTruck } from '../actions';
import {
  getWarehouseTruckAPIStatus,
  getAddEditWarehouseTruckAPIStatus
} from '../reducers/ui';
import { FullPageLoader } from '../../../common/components';
import { ErrorAlert } from '../../../common/components/ErrorAlert.component';
import { getMessage } from '../../../helper';
import { getWarehouseTruckSelector } from '../selectors/index.page.selector';
import { IWarehouseTruck } from '../models/warehouseTruckRegistration.model';
import WarehouseItems from '../components/WarehouseTruck-items.component';
import { getLoggedInUserDetails } from '../../auth/selectors/auth.selectors';
import { User } from '../../../common/models/user';
import AddWarehouseTruck from '../components/AddWarehouseTruck.component';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  t: any;
  warehouseTrucks: IWarehouseTruck[];
  user: User;
  retrieveWarehouseTruckAPIStatus: STATUS;
  addEditWarehouseTruckAPIStatus: STATUS;
  retrieveWarehouseTruck: typeof retrieveWarehouseTruck;
}

export const WarehouseTruck: FunctionComponent<IProps> = (props: IProps) => {
  const {
    t,
    warehouseTrucks,
    user,
    retrieveWarehouseTruck,
    retrieveWarehouseTruckAPIStatus,
    addEditWarehouseTruckAPIStatus
  } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const _retrieveWarehouseTruck = async () => {
    try {
      const userId: string =
        user.parent_id && user.parent_id !== 0
          ? user.parent_id.toString()
          : user.user_id;
      await retrieveWarehouseTruck(userId);
    } catch (err) {
      console.log(':: _retrieveWarehouseTruck err ', err);
    }
  };

  useEffect(() => {
    _retrieveWarehouseTruck();
  }, []);
  const [warehouseTruck, setWarehouseTruck] = useState<IWarehouseTruck | null>(
    null
  );
  return (
    <Box mt="2xl">
      <section className="dbHomeInfo">
        <Box className="container">
          <Box className="row justify-content-end" mb="md">
            <Box className="col-auto">
              <button
                className="btn-primary btn-14 w-100"
                onClick={() => {
                  onOpen();
                  setWarehouseTruck(null);
                }}
              >
                {t('ADD_WAREHOUSE_OR_TRUCK')}
              </button>
            </Box>
          </Box>
          {retrieveWarehouseTruckAPIStatus === STATUS.LOADING ||
          addEditWarehouseTruckAPIStatus === STATUS.LOADING ? (
            <Box p={'lg'}>
              <FullPageLoader />
            </Box>
          ) : null}
          {retrieveWarehouseTruckAPIStatus === STATUS.FAILURE ? (
            <Box>
              <ErrorAlert
                errorMessage={getMessage(ERROR_MESSAGE.LICENSE_FAILURE)}
                retry={_retrieveWarehouseTruck}
              />
            </Box>
          ) : null}
          {retrieveWarehouseTruckAPIStatus === STATUS.SUCCESS ? (
            <Box className="table-responsive">
              <table
                className="dbTable"
                width="100%"
                cellSpacing="0"
                cellPadding="0"
              >
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Registration Number</th>
                    <th>Type</th>
                    <th>Created At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!warehouseTrucks || warehouseTrucks.length === 0 ? (
                    <tr>
                      <td colSpan={5}>
                        <Alert status="warning">
                          {getMessage(ERROR_MESSAGE.NO_MATCHING_RESULT)}
                        </Alert>
                      </td>
                    </tr>
                  ) : null}
                  {warehouseTrucks && warehouseTrucks.length
                    ? warehouseTrucks.map(
                        (datum: IWarehouseTruck, index: number) => (
                          <WarehouseItems
                            key={datum.id}
                            srNo={index + 1}
                            warehouseTruck={datum}
                            onSelect={() => {
                              setWarehouseTruck(datum);
                              onOpen();
                            }}
                          />
                        )
                      )
                    : null}
                </tbody>
              </table>
            </Box>
          ) : null}
        </Box>
        {isOpen ? (
          <AddWarehouseTruck
            {...props}
            toggleView={() => {
              if (isOpen) {
                onClose();
              } else {
                onOpen();
              }
            }}
            retrieveWarehouseTruck={() => {
              _retrieveWarehouseTruck();
            }}
            warehouseTruck={warehouseTruck}
          />
        ) : null}
      </section>
    </Box>
  );
};

/* istanbul ignore next */
const mapStateToProps = (state: IAppState) => {
  const retrieveWarehouseTruckAPIStatus = getWarehouseTruckAPIStatus(state);
  const warehouseTrucks = getWarehouseTruckSelector(state);
  const loggedInUserDetails = getLoggedInUserDetails(state);
  const addEditWarehouseTruckAPIStatus = getAddEditWarehouseTruckAPIStatus(
    state
  );
  return {
    retrieveWarehouseTruckAPIStatus,
    addEditWarehouseTruckAPIStatus,
    user: loggedInUserDetails.user,
    warehouseTrucks
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  return bindActionCreators({ retrieveWarehouseTruck }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(withToastManager(WarehouseTruck)));
