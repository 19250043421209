/* istanbul ignore file */

import { Method } from 'axios';
import request from '../../../services/request.service';
import GlobalConfig from '../../../globalConfig';
import { IFormValues } from '../components/supportAndFeedbackForm.component';

interface IServiceProps {
  method: Method;
  headers: Record<string, any>;
  url: string;
  data?: Record<string, any>;
  responseType?: any;
}

export const submitSupportAndFeedbackFormParams = (
  data = {}
): IServiceProps => ({
  method: 'POST',
  headers: GlobalConfig.getHeaders(['JSON']),
  url: GlobalConfig.getApiUrlFromRoot(`mobile/feedback`),
  data
});
export class SupportAndFeedbackServices {
  static async submitSupportAndFeedbackForm(values: IFormValues): Promise<any> {
    const params = {
      name: values.fullName,
      message: values.comments,
      email: values.email,
      phone_no: `${values.phone}`,
      country_id: values.code,
      image: values.file,
      language: 'en' //getLocalstorage('lang')
    };
    const res = await request(submitSupportAndFeedbackFormParams(params));
    return res.data;
  }
}
