import { createSelector } from 'reselect';
import map from 'lodash/map';
import {
  getCurrentPageSubscriptions,
  getSelectedSubscription
} from '../reducers/ui';
import { getSubscriptionById, SubscriptionById } from '../reducers/entities';
import { getLocalstorage } from '../../../helper';

export const getSubscriptionSelector = createSelector(
  getCurrentPageSubscriptions,
  getSubscriptionById,
  (subscriptionId: string[], subscriptionById: SubscriptionById) => {
    if (!subscriptionId) {
      return [];
    }

    return map(subscriptionId, (datum) => {
      return subscriptionById[datum];
    });
  }
);

export const getSelectedSubscriptionSelector = createSelector(
  getSubscriptionById,
  getSelectedSubscription,
  (subscriptionById: SubscriptionById, subscriptionId: number | null) => {
    if (!subscriptionById) {
      return null;
    }
    const id = subscriptionId ?? getLocalstorage('subscriptionId');
    return subscriptionById[id];
  }
);
