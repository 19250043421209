/* istanbul ignore file */
import React, { FunctionComponent, useState, useEffect } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Box } from '@chakra-ui/core';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import EditIcon from '@material-ui/icons/Edit';
import { ThunkDispatch } from 'redux-thunk';
import { bindActionCreators } from 'redux';
import AnnouncementIcon from '@material-ui/icons/Announcement';

import MobileToggle from '../../assets/images/open-menu.svg';
import Notification from '../../assets/images/notification.png';
import WarehouseTruckHoverIcon from '../../assets/images/wherehouse-truck.png';
import DropIcon1 from '../../assets/images/drop-icon-1.png';
import DropIcon2 from '../../assets/images/drop-icon-2.png';
import DropIcon3 from '../../assets/images/drop-icon-3.png';
import DropIcon4 from '../../assets/images/drop-icon-4.png';
import DropIcon5 from '../../assets/images/drop-icon-5.png';
import ProfileIcon from '../../assets/images/profile.png';
import { ROUTES } from './../../constant';
import {
  getLocalstorage,
  changeLanguage,
  newNotificationCount,
  isAdmin
} from '../../helper';
import { getLoggedInUserDetails } from '../../modules/auth/selectors/auth.selectors';
import { logoutUser } from '../../modules/auth/actions/auth.action';
import { IAppState } from '../../store';
import { AuthRepository } from '../../modules/auth/repositories/auth.repository';
import { User, USER_ROLE } from '../models/user';
import ChangePasswordPopup from './ChangePassword.component';
import EditProfilePopup from './EditProfile.component';
import BroadcastMessage from './BroadcastMessage.component';
import { retrieveNotification } from '../actions';
import { getNotificationListSelector } from '../reducers/notification.reducer';
import {
  SelectLanguage,
  SelectLanguageMobile
} from '../../common/components/helpers/showSelectedLanguage.component';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  notificationLength: number;
  user: User;
  t: any;
  isShowMenu: boolean;
  setIsShowMenu: () => void;
  retrieveNotification: typeof retrieveNotification;
  logoutUser: typeof logoutUser;
}

export const Header: FunctionComponent<IProps> = (props: IProps) => {
  const {
    className,
    history,
    user,
    t,
    notificationLength,
    setIsShowMenu,
    retrieveNotification,
    logoutUser
  } = props;
  const lang = getLocalstorage('lang');

  const [isOpen, setIsOpen] = useState(false);
  const [language, setLanguage] = useState(lang);
  const [isOpenChangePasswordPopup, setIsOpenChangePasswordPopup] = useState(
    false
  );
  const [isOpenEditProfilePopup, setIsOpenEditProfilePopup] = useState(false);
  const [
    isOpenBroadcastMessagePopup,
    setIsOpenBroadcastMessagePopup
  ] = useState(false);
  const _retrieveNotification = async () => {
    try {
      await retrieveNotification();
    } catch (err) {
      console.log('_retrieveNotification err ', err);
    }
  };
  useEffect(() => {
    _retrieveNotification();
  }, []);

  const setUserLang = (newLang: string) => {
    setLanguage(newLang);
    changeLanguage(newLang);
  };

  const _logoutUser = async () => {
    try {
      const params = {
        device_token: AuthRepository.retrieveDeviceToken(),
        device_type: 'web'
      };
      await logoutUser(params);
      AuthRepository.clear();
      history.push(ROUTES.HOME);
      setIsOpen(false);
    } catch (err) {
      console.log(':: _logoutUser err ', err);
    }
  };

  return (
    <Box className={className}>
      <Box
        className="mb-toggle"
        onClick={() => {
          setIsShowMenu();
        }}
      >
        <img src={MobileToggle} alt="mobile" />
      </Box>
      <Box className="dbHeader">
        <Box className="d-flex align-items-center justify-content-between">
          <Box className="mb-hidden">
            <SelectLanguage
              selectedLanguage={language ?? lang}
              setLanguage={(lang: string) => {
                setUserLang(lang);
              }}
            />
          </Box>
          <Box className="mbtggle">
            <SelectLanguageMobile
              selectedLanguage={language ?? lang}
              setLanguage={(lang: string) => {
                setUserLang(lang);
              }}
            />
          </Box>
          <Box className="dbHright">
            <ul className="d-flex align-items-center">
              {user.role !== USER_ROLE.ADMIN ? (
                <li>
                  <a href={ROUTES.NOTIFICATION}>
                    <img
                      src={Notification}
                      alt="notification"
                      className="notF"
                    />
                    <span className="badge">
                      {newNotificationCount(notificationLength)}
                    </span>
                  </a>
                </li>
              ) : null}
              {isAdmin(user.role) ? (
                <li>
                  <a
                    href="#"
                    title="broadcast message"
                    onClick={() => {
                      setIsOpenBroadcastMessagePopup(true);
                    }}
                  >
                    <i className="header-dropdown-list-icon">
                      <AnnouncementIcon />
                    </i>
                  </a>
                </li>
              ) : null}
              <li className="_profile">
                <a
                  href="#"
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                >
                  <span>{user?.name ?? ''}</span>
                  <i className="irImage">
                    <img src={user?.file_name ?? ProfileIcon} alt="Icon" />
                  </i>
                </a>
                {isOpen ? (
                  <Box className="dbHdrop">
                    <ul>
                      <li>
                        <a
                          href="#"
                          onClick={() => {
                            setIsOpen(false);
                            setIsOpenEditProfilePopup(true);
                          }}
                        >
                          <i className="header-dropdown-list-icon">
                            <EditIcon fontSize="small" />
                          </i>
                          <span>{t('DASHBOARD_EDIT_PROFILE')}</span>
                        </a>
                      </li>
                      {!user.role ||
                      (user.role !== USER_ROLE.OPERATOR &&
                        user.role !== USER_ROLE.ADMIN) ? (
                        <li>
                          <a
                            href="#"
                            onClick={() => {
                              history.push(
                                ROUTES.WAREHOUSE_AND_TRUCK_REGISTRATION
                              );
                              setIsOpen(false);
                            }}
                          >
                            <i className="header-dropdown-list-icon">
                              <img
                                src={WarehouseTruckHoverIcon}
                                alt="warehouse"
                              />
                            </i>
                            <span>{t('WAREHOUSE_AND_TRUCK_REGISTRATION')}</span>
                          </a>
                        </li>
                      ) : null}
                      <li>
                        <a
                          href="#"
                          onClick={() => {
                            setIsOpen(false);
                            setIsOpenChangePasswordPopup(true);
                          }}
                        >
                          <i className="header-dropdown-list-icon">
                            <VpnKeyIcon fontSize="small" />
                          </i>
                          <span>{t('DASHBOARD_CHANGE_PASSWORD')}</span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          onClick={() => {
                            history.push(ROUTES.SUPPORT_FEEDBACK);
                            setIsOpen(false);
                          }}
                        >
                          <i>
                            <img src={DropIcon1} alt="drop down" />
                          </i>
                          <span>{t('SUPPORT_AND_FEEDBACK')}</span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          onClick={() => {
                            history.push(ROUTES.ABOUT_US);
                            setIsOpen(false);
                          }}
                        >
                          <i>
                            <img src={DropIcon2} alt="drop down" />
                          </i>
                          <span>{t('HOME_PAGE_ABOUT_US')}</span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          onClick={() => {
                            history.push(ROUTES.LEGALS);
                            setIsOpen(false);
                          }}
                        >
                          <i>
                            <img src={DropIcon3} alt="drop down" />
                          </i>
                          <span>{t('HOME_PAGE_LEGAL')}</span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          onClick={() => {
                            history.push(ROUTES.TERMS_AND_CONDITIONS);
                            setIsOpen(false);
                          }}
                        >
                          <i>
                            <img src={DropIcon4} alt="drop down" />
                          </i>
                          <span>{t('HOME_PAGE_TERMS_AND_POLICY')}</span>
                        </a>
                      </li>
                      {!user.role ||
                      (user.role !== USER_ROLE.OPERATOR &&
                        user.role !== USER_ROLE.ADMIN) ? (
                        <li>
                          <a
                            href="#"
                            onClick={() => {
                              history.push(ROUTES.UPGRADE_SUBSCRIPTION_LIST);
                              setIsOpen(false);
                            }}
                          >
                            <i>
                              <img src={DropIcon4} alt="drop down" />
                            </i>
                            <span>{t('UPGRADE_SUBSCRIPTION')}</span>
                          </a>
                        </li>
                      ) : null}
                      <li>
                        <a
                          href="#"
                          onClick={() => {
                            _logoutUser();
                          }}
                        >
                          <i>
                            <img src={DropIcon5} alt="drop down" />
                          </i>
                          <span>{t('LOGOUT')}</span>
                        </a>
                      </li>
                    </ul>
                  </Box>
                ) : null}
              </li>
            </ul>
          </Box>
        </Box>
      </Box>
      {isOpenChangePasswordPopup ? (
        <ChangePasswordPopup
          {...props}
          toggleView={() => {
            setIsOpenChangePasswordPopup(false);
          }}
        />
      ) : null}
      {isOpenEditProfilePopup ? (
        <EditProfilePopup
          {...props}
          toggleView={() => {
            setIsOpenEditProfilePopup(false);
          }}
        />
      ) : null}
      {isOpenBroadcastMessagePopup ? (
        <BroadcastMessage
          {...props}
          toggleView={() => {
            setIsOpenBroadcastMessagePopup(false);
          }}
        />
      ) : null}
    </Box>
  );
};

const mapStateToProps = (state: IAppState) => {
  const loggedInUserDetails = getLoggedInUserDetails(state);
  const notificationLength = getNotificationListSelector(state).length;
  return {
    user: loggedInUserDetails.user,
    notificationLength
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  return bindActionCreators(
    {
      retrieveNotification,
      logoutUser
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces()(withToastManager(withRouter(Header))));
