import { Reducer, AnyAction } from 'redux';
import { User } from '../../../common/models/user';
import {
  LOGIN__ERROR,
  LOGIN__SUCCESS,
  LOGOUT_SUCCESS,
  GENERATE_OTP__SUCCESS,
  GENERATE_OTP__ERROR,
  GENERATE_OTP__LOADING,
  VERIFY_OTP__LOADING,
  VERIFY_OTP__SUCCESS,
  VERIFY_OTP__ERROR
} from '../actions/auth.action';
import { AuthStatus } from '../models/auth-status';
import { EDIT_PROFILE__SUCCESS } from '../../../common/actions';
import { STATUS } from '../../../constant';
import { IAppState } from '../../../store';

export interface IAuthState {
  status: AuthStatus;
  token: null | string;
  user: null | User;
  generateOTPAPIStatus: STATUS;
  verifyOTPAPIStatus: STATUS;
}

const defaultState: IAuthState = {
  status: AuthStatus.UNKNOWN,
  token: null,
  user: null,
  generateOTPAPIStatus: STATUS.NOT_STARTED,
  verifyOTPAPIStatus: STATUS.NOT_STARTED
};

export const authReducer: Reducer<IAuthState> = (
  state: IAuthState = defaultState,
  action: AnyAction
): IAuthState => {
  switch (action.type) {
    case LOGIN__SUCCESS: {
      const { token, user } = action.payload;
      return {
        ...state,
        token,
        user,
        status: AuthStatus.AUTHENTICATED
      };
    }
    case EDIT_PROFILE__SUCCESS: {
      const { data } = action.payload;
      const { user } = state;
      if (user) {
        user.name = data.name;
        user.phone_no = `${data.phone}`;
        user.country_id = data.code;
        if (data.newImage) {
          user.file_name = data.newImage;
        }
      }
      return {
        ...state,
        user
      };
    }
    case LOGIN__ERROR:
      return {
        ...state,
        status: AuthStatus.FAILED
      };

    case LOGOUT_SUCCESS:
      return {
        ...defaultState,
        status: AuthStatus.UNAUTHENTICATED
      };

    case GENERATE_OTP__LOADING:
      return {
        ...state,
        generateOTPAPIStatus: STATUS.LOADING
      };

    case GENERATE_OTP__SUCCESS:
      return {
        ...state,
        generateOTPAPIStatus: STATUS.SUCCESS
      };

    case GENERATE_OTP__ERROR:
      return {
        ...state,
        generateOTPAPIStatus: STATUS.FAILURE
      };

    case VERIFY_OTP__LOADING:
      return {
        ...state,
        verifyOTPAPIStatus: STATUS.LOADING
      };

    case VERIFY_OTP__SUCCESS:
      return {
        ...state,
        verifyOTPAPIStatus: STATUS.SUCCESS
      };

    case VERIFY_OTP__ERROR:
      return {
        ...state,
        verifyOTPAPIStatus: STATUS.FAILURE
      };

    default:
      return state;
  }
};

export const getGenerateOTPAPIStatus = (state: IAppState) => {
  return state.auth.generateOTPAPIStatus;
};

export const getVerifyOTPAPIStatus = (state: IAppState) => {
  return state.auth.verifyOTPAPIStatus;
};
