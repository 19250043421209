import React, { FunctionComponent } from 'react';
import { Box } from '@chakra-ui/core';

export interface IProps {
  class_division: string;
}

export const EPGLinks: FunctionComponent<IProps> = ({ class_division }) => {
  const getEPGLink = () => {
    switch (class_division) {
      case '2': {
        return (
          <Box className="row">
            <a
              href="https://admin.pdgapp.com.au/pdf/EPG_CLASS_2_FLAMMABLE_GAS.pdf"
              target="_blank"
              className="col-12"
              rel="noopener noreferrer"
            >
              EPG Class 2 Flammable Compressed Gas
            </a>
            <a
              href="https://admin.pdgapp.com.au/pdf/EPG_CLASS_2.2_NON_FLAMMABL_%20GAS.pdf"
              target="_blank"
              className="col-12"
              rel="noopener noreferrer"
            >
              EPG Class 2.2 Non-flammable, compressed gas
            </a>
          </Box>
        );
      }
      case '3':
      case '3A':
      case '3B': {
        return (
          <Box className="row">
            <a
              href="https://admin.pdgapp.com.au/pdf/EPG_CLASS_3_FLAMMABLE_LIQUID.pdf"
              target="_blank"
              className="col-12"
              rel="noopener noreferrer"
            >
              EPG Class 3 Flammable Liquids
            </a>
          </Box>
        );
      }
      case '5': {
        return (
          <Box className="row">
            <a
              href="https://admin.pdgapp.com.au/pdf/EPG_CLASS_5.1_OXIDISING_AGENT.pdf"
              target="_blank"
              className="col-12"
              rel="noopener noreferrer"
            >
              EPG Class 5.1 Oxidizing Agents
            </a>
          </Box>
        );
      }
      case '8': {
        return (
          <Box className="row">
            <a
              href="https://admin.pdgapp.com.au/pdf/EPG_Class_8.pdf"
              target="_blank"
              className="col-12"
              rel="noopener noreferrer"
            >
              EPG Class 8 Corrosive
            </a>
          </Box>
        );
      }
      default: {
        return null;
      }
    }
  };
  return <Box>{getEPGLink()}</Box>;
};
