/* istanbul ignore file */
import React, { FunctionComponent, useState, useEffect } from 'react';
import { withToastManager } from 'react-toast-notifications';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';

import HomePageHeader from '../components/HomePage-header.components';
import HomePageFeature from '../components/HomePage-feature.components';
import HomePageFooter from '../components/HomePage-footer.components';
import Login from '../../auth/pages/Login.page';
import ForgotPassword from '../../forgotPassword/pages/ForgotPassword.page';
import UpdatePassword from '../../updatePassword/pages/UpdatePassword.page';
import { getLoggedInUserDetails } from '../../auth/selectors/auth.selectors';
import { IAppState } from '../../../store';
import { AuthStatus } from '../../auth/models/auth-status';
import { ROUTES } from '../../../constant';
import { removeItemFromLocalStorage } from '../../../helper';
// import './style.css';

interface IOwnProps extends RouteComponentProps {
  className: string;
}

export interface IProps extends IOwnProps {
  toastManager: any;
  status: AuthStatus;
  t: any;
}

export const HomePage: FunctionComponent<IProps> = (props: IProps) => {
  const { className, status, history } = props;
  const [isOpen, setIsOpen] = useState<number | null>(null);
  useEffect(() => {
    if (status === AuthStatus.AUTHENTICATED) {
      history.push(ROUTES.MANIFEST);
    }
  }, [status]);
  useEffect(() => {
    removeItemFromLocalStorage('user_id');
    removeItemFromLocalStorage('email');
    removeItemFromLocalStorage('subscriptionId');
    window.onpopstate = () => {
      history.go(1);
    };
  }, []);
  return (
    <div className={className}>
      <HomePageHeader
        {...props}
        toggleView={(modalNum: number) => {
          setIsOpen(modalNum);
        }}
      />
      <HomePageFeature {...props} />
      <HomePageFooter {...props} />
      {isOpen === 1 ? (
        <Login
          {...props}
          toggleView={(modalNum: number | null) => {
            setIsOpen(modalNum);
          }}
        />
      ) : null}
      {isOpen === 2 ? (
        <ForgotPassword
          {...props}
          toggleView={(modalNum: number | null) => {
            setIsOpen(modalNum);
          }}
        />
      ) : null}
      {isOpen === 3 ? (
        <UpdatePassword
          {...props}
          toggleView={(modalNum: number | null) => {
            setIsOpen(modalNum);
          }}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state: IAppState) => {
  const loggedInUserDetails = getLoggedInUserDetails(state);

  return {
    status: loggedInUserDetails.status
  };
};

export default connect(
  mapStateToProps,
  null
)(withNamespaces()(withToastManager(withRouter(HomePage))));
