/* istanbul ignore file */

import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import classNames from 'classnames';

import { Input as CommonInput, Label } from '../components/forms';

interface IProps {
  className?: string;
  errorMessage: string;
  error: boolean;
  success: boolean;
  label: string;
}

const InputComponent: FunctionComponent<any> = ({
  className,
  errorMessage,
  error,
  label,
  ...rest
}: IProps) => {
  return (
    <div className={className}>
      {label ? <Label>{label}</Label> : null}
      <CommonInput
        className={classNames({
          'has-error': error
        })}
        {...rest}
      />
      <div className="error invalid-feedback">{error ? errorMessage : ''}</div>
    </div>
  );
};

InputComponent.defaultProps = {
  className: '',
  errorMessage: '',
  error: false,
  success: false,
  label: ''
};

/* istanbul ignore next */
export const Input = styled(InputComponent)``;

Input.displayName = 'Input';
