/* istanbul ignore file */

import React, { FunctionComponent } from 'react';
import styled from '@emotion/styled';
import { Box } from '@chakra-ui/core';

import { PrimaryButton } from './Common.component';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody
} from './containers/modal.component';

const ModalContentContainer = styled(Box)`
  .description {
    text-align: center;
    margin: 20px 0;
  }
`;

interface IProps {
  isOpen: boolean;
  showModalCloseButton: boolean;
  closeModal: () => void;
  title: string;
  description: string;
  callBack: () => void;
  buttonText: string;
}

export const InfoModal: FunctionComponent<IProps> = ({
  isOpen,
  closeModal,
  title,
  description,
  showModalCloseButton,
  callBack,
  buttonText
}) => {
  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        {showModalCloseButton ? <ModalCloseButton /> : null}

        <ModalBody>
          <ModalContentContainer>
            <p className="description">{description}</p>
            <PrimaryButton isFullWidth id="uploadImage" onClick={callBack}>
              {buttonText}
            </PrimaryButton>
          </ModalContentContainer>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

InfoModal.defaultProps = {
  isOpen: false,
  showModalCloseButton: true,
  closeModal: () => {},
  title: '',
  description: '',
  callBack: () => {},
  buttonText: ''
};
