export interface ISubDivision {
  division: string;
  name: string;
}
export interface IClass {
  class: number;
  classDivision: string;
  name: string;
  description: string[];
  reason: string;
  subDivision: ISubDivision[];
  commonlyTransportedGas: string;
}
export const classList: IClass[] = [
  {
    class: 1,
    classDivision: '1',
    name: 'Class 1 Explosive substances and articles',
    description: [
      'Class 1 contains substances and articles which pose a hazard due to explosion. Items in Class 1 are further divided into divisions 1.1 - 1.6 depending on the nature of the explosion hazard and the sensitivity of the item. A compatibility group, consisting of a single letter, is also assigned to each item. The compatibility group letter indicates which items can be transported together.',
      'Explosives are materials or items which have the ability to rapidly conflagrate or detonate as a consequence of chemical reaction.',
      'DGI are proficient in handling explosives, Class 1 Dangerous Goods. DGI have the ability to service all customer requests pertaining to the logistics of explosives; packing, packaging, compliance, freight forwarding and training.'
    ],
    reason:
      '​Explosives are capable by chemical reaction of producing gases at temperatures, pressures and speeds as to cause catastrophic damage through force and/or of producing otherwise hazardous amounts of heat, light, sound, gas or smoke',
    subDivision: [
      {
        division: 'Division 1.1',
        name: 'Substances and articles which have a mass explosion hazard'
      },
      {
        division: 'Division 1.2',
        name:
          'Substances and articles which have a projection hazard but not a mass explosion hazard'
      },
      {
        division: 'Division 1.3',
        name:
          'Substances and articles which have a fire hazard and either a minor blast hazard or a minor projection hazard or both'
      },
      {
        division: 'Division 1.4',
        name:
          'Substances and articles which present no significant hazard; only a small hazard in the event of ignition or initiation during transport with any effects largely confined to the package'
      },
      {
        division: 'Division 1.5',
        name: 'Very insensitive substances which have a mass explosion hazard'
      },
      {
        division: 'Division 1.6',
        name:
          'Extremely insensitive articles which do not have a mass explosion hazard'
      }
    ],
    commonlyTransportedGas:
      'Ammunition/cartridges, Fireworks/pyrotechnics, Flares, Blasting caps / detonators, Fuse, Primers, Explosive charges (blasting, demolition etc), Detonating cord, Air bag inflators, Igniters, Rockets, TNT / TNT compositions, RDX / RDX compositions, PETN / PETN compositions.'
  },
  {
    class: 2,
    classDivision: '2.1A',
    name: 'Class 2 Gases',
    description: [
      'Gases are defined by dangerous goods regulations as substances which have a vapour pressure of 300 kPa or greater at 50°c or which are completely gaseous at 20°c at standard atmospheric pressure, and items containing these substances. The class encompasses compressed gases, liquefied gases, dissolved gases, refrigerated liquefied gases, mixtures of one or more gases with one or more vapours of substances of other classes, articles charged with a gas and aerosols.',
      'DGI are proficient in handling gases, Class 2 Dangerous Goods. DGI have the ability to service all customer requests pertaining to the logistics of gases; packing, packaging, compliance, freight forwarding and training.'
    ],
    reason:
      'Gases are capable of posing serious hazards due to their flammability, potential as asphyxiants, ability to oxidize and/or their toxicity or corrosiveness to humans.',
    subDivision: [
      {
        division: 'Division 2.1',
        name: 'Flammable gases'
      },
      {
        division: 'Division 2.2',
        name: 'Non-flammable, non-toxic gases'
      },
      {
        division: 'Division 2.3',
        name: 'Toxic gases'
      }
    ],
    commonlyTransportedGas:
      'Aerosols, Compressed air, Hydrocarbon gas-powered devices, Fire extinguishers, Gas cartridges, Fertilizer ammoniating solution, Insecticide gases, Refrigerant gases, Lighters, Acetylene / Oxyacetylene, Carbon dioxide, Helium / helium compounds, Hydrogen / hydrogen compounds, Oxygen / oxygen compounds, Nitrogen / nitrogen compounds, Natural gas, Oil gas, Petroleum gases, Butane, Propane, Ethane, Methane, Dimethyl ether, Propene / propylene, Ethylene.'
  },
  {
    class: 3,
    classDivision: '3A',
    name: 'Class 3 - Flammable Liquids',
    description: [
      'Flammable liquids are defined by dangerous goods regulations as liquids, mixtures of liquids or liquids containing solids in solution or suspension which give off a flammable vapour (have a flash point) at temperatures of not more than 60-65°C, liquids offered for transport at temperatures at or above their flash point or substances transported at elevated temperatures in a liquid state and which give off a flammable vapour at a temperature at or below the maximum transport temperature.​',
      'DGI are proficient in handling flammable liquids, Class 3 Dangerous Goods. DGI have the ability to service all customer requests pertaining to the logistics of flammable liquids; packing, packaging, compliance, freight forwarding and training.'
    ],
    reason:
      'Flammable liquids are capable of posing serious hazards due to their volatility, combustibility and potential in causing or propagating severe conflagrations.​',
    subDivision: [],
    commonlyTransportedGas:
      'Acetone / acetone oils, Adhesives, Paints / lacquers / varnishes, Alcohols, Perfumery products, Gasoline / Petrol, Diesel fuel, Aviation fuel, Liquid bio-fuels, Coal tar / coal tar distillates, Petroleum crude oil,Petroleum distillates, Gas oil, Shale oil, Heating oil, Kerosene, Resins, Tars, Turpentine, Carbamate insecticides, Organochlorine pesticides,Organophosphorus pesticides, Copper based pesticides, Esters, Ethers, Ethanol, Benzene, Butanols, Dichloropropenes, Diethyl ether, Isobutanols, Isopropyls, Methanol, Octanes.'
  },
  {
    class: 4,
    classDivision: '4.1',
    name: 'Class 4 – Flammable Solids',
    description: [
      'Flammable solids are materials which, under conditions encountered in transport, are readily combustible or may cause or contribute to fire through friction, self-reactive substances which are liable to undergo a strongly exothermic reaction or solid desensitized explosives. Also included are substances which are liable to spontaneous heating under normal transport conditions, or to heating up in contact with air, and are consequently liable to catch fire and substances which emit flammable gases or become spontaneously flammable when in contact with water.',
      'DGI are proficient in handling flammable solids, Class 4 Dangerous Goods. DGI have the ability to service all customer requests pertaining to the logistics of flammable solids; packing, packaging, compliance, freight forwarding and training.'
    ],
    reason:
      '​Flammable solids are capable of posing serious hazards due to their volatility, combustibility and potential in causing or propagating severe conflagrations.',
    subDivision: [
      {
        division: 'Division 4.1',
        name: 'Flammable solids'
      },
      {
        division: 'Division 4.2',
        name: 'Substances liable to spontaneous combustion'
      },
      {
        division: 'Division 4.3',
        name: 'Substances which, in contact with water, emit flammable gases'
      }
    ],
    commonlyTransportedGas:
      'Alkali metals, Metal powders, Aluminium phosphide,Sodium batteries, Sodium cells, Firelighters, Matches, Calcium carbide, Camphor, Carbon, Activated carbon, Celluloid, Cerium, Copra, Seed cake, Oily cotton waste, Desensitized explosives, Oily fabrics, Oily fibres, Ferrocerium, Iron oxide (spent, Iron sponge/direct-reduced iron (spent) , Metaldehyde, Naphthalene, Nitrocellulose, Phosphorus, Sulphur.'
  },
  {
    class: 5,
    classDivision: '5.1',
    name: 'Class 5 - Oxidizing substances and organic peroxides',
    description: [
      'Oxidizers are defined by dangerous goods regulations as substances which may cause or contribute to combustion, generally by yielding oxygen as a result of a redox chemical reaction. Organic peroxides are substances which may be considered derivatives of hydrogen peroxide where one or both hydrogen atoms of the chemical structure have been replaced by organic radicals.',
      'DGI are proficient in handling oxidising agents and organic peroxides, Class 5 Dangerous Goods. DGI have the ability to service all customer requests pertaining to the logistics of oxidising agents and organic peroxides; packing, packaging, compliance, freight forwarding and training.'
    ],
    reason:
      '​Oxidizers, although not necessarily combustible in themselves, can yield oxygen and in so doing cause or contribute to the combustion of other materials. Organic peroxides are thermally unstable and may exude heat whilst undergoing exothermic autocatalytic decomposition. Additionally, organic peroxides may be liable to explosive decomposition, burn rapidly, be sensitive to impact or friction, react dangerously with other substances or cause damage to eyes.',
    subDivision: [
      {
        division: 'Division 5.1',
        name: 'Oxidizing substances'
      },
      {
        division: 'Division 5.2',
        name: 'Organic peroxides'
      }
    ],
    commonlyTransportedGas:
      'Chemical oxygen generators, Ammonium nitrate fertilizers, Chlorates, Nitrates, Nitrites, Perchlorates, Permanganates, Persulphates, Aluminium nitrate, Ammonium dichromate, Ammonium nitrate, Ammonium persulphate, Calcium hypochlorite, Calcium nitrate, Calcium peroxide, Hydrogen peroxide, Magnesium peroxide, Lead nitrate, Lithium hypochlorite, Potassium chlorate, Potassium nitrate, Potassium chlorate, Potassium perchlorate, Potassium permanganate, Sodium nitrate, Sodium persulphate.'
  },
  {
    class: 6,
    classDivision: '6.1',
    name: 'Class 6 - Toxic & Infectious Substances',
    description: [
      'Toxic substances are those which are liable either to cause death or serious injury or to harm human health if swallowed, inhaled or by skin contact. Infectious substances are those which are known or can be reasonably expected to contain pathogens. Dangerous goods regulations define pathogens as microorganisms, such as bacteria, viruses, rickettsiae, parasites and fungi, or other agents which can cause disease in humans or animals.',
      'DGI are proficient in handling toxic and infectious substances, Class 6 Dangerous Goods. DGI have the ability to service all customer requests pertaining to the logistics of oxidising agents and organic peroxides; packing, packaging, compliance, freight forwarding and training.'
    ],
    reason:
      'Toxic and infectious substances can pose significant risks to human and animal health upon contact.',
    subDivision: [
      {
        division: 'Division 6.1',
        name: 'Toxic substances'
      },
      {
        division: 'Division 6.2',
        name: 'Infectious substances'
      }
    ],
    commonlyTransportedGas:
      'Medical/Biomedical waste, Clinical waste, Biological cultures / samples / specimens, Medical cultures / samples / specimens, Tear gas substances, Motor fuel anti-knock mixture, Dyes, Carbamate pesticides, Alkaloids, Allyls, Acids, Arsenates, Arsenites, Cyanides, Thiols/mercaptans, Cresols, Barium compounds, Arsenics / arsenic compounds, Beryllium/ beryllium compounds, Lead compounds, Mercury compounds, Nicotine / nicotine compounds, Selenium compounds, Antimony, Ammonium metavanadate, Adiponitrile, Chloroform, Dichloromethane, Hexachlorophene, Phenol, Resorcinol.'
  },
  {
    class: 7,
    classDivision: '7A',
    name: 'Class 7 - Radioactive Material',
    description: [
      'Dangerous goods regulations define radioactive material as any material containing radionuclides where both the activity concentration and the total activity exceeds certain pre-defined values. A radionuclide is an atom with an unstable nucleus and which consequently is subject to radioactive decay.',
      'DGI are proficient in handling radioactive material, Class 7 Dangerous Goods. DGI have the ability to service all customer requests pertaining to the logistics of radioactive material; packing, packaging, compliance, freight forwarding and training.'
    ],
    reason:
      'Whilst undergoing radioactive decay radionuclides emit ionizing radiation, which presents potentially severe risks to human health.​',
    subDivision: [],
    commonlyTransportedGas:
      'Radioactive ores, Medical isotopes, Yellowcake, Density gauges, Mixed fission products, Surface contaminated objects, Caesium radionuclides / isotopes, Iridium radionuclides / isotopes, Americium radionuclides / isotopes, Plutonium radionuclides / isotopes, Radium radionuclides / isotopes, Thorium radionuclides / isotopes, Uranium radionuclides / isotopes, Depleted uranium / depleted uranium products, Uranium hexafluoride, Enriched Uranium.'
  },
  {
    class: 8,
    classDivision: '8',
    name: 'Class 8 – Corrosives Substances',
    description: [
      'Corrosives are substances which by chemical action degrade or disintegrate other materials upon contact.',
      'DGI are proficient in handling corrosives, Class 8 Dangerous Goods. DGI have the ability to service all customer requests pertaining to the logistics of corrosives; packing, packaging, compliance, freight forwarding and training.'
    ],
    reason:
      '​Corrosives cause severe damage when in contact with living tissue or, in the case of leakage, damage or destroy surrounding materials.',
    subDivision: [],
    commonlyTransportedGas:
      'Acids/acid solutions, Batteries, Battery fluid, Fuel cell cartridges, Dyes, Fire extinguisher charges, Formaldehyde, Flux, Paints, Alkylphenols, Amines, Polyamines, Sulphides, Polysulphides, Chlorides, Chlorosilanes, Bromine, Cyclohexylamine, Phenol / carbolic acid, Hydrofluoric acid, Hydrochloric acid, Sulfuric acid, Nitric acid, Sludge acid, Hydrogen fluoride, Iodine, Morpholine.'
  },
  {
    class: 9,
    classDivision: '9',
    name: 'Class 9 - Miscellaneous Dangerous Goods',
    description: [
      'Miscellaneous dangerous goods are substances and articles which during transport present a danger or hazard not covered by other classes. This class encompasses, but is not limited to, environmentally hazardous substances, substances that are transported at elevated temperatures, miscellaneous articles and substances, genetically modified organisms and micro-organisms and (depending on the method of transport) magnetized materials and aviation regulated substances.​',
      'DGI are proficient in handling miscellaneous dangerous goods, Class 9 Dangerous Goods. DGI have the ability to service all customer requests pertaining to the logistics of miscellaneous dangerous goods; packing, packaging, compliance, freight forwarding and training.​'
    ],
    reason:
      'Miscellaneous dangerous goods present a wide array of potential hazards to human health and safety, infrastructure and/ or their means of transport.​',
    subDivision: [],
    commonlyTransportedGas:
      'Dry ice / cardice / solid carbon dioxide, Expandable polymeric beads / polystyrene beads, Ammonium nitrate fertilizers, Blue asbestos / crocidolite, Lithium ion batteries, Lithium metal batteries, Battery powered equipment, Battery powered vehicles, Fuel cell engines, Internal combustion engines, Vehicles, Magnetized material, Dangerous goods in apparatus, Dangerous goods in machinery, Genetically modified organisms, Genetically modified micro-organisms, Chemical kits, First aid kits, Life saving appliances, Air bag modules, Seatbelt pretensioners, Plastics moulding compound, Castor bean plant products, Polychlorinated biphenyls, Polychlorinated terphenyls, Dibromodifluoromethane, Benzaldehyde.'
  }
];
